import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const useBetaFeature = () => {
  const beta = localStorage.getItem("beta") === "true" ? true : false;
  const history = useHistory();

  const [isChecked, setIsChecked] = useState(beta);

  const handleFeatureToggle = () => {
    setIsChecked((prev) => {
      localStorage.setItem("beta", !prev);
      return !prev;
    });
    history.go("/calendar");
  };

  return {
    isChecked,
    handleFeatureToggle,
  };
};
