import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { addListSchema } from "../../utils/validationsSchemas";
import uuid from "uuid";
import moment from "moment";
import { getBasicInfo, getOrganization } from "../../utils";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import DatePicker from "react-datepicker";
import { CustomInput } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import api, { apiService } from "../../common/api";
import { DropDownList } from "../common";
import {injectIntl, FormattedMessage} from 'react-intl'


import * as actions from "../../page/event/redux/actions";
import { Typeahead } from "react-bootstrap-typeahead";
import ReactQuill from 'react-quill';
//Generate random ID
function makeId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

class AddMeetingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationInput: false,
      visibilityModal: false,
      id: makeId(6),
      places: [],
      visibleStaffs: [],
      emails: [],
      options: [],
    };
    this.textInput = createRef();
  }

  componentDidMount() {
    this.setOptionsEmails();
  }

  toggleLocationInput = () => {
    this.setState({
      locationInput: !this.state.locationInput,
    });
  };

  toggleVisibility = () => {
    this.setState({
      visibilityModal: !this.state.visibilityModal,
    });
  };

  searchPlace = async (query) => {
    const airports = await api.get(`/Venues/Venues?Search=${query}`);
    this.setState({
      places: airports.data.Response,
    });
  };

  addMeeting = (values) => {
    const date = moment(values.Date).format("YYYY-MM-DD");
    const time = moment(values.Time).format("HH:mm");
    values.Date = (date + "T" + time);//.toDate();
    const meeting = {
      ...values,
      VenueName: null,
    };
    this.props.actions.addMeeting(
      meeting,
      this.state.emails,
      this.props.teams,
      () => {
        this.props.toggle();
      }
    );
  };

  editMeeting = async (meeting) => {
    const request = await apiService.post(
      `/Events/Edit`, meeting
    );

    if (request.data.Status === "ok") {
      
      this.props.actions.getEvents(meeting.OrganizationGUID, getBasicInfo().GUID);
    }

    this.props.toggle();
  }

  deleteMeeting = async (meeting) => {
    const request = await api.post(
      `/Events/CancelMeeting`, meeting
    );

    if (request.data.Status === "ok") {
      debugger;
      this.props.actions.getEvents(meeting.OrganizationGUID, getBasicInfo().GUID);
    }

    this.props.toggle();
  }
  setOptionsEmails = () => {
    const emails = this.props.teams
      .map((item) =>
        item.Staffs.filter((item) => item.Account.Email !== null)
          .filter((item) => item.Account.Email.length > 2)
          .map((staff) => staff.Account.Email)
      )
      .flat();
    const uniqueEmails = emails.filter((c, i) => emails.indexOf(c) === i);
    this.setState({
      options: uniqueEmails,
    });
  };

  selectEmail = (selected) => {
    this.setState({
      emails: selected,
    });
  };

  render() {
    const { loading, onlyToRead } = this.props;

    return (
      <div
        className="event-page"
        style={{ overflowY: "visible" }}
        onClick={() => this.setState({ places: [] })}
      >
        <div className="event-expennd-section">
          <div className="event-scroll">
            <div className="text-head">
              <div className="text-left">
                <h5 className="title">{<FormattedMessage
                            id="addMeeting"
                            defaultMessage="Add Meeting"/>}</h5>
              </div>
            </div>
            <Formik
              onSubmit={(values) => onlyToRead ? this.editMeeting(values): this.addMeeting(values)}
              validationSchema={addListSchema}
              validateOnBlur={false}
              initialValues={
                onlyToRead
                  ? {
                    ...this.props.event,
                    Date: moment(this.props.event.Date).toDate(),
                    Time: moment(this.props.event.Date).toDate(),
                  }
                  : {
                    GUID: uuid.v4(),
                    Name: "",
                    Country: "",
                    EventType: "Meeting",
                    City: !this.state.locationInput
                      ? "https://web.plnify.com/#meet/" + this.state.id
                      : "",
                    State: "",
                    CreationDate: new Date(),
                    Date: moment().toDate(),
                    Time: moment().toDate(),
                    Notes: "",
                    Status: "Confirmed",
                    Longitude: "",
                    Latitude: "",
                    OrganizationGUID: getOrganization().GUID,
                    CreatedUserGUID: getBasicInfo().GUID,
                    Published: new Date(),
                    VenueName: "",
                    VenueGUID: "",
                  }
              }
            >
              {(props) => (
                <Form autoComplete="off" className="addEvent__form">
                  <div className="event-inside">
                    <div className="event-content">
                      <FormField fullWidth={true} label={<FormattedMessage
                            id="firstName.signUp"
                            defaultMessage="Name"/>}>
                        <Field
                          
                          autoFocus={true}
                          className="form-control Name"
                          type="text"
                          name="Name"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Name"
                          component="div"
                        />
                      </FormField>
                      {this.state.locationInput ? null : (
                        <div className="input-group">
                        <FormField fullWidth={true} label="URL">
                          <Field
                            
                            className="form-control "
                            type="text"
                            name="City"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            className="error-message"
                            name="City"
                            component="div"
                          />
                          {onlyToRead && props.values.City.includes("http") && (
                            <a
                              href={props.values.City}
                              target="_blank"
                              className="btn btn-primary btn-sm"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 5,
                              }}
                            >
                              <i className="fa fa-share"></i>
                            </a>
                          )}
                        </FormField>
                        </div>
                      )}

                      {!onlyToRead ? (
                        <div>
                          <a className="clean-link show-more text-left">
                            <CustomInput
                              checked={this.state.locationInput}
                              onChange={() => this.toggleLocationInput()}
                          //    defaultChecked={this.state.locationInput}
                              type="switch"
                              name="location-input"
                              id="locationInput"
                              label={
                                this.state.locationInput
                                  ? this.props.intl.formatMessage({id:"Hide Location"})
                                  : this.props.intl.formatMessage({id:"Show Location"})
                              }
                            />
                          </a>
                        </div>
                      ) : null}
                      {onlyToRead && <div>{this.props.VenueGUID}</div>}
                      {this.state.locationInput ? (
                        <div className="row">
                          <div className="col px-0">
                            <label>{<FormattedMessage
                            id="Location"
                            defaultMessage="Location"/>}</label>
                            <DebounceInput
                              minLength={5}
                              debounceTimeout={300}
                              type="text"
                              value={props.values.VenueName}
                              placeholder={this.props.intl.formatMessage({id:"Location"})}
                              className="form-control"
                              onChange={(event) => {
                                if (event.target.value.length < 3) {
                                  this.setState({ places: [] });
                                } else if (event.target.value.length > 3) {
                                  this.searchPlace(event.target.value);
                                }
                              }}
                            />
                            <DropDownList
                              searchList={this.state.places}
                              displayValue={"Name"}
                              setData={(i) => {
                                props.setFieldValue(
                                  "VenueGUID",
                                  this.state.places[i].GUID
                                );
                                props.setFieldValue(
                                  "VenueName",
                                  this.state.places[i].Name
                                );
                                props.setFieldValue(
                                  "Country",
                                  this.state.places[i].Country
                                );
                                props.setFieldValue(
                                  "City",
                                  this.state.places[i].City
                                );
                                props.setFieldValue(
                                  "State",
                                  this.state.places[i].State
                                );
                                this.setState({ places: [] });
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="row">
                        <div className="col px-0 pr-2  pt-2">
                          <div className="gray-border">
                            <label htmlFor={"checkinDate"}>{<FormattedMessage
                            id="date"
                            defaultMessage="Date"/>}</label>
                            <div>
                              <input type="date" className="form-control underline py-0"

                                name="Date"
                                value={moment(props.values.Date).format("YYYY-MM-DD")}
                                onChange={e => {

                                  props.setFieldValue("Date", e.target.value + "T" + moment(props.values.Date).format("HH:mm:ss"));
                                }}
                              />

                            </div>
                          </div>
                        </div>
                        <div className="col px-0 pl-2 pt-2">
                          <div className="gray-border">
                            <label htmlFor="checkinTime">{<FormattedMessage
    id="time"
    defaultMessage="Time"/>}</label>
                            <DatePicker
                              disabled={this.props.onlyToRead}
                              id={"Time"}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={props.values.Time}
                              onChange={(selected) =>
                                props.setFieldValue("Time", selected)
                              }
                            />
                            <ErrorMessage
                              className="error-message"
                              name="checkinTime"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="addTask__editor text-dark">
                        <label>{<FormattedMessage
    id="additionalNotes"
    defaultMessage="Additional Notes"/>}</label>
                      
                          <ReactQuill theme="snow"
                            value={props.values.Notes}
                            //onBlur={() => props.handleSubmit()}
                            onChange={content => {

                              console.log("content of the editor", content);

                              props.setFieldValue("Notes", content);
                            }}

                          />
                      </div>
                      <div
                        className="typeahead__modifier"
                        style={{ padding: "12px 0" }}
                      >
                        <label htmlFor="emailRecipients">{<FormattedMessage
    id="invitees"
    defaultMessage="Invitees"/>}</label>

                        <Typeahead
                          id="emailRecipients"
                          allowNew
                          placeholder={this.props.intl.formatMessage({id:"Select new member"})}
                          selected={this.state.emails}
                          newSelectionPrefix="Add a new member: "
                          multiple={true}
                          onChange={(selected) => {
                            this.selectEmail(selected);
                          }}
                          options={this.state.options}
                          dropup
                        />
                      </div>

                      <div className={`mt-2 ${onlyToRead ? "pb-3" : ""}`}>
                        <button
                          type="button"
                          className=" d-none btn btn-link btn-secondary btn-sm"
                          onClick={() => this.toggleVisibility()}
                        >{`${onlyToRead
                          ? [].length
                          : this.state.visibleStaffs.length
                          } users selected `}</button>
                      </div>
                     
                      {onlyToRead ?
                      <div className="row">
                        <div className="col-6 px-0 my-2">
                          <button className="btn btn-sm btn-danger " type="button" onClick={() => this.deleteMeeting(props.values)}>
                          {<FormattedMessage
    id="cancelMeeting"
    defaultMessage="Cancel Meeting"/>}
                          </button>
                        </div>
                        <div className="col-6 px-0 d-flex justify-content-between align-items-center my-2">
                          <button
                            disabled={!props.isValid}
                            type="submit"
                            className="btn btn-sm btn-primary ml-auto"
                          >{<FormattedMessage
                            id="done"
                            defaultMessage="Done"/>}
                          </button>
                        </div>
                        </div>
                        : (
                          <div className="col-12 px-0 d-flex justify-content-between align-items-center my-2">
                            <button
                              disabled={!props.isValid}
                              type="submit"
                              className="btn btn-sm btn-primary ml-auto"
                            >
                              {loading ? "Adding..." :<FormattedMessage
                    id="done"
                    defaultMessage="Done"/>}
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  teams: state.organizations.teams,
  loading: state.event.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddMeetingModal));
