import React, { useState } from "react";
import { getEventGroups } from "../utils/getEventGroups";
import { addOneYearToDate } from "../utils/addOneYearToDate";
import { filterEvents } from "../utils/filterEvents";

const useEventFilters = ({ events }) => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [eventFilterState, setEventFilterState] = useState({
    from: firstDayOfMonth,
    to: addOneYearToDate(firstDayOfMonth),
    group: null,
    search: null,
  });

  const eventGroups = events ? getEventGroups(events) : [];

  const handleFilterFrom = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        from: data,
      };
    });
  };

  const handleFilterTo = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        to: data,
      };
    });
  };

  const handleFilterByGroup = (e, item) => {
    const GUID = e.target.value;
    const [group] = eventGroups.filter((group) => group.GUID === GUID);
    setEventFilterState((prev) => {
      return {
        ...prev,
        group,
      };
    });
  };

  const filterCleanedEvents = filterEvents(events, eventFilterState);

  const eventToRender = filterCleanedEvents ? filterCleanedEvents : events;

  const handleDateChange = (e, direction) => {
    if (direction === "from") {
      handleFilterFrom(e.target.value);
    } else if (direction === "to") {
      handleFilterTo(e.target.value);
    }
  };

  const handleSearch = ({ target }) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        search: target.value,
      };
    });
  };

  return {
    eventGroups,
    eventToRender,
    eventFilterState,
    handleDateChange,
    handleFilterByGroup,
    handleFilterTo,
    handleFilterFrom,
    handleSearch,
  };
};

export default useEventFilters;
