import React, { useEffect, useState } from "react";
import { addOneYearToDate } from "../utils/addOneYearToDate";
import { ConsoleNetwork } from "mdi-material-ui";

export const useFilterModal = (sortedEvents) => {
  let localEvents = sortedEvents || [];
  const [search, setSearch] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCleanedEvents, setFilterCleanedEvents] = useState();
  const [renderFirst, setRenderFirst] = useState(false);

  const [isFilter, setIsFilter] = useState(false);
  const [eventFilterState, setEventFilterState] = useState({
    from: new Date(),
    to: addOneYearToDate(new Date()),
    groups: [],
    statusData: [],
    groups: [],
    groupsData: [],
  });

  const toggleFilterModal = () => {
    setShowFilterModal((prev) => !prev);
  };

  const handleFilterByGroup = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        groups: data,
        groupsData: data?.map((group) => group.id),
      };
    });
  };

  function handleUpdateFilters(data, reset) {
    const filterData = {
      from: eventFilterState.from || new Date(),
      to: eventFilterState.to || addOneYearToDate(new Date()),
      statusData: eventFilterState.statusData,
      groupsData: eventFilterState.groupsData || [],
    };

    if (
      reset ||
      (filterData?.from === "" &&
        filterData?.to === "" &&
        (filterData?.groupsData?.length === 0 || !filterData.groupsData) &&
        (filterData?.statusData?.length === 0 || !filterData.statusData))
    ) {
      const currentDate = new Date();
      const twoYears = 2 * 365 * 24 * 60 * 60 * 1000; // Two years in milliseconds
      const minDate = new Date(currentDate - twoYears);
      const maxDate = addOneYearToDate(new Date());
      setEventFilterState({
        from: minDate,
        to: maxDate,
        groups: [],
        groupsData: [],
        status: [],
        statusData: [],
      });
      setIsFilter(false);
      // toggleFilterModal();
    } else {
      setIsFilter(true);
      const filteredEvents = filterEvents(localEvents, filterData);
      setFilterCleanedEvents(filteredEvents);
    }
  }

  const handleFilterFrom = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        from: data,
      };
    });
  };

  const handleFilterTo = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        to: data,
      };
    });
  };

  const handleFilterByStatus = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        status: data,
        statusData: data,
      };
    });
  };

  const HandleEventFilters = () => {
    toggleFilterModal();
  };

  const eventGroupsAll = [];

  localEvents.forEach((event) => {
    if (
      !eventGroupsAll.includes(event?.EventGroup?.GUID) &&
      event?.EventGroup?.GUID
    ) {
      eventGroupsAll.push(event.EventGroup);
    }
  });

  const eventGroups = Array.from(
    eventGroupsAll.reduce((guidSet, obj) => {
      if (!guidSet.has(obj.GUID)) {
        guidSet.add(obj.GUID);
      }
      return guidSet;
    }, new Set()),
    (guid) => eventGroupsAll.find((obj) => obj.GUID === guid)
  );

  function filterEvents(localEvents, filterState) {
    const { from, to, statusData, groupsData } = filterState;

    const statusDataCleaned =
      statusData === null
        ? []
        : statusData?.map((data) => {
            const [name, value] = data.value;
            return value;
          });

    const minDate = new Date(); // Minimum possible date (January 1, 1970)
    const maxDate = new Date(8640000000000000); // Maximum possible date (December 31, 275760)

    // Use the default values for from and to if not provided
    const fromDate = from === "" ? minDate : new Date(from);
    const toDate = to === "" ? maxDate : new Date(to);

    const filteredEvents = localEvents.filter((event) => {
      if (
        from === "" &&
        to === "" &&
        statusDataCleaned.length === 0 &&
        groupsData.length === 0
      ) {
        return true;
      }

      const eventDate = new Date(event.Date);
      // Check if the event's date is within the specified range

      const isDateInRange = eventDate >= fromDate && eventDate <= toDate;

      // Filter by status if statusData is provided
      const isStatusIncluded =
        statusDataCleaned?.length === 0 ||
        statusDataCleaned.includes(event.Status);

      // Filter by groups if groupsData is not an empty array
      const isGroupIncluded = !groupsData
        ? true
        : groupsData?.length === 0 ||
          groupsData?.includes(event?.EventGroupGUID);

      // Combine conditions (e.g., both group and status conditions must match)

      return isDateInRange && isStatusIncluded && isGroupIncluded;
    });

    return filteredEvents;
  }

  useEffect(() => {
    //This is done to filter by current day + 365days.
    if (renderFirst || !sortedEvents) return;
    handleUpdateFilters([], false);
    if (localEvents?.length > 0) {
      setRenderFirst(true);
    }
  }, [sortedEvents]);

  const eventToRender = true ? filterCleanedEvents : localEvents;

  return {
    eventToRender,
    eventGroups,
    eventFilterState,
    showFilterModal,
    toggleFilterModal,
    handleUpdateFilters,
    handleFilterTo,
    handleFilterFrom,
    handleFilterByStatus,
    handleFilterByGroup,
    HandleEventFilters,
  };
};
