import React, { useState } from "react";
import { useContextMenu } from "react-contexify";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as actions from "../page/calendar/redux/actions";
import {
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../components";
import Swal from "sweetalert2";
import { getBasicInfo } from "../utils";
import { IMAGES } from "../constant";
import { sendEmail } from "../page/common/redux/sendEmail";

export const useCustomContextMenu = () => {
  const [event, setEvent] = useState("");
  const [exportType, setExportType] = useState("email");
  const intl = useIntl();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showMailModal: false,
    showMailModal: false,
  });

  const { show } = useContextMenu({
    id: event.GUID,
  });

  function handleShow(e, event) {
    setEvent(event);
    show(e, { props: { event } });
  }

  function handleEditEventStatus(Status) {
    try {
      dispatch(actions.editEvent({ ...event, Status }));
      ToastNotificationSuccess(
        Math.random(),
        "Event status updated successfully"
      );
    } catch (error) {
      console.log(error);
      ToastNotificationError(
        Math.random(),
        "Error while updating event status"
      );
    }
  }

  function handleExport() {
    if (state.showMailModal)
      return setState((prev) => {
        return { ...prev, showMailModal: !prev.showMailModal };
      });

    // Swal.fire({
    //   text: "How would you like to send this email?",
    //   imageUrl: IMAGES.LOGO_BLACK_P,
    //   imageWidth: 80,
    //   imageHeight: 80,
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "secondary",
    //   confirmButtonText: "Generate PDF",
    //   cancelButtonText: "As Body of Email",
    // }).then((result) => {
    //   if (result.value) {
    //     setExportType("export");
    //     setState((prev) => {
    //       return { ...prev, showMailModal: !prev.showMailModal };
    //     });
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    setExportType("email");
    setState((prev) => {
      return { ...prev, showMailModal: !prev.showMailModal };
    });
    //   }
    // });
  }

  function handleDeleteEvent() {
    const user = getBasicInfo();
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this event?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteEvent({ ...event, Deleted: new Date() }, () => {
            dispatch(actions.getEvents(event.OrganizationGUID, user.GUID));
            ToastNotificationSuccess(
              Math.random(),
              "Event deleted successfully"
            );
          })
        );
      }
    });
  }

  function toggleVisibilty() {
    setState((prev) => {
      return { ...prev, showVisibleBy: !prev.showVisibleBy };
    });
  }

  const ContextMenuOptions = [
    {
      id: "export",
      type: "item",
      title: "Export Event",
      actions: handleExport,
    },
    {
      id: "status",
      type: "submenu",
      label: "Change Status",
      options: [
        {
          id: "confirmed",
          type: "item",
          title: "Confirmed",
          actions: handleEditEventStatus,
        },
        {
          id: "postponed",
          type: "item",
          title: "Postponed",
          actions: handleEditEventStatus,
        },
        {
          id: "tentative",
          type: "item",
          title: "Tentative/Hold",
          actions: handleEditEventStatus,
        },
        {
          id: "cancelled",
          type: "item",
          title: "Cancelled",
          actions: handleEditEventStatus,
        },
      ],
    },
    {
      id: "visible",
      type: "item",
      title: "Visible By",
      actions: toggleVisibilty,
    },
    {
      id: "delete",
      type: "item",
      title: "Delete",
      destructive: true,
      actions: handleDeleteEvent,
    },
  ];

  return {
    ContextMenuState: state,
    contextMenuEvent: event,
    ContextMenuOptions,
    exportType,
    setExportType,
    handleExport,
    toggleVisibilty,
    sendEmail,
    handleShow: (e, event) => handleShow(e, event),
  };
};
