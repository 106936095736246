import React, { useEffect, useState } from "react";

import { IMAGES } from "../../constant";
import { useIntl, FormattedMessage } from "react-intl";
import { getOrganization, getBasicInfo } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastNotificationSuccess } from "../common";

const ShareInviteLink = (props) => {
  const [link, setLink] = useState(window.location);
  let theOrganization = getOrganization();
  let theUser = getBasicInfo();
  useEffect(() => {
    let invite = theOrganization.GUID + "|" + theUser.GUID;
    setLink(
      `${window.location.protocol}//${window.location.host}/join/${btoa(
        invite
      )}`
    );
  }, []);

  const handleLinkCopy = () => {
    navigator.clipboard.writeText(link);
    ToastNotificationSuccess(1, `Link copied to clipboard`);
    props.toggle();
  };


  return (
    <div className={""}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left w-100">
              <div className="d-flex justify-content-between">
                <h1>
                  <FormattedMessage
                    id="InviteOrganizationLink"
                    defaultMessage="Invite Link"
                  />
                </h1>
                <button
                  type="button"
                  className="btn btn-sm btn-link ml-2"
                  onClick={() => props.toggle()}
                >
                  <FontAwesomeIcon icon={faTimes} color="black" />
                </button>
              </div>
            </div>
            <div
              className="d-none close-icon d-none"
              onClick={() => props.toggle()}
            >
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside px-1">
            <div className="event-content">
              <div className="row mx-0 ">
                <div className="col-12 ">
                  <label>Copy and send this link for a new user to join your workspace</label>
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control pb-1 pt-2"
                      value={link}
                      
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between align-items-center">
                  <small className="text-warning">
                    Any one with this link can join the organization as an
                    invitee
                  </small>
                  <button className="btn btn-primary my-1" onClick={handleLinkCopy}>Copy</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareInviteLink;
