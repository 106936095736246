import { ErrorMessage, Field, useFormik } from "formik";
import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";

import { ROUTES, IMAGES } from "../../../constant";
import { useHistory, useParams } from "react-router-dom";
import api, { apiService } from "../../../common/api";
import {
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../../../components";
import { Loading } from "../../../components/common/loading";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { CogSyncOutline, Navigation } from "mdi-material-ui";
import { getBasicInfo } from "../../../utils";

const Join = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState({});
  const { id } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const basicInfo = getBasicInfo();
  //Validation
  const validationSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().required("Required"),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email"), null], "Email must match"),
    password: yup.string().required("Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    AcceptTermsAndConditions: yup
      .boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const bindRemote = async () => {
    let parsed = atob(id);
    let query = `organizationGUID=${parsed.split("|")[0]}&userguid=${
      parsed.split("|")[1]
    }`;
    let request = await apiService.get("User/InvitationToJoin?" + query);
    if (request.status === 200) {
      setModel(request.data.response);
      checkAlreadyLogin(request.data.response);
    }
  };

  const checkAlreadyLogin = (model) => {
    if (getBasicInfo()) {
      localStorage.setItem("inviteModel", JSON.stringify(model));
      history.push(ROUTES.DASHBOARD);
    }
  };

  const handleSubmit = async (obj) => {
    setIsLoading(true);
    let parsed = atob(id);

    let query = {
      OrganizationGUID: parsed.split("|")[0],
      UserGUID: parsed.split("|")[1],
      FirstName: obj.firstName,
      LastName: obj.lastName,
      Password: obj.password,
      Email: obj.email,
    };

    try {
      let request = await apiService.post(
        "User/ProcessInvitationToJoin",
        query
      );
      Swal.fire("Success", "User created successfully", "success");
      setIsLoading(false);
      history.push("/");
    } catch (err) {
      if (err.response.data === "user_exist") {
        Swal.fire({
          text: intl.formatMessage({
            id: "This email is already in use",
          }),
          imageUrl: IMAGES.LOGO_BLACK_P,
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "rgb(123 120 120)",
          confirmButtonText: intl.formatMessage({ id: "Sign In" }),
          cancelButtonText: intl.formatMessage({ id: "Try again" }),
        }).then(async (result) => {
          if (result.value) {
            history.push("/");
          }
        });
        setIsLoading(false);
      } else {
        Swal.fire("Error", "There was an error, please try again", "error");
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    console.log(id);
    bindRemote();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      AcceptTermsAndConditions: false,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleAlreadyMember = () => {
    localStorage.setItem("inviteModel", JSON.stringify(model));
    history.push("/");
  };

  console.log({ id, idParsed: atob(id), basicInfo, model });
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div className="col-12 col-md-5 col-lg-4 mx-auto my-4">
        {isLoading ? (
          <Loading />
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <div className="plnify card shadow-sm shadow-lg rounded-lg py-3">
              <div className="card-header">
                <Row>
                  <Col className="col-auto ">
                    <img src={IMAGES.LOGO_GRADIENT} width={56} />
                  </Col>
                  <Col className="px-0">
                    {model.User ? (
                      <h1>{`${model.User.Name} ${model.User.LastName} invite you to join ${model.Organization.Name}'s workspace`}</h1>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Row>
                  <Col xs={12} md={6}>
                    <label className="mt-3">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={formik.values.firstName ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.firstName ? (
                      <div className="invalid text-sm">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    <label className="mt-3">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={formik.values.lastName ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.lastName ? (
                      <div className="invalid text-sm">
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12}>
                    <label className="mt-3">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formik.values.email ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email ? (
                      <div className="invalid text-sm">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </Col>

                  <Col xs={12}>
                    <label className="mt-3">Confirm Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="confirmEmail"
                      value={formik.values.confirmEmail ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.confirmEmail ? (
                      <div className="invalid text-sm">
                        {formik.errors.confirmEmail}
                      </div>
                    ) : null}
                  </Col>

                  <Col xs={12} md={6}>
                    <label className="mt-3">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={formik.values.password ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password ? (
                      <div className="invalid text-sm">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    <label className="mt-3">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      value={formik.values.confirmPassword ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.confirmPassword ? (
                      <div className="invalid text-sm">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    <label className="mt-3">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={formik.values.phone ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.phone ? (
                      <div className="invalid text-sm">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    <label className="mt-3">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={formik.values.city ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.city ? (
                      <div className="invalid text-sm">
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    <label className="mt-3">State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={formik.values.state ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.state ? (
                      <div className="invalid text-sm">
                        {formik.errors.state}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    <label className="mt-3">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={formik.values.country ?? ""}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.country ? (
                      <div className="invalid text-sm">
                        {formik.errors.country}
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={12} className="mt-3">
                    <button
                      className="btn btn-sm "
                      type="button"
                      onClick={() => {
                        formik.setFieldValue(
                          "AcceptTermsAndConditions",
                          !formik.values.AcceptTermsAndConditions
                        );
                      }}
                    >
                      {formik.values.AcceptTermsAndConditions ? (
                        <i className="fas fa-check-square"></i>
                      ) : (
                        <i className="far fa-square"></i>
                      )}
                    </button>
                    <span>
                      I agree to Plnify’s{" "}
                      <a
                        href="https://plnify.com/terms-of-use/"
                        target="_blank"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://plnify.com/privacy-policy/"
                        target="_blank"
                      >
                        Security & Privacy
                      </a>
                      .
                    </span>
                    {formik.errors.AcceptTermsAndConditions ? (
                      <div className="invalid text-sm">
                        {formik.errors.AcceptTermsAndConditions}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <div className="card-footer d-flex">
                <button
                  type="submit"
                  className="btn btn-primary w-50 mx-1"
                  disabled={isLoading}
                >
                  Join
                </button>

                <button
                  onClick={handleAlreadyMember}
                  className="btn btn-secondary w-50 mx-1"
                  disabled={isLoading}
                >
                  Already a Member
                </button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default Join;
