import axios from "axios";

export const APIHeader = {
  Authorization:
    localStorage.getItem("ACCESS_TOKEN") === null
      ? ""
      : "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Cache-Control": "no-cache",
};
const options = {
  headers: APIHeader,
  crossdomain: true,
};
axios.interceptors.request.use((config) => {
  // log a message before any HTTP request is sent
  console.log("The request ==== ", config);
  return config;
});

const BasePathForAPI = "https://service.plnify.com/api/";
const shareApi = axios.create({
  baseURL: BasePathForAPI,
  headers: null,
});
export default shareApi;
