import * as constant from "./constants";
import api, { apiService } from "../../../common/api";

export function toggleLastSync(event) {
  const url = `/Events/Edit`;

  return async (dispatch) => {
    try {
      
      dispatch({
        type: constant.TOGGLING_EVENT,
      });
      if (event.LastSync === null) {
        await apiService.post(url, { ...event, LastSync: new Date() });
        dispatch({ type: constant.TOGGLED_EVENT });
      } else if (event.LastSync !== null) {
        await apiService.post(url, { ...event, LastSync: null });
        dispatch({ type: constant.TOGGLED_EVENT_NULL });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.TOGGLING_EVENT_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  
  switch (action.type) {
    case constant.TOGGLING_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.TOGGLED_EVENT_NULL: {
      return {
        ...state,
        loading: false,
        event: Object.assign({}, { ...state.event, LastSync: null }),
      };
    }
    case constant.TOGGLED_EVENT: {
      return {
        ...state,
        loading: false,
        event: Object.assign({}, { ...state.event, LastSync: new Date() }),
      };
    }
    default:
      return state;
  }
}
