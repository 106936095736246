import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import CustomDropDrownMenu from "../common/CustomDropDrownMenu";

export const StaffDropDown = ({
  staffSelected,
  handleSelectAssignee,
  isEditable,
  mobile,
}) => {
  const teams = useSelector((state) => state.organizations.teams);
  const [search, setSearch] = useState("");

  const handleSearch = ({ target }) => {
    setSearch(target.value);
  };
  const staffs = [];
  teams?.forEach((team) => {
    team?.Staffs?.map((staff) => staff.Account).forEach((account) => {
      let accountExist = staffs.filter((staff) => staff.GUID === account.GUID);
      accountExist.length < 1 && staffs.push(account);
    });
  });

  const sortedStaffs = staffs?.sort((a, b) => a.Name.localeCompare(b.Name));
  const unifiedStaffs = sortedStaffs?.map((staff) => {
    return {
      ...staff,
      FullName: `${staff.Name} ${staff.LastName}`,
      imgUrl: staff.Pic,
    };
  });

  const cleanStaffs = unifiedStaffs?.filter((staff) =>
    staff?.FullName?.toLowerCase()?.includes(search.toLowerCase())
  );

  return [
    isEditable ? (
      <CustomDropDrownMenu
        items={cleanStaffs}
        selectedItem={staffSelected}
        handleSelectedItem={handleSelectAssignee}
        DrownDownProp={"FullName"}
        selectedItemProp={"Name"}
        toogleStyle={{
          borderRadius: "20px",
          padding: "5px 10px 5px 5px",
          border: "none",
        }}
        emptyStateComponent={() => (
          <>
            <i className="fa fa-user-check mr-1 p-1"></i>Assign
          </>
        )}
        Tooltip={true}
        isMobile={mobile}
        hasSearch={true}
        hasTooltip={true}
        TooltipProp={"Email"}
        TooltipPlacement={"left"}
        handleSearch={handleSearch}
        search={search}
      />
    ) : mobile ? (
      <span
        className="text-uppercase text-white bg-secondary rounded-circle mr-1"
        style={{
          fontSize: "11px",
          fontWeight: "bold",
          padding: "8px",
        }}
      >
        {staffSelected?.Name?.[0]}
        {staffSelected?.LastName?.[0]}
      </span>
    ) : (
      staffSelected && (
        <span
          // className="btn btn-primary rounded-pill px-2 py-2"
          style={{ cursor: "auto", pointerEvents: "none", fontSize: "14px" }}
        >
          <span
            className="text-uppercase text-white bg-secondary rounded-circle mr-1"
            style={{
              fontSize: "11px",
              fontWeight: "bold",
              // marginLeft: "-3px",
              padding: "6px",
            }}
          >
            {staffSelected?.Name?.[0]}
            {staffSelected?.LastName?.[0]}
          </span>
          {/* {staffSelected?.Name} {staffSelected?.LastName?.[0]} */}
        </span>
      )
    ),
  ];
};
