import React, { useEffect, useState } from "react";
import api from "../../common/api";
import { getBasicInfo } from "../../utils";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

const MailHistory = (props) => {
  const [state, setState] = useState({
    Records: [],
    UI: {
      Search: "",
      Quantity: 20,
      Page: 0,
    },
  });
  const intl = useIntl();
  const requestRemote = async () => {
    let localUser = getBasicInfo();
    let query = "AccountGUID=" + localUser.GUID + "&Search=" + state.UI.Search;
    let request = await api.get("/EmailHistory/List?" + query);
    console.log("Request for mailing list", request);
    if (request.data.Status === "ok") {
      setState((prev) => ({
        ...prev,
        Records: request.data.Response,
      }));
    }
  };
  useEffect(() => {
    requestRemote();
  }, []);

  return (
    <div
      className={props.className ? props.className : "organization-transparent"}
    >
      <div className="event-page overflow-hidden">
        <div
          className={
            props.isMobile
              ? "event-expennd-section w-100"
              : "event-expennd-section"
          }
        >
          <div className="text-head headerBg" style={{ padding: "6.5px" }}>
            <div className="row align-items-center">
              {props.showSideBar && (
                <buttom
                  className="btn mr-1"
                  onClick={() => props.setShowSideBar((prev) => !prev)}
                >
                  <i className="fas fa-bars"></i>
                </buttom>
              )}
              <div className={props.showSideBar ? "col-auto pl-0" : "col-auto"}>
                <h1>
                  <FormattedMessage
                    id="mailHistory"
                    defaultMessage="Mail History"
                  />
                </h1>
              </div>
              <div className="col-auto ml-auto text-right">
                <div className="d-flex formAlternativeStyle justify-content-end align-items-center">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "search" })}
                    value={state.UI.Search ? state.UI.Search : ""}
                    onChange={async (e) => {
                      setState((prev) => ({
                        ...prev,
                        UI: { ...prev.UI, Search: e.target.value },
                      }));
                      if (
                        e.target.value.length === 0 ||
                        e.target.value.length > 2
                      ) {
                        await requestRemote();
                      }
                    }}
                  />
                  {!props.isMobile && (
                    <button
                      className="btn btn-secondary ml-1 h-75"
                      onClick={() => props.setCurrentView("")}
                    >
                      <i className="fas fa-angle-left"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="event-inside p-0 px-2"
            style={{
              overflowY: "auto",
              height: "calc(100vh - 60px)",
            }}
          >
            <div className="event-content">
              {state.Records && state.Records.length > 0
                ? state.Records.map((item) => {
                    return (
                      <div
                        key={item.GUID}
                        className={
                          props.currentEmail.id === item.GUID
                            ? "card shadow-sm my-2 clickable active"
                            : "card shadow-sm my-2 clickable"
                        }
                        onClick={() => {
                          props.selectedEmail(JSON.parse(JSON.stringify(item)));
                          props.setCurrentEmail({
                            id: item.GUID,
                            section: "sentItems",
                          });
                        }}
                      >
                        <div className="card-body p-1 text-dark">
                          <div className="row px-2">
                            <div className="col text-truncate">
                              <b>{item.Title}</b>
                            </div>
                            <div className="col-auto ml-auto text-right">
                              <small className="text-muted">
                                {moment(item.Date).fromNow()}
                              </small>
                              <br />
                            </div>
                            <div className="col-12 text-truncate">
                              <small>
                                <FormattedMessage
                                  id="for"
                                  defaultMessage="For"
                                />
                                : {item.Receivers}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailHistory;
