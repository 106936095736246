import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";

import * as React from "react";
import { addClass, removeClass, Browser } from "@syncfusion/ej2-base";
import {
  RichTextEditorComponent,
  Toolbar,
  Inject,
  Image,
  Link,
  HtmlEditor,
  Count,
  QuickToolbar,
  Table,
  EmojiPicker,
  Video,
  Audio,
  FormatPainter,
} from "@syncfusion/ej2-react-richtexteditor";
import { FileManager } from "@syncfusion/ej2-react-richtexteditor";
import { createElement } from "@syncfusion/ej2-base";

function RichTextComponent({
  handleChange,
  toolbarSettings,
  value,
  readOnly,
  containerStyle,
  handleBlur,
  noToolBar,
  textEditorRef,
  id,
}) {
  let defaultToolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      // "Image",
      "|",
      "FormatPainter",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  };
  let quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
    link: ["Open", "Edit", "UnLink"],
  };

  let injectable = noToolBar
    ? [HtmlEditor]
    : [Toolbar, Image, Link, HtmlEditor, Table];
  return (
    <div className="control-pane">
      <div className="control-section" id="rteTools">
        <div className={"rte-control-section"}>
          <RichTextEditorComponent
            ref={textEditorRef}
            id={id}
            readonly={readOnly}
            toolbarSettings={
              readOnly ? {} : toolbarSettings ?? defaultToolbarSettings
            }
            quickToolbarSettings={
              quickToolbarSettings || readOnly ? {} : quickToolbarSettings
            }
            change={readOnly ? {} : handleChange}
            style={containerStyle ?? {}}
            value={value}
            blur={handleBlur}
          >
            <Inject services={injectable} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
}
export default RichTextComponent;
