import { useState } from "react";
import uuid from "uuid";
import shareApi from "../../../common/shareApi";
import { useLocation } from "react-router-dom";

export const GuestLogin = ({ setLogin, setLoginInfo, setLoggedIn }) => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const { firstName, lastName, email } = formState;

  const location = useLocation();

  const generateGuestLogin = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let login = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      login += chars.charAt(randomIndex);
    }
    return login;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const saveGuestLoginToSessionStorage = async (
    FirstName,
    LastName,
    Email,
    Phone
  ) => {
    const guestLogin = generateGuestLogin();

    const obj = {
      FirstName,
      LastName,
      Email,
      Phone,
      guestLogin,
      GUID: uuid.v4(),
    };

    try {
      let guest = null;
      const {
        data: { Response: exist },
      } = await shareApi.get(`Collab/GuestDetails?Email=${obj.Email}`);

      if (!exist) {
        const {
          data: { Response: newGuest },
        } = await shareApi.post(
          `collab/addEditguest?collabguid=${location.pathname.replace(
            "/c/",
            ""
          )}`,
          obj
        );
        guest = newGuest;
      } else {
        guest = exist;
      }

      const storageObj = {
        ...guest,
        firstName: guest?.FirstName,
        lastName: guest?.LastName,
      };

      localStorage.setItem("guestLogin", JSON.stringify(storageObj));
      setLoginInfo((prev) => ({ ...prev, guest: storageObj }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleType = () => {
    setLogin((prev) => ({ ...prev, type: "member" }));
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleContinue = () => {
    const isEmailValid = validateEmail(email);

    if (firstName?.length < 3) {
      setFormError((prev) => ({
        ...prev,
        firstName: "First name is required",
      }));
    } else {
      setFormError((prev) => ({ ...prev, firstName: false }));
    }

    if (lastName?.length < 3) {
      setFormError((prev) => ({ ...prev, lastName: "Last name is required" }));
    } else {
      setFormError((prev) => ({ ...prev, lastName: false }));
    }

    if (email?.length < 1 || !isEmailValid) {
      setFormError((prev) => ({
        ...prev,
        email:
          !isEmailValid && email.length > 1
            ? "Please enter a valid email address"
            : "Email is required",
      }));
    } else {
      setFormError((prev) => ({ ...prev, email: false }));
    }

    if (!firstName || !lastName || !email || !isEmailValid) return;

    saveGuestLoginToSessionStorage(firstName, lastName, email);
    setLogin((prev) => ({ ...prev, isLoggedIn: true }));
    setLoggedIn(true);
  };

  return (
    <>

      <span className="text-dark font-weight-bold mb-4">Guest Login</span>
      <form className="text-dark w-75">
        <div className="form-outline mb-4">
          <label htmlFor="firstName" className="font-weight-bold">
            First Name
          </label>
          <span className="d-flex align-items-center position-relative">
            <i
              className="fa fa-user-tie position-absolute text-secondary"
              style={{ left: "12px" }}
            ></i>
            <input
              type="text"
              className="form-control"
              style={{
                color: "#66799e",
                border: "0.0625rem solid #d1d7e0",
                padding: "0.55rem 0.55rem 0.55rem 2.2rem",
                borderRadius: "0.5rem",
              }}
              placeholder="First Name"
              name="firstName"
              value={firstName}
              onChange={handleChange}
            />
          </span>
          {formError.firstName && (
            <small
              className="text-danger text-info"
              style={{ fontSize: "12px" }}
            >
              *{formError.firstName}
            </small>
          )}
        </div>

        <div className="form-outline mb-4">
          <label htmlFor="lastName" className="font-weight-bold">
            Last Name
          </label>
          <span className="d-flex align-items-center position-relative">
            <i
              className="fa fa-user position-absolute text-secondary"
              style={{ left: "12px" }}
            ></i>
            <input
              type="text"
              className="form-control"
              style={{
                color: "#66799e",
                border: "0.0625rem solid #d1d7e0",
                padding: "0.55rem 0.55rem 0.55rem 2.2rem",
                borderRadius: "0.5rem",
              }}
              placeholder="Last Name"
              name="lastName"
              value={lastName}
              onChange={handleChange}
            />
          </span>
          {formError.lastName && (
            <small
              className="text-danger text-info"
              style={{ fontSize: "12px" }}
            >
              *{formError.lastName}
            </small>
          )}
        </div>

        <div className="form-outline mb-4">
          <label htmlFor="email" className="font-weight-bold">
            Email
          </label>
          <span className="d-flex align-items-center position-relative">
            <i
              className="fa fa-envelope position-absolute text-secondary"
              style={{ left: "12px" }}
            ></i>
            <input
              type="email"
              className="form-control"
              style={{
                color: "#66799e",
                border: "0.0625rem solid #d1d7e0",
                padding: "0.55rem 0.55rem 0.55rem 2.2rem",
                borderRadius: "0.5rem",
              }}
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </span>
          {formError.email && (
            <small
              className="text-danger text-info"
              style={{ fontSize: "12px" }}
            >
              *{formError.email}
            </small>
          )}
        </div>

        <button
          type="button"
          className="btn btn-dark btn-block py-3 mb-2 border-0"
          onClick={handleContinue}
        >
          Continue
        </button>

        <div className="text-center text-dark">
          <p>
            Already a member{" "}
            <a
              className="text-primary font-weight-bold"
              style={{ fontSize: "14px" }}
              onClick={handleType}
            >
              Sign In
            </a>
          </p>
        </div>
      </form>
    </>
  );
};
