import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { StaffDropDown } from "./StaffDropDown";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

export const TodoAccordion = ({ todos, assignees, toggleTask }) => {
  const [descShow, setDescShow] = useState({ GUID: "", show: false });
  const priorityStyle = {
    High: "danger",
    Medium: "warning",
    Low: "success",
  };

  const priorityNum = [null, "Low", "Medium", "High"];

  const isOver = false;
  // const handleMouseOver = () => {
  //   setIsOver((prev) => !prev);
  // };

  const handleShowDesc = (GUID) => {
    setDescShow((prev) => ({
      GUID,
      show: !prev.show,
    }));
  };
  return (
    <>
      {todos?.map((todo, index) => {
        let assignee = todo?.Assignees ? JSON.parse(todo?.Assignees)?.[0] : "";

        return (
          <>
            <div
              key={todo.GUID}
              className="d-flex border align-items-center p-2 justify-content-between"
              // onClick={() => handleShowDesc(todo.GUID)}
            >
              <div className="d-flex align-items-center flex">
                <div
                  onClick={() => toggleTask(todo)}
                  style={{
                    verticalAlign: "middle",
                    width: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  {todo.Status ? (
                    <i
                      className="fas fa-check-circle pt-1 text-primary"
                      style={{ fontSize: "18px" }}
                    ></i>
                  ) : (
                    <i
                      className={`pt-1 far ${
                        isOver ? "fa-check" : "fa-circle"
                      } uncheck`}
                      // onMouseEnter={handleMouseOver}
                      // onMouseLeave={handleMouseOver}
                      style={{ fontSize: "16px" }}
                    ></i>
                  )}
                </div>
                <div className="d-flex flex-column align-items-baseline">
                  <span
                    className={`ml-2`}
                    style={{
                      textDecoration: `${
                        todo.Status > 0 ? "line-through" : ""
                      }`,
                    }}
                  >
                    {todo.Name}
                  </span>
                  {todo?.Date && todo?.Status === 0 && (
                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "11px" }}
                    >
                      <i className="fa fa-calendar-o mx-1" aria-hidden="true"></i>
                      <span className="text-muted">{`Due ${moment(
                        todo?.Date
                      ).format("MMM DD")} at ${moment(todo?.Date).format(
                        "hh:mm A"
                      )}`}</span>
                    </div>
                  )}
                </div>
              </div>
              {todo.Status === 0 && (
                <div
                  className="d-flex justify-content-between align-items-center px-4"
                  style={{ width: "45%" }}
                >
                  <div>
                    <StaffDropDown
                      staffSelected={assignee}
                      handleSelectAssignee={() => {}}
                      isEditable={false}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-25">
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Priority
                    </span>
                    <span
                      className={`text-uppercase text-${
                        priorityStyle[priorityNum[todo.Priority]]
                      } `}
                      style={{ fontSize: "12px" }}
                    >
                      {`${"!".repeat(todo.Priority)} ${
                        priorityNum[todo.Priority]
                      }`}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* {descShow.GUID === todo.GUID && descShow.show && (
              <div className="btn btn-secondary d-flex pe-none border-0">
                <span>Description: </span>
                <span className="ml-2">{todo.Note}</span>
              </div>
            )} */}
          </>
        );
      })}
    </>
    // <Accordion defaultActiveKey={["0"]} alwaysOpen>
    //   {todos.map((todo, index) => {
    //     return (
    //       <Accordion.Item eventKey={index}>
    //         <Accordion.Header style={{ border: "none" }}>
    //
    //         </Accordion.Header>
    //         <Accordion.Body>{todo.Note}</Accordion.Body>
    //       </Accordion.Item>
    //     );
    //   })}
    // </Accordion>
  );
};
