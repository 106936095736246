import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Masonry from "react-masonry-css";
import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "../../constant";
import * as commonActions from "../../page/common/redux/actions";
import * as eventActions from "../../page/event/redux/actions";
import Note from "./Note";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyState from "../common/emptyState";

const breakpointColumnsObj = {
  default: 2,
  1100: 2,
  700: 1,
  500: 1,
};

export const NotesContainer = (props) => {
  const Notes = useSelector((state) => state.event.notes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(eventActions.getNotes(props.event.GUID));
  }, []);

  const reloadNotes = async () => {
    dispatch(eventActions.getNotes(props.event.GUID));
  };

  const { event } = props;

  return (
    <div className="d-flex flex-column ">
      <div className="text-head">
        <div className="text-left">
          <h1 className="title">
            <FormattedMessage id="stickyNotes" defaultMessage="Sticky Notes" />
          </h1>
          {/* <p className="subTitle">{event.Name}</p> */}
        </div>
        <button
          onClick={() => dispatch(eventActions.addNote(event.GUID))}
          className="btn btn-primary ml-auto btn-sm "
        >
          <FontAwesomeIcon icon={faPlus} color="white" />
        </button>
      </div>
      <div className="px-3 py-3 overflow-auto">
        {Notes.length === 0 && (
          <div>
            <EmptyState
              image={IMAGES.NOTES_EMPTY}
              // cta="Add a new attachment"
              text={
                <FormattedMessage
                  id="noAddedYet"
                  defaultMessage="No sticky notes added yet"
                />
              }
            />
          </div>
        )}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {Notes.map((note) => (
            <Note
              setNotes={commonActions.setNotes}
              key={note.GUID}
              note={note}
              reload={reloadNotes}
              // deleteNoteData={this.deleteNoteData}
            />
          ))}
        </Masonry>
      </div>
    </div>
  );
};
