import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import db from "../../../common/db";
import { IMAGES } from "../../../constant";
import { parseDate } from "../../../utils/parseDate";

export const ChildItemContent = ({ section, item }) => {
  switch (section) {
    case "Header":
      return (
        <div className="mb-2">
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {item.Name}
          </p>
        </div>
      );

    case "UpcomingEvents":
      return (
        <div className="mb-2">
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {item.Name}
          </p>
        </div>
      );
    case "Flights":
      let lastConnection;

      return (
        <div className={`hotelItem__root border-0 flight px-0 py-0 clickableItem row`}>
          <div className="col px-0">
            <div className="flex-1 row ">
              <div className="col-12 px-0">
                {item.PrivateFlight ? (
                  <span className="badge bg-secondary text-light py-1 mr-2">
                    <FormattedMessage id="private" defaultMessage="Private" />
                  </span>
                ) : null}
              </div>

              <div className="col-4 px-0">
                <div className="flight-detail mt-1">
                  <h6 className="text-truncate">
                    {item.From}
                    <span title={item.FromAddress}>{`   ${
                      item.FromAddress
                        ? item.FromAddress
                        : item.FromAddress || ""
                    }`}</span>
                  </h6>
                  <p
                    className="text-truncate"
                    title={moment(item.DepartureDate).format("MMM DD, hh:mm a")}
                  >
                    {moment(item.DepartureDate).format("MMM DD, hh:mm a")}
                  </p>
                </div>
              </div>
              <div className="col-1 text-center px-0 ">
                <img src={IMAGES.GROUP} alt="dot" height="19" />
                {item.lastConnection && (
                  <p className="stop">
                    {item.lastConnection.stops - 1 + " stops"}
                  </p>
                )}
              </div>
              <div className="col-4">
                <div className="flight-detail mt-1">
                  <h6 className="text-truncate">
                    {lastConnection ? lastConnection.to : item.To}
                    <span
                      title={
                        lastConnection
                          ? lastConnection.ToAddress
                          : item.ToAddress
                      }
                    >{`   ${
                      lastConnection
                        ? lastConnection.ToAddress
                        : item.ToAddress || ""
                    }`}</span>
                  </h6>
                  <p
                    className="text-truncate"
                    title={moment(
                      lastConnection
                        ? item.lastConnection.ArrivalDate
                        : item.ArrivalDate
                    ).format("MMM DD, hh:mm a")}
                  >
                    {moment(
                      lastConnection
                        ? lastConnection.ArrivalDate
                        : item.ArrivalDate
                    ).format("MMM DD, hh:mm a")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        // <div className="mb-3">
        //   <span className="fa-sm font-weight-bold">#{item.AirlineFlight}</span>
        //   <div className="d-flex justify-content-between align-items-center ">
        //     <div className="d-flex flex-column">
        //       <span className="font-weight-bold">{item.From}</span>
        //       <span className="mt-1" style={{ fontSize: "12.5px" }}>
        //         {moment(item.DepartureDate).format("MMM DD,hh:mm a")}
        //       </span>
        //     </div>
        //     <div className="text-center">
        //       <img src={IMAGES.GROUP} alt="dot" height="25" />
        //     </div>
        //     <div className="d-flex flex-column">
        //       <span className="font-weight-bold">{item.To}</span>
        //       <span className="mt-1" style={{ fontSize: "12.5px" }}>
        //         {moment(item.ArrivalDate).format("MMM DD,hh:mm a")}
        //       </span>
        //     </div>
        //   </div>
        // </div>
      );

    case "Itineraries":
      return (
        <div className="mb-3">
          <span className="fa-sm font-weight-bold text-truncate">
            {item.Name}
          </span>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className="d-flex flex-column">
              <span className="fa-sm">
                {moment(item.Date).format("hh:mm a")}
              </span>
            </div>

            {item.DateUntil && <i className="fa fa-arrow-right fa-sm" />}
            <div className="d-flex flex-column">
              <span className="fa-sm">
                {item.DateUntil && moment(item.DateUntil).format("hh:mm a")}
              </span>
            </div>
          </div>
        </div>
      );
    case "Hotels":
      return (
        <div className="mb-3">
          <span
            className="font-weight-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {item.Name}
          </span>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex flex-column">
              <span className="fa-sm">
                {moment(item?.Checkin).format("MMM DD, hh:mm a")}
              </span>
            </div>

            <div className="d-flex flex-column">
              <span className="fa-sm">
                {moment(item?.Checkout).format("MMM DD, hh:mm a")}
              </span>
            </div>
          </div>
        </div>
      );
    case "Transportation":
      return (
        <div className="mb-3">
          <span className="font-weight-bold" style={{ fontSize: "14px" }}>
            {item.Company}
          </span>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <span className="fa-sm">{item.Pickup}</span>
            </div>
            <div className="d-flex flex-column">
              <span className="fa-sm">{item.Dropoff}</span>
            </div>
          </div>
        </div>
      );
    case "Contacts":
      return (
        <div className="mb-2">
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {item.Name + " " + item.LastName}
          </p>
          <p className="text-truncate">{item.Position}</p>
        </div>
      );
    case "Attachments":
      return (
        <div className="mb-2">
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {item.Name}
          </p>
        </div>
      );
    case "UpcomingEvents":
      return (
        <div className="mb-2">
          <p
            className="font-weight-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {item.Name}
          </p>
        </div>
      );

    default:
      return null;
  }
};
