import React from "react";
import { ChildItemContent } from "./ChildItemContent";
import ExportItemStyleComponent from "./ExportItemStyleComponent";

export const ExportChildItem = ({
  item,
  section,
  selectedItems,
  handleParams,
  isParentChecked,
  sectionsParams,
  setSectionsParams,
}) => {
  const HandleToggle = () => {
    setIsChecked((prev) => !prev);
    handleParams(section === "Flights" ? item.Connection : item.GUID);
  };

  const toggleRef = React.createRef();
  const [isChecked, setIsChecked] = React.useState(false);

  

  React.useEffect(() => {
    if (
      (selectedItems?.includes(item.GUID) ||
        selectedItems?.includes(item) ||
        selectedItems?.includes(item.Connection)) &&
      isParentChecked
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedItems, isParentChecked, item]);

  return (
    <div className="export-style-menu border border-bottom">
      <div className="w-100 d-flex justify-content-between align-items-center px-4 py-2">
        <div className="d-flex flex-column" style={{ width: "80%" }}>
          <div style={{ width: "100%" }}>
            <ChildItemContent section={section} item={item} />
          </div>
        </div>
        <div className="d-flex align-items-center ml-2">
          <div
            style={{ height: "10px", width: "40px" }}
            className={`toggle-switch ${isChecked && "active"}`}
            id="myToggle"
            title="Show/Hide Section"
            onClick={!isParentChecked ? () => {} : HandleToggle}
          >
            <div
              ref={toggleRef}
              style={{
                height: "20px",
                width: "20px",
                boxShadow: "0px 0px 5px 0px #000000",
                backgroundColor: `${isChecked ? "#007bff" : "#fff"}`,
              }}
              className={`toggle-switch-inner ${isChecked && "active"}`}
            ></div>
          </div>
        </div>
      </div>

      <ExportItemStyleComponent
        item={item}
        setSectionsParams={setSectionsParams}
        sectionsParams={sectionsParams}
        section={section}
        selected={isChecked}
      />
    </div>
  );
};
