import { LOGGED_USER, LOGIN_ERROR, LOGIN_USER } from "./constants";
import api, { APIHeader, apiService } from "../../../common/api";
import {
  setAccessToken,
  setBasicInfo,
  getRememberMeValue,
} from "../../../utils";
import queryString from "query-string";
import { parseJwt } from "../../../utils/parsedJWT";

const sub_url = "Account/RequestToken";
// const sub_url = "user/CanLogin";

export function login(username, password, callback) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER,
    });
    try {
      //  debugger;
      const loginRequest = await apiService.post(
        sub_url,
        {
          Username: username,
          Password: password,
          Platform: "web",
        },
        APIHeader
      );

      if (loginRequest.status === 200) {
        getRememberMeValue(username, password);
        const user = loginRequest.data.token;
        const parsedUser = parseJwt(user);
        setBasicInfo({
          ...parsedUser,
          Name: parsedUser.FirstName,
          GUID: parsedUser.ID,
        });
  
        setAccessToken(loginRequest.data.token);
        dispatch({
          type: LOGGED_USER,
          data: user,
        });
        callback();
      } else {
        dispatch({
          type: LOGIN_ERROR,
        });
      }
    } catch (error) {
      debugger;
      dispatch({
        type: LOGIN_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        ...state,
        logging: true,
        loginError: false,
      };
    }
    case LOGGED_USER: {
      return {
        ...state,
        logging: false,
        loginError: false,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        logging: false,
        loginError: true,
      };
    }
    default:
      return state;
  }
}
