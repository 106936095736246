import React from "react";

const ExportEmailSkeletton = () => {
  return (
    <div className="w-100 h-100 d-flex flex-column">
      <div className="d-flex justify-content-center border-bottom pb-3 skeleton-loading w-100 d-flex justify-content-between">
        <div className="w-75">
          <div className="skeleton-header"></div>
          <div className="skeleton-description"></div>
        </div>
        <div
          className="skeleton-image"
          style={{
            width: "80px",
            height: "80px",
          }}
        ></div>
      </div>
      <div
        className="w-100 skeleton-loading mt-5 border-bottom pb-3 d-flex justify-content-between"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
      </div>
      <div className="w-100 py-3 border-bottom">
        <div className="skeleton-header"></div>
        <div className="skeleton-description"></div>
      </div>
      <div
        className="w-100 skeleton-loading mt-2 border-bottom pb-3 d-flex justify-content-between"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
      </div>
      <div className="w-100 py-3 border-bottom">
        <div className="skeleton-header"></div>
        <div className="skeleton-description"></div>
      </div>
      <div
        className="w-100 skeleton-loading mt-2 border-bottom pb-3 d-flex justify-content-between"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
        <div className="skeleton-description"></div>
      </div>
    </div>
  );
};

export default ExportEmailSkeletton;
