import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function deleteVenue(event) {
  const url = `/Events/Edit`;
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_EVENT,
    });
    try {
      const updatedEvent = {
        Name: event.Name,
        EventType: event.EventType,
        Date: event.Date,
        VenueGUID: null,
        Notes: event.Notes,
        City: event.City,
        Country: event.Country,
        State: event.State,
        Status: event.Status,
        Latitude: event.Latitude,
        Longitude: event.Longitude,
        GUID: event.GUID,
        OrganizationGUID: event.OrganizationGUID,
        LastSync: event.LastSync,
        DateTime: event.DateTime,
        CreationDate: event.CreateDate,
        Deleted: null,
        Image: event.Image,
        AllowUsersToRequestTickets: event.AllowUsersToRequestTickets,
        WillCallCity: event.WillCallCity,
        WillCallHours: event.WillCallHours,
        WillCallState: event.WillCallState,
        WillCallAddress: event.WillCallAddress,
        WillCallCountry: event.WillCallCountry,
        WillZipCode: event.WillZipCode,
        WillCallPhone: event.WillCallPhone,
        WillCallComment: event.WillCallComment,
      };
      await db.table("event").put(updatedEvent);
      await db.table("events").put(updatedEvent);
      const request = await apiService.post(url, updatedEvent);
      const venueRequest = await api.get(
        `/Venues/Details?GUID=${request.data.Response.VenueGUID}`
      );
      dispatch({
        type: constant.EDITED_EVENT,
        data: { ...request.data.Response, Venue: venueRequest.data },
      });
    } catch (error) {
      dispatch({
        type: constant.EDITING_EVENT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_EVENT: {
      return {
        ...state,
        loading: false,
        event: { ...action.data },
        eventVenue: action.data.Venue,
      };
    }
    case constant.EDITING_EVENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
