import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import Popup from "reactjs-popup";
import api, { apiService } from "../../common/api";
import EmptyStateImg from "../../images/itineraries-empty.svg";
import {
  DropDownComponent,
  ModalComponent,
  ToastNotificationSuccess,
} from "../common";
import EmptyState from "../common/emptyState";
import TemplatesModal from "./TemplatesModal";
import AddItinerary from "./addItinerary";
import ItinerariesItem from "./itinerariesItem";
import Swal from "sweetalert2";
import db from "../../common/db";
import { checkSelectionStatus, exist } from "../../common/helpers";
import { IMAGES } from "../../constant";
import ExportToEvent from "../common/ExportToEvent";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const Itineraries = (props) => {
  const {
    schedulesMasters,
    handleSidebarItem,
    sideBarItemData,
    event,
    addItinerary,
    toggleExportModal,
    toggleMailingList,
    deleteItinerary,
    editItinerary,
    toggleActivityForm,
    toggleVisibleBy,
    createTemplate,
    createFromTemplate,
    templates,
    loading,
    toggleLabelForm,
    actionResource,
    toggleShare,
    showTemplateLabel,
    handleCreateTemplate,
    handleAddEditTemplateLabel,
  } = props;
  const [addItineraryModal, setAddItineraryModal] = useState(false);
  const [currentSchedules, setCurrentSchedules] = useState([]);
  const [templatesModal, setTemplatesModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    SelectedItems: [],
    UI: {
      showExportToEvent: false,
      exportMode: "",
    },

    Schedules: schedulesMasters ? schedulesMasters : [],
  });

  useEffect(() => {
    setCurrentSchedules(schedulesMasters);
  }, [schedulesMasters]);

  function setItineraries() {
    if (schedulesMasters?.length) {
      handleSidebarItem("ACTIVITIES", schedulesMasters?.[0]);
    } else {
      handleSidebarItem("EVENT_DETAILS", {});
    }
  }

  const ItinerariesRemote = async () => {
    try {
      const request = await apiService.get("/ScheduleMaster/ScheduleForEvent", {
        params: { EventGUID: event?.GUID },
      });
      let cleaned = await request?.data;
      await db.table("schedules").bulkPut(cleaned);
      await reloadItineraries();
    } catch (error) {
      console.log({ ErrorWhileDeleting: error });
    }
  };

  const reloadItineraries = async () => {
    try {
      let items = await db
        .table("schedules")
        .where({ EventGUID: event?.GUID })
        .toArray();
      let filterDeleted = items.filter((x) => x.Deleted === null);

      setState((prev) => {
        return {
          ...prev,
          Schedules: filterDeleted,
        };
      });
    } catch (ex) {
      console.log(ex);
      debugger;
    }
  };

  const toggleAddEdit = () => {
    setAddItineraryModal(!addItineraryModal);
  };

  const addItineraryMiddleMan = (values, toggle) => {
    addItinerary(values, toggle);
    toggleAddEdit(values);
  };

  //GROUP ACTIONS==========================================
  const toggleExportToEvent = async (exportMode, reload) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        showExportToEvent: !prev.UI.showExportToEvent,
        exportMode,
      },
    }));

    if (reload) {
      await ItinerariesRemote();
      setState((prev) => ({
        ...prev,
        SelectedItems: [],
      }));
    }
  };

  const deleteAllSelected = () => {
    Swal.fire({
      text: "Are you sure you want to delete all selected items?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        state.SelectedItems.forEach((item) => {
          deleteItinerary(item, true);
        });
        setState((prev) => {
          return {
            ...prev,
            SelectedItems: [],
          };
        });
        ToastNotificationSuccess(1, "Selected itineraries sucessfully deleted");
      }
    });
  };

  const selectAll = () => {
    let SelectedItems = state?.SelectedItems;
    let filter = schedulesMasters;
    let checkSelection = checkSelectionStatus(filter, null, SelectedItems);

    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        }
      });

      setState((prev) => ({
        ...prev,
        SelectedItems,
      }));
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      setState((prev) => ({
        ...prev,
        SelectedItems: [],
      }));
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        } else {
          //Check if deleted
        }
      });

      setState((prev) => ({
        ...prev,
        SelectedItems,
      }));
    }
  };

  const toggleSelect = (item) => {
    let SelectedItems = state?.SelectedItems;
    if (exist(SelectedItems, item)) {
      if (
        SelectedItems.filter(
          (x) => x.GUID === item.GUID && x.Deleted === null
        )[0]
      ) {
        SelectedItems = SelectedItems.filter((x) => x.GUID !== item.GUID);
      }
    } else {
      SelectedItems.push(item);
    }

    setState((prev) => ({
      ...prev,
      SelectedItems,
    }));
  };

  const selectionStatus = () => {
    try {
      let result = null;
      if (!state.SelectedItems) {
        return null;
      }
      let check = checkSelectionStatus(
        schedulesMasters,
        null,
        state.SelectedItems
      );
      if (check === 0) {
        result = <i className="far fa-square"></i>;
      } else if (check === 1) {
        result = <i className="fas fa-check-square"></i>;
      } else {
        result = <i className="fas fa-minus-square"></i>;
      }
      return result;
    } catch (ex) {
      console.error(ex);
    }
  };
  //END GROUP ACTIONS

  //Delete Selected Items on Delete Key Down
  useEffect(() => {
    const handleDeleteKey = ({ key }) => {
      if (key === "Delete") {
        deleteAllSelected();
      }
    };
    const rootElement = document.getElementById("root");
    rootElement.addEventListener("keydown", handleDeleteKey);

    return () => {
      rootElement.removeEventListener("keydown", handleDeleteKey);
    };
  }, [state.SelectedItems]);

  return (
    <div className="resource shadow-sm itineraries__list">
      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state.UI.showExportToEvent}
        childeren={
          <ExportToEvent
            originEvent={props.event}
            exportMode={state.UI.exportMode}
            items={state.SelectedItems}
            toggle={toggleExportToEvent}
            section="itinerary"
          />
        }
      />
      <ModalComponent
        modal={addItineraryModal}
        toggle={() => setAddItineraryModal(!addItineraryModal)}
        childeren={
          <AddItinerary
            loading={loading}
            event={event}
            addItinerary={addItineraryMiddleMan}
            handleSidebarItem={handleSidebarItem}
            toggle={toggleAddEdit}
          />
        }
      />
      <ModalComponent
        modal={templatesModal}
        toggle={() => setTemplatesModal(!templatesModal)}
        childeren={
          <TemplatesModal
            createFromTemplate={createFromTemplate}
            templates={templates}
            scheduleMaster={schedulesMasters}
            toggle={() => setTemplatesModal(!templatesModal)}
            showTemplateLabel={showTemplateLabel}
            handleCreateTemplate={handleCreateTemplate}
            handleAddEditTemplateLabel={handleAddEditTemplateLabel}
            parentState={state}
          />
        }
      />
      <div className="resource__header " onClick={() => setItineraries()}>
        <div className="row ">
          <div className="col-auto px-0 py-1">
            <button
              className="btn btn-sm text-secondary"
              onClick={() => selectAll()}
              type="button"
            >
              {selectionStatus()}
            </button>
          </div>
          <div className="col-5 px-0 py-1">
            <div className="head-left pt-1">
              <h4>
                <FormattedMessage
                  id="itineraries"
                  defaultMessage={props.blockName}
                />
              </h4>
            </div>
          </div>

          <div className="col-auto px-0 pr-3 text-right ml-auto py-1">
            <div className="create-button" style={{ zIndex: "50" }}>
              <Popup
                trigger={
                  <button type="button" className="btn btn-sm btn-primary">
                    <i className="fa fa-plus" />
                  </button>
                }
                style={{ zIndex: 100 }}
                position="bottom right"
                arrow={false}
              >
                {(open) => (
                  <>
                    <button
                      onClick={() => {
                        open();
                        setAddItineraryModal(!addItineraryModal);
                      }}
                      className="btn btn-block flex-1"
                      type="button"
                    >
                      <FormattedMessage id="new.event" defaultMessage="New" />
                    </button>
                    <button
                      onClick={() => {
                        open();
                        setTemplatesModal(!templatesModal);
                      }}
                      className="btn btn-block flex-1 mt-1"
                      type="button"
                    >
                      <FormattedMessage
                        id="template"
                        defaultMessage="Template"
                      />
                    </button>
                  </>
                )}
              </Popup>
            </div>
          </div>
          {checkSelectionStatus(schedulesMasters, null, state.SelectedItems) !==
          0 ? (
            <div className="col-12 bg-subOption text-dark text-center py-2">
              <button
                className="btn btn-secondary mx-2 btn-sm"
                onClick={() => toggleExportToEvent("export")}
              >
                <FormattedMessage
                  id="moveToAnotherEvent.Flight"
                  defaultMessage="Move to another event"
                />
              </button>
              <button
                className="btn btn-secondary mx-2 btn-sm"
                onClick={() => toggleExportToEvent("copy")}
              >
                <FormattedMessage
                  id="copyToAnotherEvent.Flight"
                  defaultMessage="Copy to another event"
                />
              </button>
              <button
                className="btn btn-danger mx-2 btn-sm"
                onClick={() => deleteAllSelected()}
              >
                <FormattedMessage
                  id="deleteSelected.Flight"
                  defaultMessage="Delete Selected"
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div style={{ height: 250 }}>
        {!schedulesMasters || schedulesMasters?.length === 0 ? (
          <EmptyState
            image={EmptyStateImg}
            cta="Add a new itinerary"
            text="No itineraries added yet"
            actionBtn={{
              action: toggleAddEdit,
              title: "Add Itineraries",
            }}
          />
        ) : (
          <Scrollbars style={{ width: "100%", height: 250 }}>
            {currentSchedules &&
              currentSchedules
                .sort((a, b) => {
                  if (a.Date > b.Date) return 1;
                  if (a.Date < b.Date) return -1;
                })
                .map((itineraries) => (
                  <ItinerariesItem
                    editItinerary={editItinerary}
                    toggleActivityForm={toggleActivityForm}
                    sideBarItemData={sideBarItemData}
                    deleteItinerary={deleteItinerary}
                    toggleMailingList={toggleMailingList}
                    toggleExportModal={toggleExportModal}
                    handleSidebarItem={handleSidebarItem}
                    schedulesMasters={schedulesMasters}
                    itinerary={itineraries}
                    key={itineraries.GUID}
                    toggleVisibleBy={toggleVisibleBy}
                    createTemplate={createTemplate}
                    toggleLabelForm={toggleLabelForm}
                    toggleShare={toggleShare}
                    deleteAllSelected={deleteAllSelected}
                    selectedItems={state.SelectedItems}
                    toggleSelect={toggleSelect}
                    checkSelectionStatus={checkSelectionStatus}
                    setAddItineraryModal={setAddItineraryModal}
                    actionResource={actionResource}
                    event={event}
                  />
                ))}
          </Scrollbars>
        )}
      </div>
    </div>
  );
};

export default Itineraries;
