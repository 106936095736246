import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getAccessToken } from "../../../utils";
import * as authActions from "../../authentication/redux/actions";

export const MemberLogin = ({
  setLogin,
  handleForgotPassword,
  setLoginInfo,
  setLoggedIn,
}) => {
  const handleType = () => {
    setLogin((prev) => ({ ...prev, type: "guest" }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    remember: true,
  });

  const [formError, setFormError] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const { password, email, remember } = formState;

  const handleChange = ({ target }) => {
    const { name, value, checked } = target;
    setFormState((prev) => ({
      ...prev,
      [name]: name === "remember" ? checked : value,
    }));
  };

  const handleContinue = async () => {
    setIsLoading(true);

    const { password, email } = formState;

    console.log({ email });

    if (password?.length < 3) {
      setFormError((prev) => ({ ...prev, password: true }));
    } else {
      setFormError((prev) => ({ ...prev, password: false }));
    }
    if (email?.length < 3) {
      setFormError((prev) => ({ ...prev, email: true }));
    } else {
      setFormError((prev) => ({ ...prev, email: false }));
    }

    console.log({ formError });

    await dispatch(
      authActions.login(formState.email, formState.password, () => {
        const accessToken = getAccessToken();
        if (accessToken) {
          setLogin((prev) => ({ ...prev, isLoggedIn: true }));
          setLoginInfo((prev) => ({ ...prev, accessToken }));
          setLoggedIn(true);
          setIsLoading(false);
          return;
        }
      })
    );

    setIsLoading(false);
  };

  return (
    <>
      <span className="text-dark font-weight-bold mb-4">Login</span>

      <form className="text-dark w-75 py-2">
        <div className="form-outline mb-4">
          <label htmlFor="email" className="font-weight-bold">
            Email
          </label>

          <span className="d-flex align-items-center position-relative">
            <i
              className="fa fa-envelope position-absolute text-secondary"
              style={{ left: "12px" }}
            ></i>
            <input
              type="email"
              style={{
                color: "#66799e",
                border: "0.0625rem solid #d1d7e0",
                padding: "0.55rem 0.55rem 0.55rem 2.2rem",
                borderRadius: "0.5rem",
              }}
              className="form-control font-weight-normal text-secondary"
              name="email"
              placeholder="example@plnify.com"
              value={email}
              onChange={handleChange}
            />
          </span>
          {formError.email && (
            <small
              className="text-danger text-info"
              style={{ fontSize: "12px" }}
            >
              *Email is required
            </small>
          )}
        </div>

        <div className="form-outline mb-4">
          <label htmlFor="password" className="font-weight-bold">
            Password
          </label>
          <span className="d-flex align-items-center position-relative">
            <i
              className="fa fa-unlock-alt position-absolute text-secondary"
              style={{ left: "12px" }}
            ></i>

            <input
              type="password"
              className="form-control"
              style={{
                color: "#66799e",
                border: "0.0625rem solid #d1d7e0",
                padding: "0.55rem 0.55rem 0.55rem 2.2rem",
                borderRadius: "0.5rem",
              }}
              name="password"
              placeholder="Password"
              value={password}
              onChange={handleChange}
            />
          </span>
          {formError.password && (
            <small className="text-danger" style={{ fontSize: "12px" }}>
              *Password is required.
            </small>
          )}
        </div>

        <div className="row mb-4">
          <div className="col d-flex justify-content-center align-items-center">
            <div className="form-check ">
              <input
                className="form-check-input"
                name="remember"
                type="checkbox"
                checked={remember}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="remember"></label>
            </div>
            <p>
              Remember me{" "}
              <a
                className="text-primary font-weight-bold"
                onClick={handleForgotPassword}
              >
                forgot password?
              </a>
            </p>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between text-center text-dark">
          <button
            type="button"
            className="btn btn-primary btn-block py-3 border-0"
            onClick={handleContinue}
          >
            {isLoading && (
              <div
                className="spinner-border text-light mr-2"
                role="status"
                style={{ width: "15px", height: "15px" }}
              >
                <p className="sr-only">Loading...</p>
              </div>
            )}
            Sign in
          </button>
          <p className="m-2 text-1">Or</p>

          <button
            className="btn btn-dark btn-block py-3 border-0"
            onClick={handleType}
          >
            sign in as a Guest
          </button>
        </div>
      </form>
    </>
  );
};
