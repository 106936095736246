import React, { useEffect, useState } from "react";
import { Link, Events, scroller } from "react-scroll";
import { SimpleLoader } from "../common";
import { useIntl, FormattedMessage } from "react-intl";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import api, { apiService } from "../../common/api";
import { useBetaFeature } from "../../hooks/useBetaFeature";

const EventNavigation = (props) => {
  let {
    options,
    loading,
    layout,
    event,
    layoutProduction,
    layoutEvent,
    currentElement,
    handleNavigationSelect,
    history,
    scrollToElement,
    selected,
    setSelected,
    isMobile,
  } = props;

  const location = useLocation();
  const navigate = history;

  const { isChecked } = useBetaFeature();

  const handleSelect = ({ target }, name) => {
    name = name.toLocaleLowerCase();
    setSelected(name);
    handleNavigationSelect(name);
    navigate.push(`#${name}`);
  };

  useEffect(() => {
    let section = !location.hash.replace("#", "")
      ? "general"
      : location.hash.replace("#", "");
    setSelected((prev) => section);
    handleNavigationSelect(section);
    scrollToElement(!location.hash ? "#general" : location.hash);
  }, [location]);

  return (
    <div className="eventNavigation__container">
      {layout === "EVENT" ? (
        <>
          {options
            .filter((option) => option.Show !== false)
            .map((option, index) => (
              <Link
                title={option?.Name}
                containerId="listSection"
                smooth={true}
                offset={-20}
                duration={500}
                className={
                  selected.toLocaleLowerCase() ===
                  option.Name.toLocaleLowerCase()
                    ? "active_link"
                    : ""
                }
                to={option.BlockType.toLocaleLowerCase()}
                // spy={true}
                key={option.BlockType.toLocaleLowerCase()}
                onClick={(event) => {
                  handleSelect(event, option.BlockType.toLocaleLowerCase());
                  props.toggleProductionModal(false);
                }}
              >
                <span className="d-none d-md-none d-lg-block">
                  {option.Name}
                </span>
                <span className="d-block d-md-block d-lg-none">
                  <i className={option.Icon}></i>
                </span>
              </Link>
            ))}
          {/* <a className=" text-truncate"
            onClick={() => props.handleSidebarItem("MSG", "")}>
            {<FormattedMessage
              id="msg"
              defaultMessage="Msg" />}
          </a> */}
          <a
            className={`beta ${!isChecked ? "active" : ""} ${
              selected === "moreDetails" ? "active_link" : ""
            } text-truncate`}
            onClick={() => {
              props.toggleProductionModal(true);
              setSelected("moreDetails");
            }}
          >
            {!isMobile ? (
              <>
                <FormattedMessage
                  id="advancedDetails"
                  defaultMessage="Advanced Details"
                />
                <span className="badge badge-info mx-2">Beta</span>
              </>
            ) : (
              <i className="fas fa-info-circle"></i>
            )}
          </a>
          <button
            className="btn btn-primary new-option-btn"
            onClick={() => {
              props.toggleNewMenuModal();
            }}
          >
            {!isMobile ? (
              <>
                <i className={`fas fa-edit `}></i>
                <FormattedMessage
                  id="newMenuOption"
                  defaultMessage="Manage Sections"
                />
              </>
            ) : (
              <i className="fas fa-plus"></i>
            )}
          </button>
        </>
      ) : null}

      {loading && <SimpleLoader loading={loading} />}
    </div>
  );
};

export default EventNavigation;
