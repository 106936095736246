import React, { useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Element } from "react-scroll";
import { ModalComponent } from "../../../components";
import AttachmentsContainers from "../../../components/attachments/attachmentsContainers";
import DragAndDrop from "../../../components/common/DragAndDrop";
import ContactsContainers from "../../../components/contacts/contactsContainers";
import SideEvents from "../../../components/event/SidebarEvents";
import GroundContainers from "../../../components/ground/groundContainers";
import HotelContainers from "../../../components/hotel/hotelContainers";
import ItinerariesContainers from "../../../components/itineraries/itinerariesContainers";
import FlightContainer from "../../flights/flight/flightContainer";
import EventGeneral from "../eventDetailsGeneral/eventDetailsContainer";
import { renderSidebarItem } from "./renderSidebarItem";
import MoreDetails from "../../../components/event/MoreDetails";
import { useHistory } from "react-router-dom";

const BlocksComponent = ({ blocks, blocksObject }) => {
  console.log({ blocks, blocksObject });
  return blocks?.map((block, index) => {
    const Component = blocksObject?.find((obj) => obj.id === block)
      ?.component || <div key={index + block}></div>;
    return Component;
  });
};

export const EventDetail = (props) => {
  const {
    currentSideBarItem,
    handleSidebarItem,
    showEventsSidebar,
    eventsList,
    toggleSidebarEvents,
    addContact,
    addAttachment,
    getAttachmentsEvent,
    addItinerary,
    setVenue,
    toggleVenueModal,
    venueModal,
    attachments,
    contacts,
    hotels,
    grounds,
    venues,
    event,
    advancedDetails,
    menuOptions,
  } = props;

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  // const style = { display: showDrop ? "none" : "inline" };
  const history = useHistory();

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: true,
        style: { display: "inline" },
      };
    });
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const cleanOptions = useMemo(() => {
    return menuOptions
      .sort((a, b) => {
        return a.Order - b.Order;
      })
      .filter((option) => option.Show !== false)
      .map((option) => {
        return option.BlockType.toLowerCase();
      });
  }, [menuOptions]);

  const blocksObject = [
    {
      id: "general",
      component: (
        <Element
          style={{ color: "white" }}
          name="general"
          className="element"
          id="general"
          key={"general1"}
          // onClick={()=>handleNavigationSelect("general")}
        >
          <EventGeneral
            setVenue={setVenue}
            venuesList={venues}
            blockName={
              menuOptions.find((option) => option.BlockType === "general")?.Name
            }
            handleSidebarItem={handleSidebarItem}
            toggleVenueModal={toggleVenueModal}
            venueModal={venueModal}
          />
        </Element>
      ),
    },
    {
      id: "itineraries",
      component: (
        <Element
          style={{ color: "white" }}
          name="itineraries"
          className="element"
          id="itineraries"
          key={"itineraries3"}
          // onClick={()=>handleNavigationSelect("itineraries")}
        >
          <ItinerariesContainers
            addItinerary={addItinerary}
            blockName={
              menuOptions.find((option) => option.BlockType === "itineraries")
                ?.Name
            }
            handleSidebarItem={handleSidebarItem}
            scheduleMaster={props.sideBarItemData}
            sideBarItemData={props.sideBarItemData}
            deleteItinerary={props.deleteItinerary}
          />
        </Element>
      ),
    },
    {
      id: "flights",
      component: (
        <Element
          style={{ color: "white" }}
          name="flights"
          className="element"
          id="flights"
          key={"flights3"}
          // onClick={()=>handleNavigationSelect("flights")}
        >
          <FlightContainer
            {...props}
            blockName={
              menuOptions.find((option) => option.BlockType === "flights")?.Name
            }
          />
        </Element>
      ),
    },
    {
      id: "hotels",
      component: (
        <Element
          style={{ color: "white" }}
          name="hotels"
          className="element"
          id="hotels"
          key={"hotels4"}
          // onClick={()=>handleNavigationSelect("hotels")}
        >
          <HotelContainers
            blockName={
              menuOptions.find((option) => option.BlockType === "hotels")?.Name
            }
            deleteHotel={props.deleteHotel}
            handleSidebarItem={handleSidebarItem}
            hotels={hotels}
            addHotel={props.addHotel}
            {...props}
          />
        </Element>
      ),
    },
    {
      id: "transportation",
      component: (
        <Element
          style={{ color: "white" }}
          name="transportation"
          className="element"
          id="transportation"
          key={"transportation5"}
          // onClick={()=>handleNavigationSelect("ground")}
        >
          <GroundContainers
            blockName={
              menuOptions.find(
                (option) => option.BlockType === "transportation"
              )?.Name
            }
            deleteGround={props.deleteGround}
            addGround={props.addGround}
            sideBarItemData={props.sideBarItemData}
            handleSidebarItem={handleSidebarItem}
            grounds={grounds}
          />
        </Element>
      ),
    },
    {
      id: "contacts",
      component: (
        <Element
          style={{ color: "white" }}
          name="contacts"
          className="element"
          id="contacts"
          key={"contacts6"}
          // onClick={()=>handleNavigationSelect("contacts")}
        >
          <ContactsContainers
            event={event?.GUID}
            addContact={addContact}
            contacts={contacts}
            blockName={
              menuOptions.find((option) => option.BlockType === "contacts")
                ?.Name
            }
            handleSidebarItem={handleSidebarItem}
            sideBarItemData={props.sideBarItemData}
          />
        </Element>
      ),
    },
    {
      id: "attachments",
      component: (
        <Element
          style={{ color: "white" }}
          name="attachments"
          className="element"
          id="attachments"
          key={"attachments7"}
          // onClick={()=>handleNavigationSelect("attachments")}
        >
          <AttachmentsContainers
            blockName={
              menuOptions.find((option) => option.BlockType === "attachments")
                ?.Name
            }
            addAttachmentDropbox={props.addAttachmentDropbox}
            getAttachmentsEvent={getAttachmentsEvent}
            addAttachment={addAttachment}
            event={event?.GUID}
            attachments={attachments}
            handleSidebarItem={handleSidebarItem}
            sideBarItemData={props.sideBarItemData}
          />
        </Element>
      ),
    },
  ];

  return (
    <>
      {advancedDetails ? (
        <div style={{ marginTop: "112px", height: "calc(100dvh - 112px)" }}>
          <MoreDetails
            event={event}
            containerClass="w-100 h-100 z-index-0"
            noteClass="card-body containerEditor w-100 z-index-0"
          />
        </div>
      ) : (
        <div
          className={`dashboard-screen event-detail-page`}
          style={{ marginTop: "112px" }}
          onDragEnterCapture={handleDragIn}
          // onDragOverCapture={handleDragOut}
        >
          <div className={`listSection`} id="listSection">
            <Element style={showDrop.style}>
              <DragAndDrop
                height="100vh"
                width="55%"
                top="-112px"
                handleDragOut={handleDragOut}
              >
                <div
                  style={{
                    height: "100vh",
                    minWidth: "55%",
                    position: "absolute",
                    top: 0,
                    zIndex: 999,
                  }}
                ></div>
              </DragAndDrop>
            </Element>

            <BlocksComponent
              blocks={cleanOptions}
              blocksObject={blocksObject}
            />

            {/* {cleanOptions.includes("general") && (
              <Element
                style={{ color: "white" }}
                name="general"
                className="element"
                id="general"
                // onClick={()=>handleNavigationSelect("general")}
              >
                <EventGeneral
                  setVenue={setVenue}
                  venuesList={venues}
                  handleSidebarItem={handleSidebarItem}
                  toggleVenueModal={toggleVenueModal}
                  venueModal={venueModal}
                />
              </Element>
            )}

            {cleanOptions.includes("itineraries") && (
              <Element
                style={{ color: "white" }}
                name="itineraries"
                className="element"
                id="itineraries"
                // onClick={()=>handleNavigationSelect("itineraries")}
              >
                <ItinerariesContainers
                  addItinerary={addItinerary}
                  handleSidebarItem={handleSidebarItem}
                  scheduleMaster={props.sideBarItemData}
                  sideBarItemData={props.sideBarItemData}
                  deleteItinerary={props.deleteItinerary}
                />
              </Element>
            )}

            {cleanOptions.includes("flights") && (
              <Element
                style={{ color: "white" }}
                name="flights"
                className="element"
                id="flights"
                // onClick={()=>handleNavigationSelect("flights")}
              >
                <FlightContainer {...props} />
              </Element>
            )}

            {cleanOptions.includes("hotels") && (
              <Element
                style={{ color: "white" }}
                name="hotels"
                className="element"
                id="hotels"
                // onClick={()=>handleNavigationSelect("hotels")}
              >
                <HotelContainers
                  deleteHotel={props.deleteHotel}
                  handleSidebarItem={handleSidebarItem}
                  hotels={hotels}
                  addHotel={props.addHotel}
                  {...props}
                />
              </Element>
            )}

            {cleanOptions.includes("transportation") && (
              <Element
                style={{ color: "white" }}
                name="transportation"
                className="element"
                id="transportation"
                // onClick={()=>handleNavigationSelect("ground")}
              >
                <GroundContainers
                  deleteGround={props.deleteGround}
                  addGround={props.addGround}
                  sideBarItemData={props.sideBarItemData}
                  handleSidebarItem={handleSidebarItem}
                  grounds={grounds}
                />
              </Element>
            )}

            {cleanOptions.includes("contacts") && (
              <Element
                style={{ color: "white" }}
                name="contacts"
                className="element"
                id="contacts"
                // onClick={()=>handleNavigationSelect("contacts")}
              >
                <ContactsContainers
                  event={event?.GUID}
                  addContact={addContact}
                  contacts={contacts}
                  handleSidebarItem={handleSidebarItem}
                  sideBarItemData={props.sideBarItemData}
                />
              </Element>
            )}

            {cleanOptions.includes("attachments") && (
              <Element
                style={{ color: "white" }}
                name="attachments"
                className="element"
                id="attachments"
                // onClick={()=>handleNavigationSelect("attachments")}
              >
                <AttachmentsContainers
                  addAttachmentDropbox={props.addAttachmentDropbox}
                  getAttachmentsEvent={getAttachmentsEvent}
                  addAttachment={addAttachment}
                  event={event?.GUID}
                  attachments={attachments}
                  handleSidebarItem={handleSidebarItem}
                  sideBarItemData={props.sideBarItemData}
                />
              </Element>
            )} */}
            <Element
              style={{ color: "white" }}
              // name="attachments"
              // className="element"
              // id="attachments"
              // onClick={()=>handleNavigationSelect("attachments")}
            >
              <div
                className="w-100"
                style={{ height: "calc(100vh - 440px)" }}
              ></div>
            </Element>
          </div>
          <div className="modalSection shadow-sm">
            <Scrollbars>
              <div className="sidebar__item  p-0 px-2">
                {renderSidebarItem(currentSideBarItem, props)}
              </div>
            </Scrollbars>
            <ModalComponent
              toggle={toggleSidebarEvents}
              modal={showEventsSidebar}
              childeren={
                <SideEvents
                  fromGuestList={false}
                  toggle={toggleSidebarEvents}
                  events={eventsList}
                  selectedEvent={event}
                  renderBy={"navigation"}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
