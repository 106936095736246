import React, { useEffect, useState } from "react";
import api, { apiService } from "../../../common/api";
import { ExportChildItem } from "./ExportChildItem";
import { ExportItemControlMenu } from "./ExportItemControlMenu";
import { ExportItemItinerary } from "./ExportItemItinerary";

const ApiUrlBySection = {
  // Header: "/Event/GetEventHeader",
  Itineraries: "/ScheduleMaster/ScheduleForEvent",
  Flights: "/Flights/FlightsForEvent",
  Hotels: "/Hotels/HotelForEvent",
  Transportation: "/Grounds/GroundForEvent",
  Contacts: "/ProductionVenueContact/GetProductionVenueContact",
  Attachments: "/Attachment/Attachments",
  // Upcoming: "/Event/GetUpcomingEvents",
};

export const ExportItem = ({
  icon,
  title,
  setSectionsParams,
  sectionsParams,
  selectedEvent,
  setSelectedEvent,
}) => {
  const sectionKey =
    title === "Itineraries" ? "ItinerariesShow" : `${title}Show`;

  const [sectionData, setSectionData] = useState([]);
  const [sectionShow, setSectionShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSectionShow = () => {
    setSectionShow((prev) => !prev);
  };

  const handleSelect = (e) => {
    e?.stopPropagation();
    setSectionsParams((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
      [title]: [],
    }));
  };

  const setDocumentSectionPosition = (hasData) => {
    setSectionsParams((prev) => {
      const currentPosition = prev.Position || [];
      const newPosition = {
        order: currentPosition?.length + 1,
        section: title,
        align: null,
      };
      const exist = currentPosition.some((item) => item.section === title);
      if (!exist && hasData) currentPosition.push(newPosition);
      return {
        ...prev,
        Position: currentPosition,
      };
    });
  };

  const fetchLocalHeaderData = () => {
    return {
      section: "Header",
      data: [
        {
          GUID: "logo",
          Name: "Logo",
        },
        {
          GUID: "venue",
          Name: "Venue",
        },
        {
          GUID: "event",
          Name: "Event",
        },
      ],
    };
  };

  const fetchSectionData = async () => {
    const dataInResponseTitles = ["Hotels", "Transportation"];
    const fetchFromApi = ["Hotels", "Contacts"];
    const apiToUse = fetchFromApi.includes(title) ? api : apiService;

    const { data } =
      title == "Header"
        ? fetchLocalHeaderData()
        : ApiUrlBySection[title]
        ? await apiToUse.get(
            `${ApiUrlBySection[title]}?EventGUID=${selectedEvent?.GUID}`
          )
        : [];

    let stateData =
      title === "Flights"
        ? data?.Response?.reduce((acc, current) => {
            const x = acc.find(
              (item) => item.Connection === current.Connection
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : title === "Attachments"
        ? data?.filter((item) => !item.Deleted)
        : dataInResponseTitles.includes(title)
        ? data.response
        : data;

    if (title === "Itineraries") {
      stateData = stateData?.map((data) => ({
        ...data,
        Schedules: data?.Schedules?.filter((item) => !item.Deleted).sort(
          (a, b) => new Date(a.Date) - new Date(b.Date)
        ),
      }));
    }

    setSectionData({
      section: title,
      data: stateData?.filter((item) => !item.Deleted),
    });
    setDocumentSectionPosition(
      title === "UpcomingEvents" || title === "Header" || title === "Notes"
        ? true
        : stateData?.length > 0
    );
  };

  useEffect(() => {
    if (selectedEvent) {
      fetchSectionData();
    }
    setSectionShow(false);
  }, [selectedEvent, sectionsParams[sectionKey]]);

  useEffect(() => {
    if (title !== "Itineraries") {
      const updatedSelectedItems = sectionData?.data?.map((item) => {
        // handleParams(title === "Flights" ? item.Connection : item.GUID);
        setSectionsParams((prev) => {
          return {
            ...prev,
            [title]: prev[title]?.concat(
              title === "Flights" ? item.Connection : item.GUID
            ),
          };
        });
        return title === "Flights" ? item.Connection : item.GUID;
      });
      setSelectedItems(updatedSelectedItems);
    }
  }, [sectionData, selectedEvent]);

  function handleParams(item) {
    setSectionsParams((prev) => {
      let currentSection = prev[title] ?? [];
      if (currentSection.includes(item)) {
        currentSection = currentSection.filter(
          (currentItem) => currentItem !== item
        );
      } else {
        currentSection.push(item);
      }
      return {
        ...prev,
        [title]: currentSection,
      };
    });
  }

  const renderChildItems = () => {
    switch (title) {
      case "Itineraries":
        return (
          sectionData.data &&
          sectionData?.data?.map((section, index) => (
            <ExportItemItinerary
              key={index + section.GUID}
              section={section}
              sectionName={title}
              sectionItems={section.Schedules}
              setSectionsParams={setSectionsParams}
              sectionKey={sectionKey}
              sectionsParams={sectionsParams}
              isParentChecked={sectionsParams[sectionKey]}
            />
          ))
        );
      case "Header":
      case "Flights":
        return (
          <div className="bg-light">
            {sectionData?.data &&
              selectedItems?.length > 0 &&
              sectionData?.data?.map((section, index) => (
                <ExportChildItem
                  key={index + section.GUID}
                  item={section}
                  section={title}
                  selectedItems={selectedItems || []}
                  setSectionsParams={setSectionsParams}
                  sectionsParams={sectionsParams}
                  isParentChecked={sectionsParams[sectionKey]}
                  handleParams={handleParams}
                />
              ))}
          </div>
        );
      case "Hotels":
      case "Transportation":
      case "Contacts":
      case "Attachments":
      case "Upcoming Events":
      case "Notes":
        return (
          <div className="bg-light">
            {sectionData?.data &&
              selectedItems?.length > 0 &&
              sectionData?.data?.map((section, index) => (
                <ExportChildItem
                  key={index + section.GUID}
                  item={section}
                  section={title}
                  selectedItems={selectedItems || []}
                  setSectionsParams={setSectionsParams}
                  sectionsParams={sectionsParams}
                  isParentChecked={sectionsParams[sectionKey]}
                  handleParams={handleParams}
                />
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    (title === "UpcomingEvents" ||
      title === "Notes" ||
      sectionData?.data?.length > 0) && (
      <div
        className="d-flex flex-column w-100"
        style={{ transition: "all 1s" }}
      >
        <div
          className="d-flex align-items-center px-4 py-4 border clickable w-100"
          onClick={handleSectionShow}
          style={{ position: "relative", zIndex: 100 }}
        >
          <input
            style={{ width: "15px", height: "15px" }}
            className="mr-2 pointer"
            type="checkbox"
            checked={sectionsParams[sectionKey]}
            onChange={handleSelect}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          {typeof icon === "function" ? (
            icon()
          ) : (
            <i className={`fa ${icon}`}></i>
          )}
          <p className="ml-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            {title === "UpcomingEvents"
              ? "Upcoming Events"
              : title === "Notes"
              ? "Notes"
              : title}
          </p>
          {
            <i
              className={`fa fa-angle-up collap-arrow ${
                !sectionShow ? "active" : "inactive"
              } ml-auto fa-lg`}
            ></i>
          }
        </div>

        <div
          className={`export-item-conatiner ${
            sectionShow ? "active" : "inactive"
          }`}
        >
          {
            <ExportItemControlMenu
              section={title}
              setSectionsParams={setSectionsParams}
              sectionsParams={sectionsParams}
            />
          }
          {renderChildItems()}
        </div>
      </div>
    )
  );
};
