import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import { organizationUsers } from "../../common/helpers";
import { getOrganization } from "../../utils";
import { UserGroupComponent } from "./UserGroupComponent";

const UsersSelector = (props) => {
  const [state, setState] = useState({
    OrganizationUsers: [],
    // SelectedUsers: props?.receivers || [],
    UI: {
      IsLoading: false,
      Search: "",
    },
  });

  const requestUsers = async () => {
    let theOrganization = getOrganization();
    let users = await organizationUsers(theOrganization.GUID);

    setState((prev) => {
      return {
        ...prev,
        OrganizationUsers:
          props.receivers.length === 0
            ? users
            : users.map((org) => {
                console.log({ org, receivers: props.receivers });

                let staffs = org?.Staffs?.map((user) => {
                  let isChecked = props.receivers.some((receiver) => {
                    return (
                      receiver.GUID === user.Account.GUID &&
                      receiver.RoleGUID === user.RoleGUID
                    );
                  });
                  return {
                    ...user,
                    isChecked,
                  };
                });

                let orgIsChecked = [];
                staffs.forEach((staff) => {
                  {
                    orgIsChecked.push(staff.isChecked ? 1 : 0);
                  }
                });

                let isChecked = orgIsChecked.every((isChecked) => isChecked);

                return {
                  ...org,
                  isChecked,
                  Staffs: staffs,
                };
              }),
      };
    });
  };

  const selectAll = (organization) => {
    setState((prev) => {
      return {
        ...prev,
        OrganizationUsers: prev.OrganizationUsers.map((org) => {
          if (org.GUID === organization.GUID) {
            org.isChecked = !org.isChecked;
            org.Staffs = org.Staffs.map((staff) => {
              if (org.isChecked) {
                staff.isChecked = true;
              } else {
                staff.isChecked = false;
              }
              return staff;
            });
          }
          return org;
        }),
      };
    });
  };

  const toggleMember = (organization, staff) => {
    setState((prev) => {
      let selectedUsers = prev.OrganizationUsers.map((org) => {
        if (org.GUID === organization.GUID) {
          org.Staffs = org.Staffs.map((user) => {
            if (user.GUID === staff.GUID) {
              user.isChecked = !user.isChecked;
            }
            return user;
          });
        }
        return org;
      });

      let checkedOrganizations = selectedUsers.map((org) => {
        let orgIsChecked = [];
        org.Staffs.forEach((staff) => {
          orgIsChecked.push(staff.isChecked ? 1 : 0);
        });

        let isChecked = orgIsChecked.every((isChecked) => isChecked);
        org.isChecked = isChecked;
        return org;
      });
      return {
        ...prev,
        OrganizationUsers: checkedOrganizations,
      };
    });
  };

  const handleSubmit = () => {
    let selectedUsers = [];
    state.OrganizationUsers.forEach((org) => {
      org.Staffs.forEach((user) => {
        if (user.isChecked) {
          selectedUsers.push(user);
        }
      });
    });
    props.handleReceiversSelect(selectedUsers);
  };

  useEffect(() => {
    requestUsers();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 560);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 560);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div
      className={isMobile ? "overflow-y-hidden" : "organization-transparent"}
      style={{ background: "#ffffff", height: "100vh" }}
    >
      <div className={isMobile ? "overflow-y-hidden" : "event-page"}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="row">
              <div className="text-left col">
                <h1 className="title">
                  <FormattedMessage
                    id={props.menuTitleId ?? "selectUser"}
                    defaultMessage={props.menuTitle ?? "Select user"}
                  />
                </h1>
              </div>
              <div className="col-auto ml-auto d-none">
                <div className="formAlternativeStyle">
                  <input
                    type="text"
                    value={state.UI.Search}
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      setState((prev) => {
                        const { value } = e.target;
                        return {
                          ...prev,
                          UI: {
                            ...prev.UI,
                            Search: value,
                          },
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-1 py-3">
            <div className="col-12 mx-0 px-0">
              <Scrollbars style={{ height: "calc(100vh - 125px)" }}>
                {state?.OrganizationUsers?.filter(
                  (organization) => organization.Deleted === null
                ).map((organization) => {
                  return (
                    <UserGroupComponent
                      organization={organization}
                      HandleSelectAll={selectAll}
                      HandleSelectUser={toggleMember}
                    />
                  );
                })}
              </Scrollbars>
              <div className="submit-button">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn primary"
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersSelector;
