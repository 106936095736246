import useDrivePicker from "react-google-drive-picker";

export const getGooglePickerToken = () => {
  const token = JSON.parse(localStorage.getItem("GOOGLE_PICKER_TOKEN"));
  if (token) {
    const { providedDate, expires_in } = token;
    const now = new Date().getTime();
    const diff = providedDate + expires_in * 1000 - now;
    const remaining = isNaN(Math.round(diff / 60000))
      ? 0
      : Math.round(diff / 60000);
    const isExpired = remaining <= 0;

    if (isExpired) {
      localStorage.removeItem("GOOGLE_PICKER_TOKEN");
      return {
        ...token,
        isExpired: true,
        remaining,
      };
    }
    return {
      ...token,
      isExpired: false,
      remaining,
    };
  }
  return null;
};

export const useGooglePicker = (clientId, developerKey, callBack) => {
  const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  let token = getGooglePickerToken();
  if ((authResponse && !token) || token?.isExpired) {
    token = {
      ...authResponse,
      providedDate: new Date().getTime(),
      isExpired: false,
      remaining: 60,
    };
    localStorage.setItem("GOOGLE_PICKER_TOKEN", JSON.stringify(token));
  }

  const handleOpenGooglePicker = () => {
    openPicker({
      clientId,
      developerKey,
      viewId: "DOCS",
      token: token?.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: callBack,
    });
  };

  return {
    handleOpenGooglePicker,
  };
};
