import React, { useEffect } from "react";

const controlMenuItems = [
  {
    icon: "fa-arrow-up",
    title: "Move Up",
    show: true,
  },
  {
    icon: "fa-arrow-down",
    title: "Move Down",
    show: true,
  },
  {
    icon: "fa-align-left",
    title: "Left",
    show: true,
  },
  {
    icon: "fa-align-center",
    title: "center",
    show: true,
  },
  {
    icon: "fa-align-right",
    title: "Right",
    show: true,
  },
];

export const ExportItemControlMenu = ({
  section,
  setSectionsParams,
  sectionsParams,
}) => {
  const moveItem = (array, fromIndex, toIndex) => {
    const element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
  };

  const findItemIndex = (array, section) => {
    return array.findIndex((item) => item.section === section);
  };

  const moveItemUp = (array, index) => {
    if (index > 0) {
      moveItem(array, index, index - 1);
    }
  };

  const moveItemDown = (array, index) => {
    if (index < array.length - 1) {
      moveItem(array, index, index + 1);
    }
  };

  const adjustOrder = (array, action, section) => {
    const currentIndex = findItemIndex(array, section);

    if (currentIndex !== -1) {
      if (action === "Move Up") {
        moveItemUp(array, currentIndex);
      } else if (action === "Move Down") {
        moveItemDown(array, currentIndex);
      }
    }
  };

  const handleChangePosition = (action) => {
    setSectionsParams((prev) => {
      const currentPosition = prev.Position || [];

      const newPosition = {
        order: currentPosition.length + 1,
        section,
        align: action === "Move Down" || action === "Move Up" ? null : action,
      };

      const exist = currentPosition.some((item) => item.section === section);

      if (!exist) currentPosition.push(newPosition);

      if (
        (exist && currentPosition.length > 1 && action === "Move Up") ||
        action === "Move Down"
      ) {
        adjustOrder(currentPosition, action, section);
      } else {
        currentPosition.forEach((item) => {
          if (item.section === section) {
            item.align =
              action === "Move Down" || action === "Move Up" ? null : action;
          }
        });
      }

      currentPosition.forEach((item, index) => {
        item.order = index + 1;
      });

      return {
        ...prev,
        Position: currentPosition,
      };
    });
  };

  const { Position } = sectionsParams;

  const sectionIndex = Position?.findIndex((item) => item.section === section);

  return (
    <div
      className="p-2 d-flex w-100 border bg-white"
      style={{ position: "relative", zIndex: 50 }}
    >
      {controlMenuItems?.map((item, index) => {
        if (
          section === "Hotels" &&
          (item.title === "Move Up" || item.title === "Move Down")
        )
          return null;
        return (
          <button
            onClick={() => handleChangePosition(item.title)}
            className="btn border mr-2"
            key={index + item.title}
            disabled={
              (sectionIndex === 0 && item?.title === "Move Up") ||
              (sectionIndex === Position?.length - 1 &&
                item.title === "Move Down")
            }
          >
            <i className={`fas ${item.icon}`}></i>
          </button>
        );
      })}
    </div>
  );
};
