import React from "react";
import CustomDropDrownMenu from "../common/CustomDropDrownMenu";

export const PriorityDropDown = ({
  priorityItems,
  selectedPriority,
  isEditable,
  handleSelectPriority,
  isMobile,
}) => {
  return isEditable ? (
    <CustomDropDrownMenu
      renderBy="todos"
      items={priorityItems}
      selectedItemProp={"name"}
      selectedItem={selectedPriority}
      handleSelectedItem={handleSelectPriority}
      DrownDownProp={"name"}
      toogleStyle={{
        borderRadius: "20px",
        padding: "10px 10px 10px 10px",
        border: "none",
      }}
      toogleClass={selectedPriority?.style ?? "secondary"}
      emptyStateComponent={() => (
        <>
          <i className="fa fa-exclamation mr-1"></i>Priority
        </>
      )}
    />
  ) : (
    selectedPriority.id !== null && (
      <span
        className={`btn btn-outline-${
          selectedPriority.style ?? "secondary"
        } rounded`}
        style={{
          cursor: "auto",
          pointerEvents: "none",
          fontSize: "14px",
        }}
      >
        {selectedPriority.name[0] ?? ""}
      </span>
    )
  );
};
