import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../constant";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Settings } from "./DocsComponent";

export const SpreadSheets = (props) => {
  const spreadsheetRef = props.forwardRef;
  const [fileSeleted, setFileSelected] = useState(null);
  const [fileName, setfileName] = useState(null);

  const beforeSave = (args) => {
    // setCustomParams({ customParams: 'you can pass custom params in server side' });
    //args.customParams = customParams; // you can pass the custom params
    args.fileName = props?.file?.Title;
    args.isFullPost = false;
    //args.needBlobData = true
  };

  useEffect(() => {
    //Now let's try to get the file and open
    // if (props?.file?.Path.includes("newFile")) return;
    let fullPath = `${Settings.appApiUrl}files/${props?.file?.Path}`;
    setfileName(`${props?.file?.name}`);
    const fetchData = async () => {
      const response = await fetch(fullPath, {
        mode: "no-cors",
      }); // fetch the remote url
      const fileBlob = await response.blob(); // convert the excel file to blob
      const file = new File([fileBlob], props?.file?.Name); //convert the blob into file
      let spreadsheet = spreadsheetRef.current;
      if (spreadsheet) {
        spreadsheet.open({ file }); // open the file into Spreadsheet
      }
    };
    props?.file && fetchData();
  }, []);

  const handleSaveChanges = (e) => {
    e?.preventDefault();
    let spreadsheet = spreadsheetRef.current;
    spreadsheet.save();
  };

  const loadUI = () => {
    return (
      <SpreadsheetComponent
        ref={spreadsheetRef}
        dialogBeforeOpen={(args) => {
          console.log({ args });
          if (args.dialogName === "Save As") {
            args.cancel = true;
            let spreadsheet = spreadsheetRef.current;
            spreadsheet.save();
          }
        }}
        beforeSave={beforeSave}
        allowSave={true}
        allowOpen={true}
        saveUrl={`${Settings.serviceApiUrl}file/SaveExcel`}
        openUrl={`${Settings.serviceApiUrl}file/OpenSpreadsheet`}
      />
    );
  };

  if (props.modal) {
    return (
      <Modal
        show={props.modal}
        onHide={props.toggle}
        className="overflow-hidden"
        size="xl"
        style={{ margin: 0, padding: 0 }}
      >
        <div
          className="e-documents-container bg-light d-flex flex-column align-items-center"
          style={{ width: "60vw", position: "relative", right: "-70px" }}
        >
          {!props.hideHeader && (
            <div className="w-100 d-flex align-items-center justify-content-center px-5 px-2">
              <img
                src={IMAGES.LOGO_BLACK}
                alt="Plnify"
                height={60}
                width={130}
              />
              <p
                style={{ fontSize: "40px", fontWeight: "bold", color: "black" }}
              >
                Sheets
              </p>
            </div>
          )}
          {loadUI()}
        </div>
      </Modal>
    );
  }

  return (
    <div className="e-documents-container w-100">
      {!props.hideHeader && (
        <div className="w-100 d-flex align-items-center justify-content-center px-5 px-2">
          <img src={IMAGES.LOGO_BLACK} alt="Plnify" height={60} width={130} />
          <p style={{ fontSize: "40px", fontWeight: "bold" }}>Sheets</p>
        </div>
      )}
      {loadUI()}
    </div>
  );
};
