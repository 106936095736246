import React, { useEffect, useState } from "react";
import { IMAGES } from "../../constant";
import ContactItem from "./contactsItem";
import EmptyState from "../common/emptyState";
import { Scrollbars } from "react-custom-scrollbars";
import { checkSelectionStatus, exist } from "../../common/helpers";
import Swal from "sweetalert2";
import api from "../../common/api";

import { ModalComponent } from "..";
import EmptyStateImg from "../../images/contacts-empty.svg";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { getContacts } from "../../page/event/redux/getContacts";
import ExportToEvent from "../../components/common/ExportToEvent";

const Contacts = (props) => {
  let {
    toggleModal,
    contacts,
    handleSidebarItem,
    sideBarItemData,
    deleteContact,
    // toggleExport,
  } = props;

  const dispatch = useDispatch();

  const [state, setState] = useState({
    SelectedItems: [],
    UI: {
      showExportToEvent: false,
      exportMode: "",
    },
  });

  const toggleExportToEvent = (exportMode, reload) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        showExportToEvent: !prev.UI.showExportToEvent,
        exportMode,
      },
    }));

    if (reload) {
      dispatch(getContacts(props.originEvent));
    }
  };

  const intl = useIntl();

  const deleteAllSelected = () => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete all selected items",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let query = {
          Items: "",
          Events: "",
        };

        state?.SelectedItems.forEach((item) => {
          query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;
        });
        await api.post("/ProductionVenueContact/DeleteBatch", query);
        dispatch(getContacts(props.originEvent));
      }
    });
  };

  const selectAll = () => {
    let SelectedItems = JSON.parse(JSON.stringify(state?.SelectedItems));
    let filter = contacts;
    let checkSelection = checkSelectionStatus(contacts, null, SelectedItems);
    debugger;
    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        }
      });

      setState((prev) => ({ ...prev, SelectedItems }));
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      setState((prev) => ({ ...prev, SelectedItems: [] }));
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        } else {
          //Check if deleted
        }
      });

      setState((prev) => ({ ...prev, SelectedItems }));
    }
  };

  const toggleSelect = (item) => {
    let SelectedItems = JSON.parse(JSON.stringify(state?.SelectedItems));
    if (exist(SelectedItems, item)) {
      SelectedItems = SelectedItems.filter((x) => x.GUID !== item.GUID);
    } else {
      SelectedItems.push(item);
    }
    setState((prev) => ({ ...prev, SelectedItems }));

    console.log("new state", state);
  };

  function setContact() {
    if (contacts.length) {
      handleSidebarItem("CONTACTS", contacts[0]);
    }
  }

  //Delete Selected Items on Delete Key Down
  useEffect(() => {
    const handleDeleteKey = ({ key }) => {
      if (key === "Delete") {
        deleteAllSelected();
      }
    };
    const rootElement = document.getElementById("root");
    rootElement.addEventListener("keydown", handleDeleteKey);

    return () => {
      rootElement.removeEventListener("keydown", handleDeleteKey);
    };
  }, [state.SelectedItems]);

  return (
    <>
      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state?.UI?.showExportToEvent}
        childeren={
          <ExportToEvent
            originEvent={props.originEvent}
            exportMode={state?.UI.exportMode}
            items={state?.SelectedItems}
            toggle={toggleExportToEvent}
            section="contact"
          />
        }
      />
      <div className="resource shadow-sm">
        <div className="resource__header " onClick={() => setContact()}>
          <div className="row">
            <div className="col-auto px-0 py-1">
              <button
                className="btn btn-sm text-secondary"
                onClick={() => selectAll()}
                type="button"
              >
                {checkSelectionStatus(
                  contacts,
                  null,
                  JSON.parse(JSON.stringify(state?.SelectedItems))
                ) === 0 ? (
                  <i className="far fa-square"></i>
                ) : checkSelectionStatus(
                    contacts,
                    null,
                    JSON.parse(JSON.stringify(state?.SelectedItems))
                  ) === 1 ? (
                  <i className="fas fa-check-square"></i>
                ) : (
                  <i className="fas fa-minus-square"></i>
                )}
              </button>
            </div>
            <div className="col-5 px-0 py-1">
              <div className="head-left pt-1">
                <h4>
                  <FormattedMessage
                    id="eventContacts"
                    defaultMessage={props.blockName}
                  />
                </h4>
              </div>
            </div>

            <div className="col-auto px-0 pr-3 text-right ml-auto py-1">
              <div className="resource_button-right">
                <div className="filter-icon green d-none">
                  <img src={IMAGES.EXPORT} alt="dot" width="20" height="20" />
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => toggleModal()}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
            {checkSelectionStatus(
              contacts,
              null,
              JSON.parse(JSON.stringify(state?.SelectedItems))
            ) !== 0 ? (
              <div className="col-12 bg-subOption text-dark text-center py-2">
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("export")}
                >
                  <FormattedMessage
                    id="moveToAnotherEvent"
                    defaultMessage="Move to another event"
                  />
                </button>
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("copy")}
                >
                  <FormattedMessage
                    id="copyToAnotherEvent"
                    defaultMessage="Copy to another event"
                  />
                </button>
                <button
                  className="btn btn-danger mx-2 btn-sm"
                  onClick={() => deleteAllSelected()}
                >
                  <FormattedMessage
                    id="deleteSelected"
                    defaultMessage="Delete Selected"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ height: 250 }} className="selectorContainer">
          <Scrollbars style={{ width: "100%", height: 250 }}>
            {contacts.length === 0 ? (
              <EmptyState
                image={EmptyStateImg}
                cta="Add a new contact"
                text="No contacts added yet"
                actionBtn={{
                  action: toggleModal,
                  title: "Add Contacts",
                }}
              />
            ) : (
              <>
                {contacts &&
                  contacts
                    .sort(function (a, b) {
                      var nameA = a.Name.toUpperCase(); // ignore upper and lowercase
                      var nameB = b.Name.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    })
                    .map((contact) => (
                      <ContactItem
                        key={contact.GUID}
                        contact={contact}
                        handleSidebarItem={handleSidebarItem}
                        sideBarItemData={sideBarItemData}
                        deleteContact={deleteContact}
                        //  toggleExport={toggleExport}

                        toggleSelect={toggleSelect}
                        checkSelectionStatus={checkSelectionStatus}
                        selectedItems={state?.SelectedItems}
                      />
                    ))}
              </>
            )}
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

export default Contacts;
