import React, { useEffect, useState } from "react";
import api from "../../common/api";
import { getBasicInfo } from "../../utils";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import db from "../../common/db";
import { deleteDraftEmail, deleteFromDraft } from "../../utils/closeMailModal";

const MailDrafts = (props) => {
  const intl = useIntl();

  const [search, setSearch] = useState("");

  const filteredDrafts =
    props?.draftEmails?.Records?.drafts?.filter(
      (email) =>
        email?.Title === undefined ||
        email?.Title === null ||
        email?.Title?.toLowerCase()?.includes(search?.toLowerCase())
    ) || props?.draftEmails?.Records?.drafts;

  return (
    <div
      className={props.className ? props.className : "organization-transparent"}
      style={props.isMobile ? {} : { width: "500px" }}
    >
      <div
        className="text-head headerBg d-flex align-items-center"
        style={{ padding: "6.5px" }}
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center w-50">
            {props.showSideBar && (
              <buttom
                className="btn mr-1"
                onClick={() => props.setShowSideBar((prev) => !prev)}
              >
                <i className="fas fa-bars"></i>
              </buttom>
            )}
            <div className={props.showSideBar ? "col-auto pl-0" : "col-auto"}>
              <h1>
                <FormattedMessage id="mailDraft" defaultMessage="Mail Draft" />
              </h1>
            </div>
          </div>
          <div className="text-right w-50">
            <div
              className={
                props.isMobile
                  ? "d-flex formAlternativeStyle justify-content-end"
                  : "d-flex  formAlternativeStyle justify-content-end align-items-center"
              }
            >
              <input
                type="text"
                className={
                  props.isMobile ? "form-control w-50" : "form-control"
                }
                placeholder={intl.formatMessage({ id: "search" })}
                value={search}
                onChange={async (e) => {
                  setSearch(e?.target?.value);
                  if (
                    e?.target?.value?.length === 0 ||
                    e?.target?.value?.length > 2
                  ) {
                    await props.requestRemote();
                  }
                }}
              />
              {!props.isMobile && (
                <button
                  className="btn btn-secondary ml-1 h-75"
                  onClick={() => props.setCurrentView("")}
                >
                  <i className="fas fa-angle-left"></i>
                </button>
              )}
              {props.isMobile && (
                <button className="btn btn-primary ml-1" onClick={props.toggle}>
                  Done
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="event-inside p-0 px-2"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 60px)",
        }}
      >
        <div className="event-content">
          {filteredDrafts && filteredDrafts.length > 0 ? (
            filteredDrafts.map((item) => {
              return (
                <div
                  key={item.GUID}
                  className={
                    props.currentEmail.id === item.GUID
                      ? "card shadow-sm my-2 d-flex flex-row align-items-center clickable active pr-2"
                      : "card shadow-sm my-2 d-flex flex-row align-items-center clickable pr-2"
                  }
                  onClick={() => {
                    props.selectedEmail(item, true);
                    props.setCurrentEmail({
                      id: item.GUID,
                      section: "drafts",
                    });
                    // props.toggle();
                  }}
                >
                  <div className="card-body p-1 text-dark w-75">
                    <div className="row px-2">
                      <div className="col text-truncate">
                        <span className="badge badge-danger mr-1">Draft</span>
                        <b>{item.Title}</b>
                      </div>
                      <div className="col-auto ml-auto text-right">
                        <small className="text-muted">
                          {moment(item.Date).fromNow()}
                        </small>
                        <br />
                      </div>
                      <div className="col-12 text-truncate">
                        <small>
                          <FormattedMessage id="for" defaultMessage="For" />:{" "}
                          {item.Receivers}
                        </small>
                      </div>
                    </div>
                  </div>
                  {item?.attachments?.length > 0 && (
                    <i
                      className="fas fa-paperclip text-black-50 mr-2"
                      title="Attachments"
                    ></i>
                  )}
                  <button
                    title="Delete"
                    className="btn btn-danger border-0 h-25"
                    onClick={() => {
                      deleteDraftEmail(item, props.requestRemote);
                      props.setCurrentEmail({
                        id: "",
                        section: "",
                      });
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              );
            })
          ) : (
            <div className="alert alert-info d-flex justify-content-center align-items-center mt-1">
              <span>No Draft Found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MailDrafts;
