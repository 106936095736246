import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import db from "../../../common/db";
import { getBasicInfo } from "../../../utils";
import { useIntl, FormattedMessage } from "react-intl";

const Weather = (props) => {
  const basicInfoGUID = getBasicInfo().GUID;
  const [state, setState] = useState({
    AppSettings: null,
    Weather: props.weather,
    Time: "",
  });

  const [time, setTime] = useState(moment().format("hh:mm a"));

  const loadData = async () => {
    if (state) {
      return;
    }
    try {
      let copy = JSON.parse(JSON.stringify(state));
      const appSettings = await db
        .table("appSettings")
        .where({ AccountGUID: basicInfoGUID })
        .first();
      if (appSettings) {
        if (appSettings.Temperature === undefined) {
          appSettings.Temperature = "f";
          await db.table("appSettings").put(appSettings);
        }

        copy.AppSettings = appSettings;
        if (state) {
          setState(copy);
        }
        //}, 500)
      } else {
        let newAppSettings = {
          AccountGUID: basicInfoGUID,
          Temperature: "f",
        };
        await db.table("appSettings").put(newAppSettings);

        // state.AppSettings.set(newAppSettings);

        copy.AppSettings = newAppSettings;
        if (state) {
          setState(copy);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  let intervalId = useRef(null);
  useEffect(() => {
    setTimeout(async () => {
      await loadData();
    }, 3000);
  }, []);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (props.weather) {
        setTime(
          moment.utc().add(props.weather.GMTTime, "hours").format("hh:mm a") +
            " " +
            props.weather.TimeZoneCode
        );
      }
    }, 500);

    return () => {
      console.log("is going to clean the weather");
      clearInterval(intervalId.current);
    };
  });

  const loadDegrees = () => {
    if (state.error) {
      console.error("the state has error", state.error);
      debugger;
    }
    if (state.promised) {
      console.error("state is loading", state.promised);
      debugger;
    }
    if (!state?.AppSettings?.Temperature) {
      //  console.log("for some reason the temperature is not here", props, state);
      return (
        <p className="weather__deegre ">
          {Math.round(props.weather.temp_f)}
          <span className="degreeThing">°f</span>
        </p>
      );
    }
    if (state?.AppSettings?.Temperature === "f") {
      return (
        <p className="weather__deegre ">
          {Math.round(props.weather.temp_f)}
          <span className="degreeThing">°f</span>
        </p>
      );
    } else if (state.AppSettings.Temperature === "c") {
      return (
        <p className="weather__deegre">
          {Math.round(props.weather.temp_c)}
          <span className="degreeThing">°c</span>
        </p>
      );
    } else {
      return null;
    }
  };

  if (!props.weather) {
    return <></>;
  }
  return (
    <div className="weather__box d-flex align-items-stretch justify-content-between w-100">
      <div className="weather__box-left  row">
        <div className="col-6 p-2">
          <p className="weather__city">
            {state && props.weather && props.weather.PlaceName}
          </p>
          {loadDegrees()}

          <div className="row">
            <div className="col px-0">
              {props.weather.wx_icon ? (
                <p className="text-white">
                  <img
                    src={props.weather.wx_icon}
                    className="img-fluid"
                    style={{ height: "16px" }}
                    alt=""
                  />
                </p>
              ) : null}
              <p className="weather__type">{props.weather.wx_desc}</p>
            </div>
            <div className="col text-right">
              <button
                className="btn btn-success mt-2"
                type="button"
                onClick={() => {
                  window.open(
                    props.weather.Link,
                    "MapWindow",
                    "width=1280,height=840"
                  );
                }}
              >
                <FormattedMessage id="seeMore" defaultMessage="See more" />
              </button>
            </div>
          </div>
        </div>
        {props.weather.GMTTime || props.weather.TimeZoneCode === "GMT" ? (
          <div className="col-6 p-2 leftWhiteBorder">
            <p className="weather__city">
              <FormattedMessage
                id="currentTimeLocation"
                defaultMessage="Current Time at location"
              />
            </p>
            <p>{time}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Weather;
