import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { ModalComponent, ComponentLoader } from "../common";
import { IMAGES, ROUTES } from "../../constant";
import { Link, useHistory } from "react-router-dom";
import { getOrganization, getBasicInfo } from "../../utils";
import { parseLogo } from "../../utils";
import { ImageArea } from "mdi-material-ui";

const OrganizationItem = (props) => {
  const organization = useSelector((state) => state.organization);
  const currentOrganization = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const [state, setState] = useState({
    modal: false,
    loading: false,
  });

  const history = useHistory();
  const OpenTeam = () => {
    history.push("/users");
  };

  const { toggleOrganizations } = props;
  let img =
    currentOrganization.Logo === undefined || currentOrganization.Logo == null
      ? IMAGES.Logo
      : currentOrganization.Logo !== undefined &&
        currentOrganization.Logo.includes("http")
      ? currentOrganization.Logo
      : "https://service.plnify.com/files/" + currentOrganization.Logo;

  return (
    <>
      {currentOrganization && (
        <div className="organizationItem d-flex justify-content-start align-items-center mb-0 px-0 ">
          <div className="organizationItem__photo active ml-3 ">
            {currentOrganization.Logo ? (
              <img
                src={img}
                alt={currentOrganization.Name}
                className="img-responsive"
              />
            ) : null}
          </div>

          <div className="organizationItem__detail justify-content-center flex-column ">
            <p className="pb-2" onClick={() => OpenTeam()}>
              {currentOrganization.Name}
            </p>
            <div className="organizationItem__overlay-link d-none">
              <a onClick={() => toggleOrganizations()}>Change Organization</a>
              <Link className="d-none" to={ROUTES.SELECT_ORGANIZATION}>
                Change Organization
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizationItem;
