import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import { apiService } from "../../common/api";
import { DropboxKey, checkSelectionStatus, exist } from "../../common/helpers";
import { IMAGES } from "../../constant";
import EmptyStateImg from "../../images/attachments-empty.svg";
import { ModalComponent, SimpleLoader } from "../common";
import DropboxChooser from "../common/DropboxChooser";
import ExportToEvent from "../common/ExportToEvent";
import EmptyState from "../common/emptyState";
import AttachamentItem from "./attachmentsItem";
// import { OneDrivePicker } from "../common/OneDrivePicker";
// import { useOneDrivePicker } from "../../hooks/useOneDrivePicker";
import AddLinkModal from "../../page/files/AddEditLink";

const Attachments = (props) => {
  const {
    attachments,
    onChange,
    handleSidebarItem,
    addFromDropbox,
    sideBarItemData,
    confirmDeleteAttachment,
    addingAttachment,
    handleOpenGooglePicker,
    handleAddLink,
  } = props;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const intl = useIntl();
  const [AttachmentList, setAttachmentList] = useState([]);
  const [state, setState] = useState({
    SelectedItems: [],
    UI: {
      showExportToEvent: false,
      exportMode: "",
    },
  });

  useEffect(() => {
    setAttachmentList(
      props.attachments.filter(
        (x) =>
          x.Deleted === null && !x.HotelGUID && !x.RoomGUID && !x.FlightGUID
      )
    );
  }, [props.originEvent, props.attachments]);

  function setAttachment() {
    if (AttachmentList.length) {
      handleSidebarItem("ATTACHMENT", AttachmentList[0]);
    }
  }

  const toggleExportToEvent = (exportMode, reload) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        showExportToEvent: !prev.UI.showExportToEvent,
        exportMode: exportMode,
      },
    }));

    if (reload) {
      dispatch(props.actions.getAttachmentsEvent(props.originEvent));
    }
  };
  const deleteAllSelected = () => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete all selected items",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let query = {
          Items: "",
          Events: "",
        };

        state.SelectedItems.forEach((item) => {
          query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;
        });
        await apiService.post("/attachment/DeleteBatch", query);
        dispatch(props.actions.getAttachmentsEvent(props.originEvent));
        // handleSidebarItem("EVENT_DETAILS", {})
      }
    });
  };
  const selectAll = () => {
    let SelectedItems = state.SelectedItems;
    let filter = attachments;
    let checkSelection = checkSelectionStatus(
      attachments,
      null,
      state.SelectedItems
    );

    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        }
      });
      setState((prev) => ({
        ...prev,
        SelectedItems: SelectedItems,
      }));
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      setState((prev) => ({
        ...prev,
        SelectedItems: [],
      }));
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        } else {
          //Check if deleted
        }
      });
      setState((prev) => ({
        ...prev,
        SelectedItems: SelectedItems,
      }));
    }
  };

  const toggleSelect = (item) => {
    let SelectedItems = state.SelectedItems;
    if (exist(SelectedItems, item)) {
      if (
        SelectedItems.filter(
          (x) => x.GUID === item.GUID && x.Deleted === null
        )[0]
      ) {
        SelectedItems = SelectedItems.filter((x) => x.GUID !== item.GUID);
      }
    } else {
      SelectedItems.push(item);
    }

    setState((prev) => ({
      ...prev,
      SelectedItems: SelectedItems,
    }));
  };

  // const { HandleOpenOneDrive } = useOneDrivePicker(handleOneDrive);

  const handleEmptyAttachment = () => {
    //initializing dropbox
    const props = {
      appKey: DropboxKey,
      success: (files) => addFromDropbox(files),
      cancel: () => console.log("cancel"),
      multiselect: true,
      extensions: ["*"],
      handleSidebarItem: handleSidebarItem,
    };
    const dropbox = new DropboxChooser(props);
    dropbox.componentDidMount();
    dropbox.isDropboxReady();

    //adding input options to modal
    const inputOptions = {
      "Local file": "Local file",
      Dropbox: "Dropbox",
      "Google Drive": "Google Drive",
      // OneDrive: "OneDrive",
    };

    Swal.fire({
      title: `Select your file location`,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      input: "select",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "Please select upload option.";
        }
      },
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Close",
      customClass: {
        popup: "px-2 w-100 align-items-center fs-3",
        content: "m-0 p-0",
      },
    }).then((result) => {
      if (result.value === "Dropbox") {
        dropbox.onChoose();
      } else if (result.value === "Local file") {
        BrowseLocalFilesFromSweetModal();
        setAttachment();
      } else if (result.value === "Google Drive") {
        handleOpenGooglePicker();
      } else if (result.value === "OneDrive") {
        // HandleOpenOneDrive();
      }
    });
  };

  const BrowseLocalFilesFromSweetModal = async () => {
    await Swal.fire({
      title: "Choose Files",
      input: "file",
      showCancelButton: true,
      cancelButtonText: "Close",
      cancelButtonColor: "#d33",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      inputAttributes: {
        "aria-label": "",
        multiple: true,
      },
      customClass: {
        input: "btn btn-sm",
      },
    }).then(async (result) => {
      if (result?.value) {
        await onChange(result.value);
      }
    });
  };

  const [showLink, setShowLink] = useState(false);
  const toggleAddLink = () => {
    setShowLink((prev) => !prev);
  };

  const handleLinkEdit = (LinkValues) => {
    const NewLink = {
      Deleted: null,
      Event: null,
      EventGUID: null,
      GUID: null,
      ID: null,
      lastSync: null,
      name: LinkValues?.Name,
      note: null,
      url: LinkValues?.Path,
      updated: null,
    };

    handleAddLink(NewLink);
    toggleAddLink();
  };

  //Delete Selected Items on Delete Key Down
  useEffect(() => {
    const handleDeleteKey = ({ key }) => {
      if (key === "Delete") {
        deleteAllSelected();
      }
    };
    const rootElement = document.getElementById("root");
    rootElement.addEventListener("keydown", handleDeleteKey);

    return () => {
      rootElement.removeEventListener("keydown", handleDeleteKey);
    };
  }, [state.SelectedItems]);

  return (
    <>
      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state.UI.showExportToEvent}
        childeren={
          <ExportToEvent
            originEvent={props.originEvent}
            //  loading={loading}
            exportMode={state.UI.exportMode}
            items={state.SelectedItems}
            toggle={toggleExportToEvent}
            section="attachment"
            //  toggleEvent={this.toggleEvent}
            // eventsSelected={eventsSelected}
            //submit={exportToEvents}
          />
        }
      />

      <ModalComponent
        modal={showLink}
        toggle={toggleAddLink}
        childeren={
          <AddLinkModal
            Model={{}}
            toggle={toggleAddLink}
            handleLinkEdit={handleLinkEdit}
          />
        }
      />

      <div className="resource relative shadow-sm">
        <div
          className="resource__header"
          id="container"
          onClick={() => setAttachment()}
        >
          <div className="row">
            <div className="col-auto px-0 py-1">
              <button
                className="btn btn-sm text-secondary"
                onClick={() => selectAll()}
                type="button"
              >
                {checkSelectionStatus(
                  attachments,
                  null,
                  state.SelectedItems
                ) === 0 ? (
                  <i className="far fa-square"></i>
                ) : checkSelectionStatus(
                    attachments,
                    null,
                    state.SelectedItems
                  ) === 1 ? (
                  <i className="fas fa-check-square"></i>
                ) : (
                  <i className="fas fa-minus-square"></i>
                )}
              </button>
            </div>
            <div className="col-5 px-0 py-1">
              <div className="head-left pt-1">
                <h4>
                  <FormattedMessage
                    id="attachments"
                    defaultMessage={props.blockName}
                  />
                </h4>
              </div>
            </div>
            <div className="col-auto px-0 pr-3 text-right ml-auto py-1">
              <div className="resource_button-right">
                <div className="filter-icon green d-none">
                  <img src={IMAGES.EXPORT} alt="dot" width="20" height="20" />
                </div>
                {addingAttachment ? (
                  <SimpleLoader loading={addingAttachment} />
                ) : (
                  <Popup
                    closeOnDocumentClick
                    on="click"
                    trigger={
                      <button type="button" className="btn btn-sm btn-primary">
                        <i className="fa fa-plus" />
                      </button>
                    }
                    position="bottom right"
                    arrow={false}
                  >
                    {(open) => (
                      <div>
                        <div className="create-button mb-2">
                          <span
                            className="btn btn-block btn-file"
                            onClick={() => {
                              toggleAddLink();
                              open();
                            }}
                          >
                            <FormattedMessage
                              id="uploadNew"
                              defaultMessage="Add Link"
                            />
                          </span>
                        </div>
                        <div className="create-button mb-2">
                          <span className="btn btn-block btn-file">
                            <FormattedMessage
                              id="uploadNew"
                              defaultMessage="Upload Local Files"
                            />
                            <input
                              className=""
                              onChange={(e) => {
                                onChange(e);
                                open();
                              }}
                              id="inputFiles"
                              type="file"
                              multiple
                            />
                          </span>
                        </div>

                        <DropboxChooser
                          appKey={DropboxKey}
                          success={(files) => addFromDropbox(files)}
                          cancel={() => console.log("cancel")}
                          multiselect={true}
                          extensions={["*"]}
                        >
                          <div
                            style={{ fontSize: 14 }}
                            onClick={() => open()}
                            className="btn btn-block"
                          >
                            <FormattedMessage
                              id="uploadDropbox"
                              defaultMessage="Upload from Dropbox"
                            />
                          </div>
                        </DropboxChooser>
                        <div
                          className="create-button mt-2"
                          onClick={() => {
                            handleOpenGooglePicker();
                            open();
                          }}
                        >
                          <span className="btn btn-block btn-file">
                            <FormattedMessage
                              id="uploadGoogleDrive"
                              defaultMessage="Upload from Google"
                            />
                          </span>
                        </div>
                        {/* <OneDrivePicker
                          closeMenu={open}
                          handleOneDrive={handleOneDrive}
                          oneDriveAccessToken={oneDriveAccessToken}
                        /> */}
                      </div>
                    )}
                  </Popup>
                )}
              </div>
            </div>
            {checkSelectionStatus(attachments, null, state.SelectedItems) !==
            0 ? (
              <div className="col-12 bg-subOption text-dark text-center py-2">
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("export")}
                >
                  <FormattedMessage
                    id="moveToAnotherEvent"
                    defaultMessage="Move to another event"
                  />
                </button>
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("copy")}
                >
                  <FormattedMessage
                    id="copyToAnotherEvent"
                    defaultMessage="Copy to another event"
                  />
                </button>
                <button
                  className="btn btn-danger mx-2 btn-sm"
                  onClick={() => deleteAllSelected()}
                >
                  <FormattedMessage
                    id="deleteSelected"
                    defaultMessage="Delete Selected"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div
          style={
            AttachmentList.length > 5
              ? { minHeight: "calc(100vh - 12rem)" }
              : {}
          }
        >
          {AttachmentList.length === 0 ? (
            <EmptyState
              image={EmptyStateImg}
              cta="Add a new attachment"
              text={intl.formatMessage({ id: "noAttachmentsYet" })}
              actionBtn={{
                action: handleEmptyAttachment,
                title: "Upload Files",
                customClass: "btn btn-primary mt-2",
              }}
            />
          ) : (
            <Scrollbars
              style={
                AttachmentList.length > 5
                  ? { width: "100%", height: "calc(100vh - 12rem)" }
                  : { width: "100%", height: 250 }
              }
            >
              {AttachmentList &&
                AttachmentList.map((attachment) => (
                  <AttachamentItem
                    toggleSelect={toggleSelect}
                    checkSelectionStatus={checkSelectionStatus}
                    selectedItems={state.SelectedItems}
                    handleSidebarItem={handleSidebarItem}
                    attachment={attachment}
                    attachments={attachments}
                    key={attachment.GUID}
                    sideBarItemData={sideBarItemData}
                    confirmDeleteAttachment={confirmDeleteAttachment}
                    // toggleMove={toggleMove}
                    //toggleExport={toggleExport}
                  />
                ))}
            </Scrollbars>
          )}
        </div>
      </div>
    </>
  );
};

export default Attachments;
