import React, { use, useRef, useState } from "react";
import { IMAGES } from "../../constant";
import { getBasicInfo, logoutUser } from "../../utils";
import history from "../../common/history";
import { useIntl, FormattedMessage } from "react-intl";
import { randomImage } from "../common/randomImage";
import { ModalComponent } from "../common";
import EmailModal from "../hotel/emailModal";
import OneSignal from "react-onesignal";
import { useBetaFeature } from "../../hooks/useBetaFeature";
import { handleCloseEmail } from "../../utils/closeMailModal";
import { useHistory } from "react-router-dom";
const Profile = ({ toggle, launchAccount }) => {
  let userInfo = getBasicInfo();
  const toggleRef = useRef();
  const [exportMailModal, setExportMailModal] = useState();
  const Navigate = useHistory();
  let fullName = userInfo.Name + " " + userInfo.LastName;
  let initials =
    (userInfo.Name && userInfo.Name.charAt(0).toUpperCase()) +
    (userInfo.LastName && userInfo.LastName.charAt(0));
  let img = randomImage(initials);

  function logout() {
    OneSignal.logout();
    history.push("/");
    logoutUser();
  }

  function basicInfoToggle(area) {
    launchAccount(area);
  }
  const toggleExportMail = (event) => {
    setExportMailModal(!exportMailModal);
    // basicInfoToggle("support")
  };

  const { isChecked, handleFeatureToggle } = useBetaFeature();

  return (
    <div className="profileContainer shadow-sm ">
      <ModalComponent
        modal={exportMailModal}
        toggle={() => handleCloseEmail(toggleExportMail)}
        childeren={
          <EmailModal
            fromSection="SUPPORT"
            emails={[
              {
                id: "starlin@plnify.com",
                label: "Support (starlin@plnify.com)",
                value: "starlin@plnify.com",
              },
            ]}
            subject={"Support"}
            toggle={() => toggleExportMail()}
          />
        }
      />
      <div className=" px-0 row py-3">
        <div className="col-12">
          {userInfo.Picture ? (
            <img
              src={`https://app.plnify.com/photos/${userInfo.Picture}?w=35&h=35&mode=crop&autorotate=true`}
              alt={userInfo.Name}
              className="img-fluid mx-auto avatar d-inline-block"
            />
          ) : (
            <img
              src={img}
              alt={fullName}
              title={fullName}
              className="img-fluid mx-auto avatar d-inline-block"
            />
          )}
          <div className="d-inline-block mx-2">
            <h6 className="">{fullName}</h6>
          </div>
        </div>

        <div className="col-12 p-0">
          <hr />
        </div>
        <div className="col-12">
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => basicInfoToggle("basicInfo")}
          >
            <FormattedMessage
              id="basicInformation"
              defaultMessage="Basic Information"
            />
          </button>
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => basicInfoToggle("selectWorkSpace")}
          >
            <FormattedMessage id="workspaces" defaultMessage="Workspaces" />
          </button>
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => basicInfoToggle("travelProfile")}
          >
            <FormattedMessage
              id="travelProfile"
              defaultMessage="Travel Profile"
            />
          </button>
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => basicInfoToggle("socialMedia")}
          >
            <FormattedMessage id="socialMedia" defaultMessage="Social Media" />
          </button>

          <button
            className="btn btn-block p-0 py-1"
            onClick={() => basicInfoToggle("settings")}
          >
            <FormattedMessage id="preferences" defaultMessage="Preferences" />
          </button>
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => {
              toggleExportMail();
            }}
          >
            <FormattedMessage id="Support" defaultMessage="Support" />
          </button>
        </div>
        <div className="col-12 p-0">
          <hr />
        </div>
        <div className="col-12">
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => {
              Navigate.push("account/billing");
            }}
          >
            <FormattedMessage id="billing" defaultMessage="Billing" />
          </button>
          <button
            className="btn btn-block p-0 py-1"
            onClick={() => basicInfoToggle("changePassword")}
          >
            <FormattedMessage
              id="changePassword"
              defaultMessage="Change Password"
            />
          </button>

          <div className="d-flex  justify-content-between align-items-center mt-3">
            <p className="toggle-title">Beta Features</p>
            <div
              className={`toggle-switch mr-3 ${isChecked && "active"}`}
              id="myToggle"
              onClick={handleFeatureToggle}
            >
              <div
                ref={toggleRef}
                className={`toggle-switch-inner ${isChecked && "active"}`}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-12 p-0">
          <hr />
        </div>
        <div className="col-12">
          <button
            className="btn btn-block text-danger p-0 py-1"
            onClick={() => logout()}
          >
            <FormattedMessage id="signOut" defaultMessage="Sign Out" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
