import React, { useEffect, useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import db from "../../common/db";
import { ToastNotificationSuccess } from "../common";
import { getBasicInfo } from "../../utils";
import history from "../../common/history";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AdminAcces = (props) => {
  return (
    <div className="card shadow-sm my-2">
      <div className="card-body p-1">
        <div className="text-head">
          <div className="text-left d-flex w-100 justify-content-between">
            <h5 className="title">
              <FormattedMessage
                id="adminAccess"
                defaultMessage="Admin Access"
              />
            </h5>
            <button
              type="button"
              className="btn btn-sm btn-link ml-2"
              onClick={() => props.toggle(false)}
            >
              <FontAwesomeIcon icon={faTimes} color="black" />
            </button>
          </div>
        </div>
        <Formik>
          {(props) => (
            <Form autoComplete="off" className="pt-1 row">
              <div className="col-6">
                <div>
                  <label className="mb-3 text-body">
                    <FormattedMessage id="event" defaultMessage="Event" />
                  </label>
                  <div>
                    <Field
                      as="select"
                      name="Preferred"
                      className="form-control"
                    >
                      {/* <option value="0">Sunday</option> */}
                      {/* <option value="1">Monday</option> */}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label className="mb-3 text-body">Guest List</label>
                  <div>
                    <Field
                      as="select"
                      name="Temperature"
                      className="form-control"
                    >
                      {/* <option value="f">Fahrenheit</option> */}
                      {/* <option value="c">Celsius</option> */}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label className="mb-3 text-body">
                    <FormattedMessage
                      id="eventDetail"
                      defaultMessage="Event Detail"
                    />
                  </label>
                  <div>
                    <Field as="select" name="metric" className="form-control">
                      {/* <option value="k">Kilometers</option> */}
                      {/* <option value="m">Miles</option> */}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label className="mb-3 text-body">
                    <FormattedMessage
                      id="organization"
                      defaultMessage="Organization"
                    />
                  </label>
                  <div>
                    <Field as="select" name="metric" className="form-control">
                      {/* <option value="k">Kilometers</option> */}
                      {/* <option value="m">Miles</option> */}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label className="mb-3 text-body">
                    <FormattedMessage
                      id="managerUser"
                      defaultMessage="Manager User"
                    />
                  </label>
                  <div>
                    <Field
                      as="select"
                      name="metric"
                      className="form-control"
                      placeholder="Optional"
                    >
                      {/* <option value="k">Kilometers</option> */}
                      {/* <option value="m">Miles</option> */}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="col-12 text-right">
                <button className="btn btn-primary" type="submit">
                  <FormattedMessage id="save" defaultMessage="Save" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminAcces;
