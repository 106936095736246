import React, { useEffect, useState } from "react";
import { IMAGES } from "../../constant";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Navigate } from "react-big-calendar";
import { useHistory, useLocation } from "react-router-dom";
import { apiService } from "../../common/api";

const initialOptions = {
  "client-id": "test",
  currency: "USD",
  intent: "capture",
};

const steps = {
  subscription: [
    {
      name: "Business",
      icon: "fa fa-briefcase",
      color: "text-success",
    },
    {
      name: "Personal",
      icon: "fa fa-user",
      color: "text-primary",
    },
    {
      name: "Companies",
      icon: "fa fa-users",
      color: "text-warning",
    },
  ],
  modules: [
    {
      name: "Module 1",
      icon: "fa fa-cogs",
      color: "text-success",
      price: 9.99,
    },
    {
      name: "Module 2",
      icon: "fa fa-check-square",
      color: "text-primary",
      price: 25.99,
    },
    {
      name: "Module 3",
      icon: "fa fa-user",
      color: "text-warning",
      price: 49.99,
    },
  ],
  plan: [
    {
      name: "Monthly",
      icon: "fa fa-calendar",
      color: "text-success",
      multiply: 1,
    },
    {
      name: "Quarterly",
      icon: "fa fa-calendar-alt",
      color: "text-primary",
      multiply: 3,
    },
    {
      name: "Annual",
      icon: "fa fa-calendar-check",
      color: "text-warning",
      multiply: 12,
    },
  ],
};

const PlanSelectionComponent = () => {
  const currentDomain = window.location.origin;
  const [suscriptions, setSuscriptions] = useState([]);
  const location = useLocation();
  const userId = location.search.split("=")[1];
  const Navigate = useHistory();

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: (
              (formState.subscription.Cost +
                formState.modules.reduce(
                  (acc, module) =>
                    acc + steps.modules.find((x) => x.name === module).price,
                  0
                )) *
              formState.plan
            ).toString(),
          },
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  const [formState, setFormState] = React.useState({
    subscription: "Business",
    modules: ["Module 1"],
    plan: 0,
  });

  const handleSubscriptionSelect = (value) => {
    setFormState({ ...formState, subscription: value });
  };

  const handlePlanSelect = (value) => {
    setFormState({ ...formState, plan: value });
  };

  const handleModuleSelect = (value) => {
    const selectedPlans = [...formState.modules];
    if (selectedPlans.includes(value)) {
      selectedPlans.splice(selectedPlans.indexOf(value), 1);
    } else {
      selectedPlans.push(value);
    }
    setFormState({ ...formState, modules: selectedPlans });
  };

  const fetchSuscriptions = async () => {
    try {
      const response = await apiService.get(
        `/Subscriptions/Types?Id=${userId}`
      );
      setSuscriptions(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuscriptions();
  }, []);

  useEffect(() => {
    if (suscriptions.length > 0) {
      handleSubscriptionSelect(suscriptions[0]);
    }
  }, [suscriptions]);

  const totals = (
    (formState.subscription.Cost +
      formState.modules.reduce(
        (acc, module) =>
          acc + steps.modules.find((x) => x.name === module).price,
        0
      )) *
    formState.plan
  ).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div
        className="d-flex justify-content-center align-items-center px-10 flex-column"
        style={{
          maxWidth: "1440px",
          margin: "auto",
          height: "100vh",
        }}
      >
        <div className="d-flex justify-content-center align-items-center p-4 w-100  border-bottom position-relative">
          <button
            className="btn btn-primary position-absolute"
            style={{ left: "10px" }}
            onClick={() => Navigate.goBack()}
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>
          <a href={currentDomain}>
            <img src={IMAGES.LOGO_COLOR} alt="plnify logo" />
          </a>
        </div>

        <div
          className="d-flex p-4 w-100 flex-column flex-md-row"
          style={{
            height: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <div className="d-flex px-4 flex-column w-100">
            <div className="d-flex justify-content-center align-items-center flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="p-4 w-100 border-bottom-2  border">
                1. Select subscription
              </h4>
              <div className="d-flex justify-content-center align-items-center p-4 w-100">
                <div className="d-flex flex-wrap justify-content-center align-items-center w-100 bg-white p-2">
                  {suscriptions.map((subscription) => (
                    <div
                      key={subscription.Name + subscription.GUID}
                      style={{ cursor: "pointer", width: "150px" }}
                      onClick={() => handleSubscriptionSelect(subscription)}
                      className={` p-4 d-flex flex-column justify-content-center align-items-center
                        rounded-lg
                        ${
                          formState.subscription.GUID === subscription.GUID
                            ? "bg-primary text-white"
                            : "bg-white"
                        }
                        `}
                    >
                      <i
                        className={`${subscription.icon} ${subscription.color}`}
                      />
                      <span>{subscription.Name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="p-4 w-100 border-bottom-2  border">
                2. Enter amount of additional admins
              </h4>
              <div className="d-flex justify-content-center align-items-center w-100 p-4">
                <div className="d-flex justify-content-center align-items-center w-100 bg-white p-2 flex-wrap">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter amount of additional admins"
                    min={0}
                  />

                  {/* {steps.modules.map((module) => (
                    <div
                      key={module.name}
                      style={{ cursor: "pointer", width: "150px" }}
                      onClick={() => handleModuleSelect(module.name)}
                      className={`p-4 d-flex flex-column justify-content-center align-items-center
                        rounded-lg
                        ${
                          formState.modules.includes(module.name)
                            ? "bg-primary text-white"
                            : "bg-white"
                        }
                        `}
                    >
                      <i
                        className={`${module.icon} ${module.color} fa-3x mb-2`}
                      />
                      <span>{module.name}</span>
                      <span>${module.price}</span>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="p-4 w-100 border-bottom-2 border">
                3. Duration in month
              </h4>
              <div className="d-flex justify-content-center align-items-center p-4 w-100">
                <div className="d-flex justify-content-center align-items-center w-100 bg-white p-2 flex-wrap">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter duration in month"
                    min={1}
                    value={formState.plan}
                    onChange={(e) =>
                      setFormState({ ...formState, plan: e.target.value })
                    }
                  />
                  {/* {steps.plan.map((plan) => (
                    <div
                      key={plan.name}
                      style={{
                        cursor: "pointer",
                        width: "150px",
                      }}
                      onClick={() => handlePlanSelect(plan.name)}
                      className={`p-4 d-flex flex-column justify-content-center align-items-center
                        rounded-lg
                        ${
                          formState.plan === plan.name
                            ? "bg-primary text-white"
                            : "bg-white"
                        }
                        `}
                    >
                      <i className={`${plan.icon} ${plan.color} fa-3x mb-2`} />
                      <span>{plan.name}</span>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="p-4 w-100 border-bottom-2  border">4. Summary</h4>
              <div className="d-flex justify-content-center p-4 w-100 flex-column pt-0">
                <div className="d-flex w-100 bg-white p-2 align-items-center">
                  <span className="text-primary">Subscription:</span>
                  <span className="mx-1">{formState.subscription.Name}</span>
                </div>
                <div className="d-flex align-items-center w-100 bg-white p-2">
                  <span className="text-primary">Modules:</span>
                  <span className="mx-1">{formState?.modules?.join(", ")}</span>
                </div>
                <div className="d-flex align-items-center w-100 bg-white p-2">
                  <span className="text-primary">Period:</span>
                  <span className="mx-1">
                    {`${formState.plan} ${
                      formState.plan == 1 ? "Month" : "Months"
                    }`}
                  </span>
                </div>
                <div className="d-flex align-items-center w-100 bg-white p-2">
                  <span className="text-primary">Total :</span>
                  <span className="mx-1">{totals}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex px-4 flex-column w-100 h-100">
            <div className="d-flex flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="p-4 w-100 border-bottom-2  border">5. Pay</h4>
              <div className="d-flex justify-content-center align-items-center w-100 fa-2x p-4">
                <span className="text-primary">Total :</span>
                <span className="mx-1">{totals}</span>
              </div>

              <div className="d-flex justify-content-center align-items-center p-4 w-100">
                <PayPalButtons
                  className="w-100"
                  style={{ layout: "vertical" }}
                  createOrder={(data, actions) => onCreateOrder(data, actions)}
                  onApprove={(data, actions) => onApproveOrder(data, actions)}
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center p-4 flex-column flex-md-row"
                style={{ gap: "10px" }}
              >
                <button
                  className="btn btn-secondary p-3 px-4"
                  style={{ width: "100%", textAlign: "center" }}
                  onClick={() => Navigate.push("/selectOrganization")}
                >
                  Change Organization
                </button>
                <button
                  className="btn btn-primary p-3 px-4"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default PlanSelectionComponent;
