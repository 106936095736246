import React from "react";
import { IMAGES } from "../../constant";

const AccountStatement = () => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="container mt-5 p-4 bg-white shadow-lg rounded">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
        <div className="d-flex flex-column">
          <img src={IMAGES.LOGO_COLOR} alt="plnify logo" />
        </div>
        <div className="text-end">
          <p className="text-muted small">
            No. d94f5985-83f0-41f1-ab35-de00ae0b9c40
          </p>
          <p className="fw-semibold">Fecha: Jul 06, 2024</p>
        </div>
      </div>

      {/* Payment Details */}
      <div className="mb-3">
        <h3 className="bold">Workspace Name</h3>
        <p className="text-muted small">cbfa7643-ed85-48d0-ab56-01e93614e07a</p>
      </div>

      {/* Payment Table */}
      <div className="border rounded overflow-hidden">
        <div className="bg-light px-3 py-2 fw-semibold">PAGO</div>
        <div className="p-3">
          <div className="d-flex justify-content-between border-bottom pb-2 mb-2">
            <p className="fw-semibold">CONCEPTO</p>
            <p className="fw-semibold">SUB-TOTAL</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-muted">
              Plan: Standard | Renewal Period: 4 | Amount: 519.88 |
            </p>
            <p className="text-muted">$519.88</p>
          </div>
        </div>
      </div>

      {/* Total Amount */}
      <div className="mt-3 text-end">
        <p className="text-muted">
          Sub-total: <span className="fw-semibold">$519.88</span>
        </p>
        <p className="h5 fw-bold">Total: $519.88</p>
      </div>

      {/* Print Button */}
      <div className="mt-4 text-center">
        <button
          onClick={() => window.print()}
          className="btn btn-primary px-4 py-2 d-print-none"
        >
          Imprimir
        </button>
      </div>
    </div>
  );
};

export default AccountStatement;
