import React, { useState } from "react";
import moment from "moment";
import {
  DropDownComponent,
  ToastNotificationSuccess,
  ModalComponent,
} from "../common";
import { Alarm, PencilOutline, Check } from "mdi-material-ui";
import ShowMoreText from "react-show-more-text";
import Swal from "sweetalert2";
import ActivityForm from "./activityform";
import ReactTooltip from "react-tooltip";
import { Form, Formik, Field, ErroField } from "formik";
import { shortActivitySchema } from "../../utils/validationsSchemas";
import { IMAGES } from "../../constant";
import api from "../../common/api";
import TemplateActivityForm from "./TemplateActivityForm";
import { extractContentText } from "../../common/helpers";
import { useIntl, FormattedMessage } from "react-intl";

const TemplateActivity = (props) => {
  const {
    activity,
    event,
    scheduleMaster,
  } = props;
  const intl = useIntl();
  const [theActivity, setTheActivity] = useState(activity);

  const [editMode, setEditMode] = useState(false);
  function deleteConfirmation(activity) {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDelete" }) + activity.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        let request = await api.get(
          "/ScheduleTemplates/RemoveActivity?ActivityGUID=" + activity.GUID
        );
        if (request.data) {
          props.updateTemplate(request.data);
          ToastNotificationSuccess(1, "Activity deleted successfully");
        }
      }
    });
  }

  const toggleAddEditActivity = (reload) => {
    setEditMode((prev) => !prev);
    if (reload) {
    }
  };

  function activityEdit(activity, until) {
    setTheActivity(activity);
    toggleAddEditActivity(true);
    debugger;
    // editActivity(
    //   activity,
    //   () => ToastNotificationSuccess(1, `${activity.Name} succesfully edited`),
    //   () => console.log(""),
    //   until
    //);
  }

  // let activityObjectToEdit = activity
  //   ? {
  //       ...activity,
  //       Name: activity && activity.Name,
  //       StartDate: activity && moment(activity.Date).toDate(),
  //       StartTime: activity && moment(activity.Date).toDate(),
  //       UntilDate:
  //         activity && activity.DateUntil === null
  //           ? moment(activity.Date).toDate()
  //           : moment(activity.DateUntil).toDate(),
  //       UntilTime:
  //         activity && activity.DateUntil === null
  //           ? moment(activity.Date).toDate()
  //           : moment(activity.DateUntil).toDate(),
  //       Notes: activity && activity.Notes,
  //       EventGUID: activity && activity.EventGUID,
  //       ScheduleMasterGUID: activity && activity.ScheduleMasterGUID,
  //       CreatedUserGUID: activity && activity.CreatedUserGUID,
  //     }
  //   : null;



  return (
    <div className="activitiesItem d-flex align-items-center px-2 py-1">
      <ModalComponent
        clear={true}
        modal={editMode}
        toggle={toggleAddEditActivity}
        childeren={
          <TemplateActivityForm
            edit={true}
            activity={theActivity}
            toggle={toggleAddEditActivity}
            //addActivity={addActivity}
            //editActivity={editActivity}
            editActivity={activityEdit}
            scheduleMaster={scheduleMaster}
            event={event}
          />
        }
      />
      <div
        className="activitiesItem__time text-center"
        onClick={toggleAddEditActivity}
      >
        {moment(theActivity.Date).format("LT")}

        {theActivity.DateUntil &&
          theActivity.DateUntil !== theActivity.Date &&
          "to " + theActivity.DateUntil && (
            <div>
              <p className="text-center divisorVertical">|</p>

              {moment(theActivity.DateUntil).format("LT")}
            </div>
          )}
      </div>
      <div className="activitiesItem__details">
        <div className="d-flex align-items-center">
          <p
            className="top text-truncate"
            onClick={() => toggleAddEditActivity()}
          >
            {theActivity.Name}
          </p>
        </div>
        <div className="text">
          {theActivity.Notes && (
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass="d-inline"
              expanded={false}
              width={0}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: extractContentText(activity.Notes),
                }}
              />
            </ShowMoreText>
          )}
        </div>
      </div>

      <div className="activitiesItem__actions">
        {theActivity.Reminder && (
          <>
            <Alarm
              data-tip={`${theActivity.Reminder} minutes`}
              className="activitiesItem__alarm"
            />
            <ReactTooltip place={"left"} effect="solid" />
          </>
        )}
        <DropDownComponent
          data={[
            {
              name: <FormattedMessage id="delete" defaultMessage="Delete" />,
              delete: true,
              trigger: () => deleteConfirmation(theActivity),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TemplateActivity;
