import React from 'react'

const RegisterPage = () => {
  return (
    <div>
      Register Page
    </div>
  )
}

export default RegisterPage
