import React, { useEffect, useState } from "react";
import { organizationUsers } from "../../common/helpers";
import { getOrganization } from "../../utils";
import { Scrollbars } from "react-custom-scrollbars";
import {
  SimpleLoader,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../common";
import GeneralUserItem from "../common/GeneralUserItem";
import mainState from "../../common/mainState";
import { FormattedMessage } from "react-intl";

import api, { apiService } from "../../common/api";
import moment from "moment";
import uuid from "uuid";

const EventVisibleBy = (props) => {
  const [state, setState] = useState({
    OrganizationUsers: [],
    SelectedUsers: [],
    UI: {
      IsLoading: false,
      Search: "",
    },
  });

  const [globalState, setGlobalState] = useState(mainState);

  const requestUsers = async () => {
    let theOrganization = getOrganization();
    let users = await organizationUsers(theOrganization.GUID);

    setState((prev) => {
      return {
        ...prev,
        OrganizationUsers: users,
      };
    });
  };

  const requestUsersAssignedRemote = async () => {
    const request = await api.get(
      "/Events/VisibleBy?EventGUID=" + props.event.GUID
    );
    if (request.data.status === "ok") {
      setState((prev) => {
        return {
          ...prev,
          SelectedUsers: request.data.response,
        };
      });
    }
  };

  const exist = (staff) => {
    let users = JSON.parse(JSON.stringify(state.SelectedUsers));
    let record =
      users.length > 0 &&
      users.filter((x) => x.StaffGUID === staff.GUID && x.Deleted === null)[0];

    return record;
  };

  const selectAll = (role) => {
    console.log(role);
    let SelectedUsers = JSON.parse(JSON.stringify(state.SelectedUsers));
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    let checkSelection = checkSelectionStatus(role);
    console.log("checking the status of the role ", role, checkSelection);
    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(item)) {
          SelectedUsers.push({
            EventGUID: props.event.GUID,
            GUID: uuid.v4(),
            StaffGUID: item.GUID,
            Deleted: null,
          });
        } else {
          //Check if deleted
        }
      });
      setState((prev) => {
        return {
          ...prev,
          SelectedUsers,
        };
      });
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      filter.forEach((item) => {
        if (exist(item)) {
          let indexFound = SelectedUsers.findIndex(
            (x) => x.StaffGUID === item.GUID && x.Deleted === null
          );
          SelectedUsers[indexFound].Deleted = moment().format();
          //SelectedUsers.push({ EventGUID: props.event.GUID, GUID: uuid.v4(), StaffGUID: item.GUID, Deleted: null });
        } else {
          //Check if deleted
        }
      });
      setState((prev) => {
        return {
          ...prev,
          SelectedUsers,
        };
      });
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(item)) {
          SelectedUsers.push({
            EventGUID: props.event.GUID,
            GUID: uuid.v4(),
            StaffGUID: item.GUID,
            Deleted: null,
          });
        } else {
          //Check if deleted
        }
      });
      setState((prev) => {
        return {
          ...prev,
          SelectedUsers,
        };
      });
    }
  };

  const toggleMember = (staff) => {
    let SelectedUsers = JSON.parse(JSON.stringify(state.SelectedUsers));
    if (exist(staff)) {
      if (
        SelectedUsers.filter(
          (x) => x.StaffGUID === staff.GUID && x.Deleted === null
        )[0]
      ) {
        SelectedUsers.filter(
          (x) => x.StaffGUID === staff.GUID && x.Deleted === null
        )[0].Deleted = moment().format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      SelectedUsers.push({
        EventGUID: props.event.GUID,
        GUID: uuid.v4(),
        StaffGUID: staff.GUID,
        Deleted: null,
      });
    }
    setState((prev) => {
      return {
        ...prev,
        SelectedUsers,
      };
    });

    console.log("new state", state);
  };
  const loadUsersUI = (role) => {
    let users = [];
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    if (state.UI.Search.length > 0) {
      let search = state.UI.Search.toLowerCase();
      filter = filter.filter(
        (x) =>
          x.Account.Name.toLowerCase().includes(search) ||
          (x.Account.LastName &&
            x.Account.LastName.toLowerCase().includes(search))
      );
    }
    users = filter
      .sort((a, b) => a.Account.Name.localeCompare(b.Account.Name))
      .map((staff, index) => {
        let parsedStaff = JSON.parse(JSON.stringify(staff));
        //  console.log("does the user exist ====", parsedStaff, exist(parsedStaff));
        return (
          <div className="col-12 my-1 px-0" key={index}>
            <div
              className={
                (exist(parsedStaff) ? "selected" : "") +
                " visible-info-main card shadow-sm align-items-start"
              }
            >
              <div
                className="card-body p-1"
                onClick={() => toggleMember(parsedStaff)}
              >
                <div className="row">
                  <div className="col-auto px-1">
                    {exist(parsedStaff) ? (
                      <i className="fa fa-check-square text-dark"></i>
                    ) : (
                      <i className="far fa-square text-dark uncheck"></i>
                    )}
                  </div>
                  <div
                    className="col"
                    title={staff.GUID + " account " + staff.AccountGUID}
                  >
                    <GeneralUserItem account={staff.Account} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });

    return users;
  };

  useEffect(() => {
    requestUsers();
    requestUsersAssignedRemote();
  }, []);

  const handleSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        UI: {
          ...prev.UI,
          IsLoading: !prev.UI.IsLoading,
        },
      };
    });
    if (state.SelectedUsers.length > 0) {
      const staffedAllowed = state.SelectedUsers.map((staff) => {
        delete staff.Staff;
        return staff;
      });
      let staff = JSON.parse(JSON.stringify(staffedAllowed));

      try {
        await apiService.post("/EventVisibleBy/AddEditStaffBatch", staff);
        ToastNotificationSuccess(
          Math.random(),
          "Event visibility updated suscessfully"
        );
      } catch (error) {
        ToastNotificationError(
          Math.random(),
          "Error while updating, please try again"
        );
      }
    }
    setState((prev) => {
      return {
        ...prev,
        UI: {
          ...prev.UI,
          IsLoading: !prev.UI.IsLoading,
        },
      };
    });

    setGlobalState((prev) => {
      return {
        ...prev,
        EventsUI: {
          ...prev.EventsUI,
          SelectedEvent: props.ground,
          ReloadEvent: true, //this will set a refresh on the entire component, you must find where you set this to false or it will continue in an infinite loop
        },
      };
    });

    props.toggle();
    if (props.parentToggle) {
      props.parentToggle();
    }
  };

  const checkSelectionStatus = (role) => {
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    let count = 0;
    filter.forEach((item) => {
      if (exist(item)) {
        count++;
        //Check if deleted
      }
    });
    if (count === 0) {
      return 0; //none
    } else if (filter.length === count) {
      return 1; //total
    } else {
      return 2; //partial
    }
  };

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="row">
              <div className="text-left col">
                <h1 className="title">
                  <FormattedMessage
                    id="visibleBy"
                    defaultMessage="Visible By"
                  />
                </h1>
              </div>
              <div className="col-auto ml-auto d-none">
                <div className="formAlternativeStyle">
                  <input
                    type="text"
                    value={state.UI.Search}
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      setState((prev) => {
                        const { value } = e.target;
                        return {
                          ...prev,
                          UI: {
                            ...prev.UI,
                            Search: value,
                          },
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-1 py-3">
            <div className="row  containerFullHeight text-dark">
              <div className="col-12 mx-0 px-0">
                <Scrollbars style={{ height: "calc(100vh - 125px)" }}>
                  {/*FILES */}
                  {
                    state.OrganizationUsers.length > 0
                      ? state.OrganizationUsers.map((role, index) => {
                          let parsed = role;
                          let usersUI = loadUsersUI(parsed);
                          let allUsersSelected = checkSelectionStatus(role); //1 = all;2= partial;0 =none
                          return (
                            <div
                              className="row px-0 selectorContainer"
                              key={index}
                            >
                              <div className="col-auto px-0 text-right  bgTitleModal py-1 mt-4">
                                <button
                                  className="btn btn-sm text-secondary"
                                  onClick={() => selectAll(parsed)}
                                >
                                  {allUsersSelected === 0 ? (
                                    <i className="far fa-square text-dark"></i>
                                  ) : allUsersSelected === 1 ? (
                                    <i className="fa fa-check-square text-dark"></i>
                                  ) : (
                                    <i className="far fa-square text-dark"></i>
                                  )}
                                </button>
                              </div>
                              <div className="col bgTitleModal py-2 mt-4 ">
                                <h3>{role.Name}</h3>
                              </div>

                              {usersUI}

                              {/*requestFiles()*/}
                            </div>
                          );
                        })
                      : null
                    //Supposed to display empty state
                  }
                </Scrollbars>
                <div className="submit-button">
                  {state.UI.IsLoading ? (
                    <SimpleLoader loading={state.UI.IsLoading} />
                  ) : props.donable ? (
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn primary"
                    >
                      {state.SelectedUsers.length > 0 ? (
                        <FormattedMessage id="done" defaultMessage="Done" />
                      ) : (
                        <FormattedMessage id="Skip" defaultMessage="Skip" />
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn primary"
                    >
                      <FormattedMessage id="done" defaultMessage="Done" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventVisibleBy;
