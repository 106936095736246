import React, { Component, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Airplane,
  Bus,
  Domain,
  ClipboardList,
  AccountBox,
  Attachment,
} from "mdi-material-ui";
import { ModalComponent } from "../common";
import AddMeetingModal from "../meet/AddMeetingModal";
import CalendarEventModal from "./CalendarEventModal";
import { MeetingEvent } from "./MeetingEvent";

const CalendarEventDots = (props) => {
  const events = useSelector((state) => state.calendar.calendarEvents);
  const [state, setState] = useState({
    showEventModal: false,
    showDetailsMeetingModal: false,
  });

  const toggleMeetingModal = () => {
    setState((prev) => ({
      ...prev,
      showDetailsMeetingModal: !state.showDetailsMeetingModal,
    }));
  };

  const toggleShowEventModal = () => {
    setState((prev) => ({ ...prev, showEventModal: !state.showEventModal }));
  };

  const { event } = props;

  const currentEvent = moment(event.Date).toDate();

  const sameDayEvents = events.filter(
    (event) => moment(event.Date).isSame(currentEvent, "day") === true
  );
  const sameDayEventsCount = sameDayEvents.length - 1;

  return event.EventType === "Meeting" ? (
    <MeetingEvent
      event={event}
      toggleMeetingModal={toggleMeetingModal}
      state={state}
    />
  ) : (
    <div>
      <ModalComponent
        modal={state.showEventModal}
        toggle={toggleShowEventModal}
        childeren={
          <CalendarEventModal date={event.Date} events={sameDayEvents} />
        }
      />
      <Link
        className="clean-link inner event-container relative"
        style={{
          opacity:
            moment(event.Date).month() < moment().month() &&
            moment(event.Date).year() <= moment().year()
              ? 0.5
              : 1,
        }}
        to={`/calendar/${event.GUID}`}
      >
        <div className="event-icons d-flex align-items-center h-100 w-100">
          <span className={`event-status ${event.Status}`}></span>

          <div className="icon-section">*</div>
        </div>
      </Link>
      {sameDayEventsCount > 0 && (
        <button
          className="rbc-show-more-modal clean-link btn btn-block"
          onClick={() => toggleShowEventModal()}
        >
          + {sameDayEventsCount}
        </button>
      )}
    </div>
  );
};

export default CalendarEventDots;
