import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "../../../common/api";
import { Loading } from "../../../components/common/loading";
import { TodoAccordion } from "../../../components/todo/TodoAccordion";
import { IMAGES } from "../../../constant";
import Dropdown from "react-bootstrap/Dropdown";
import { StaffDropDown } from "../../../components/todo/StaffDropDown";
import moment from "moment";
import { Table } from "reactstrap";

const initialValue = {
  isLoading: false,
  task: [
    {
      Created: null,
      CreatedByGUID: "",
      Date: "",
      Deleted: null,
      EventGUID: "",
      GUID: "",
      Name: "",
      Note: "",
      OrganizationGUID: "",
      TodoTasks: [],
    },
  ],
};

export const TodoView = (props) => {
  const location = useLocation();

  const ids = location.pathname.replace("/todo/", "").split("/");
  const [taskId, todoId] = ids;
  // const taskId = ids[0] const todoId = ids[1];
  const priorityStyle = {
    High: "danger",
    Medium: "warning",
    Low: "success",
  };

  const daysCount = ["Today", "Tomorrow"];

  const priorityNum = [null, "Low", "Medium", "High"];

  const event = useSelector((state) => state.event.event);
  const [state, setState] = useState(initialValue);

  const getTodos = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const request = await api.get(`todo/ToDoDetails?TodoGUID=${taskId}`);
    const response = await request.data;

    const viewTodo = response?.Response?.filter(
      (data) => data.Deleted === null && data.GUID === taskId
    );

    const viewTodos = viewTodo
      ?.map((todo) => todo.TodoTasks)?.[0]
      ?.filter((todo) => todo.GUID === todoId);

    setState((prev) => ({
      ...prev,
      task: viewTodos,
      isLoading: false,
    }));
  };

  useEffect(() => {
    getTodos();
  }, []);

  const { Name, Note, TodoTasks, TodoAssignees, Priority } = state?.task[0];

  const [priority, setPriority] = useState(Priority);
  const [todoState, setTodoState] = useState(state?.task[0]);
  const [staffSelected, setStaffSelected] = useState(TodoAssignees);
  const [isDatePick, setIsDatePick] = useState(false);

  const handleSelectPriority = (priority) => {
    setTodoState((prev) => ({
      ...prev,
      Priority: priorityNum.indexOf(priority),
    }));
    setPriority(priorityNum.indexOf(priority));
  };

  const [noteState, setNoteState] = useState({ show: false, Note });

  const handleAddDescription = () => {
    setNoteState((prev) => ({
      ...prev,
      show: true,
    }));
  };

  const handleSelectAssignee = (Assignee) => {
    setTodoState((prev) => ({ ...prev, assignee: Assignee }));
    setStaffSelected({ ...Assignee });
  };

  const handleChange = ({ target }) => {
    setTodoState((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const handleInputDate = () => {
    setIsDatePick((prev) => true);
  };

  const handleOpenMap = () => {
    window.open(
      `https://www.google.com/maps`,
      "MapWindow",
      "width=1280,height=840"
    );
  };

  const todoTaskClean = TodoTasks?.filter(
    (todoTask) => todoTask.Deleted === null
  );

  const colors = ["#FAD7A0", "#D2B4DE", "#AED6F1", "#F8C471", "#E74C3C"];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  return state.isLoading ? (
    <Loading />
  ) : (
    <div className="d-flex justify-content-center" style={{ width: "100%" }}>
      <div
        className="d-flex flex-column border m-4 bg-white rounded shadow justify-content-between align-items-center"
        style={{ width: "45%", height: "95vh" }}
      >
        <section className="list-header" style={{ height: "70px" }}>
          <button className="btn btn-primary">
            <i className="fa fa-check mr-2"></i>Mark complete
          </button>
          <div
            className="d-flex justify-content-around"
            style={{ width: "100px" }}
          >
            <i className="fa fa-tasks"></i>
            <i className="fa fa-paperclip"></i>
            <i className="fa fa-link"></i>
          </div>
        </section>

        <section
          className="overflow-auto w-75 py-4"
          style={{ height: "100vh" }}
        >
          <section className="d-flex flex-column">
            <h3>{Name}</h3>

            <Table striped="columns">
              <tbody>
                <tr>
                  <th scope="row">Assignee</th>
                  <td>
                    <StaffDropDown
                      staffSelected={staffSelected}
                      handleSelectAssignee={handleSelectAssignee}
                      isEditable={true}
                    />
                  </td>
                </tr>

                <tr>
                  <th scope="row">Due date</th>
                  <td>
                    {todoState.isEditable ? (
                      <div className="d-flex items-center">
                        <input
                          name="DueDate"
                          placeholder="due date"
                          type="datetime-local"
                          value={todoState.DueDate}
                          onChange={handleChange}
                          className="border-0 form-control"
                          onClick={handleInputDate}
                          style={{ width: `${!isDatePick ? "20px" : "auto"}` }}
                        />
                        {!isDatePick && <span>Due Date</span>}
                        {/* <input name="dueTime" placeholder="due date" type="time" /> */}
                      </div>
                    ) : (
                      <span>
                        {todoState?.Date
                          ? moment(todoState.Date).format("MMM, DD YYYY") +
                            "-" +
                            moment(todoState.Date).format("hh:mm")
                          : "No Due Date"}
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Priority</th>
                  <td>
                    <Dropdown className="d-inline">
                      <Dropdown.Toggle
                        id="dropdown-autoclose-true"
                        className="rounded-pill"
                        variant={priorityStyle[priorityNum[Priority]] ?? ""}
                      >
                        {!Priority ? (
                          <>
                            <i className="fa fa-exclamation mr-2"></i>Priority
                          </>
                        ) : (
                          `${priorityNum[Priority]}`
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleSelectPriority("High")}
                        >{`High`}</Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleSelectPriority("Medium")}
                        >{`Medium`}</Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleSelectPriority("Low")}
                        >{`Low`}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                {/* <tr>
                  <th scope="row">Location</th>
                  <td>
                    {todoState.isEditable ? (
                      <>
                        <span
                          onClick={handleOpenMap}
                          style={{ cursor: "pointer"}}
                        >
                          <i className="fa fa-location mr-2"></i>
                          Location
                        </span>
                      </>
                    ) : (
                      <span>
                        <i className="fa fa-location mr-2"></i>Location
                      </span>
                    )}
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </section>
          <section className="d-flex flex-column mt-4">
            <h4>Description</h4>
            {Note || noteState.show ? (
              <textarea
                className="form-control"
                rows="18"
                value={Note}
              ></textarea>
            ) : (
              <div
                className="d-flex flex-column align-items-center"
                onClick={handleAddDescription}
              >
                <img
                  src={IMAGES.TODO_EMPTYSTATE}
                  alt="No Description Added"
                  width="50%"
                />
                <h6 className="text-muted mt-5">No Description Added</h6>
              </div>
            )}
          </section>
          <section className="d-flex flex-column mt-4">
            <h4>Subtasks</h4>
            <button className="btn btn-primary border-0">
              <i className="fa fa-plus mr-2"></i>Add todo
            </button>
          </section>
          <TodoAccordion todos={todoTaskClean} assignees={TodoAssignees} />
        </section>

        <section
          className="w-100 d-flex border align-items-center justify-content-between p-4"
          style={{ height: "70px" }}
        >
          <div
            style={{ backgroundColor: randomColor }}
            className="d-flex align-items-center justify-content-center rounded-pill p-2"
          >
            <span
              className="text-uppercase text-white"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {TodoAssignees?.[0]?.Account?.Name[0]}
              {TodoAssignees?.[0]?.Account?.LastName[0]}
            </span>
          </div>
          <textarea className="form-control"></textarea>
          <div
            className="d-flex justify-content-around"
            style={{ width: "100px" }}
          >
            <i className="fa fa-paperclip"></i>
          </div>
          <button className="btn btn-primary">Add Comment</button>
        </section>
      </div>
    </div>
  );
};
