import React, { useEffect, useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import { organizationUsers, sortArray } from "../../common/helpers";
import { getOrganization } from "../../utils";
import { Scrollbars } from "react-custom-scrollbars";
import { SimpleLoader } from "../common";
import api, { apiService } from "../../common/api";
import moment from "moment";
import uuid from "uuid";
import AssignMembersFlightUser from "./AssignMembersFlightUser";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getFlights } from "../../page/event/redux/getFlights";
import db from "../../common/db";
import mainState from "../../common/mainState";
import { addFlightStaff } from "../../page/event/redux/addFlightStaff";

const AssignMembersFlight = (props) => {
  const dispatch = useDispatch();
  const history = useDispatch();

  const [state, setState] = useState({
    OrganizationUsers: [],
    SelectedUsers: [],
    UI: {
      IsLoading: false,
      Search: "",
    },
  });

  const intl = useIntl();
  // const globalState = useHookState(mainState);

  const requestUsers = async () => {
    let theOrganization = getOrganization();
    let users = await organizationUsers(theOrganization.GUID);

    setState((prev) => ({
      ...prev,
      OrganizationUsers: users,
    }));
  };

  const requestUsersAssignedRemote = async () => {
    // console.log("is going to request users remote for flight", props);
    let response = await api.get(
      "/Flights/GetStaff?FlightGUID=" + props.flight.GUID
    );
    if (response.data.Status === "ok") {
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          SelectedUsers: response.data.Response,
        }));
      }, 500);
    }
  };

  const exist = (staff) => {
    let users = JSON.parse(JSON.stringify(state.SelectedUsers));
    let record =
      users.length > 0 &&
      users.filter((x) => x.StaffGUID === staff.GUID && x.Deleted === null)[0];

    return record;
  };

  const selectAll = (role) => {
    let SelectedUsers = JSON.parse(JSON.stringify(state.SelectedUsers));
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    let checkSelection = checkSelectionStatus(role);
    // console.log("checking the status of the role ", role, checkSelection);
    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(item)) {
          SelectedUsers.push({
            FlightGUID: props.flight.GUID,
            GUID: uuid.v4(),
            StaffGUID: item.GUID,
            Deleted: null,
            SeatNumber: "",
            ReservationNumber: "",
            TicketNumber: "",
          });
        } else {
          //Check if deleted
        }
      });
      setState((prev) => ({
        ...prev,
        SelectedUsers: SelectedUsers,
      }));
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      filter.forEach((item) => {
        if (exist(item)) {
          let indexFound = SelectedUsers.findIndex(
            (x) => x.StaffGUID === item.GUID && x.Deleted === null
          );
          SelectedUsers[indexFound].Deleted = moment().format();
          //SelectedUsers.push({ FlightGUID: props.flight.GUID, GUID: uuid.v4(), StaffGUID: item.GUID, Deleted: null });
        } else {
          //Check if deleted
        }
      });

      setState((prev) => ({
        ...prev,
        SelectedUsers: SelectedUsers,
      }));
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(item)) {
          SelectedUsers.push({
            FlightGUID: props.flight.GUID,
            GUID: uuid.v4(),
            StaffGUID: item.GUID,
            Deleted: null,
            SeatNumber: "",
            ReservationNumber: "",
            TicketNumber: "",
          });
        } else {
          //Check if deleted
        }
      });

      setState((prev) => ({
        ...prev,
        SelectedUsers: SelectedUsers,
      }));
    }
  };

  //Update the record for the collection if needed
  const updateSelected = (obj) => {
    let SelectedUsers = JSON.parse(JSON.stringify(state.SelectedUsers));
    SelectedUsers.forEach((item, index) => {
      if (item.StaffGUID === obj.StaffGUID) {
        SelectedUsers[index] = obj;
      }
    });
    setState((prev) => ({
      ...prev,
      SelectedUsers: SelectedUsers,
    }));
    // console.log("updating selected", obj, SelectedUsers, state.SelectedUsers);
  };
  const toggleMember = (staff) => {
    let SelectedUsers = JSON.parse(JSON.stringify(state.SelectedUsers));
    if (exist(staff)) {
      if (
        SelectedUsers.filter(
          (x) => x.StaffGUID === staff.GUID && x.Deleted === null
        )[0]
      ) {
        SelectedUsers.filter(
          (x) => x.StaffGUID === staff.GUID && x.Deleted === null
        )[0].Deleted = moment().format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      SelectedUsers.push({
        FlightGUID: props.flight.GUID,
        GUID: uuid.v4(),
        StaffGUID: staff.GUID,
        Deleted: null,
        SeatNumber: "",
        ReservationNumber: "",
        TicketNumber: "",
      });
    }
    setState((prev) => ({
      ...prev,
      SelectedUsers: SelectedUsers,
    }));

    // console.log("new state", state);
  };
  const loadUsersUI = (role) => {
    try {
      let users = [];
      let parsedRole = JSON.parse(JSON.stringify(role));
      let filter = parsedRole.Staffs.filter((x) => x.Deleted === null);
      if (state.UI.Search.length > 0) {
        let search = state.UI.Search.toLowerCase();
        // console.log("filter before", filter, search);
        filter = filter.filter(
          (x) => x.Account && x.Account.Name.toLowerCase().includes(search) //||
          //(x.Account.LastName && x.Account.LastName.toLowerCase().includes(search))
        );
        // console.log(state.UI.Search, filter);
      }
      //sort
      filter = sortArray(filter, "FLIGHTMEMBER_ASSIGN");

      users = filter.map((staff, index) => {
        let parsedStaff = JSON.parse(JSON.stringify(staff));
        let usersSelected = JSON.parse(JSON.stringify(state.SelectedUsers));
        //   console.log("does the user exist ====", parsedStaff, exist(parsedStaff));
        let Reservation = null;
        if (exist(parsedStaff)) {
          let record = usersSelected.filter(
            (x) => x.StaffGUID === parsedStaff.GUID
          )[0];
          Reservation = record;
        }

        return (
          <AssignMembersFlightUser
            exist={exist}
            key={index}
            selectedItems={usersSelected}
            parsedStaff={parsedStaff}
            flight={props.flight}
            toggleMember={toggleMember}
            updateSelected={updateSelected}
            Reservation={Reservation}
          />
        );
      });

      return users;
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    requestUsers();
    requestUsersAssignedRemote();
  }, []);

  const handleSubmit = async (skip) => {
    setState((prev) => ({
      ...prev,
      UI: { ...prev.UI, IsLoading: !prev.UI.IsLoading },
    }));
    try {
      if (!skip && state.SelectedUsers.length > 0) {
        // state.UI.IsLoading.set(!state.UI.IsLoading.value);

        if (state.SelectedUsers.length > 0) {
          if (state.SelectedUsers.length > 0) {
            let staff = JSON.parse(JSON.stringify(state.SelectedUsers));
            //Assign the flight guid
            staff = staff.map((item) => {
              item.FlightGUID = props.flight.GUID;
              return item;
            });

            console.log({ sentStaff: staff });
            await apiService.post("/Flights/AddEditStaffBatch", staff);
          }
        }

        setState((prev) => ({
          ...prev,
          UI: { ...prev.UI, IsLoading: !prev.UI.IsLoading },
        }));

        // globalState.EventsUI.SelectedFlight.set(props.flight);
        // globalState.EventsUI.ReloadFlight.set(true); //this will set a refresh on the entire component, you must find where you set this to false or it will continue in an infinite loop
      }

      props.toggle();
      dispatch(getFlights(props.flight.EventGUID));
      if (props.parentToggle && typeof props.parentToggle === "function") {
        props.parentToggle(true);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const checkSelectionStatus = (role) => {
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    let count = 0;
    filter.forEach((item) => {
      if (exist(item)) {
        count++;
        //Check if deleted
      }
    });
    if (count === 0) {
      return 0; //none
    } else if (filter.length === count) {
      return 1; //total
    } else {
      return 2; //partial
    }
  };

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="row">
              <div className="text-left col">
                <h1 className="title">
                  <FormattedMessage
                    id="assignMembers"
                    defaultMessage="Assign Members"
                  />
                </h1>
              </div>
              <div className="col-auto ml-auto">
                <div className="formAlternativeStyle">
                  <input
                    type="text"
                    value={state.UI.Search}
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "search" })}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        UI: {
                          ...prev.UI,
                          Search: e?.target?.value?.toLowerCase(),
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-1 py-3">
            <div className="row  containerFullHeight text-dark">
              <div className="col-12 mx-0 px-0 selectorContainer">
                <Scrollbars style={{ height: "calc(100vh - 125px)" }}>
                  {/*FILES */}
                  {
                    state.OrganizationUsers.length > 0
                      ? state.OrganizationUsers.map((role, index) => {
                          let parsed = role;
                          let usersUI = loadUsersUI(parsed);
                          let allUsersSelected = checkSelectionStatus(role); //1 = all;2= partial;0 =none
                          return (
                            <div className="row px-0" key={index}>
                              <div className="col-auto px-0 bgTitleModal py-1 mt-4">
                                <button
                                  className="btn btn-sm text-secondary"
                                  onClick={() => selectAll(parsed)}
                                >
                                  {allUsersSelected === 0 ? (
                                    <i className="far fa-square"></i>
                                  ) : allUsersSelected === 1 ? (
                                    <i className="fas fa-check-square"></i>
                                  ) : (
                                    <i className="fas fa-minus-square"></i>
                                  )}
                                </button>
                              </div>
                              <div className="col bgTitleModal py-2 mt-4 ">
                                <h3>{role.Name}</h3>
                              </div>

                              {usersUI}

                              {/*requestFiles()*/}
                            </div>
                          );
                        })
                      : null
                    //Supposed to display empty state
                  }
                </Scrollbars>
                <div className="submit-button">
                  {state.UI.IsLoading ? (
                    <SimpleLoader loading={state.UI.IsLoading} />
                  ) : props.donable ? (
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn primary"
                      disabled={state.UI.IsLoading}
                    >
                      {state.SelectedUsers.length > 0 ? "Done" : "Skip"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn primary"
                      disabled={state.UI.IsLoading}
                    >
                      <FormattedMessage id="done" defaultMessage="Done" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignMembersFlight;
