import React from "react";
import { IMAGES } from "../../../constant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../../components/form/formField";
import { ComponentLoader } from "../../../components/common";
import { Link } from "react-router-dom";
import { newPasswordSchema } from "../../../utils/validationsSchemas";

const ChangePassword = ({
  changePassword,
  token,
  history,
  loading,
  requested,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  return (
    <div className="">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "calc(100vh)" }}
      >
        <div className="login-right bg-white rounded shadow-lg mt-4 p-4">
          <div className="text-align-center d-flex align-items-center justify-content-center">
            <img
              src={IMAGES.LOGO_COLOR}
              width="138"
              alt="PLNIFY"
              className="mx-auto mb-5"
            />
          </div>
          <div className="changePassword-form shadow-sm">
            <div className="text-center">
              <h2 className="text-center"
                style={{
                  color: "#000000",
                  fontSize: "1.2rem",
                  fontWeight: "700",
                }}
              >Change your password</h2>
            </div>
            <p
              className="text-center mb-4 mt-2 px-5"
              style={{ color: "#000000" }}
            >
              A strong password helps prevent unauthorized access to your
              account
            </p>
            {requested ? (
              <div className="text-center">
                <p className="changePassword-form__sentence">
                  Password Changed.
                </p>
                <Link to="/">Back to home</Link>
              </div>
            ) : (
              <Formik
                initialValues={{ newPassword: "", confirmNewPassword: "" }}
                onSubmit={(values) =>
                  changePassword(values.newPassword, token, () =>
                    history.push("/")
                  )
                }
                validationSchema={newPasswordSchema}
              >
                <Form style={{
                  gap: "1.2rem",
                  display: "flex",
                  flexDirection: "column",
                }} >
                  <FormField fullWidth={true} label="New password">
                    <i
                      className="fa fa-lock position-absolute"
                      style={{
                        top: "29px",
                        left: "10px",
                        color: "rgb(102, 121, 158)",
                      }}
                    ></i>
                    <Field
                      className="w-100 rounded-lg py-2 login-input-custom"
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                    />
                    <i
                      className={` fa fa-eye${
                        showPassword ? "-slash" : ""
                      } position-absolute`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        cursor: "pointer",
                        top: "29px",
                        right: "10px",
                        color: "rgb(102, 121, 158)",
                      }}
                    ></i>
                    <ErrorMessage
                      className="error-message"
                      name="newPassword"
                      component="div"
                    />
                  </FormField>
                  <FormField fullWidth={true} label="Confirm new password">
                    <i
                      className="fa fa-lock position-absolute"
                      style={{
                        top: "29px",
                        left: "10px",
                        color: "rgb(102, 121, 158)",
                      }}
                    ></i>
                    <Field
                      className="w-100 rounded-lg py-2 login-input-custom"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmNewPassword"
                    />
                    <i
                      className={` fa fa-eye${
                        showConfirmPassword ? "-slash" : ""
                      } position-absolute`}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      style={{
                        cursor: "pointer",
                        top: "29px",
                        right: "10px",
                        color: "rgb(102, 121, 158)",
                      }}
                    ></i>
                    <ErrorMessage
                      className="error-message"
                      name="confirmNewPassword"
                      component="div"
                    />
                  </FormField>

                  <div className="submit-button text-center">
                    {loading ? (
                      <ComponentLoader loading={loading} />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-block btn-primary p-3"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
