import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import moment from "moment";

export function editHotel(hotel, fromNotes, success) {
  const url = `/Hotels/AddEditBatch`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_HOTEL,
      });
      const request = await apiService.post(url, [
        {
          ...hotel,
          Checkout: fromNotes
            ? hotel.Checkout
            : `${moment(hotel.Checkout).format("YYYY-MM-DD")}T${moment(
              hotel.CheckoutTime
            ).format("HH:mm")}`,
          Checkin: fromNotes
            ? hotel.Checkin
            : `${moment(hotel.Checkin).format("YYYY-MM-DD")}T${moment(
              hotel.CheckinTime
            ).format("HH:mm")}`,
          HotelReservations: null,
        },
      ]);
      dispatch({
        type: constant.EDITED_HOTEL,
        data: request.data,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_HOTEL_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_HOTEL: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_HOTEL:
    case constant.EDITING_HOTEL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
