import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import NewFileComponent from "./NewFileComponent";
import { IMAGES } from "../../../constant";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { apiService } from "../../../common/api";
import { Beta } from "mdi-material-ui";

const SectionsCreationModal = ({
  showModal,
  toggleModal,
  handleCreateNewSection,
  deleteSection,
  fetchNavigationOptions,
  sections,
  eventGUID,
}) => {
  const cleanOptions = useMemo(() => {
    return sections.map((option) => {
      return option.Name.toLowerCase();
    });
  }, [sections]);

  const [predifinedOptions, setPredifinedOptions] = React.useState(sections);

  useEffect(() => {
    setPredifinedOptions(sections);
  }, [sections]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const items = Array.from(predifinedOptions);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    // Handle the new order here (e.g., update state or call an API)
    // setPredifinedOptions(items);
    setPredifinedOptions(items);
  };

  const handleShowSections = (sectionId) => {
    console.log({ sectionId });
    setPredifinedOptions((prev) => {
      return prev.map((section) => {
        if (section.Id === sectionId) {
          return {
            ...section,
            Show: !section.Show,
          };
        }
        return section;
      });
    });
  };

  const saveOrderAndBlocks = async () => {
    const orderToSave = predifinedOptions.map((option, index) => {
      return {
        Id: option.Id,
        EventId: option.EventId,
        Name: option.Name,
        BlockType: option.BlockType,
        Icon: option.Icon,
        Order: index,
        Show: option.Show || false,
        Beta: option.Beta || false,
      };
    });

    try {
      // call api to save the order
      const response = await apiService.post(
        "EventBlock/AddEditBatch",
        orderToSave
      );

      await fetchNavigationOptions();
    } catch (error) {
      console.log({ error });
    } finally {
      toggleModal();
    }
  };

  const handleChangeOptionName = (sectionId, newName) => {
    setPredifinedOptions((prev) => {
      return prev.map((section) => {
        if (section.Id === sectionId) {
          return {
            ...section,
            Name: newName,
          };
        }
        return section;
      });
    });
  };

  const [isEditMode, setIsEditMode] = React.useState(false);
  const handleEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      backdrop={true}
      className="d-flex justify-content-center align-items-center"
      dialogClassName="w-100 h-100 p-0 m-0 d-flex justify-content-center "
      contentClassName="w-screen h-screen border-0 d-flex align-items-center"
      style={{
        width: "100dvw",
        height: "100dvw",
      }}
    >
      <div
        className="bg-white"
        style={{
          width: "100dvw",
          height: "100dvw",
        }}
      >
        <div className="d-flex text-head justify-content-between align-items-center text-dark font-weight-bold">
          <div className="d-flex align-items-center">
            <span>Sections Manager</span>
          </div>
          <div className="text-center page-header-logo">
            <img
              src={IMAGES.LOGO_BLACK}
              alt="Plnify"
              style={{ width: "75px" }}
            />
          </div>
          <button className="btn " onClick={toggleModal}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div
          style={{
            height: "calc(100dvh - 50px)",
            overflowY: "auto",
          }}
        >
          <div className="p-4 bg-light w-screen">
            <div className="container px-4 ">
              <span className="text-dark font-weight-bold ">Create New</span>
              <p className="text-black-50">
                Move the sections around to change their order
              </p>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="sections"
                  direction="horizontal"
                  style={{ gap: "20px" }}
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="row mt-2"
                      style={{ gap: "20px" }}
                    >
                      {predifinedOptions.map((section, index) => (
                        <Draggable
                          key={section?.Id}
                          draggableId={section?.Id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <NewFileComponent
                                showEditBtn={true}
                                handleChangeOptionName={handleChangeOptionName}
                                isChecked={section.Show}
                                sectionId={section.Id}
                                color={section.Color}
                                cardClass="bg-white"
                                icon={section.Icon}
                                title={section.Name}
                                description={`Create ${section.Name} section`}
                                action={() => {
                                  if (
                                    !cleanOptions.includes(
                                      section?.Name?.toLowerCase()
                                    )
                                  ) {
                                    handleCreateNewSection(
                                      section.Name,
                                      section.Icon
                                    );
                                  } else {
                                    handleShowSections(section?.Id);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="mt-4 align-items-end d-flex justify-content-end ">
                {/* <buttom
                  className="btn btn-secondary mr-2"
                  onClick={toggleModal}
                >
                  Save as a template
                </buttom> */}
                <button
                  className="btn btn-primary w-100"
                  onClick={saveOrderAndBlocks}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          {/* <div className="container p-4 ">
            <span className="text-dark font-weight-bold ">Templates</span>

            <div
              className="d-flex flex-column justify-content-center align-items-center  font-weight-bold"
              style={{ height: "calc(100dvh - 700px)" }}
            >
              <i className="fas fa-file-alt text-black-50 fa-3x mb-2"></i>
              <span className="text-black-50">
                Saved templates will appear here
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default SectionsCreationModal;
