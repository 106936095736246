import Spinner from "react-bootstrap/Spinner";
import { IMAGES } from "../../constant";


export const Loading = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center align-content-center"
      style={{ height: "100vh" }}
    >
      <div className="position-relative" style={{ top: "25px", left: "37px" }}>
        <Spinner animation="grow" variant="info" size="sm" />
      </div>
      <img src={IMAGES.LOGO_ALT} alt="Plnify Logo" />
    </div>
  );
};
