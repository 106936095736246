import React from "react";
import GeneralUserItem from "./GeneralUserItem";

export const UserComponent = ({ user, organization, HandleSelectUser }) => {
  return (
    <div className="col-12 my-1 px-0" key={user.GUID}>
      <div
        className={
          (user.isChecked ? "selected" : "") +
          " visible-info-main card shadow-sm align-items-start"
        }
      >
        <div
          className="card-body p-1"
          onClick={() => HandleSelectUser(organization, user)}
        >
          <div className="row">
            <div className="col-auto px-1">
              {user.isChecked ? (
                <i className="fa fa-check-square text-dark"></i>
              ) : (
                <i className="far fa-square text-dark uncheck"></i>
              )}
            </div>
            <div
              className="col"
              title={user.GUID + " account " + user.AccountGUID}
            >
              <GeneralUserItem account={user.Account} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
