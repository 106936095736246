import React from "react";

const SkeletonLoading = () => {
  return (
    <div className="skeleton-loading">
      <div className="skeleton-image"></div>
      <div className="skeleton-text">
        <div className="skeleton-header"></div>
        <div className="skeleton-description"></div>
      </div>
    </div>
  );
};

export default SkeletonLoading;
