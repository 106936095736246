import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MemberLogin } from "./MemberLogin";
import { GuestLogin } from "./GuestLogin";

import { IMAGES } from "../../../constant";
import { BootStrapModal } from "../../authentication/forgotPassword/BootStrapModal";

export const TodoViewLogin = ({ isLoggedIn, setLoginInfo, setLoggedIn }) => {
  const [login, setLogin] = useState({ type: "member", isLoggedIn });
  const [submited, setSubmited] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotPass, setForgotPass] = useState({
    show: false,
    email: "",
    sent: false,
  });

  const handleClose = () =>
    setLogin((prev) => ({ ...prev, isLoggedIn: false }));

  const handleForgotPassword = () => {
    setForgotPass((prev) => ({ ...prev, show: !prev.show }));
  };

  const handleSubmit = () => {
    setForgotPass((prev) => ({ ...prev, sent: true }));
  };

  const setEmail = (value) => setForgotEmail(value);
  return (
    <>
      {forgotPass.show ? (
        <BootStrapModal
          open={forgotPass.show}
          sent={forgotPass.sent}
          toggle={handleForgotPassword}
          setEmail={setEmail}
          handleSuccess={handleSubmit}
          email={forgotEmail}
        />
      ) : (
        <Modal
          show={!login.isLoggedIn}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <div
            className="bg-white d-flex flex-column justify-content-center align-items-center py-5 rounded"
            style={{ minHeight: "300px"}}
          >
            <img src={IMAGES.LOGO_GRADIENT} className="mb-5" style={{width:"100px"}} />
            {login.type === "member" ? (
              <MemberLogin
                setLogin={setLogin}
                setLoggedIn={setLoggedIn}
                handleForgotPassword={handleForgotPassword}
                setLoginInfo={setLoginInfo}
              />
            ) : (
              <GuestLogin
                setLogin={setLogin}
                setLoggedIn={setLoggedIn}
                setLoginInfo={setLoginInfo}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
