import React, { useState } from "react";
import { IMAGES } from "../../../constant";
import DragAndDrop from "../../common/DragAndDrop";
import { FormattedMessage } from "react-intl";
import { ImageCrop } from "../../common/ImageCrop";

export const GroupImage = ({
  handleDragOut,
  onChangeDrop,
  groupImage,
  groupColor,
  groupImageEdit,
  setGroupImage,
  showDrop,
}) => {
  const editImageUrl = groupImage
    ? groupImage
    : groupImageEdit?.length < 41
    ? `https://service.plnify.com/files/${groupImageEdit}?n=${Math.random()}`
    : groupImageEdit;

  const [isNewDrop, setIsNewDrop] = useState(false);

  const handleImageDrop = (e, from) => {
    onChangeDrop(e, from);
    setIsNewDrop(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "200px",
        border: editImageUrl ? "" : "2px dashed gray ",
      }}
    >
      <DragAndDrop handleDragOut={handleDragOut} onChangeDrop={handleImageDrop}>
        <div
          style={{
            ...showDrop.style,
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        ></div>
      </DragAndDrop>
      <div className="createOrganization__thumbnail">
        {editImageUrl ? (
          <img
            alt="Crop"
            className="createOrganization__selected avatarLogo"
            style={{
              background: groupColor ?? "",
              width: "120px",
              height: "120px",
              objectPosition: "50% 50%",
              padding: "8px",
            }}
            src={editImageUrl}
          />
        ) : (
          <img
            src={IMAGES.ORGANIZATION_CREATE_PLACEHOLDER}
            alt="Create Organization"
            style={{
              height: "100px",
              width: "100px",
              objectFit: "cover",
              objectPosition: "50% 35%",
            }}
          />
        )}
        {/* <input
          style={{}}
          type="file"
          accept="image/*"
          onChange={setGroupImage}
          autoComplete="off"
        /> */}

        <ImageCrop
          handleChange={setGroupImage}
          dropImage={groupImage || groupImageEdit}
          isNewDrop={isNewDrop}
          setIsNewDrop={setIsNewDrop}
        />
        <div
          className="createOrganization__little font-weight-bold"
          style={{ display:"flex", flexDirection:"column", bottom: "50px", margin: "0 auto" }}
        >
          <FormattedMessage
            id="uploadphoto"
            defaultMessage="Drop or upload your banner here"
          />
          <p style={{fontSize:"10px", margin:"0"}}>[Max: 2MB Size: 716x300]</p>
        </div>
      </div>
    </div>
  );
};
