import React from "react";
import { NoteTextOutline } from "mdi-material-ui";
import Swal from "sweetalert2";
import { IMAGES } from "../../../../constant";

export const GuestListTableRow = ({ ticket, deleteTicket }) => {
  function handleShowNote(note) {
    Swal.fire({
      title: "Request Note",
      text: note,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    }).then((result) => {});
  }

  function handleDeleteTicket(ticket) {
    deleteTicket(ticket);
  }

  let note = ticket?.Note?.split("|")[0].trim();
  note = note.includes("First Name") ? null : note;
  return (
    <tr>
      <td
        // onClick={() => toggleTask(todo)}
        style={{
          width: "1.5rem",
          verticalAlign: "middle",
        }}
      >
        {`${ticket.RequestedFor} ${ticket.RequestedForLastName}`}
      </td>
      <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {ticket.Passes}
      </td>
      <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {ticket.PassType}
      </td>
      {/* <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {ticket.PickupLocation}
      </td> */}

      <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {note ? (
          <NoteTextOutline
            onClick={() => {
              handleShowNote(note);
            }}
            htmlColor="#01aeff"
          />
        ) : (
          ""
        )}
      </td>
      {/* <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {ticket.ConfirmAccount}
      </td>
      <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {ticket.Seat}
      </td> */}
      <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        <button
          className="btn btn-link text-danger"
          onClick={() => handleDeleteTicket(ticket)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};
