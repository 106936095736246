import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddHotelComponent from "../../page/addHotel/addHotelContainer";
import * as commonActions from "../../page/common/redux/actions";
import * as actions from "../../page/event/redux/actions";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import LinkModal from "../common/linkModal";
import EmailModal from "./emailModal";
import Hotel from "./hotel";

import Swal from "sweetalert2";
import ExportReservations from "./ExportReservations";

import { FormattedMessage } from "react-intl";
import { IMAGES } from "../../constant";
import LabelForm from "../form/LabelForm";

import AssignMembersHotel from "../hotels/AssignMembersHotel";
import { handleCloseEmail } from "../../utils/closeMailModal";

const HotelContainers = ({ handleSidebarItem, sideBarItemData, ...props }) => {
  const teams = useSelector((state) => state.organizations.teams);
  const events = useSelector((state) => state.calendar.calendarEvents);
  const event = useSelector((state) => state.event.event);
  const hotels = useSelector((state) => state.event.hotels);
  const loading = useSelector((state) => state.event.loading);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    modal: false,
    moveComponent: false,
    exportComponent: false,
    addNewsModal: false,
    linkModal: false,
    editMode: false,
    modalReservations: false,
    labelModal: false,
    eventsSelected: [],
    staffSelected: [],
    hotelSelected: [],
  });

  const toggleEvent = (event) => {
    let events = state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return setState((prev) => ({ ...prev, eventsSelected: filtered }));
    } else {
      let newOnes = events.concat(event);
      return setState((prev) => ({ ...prev, eventsSelected: newOnes }));
    }
  };

  const toggleHotel = (hotel) => {
    let hotels = state.hotelSelected;
    if (hotels.filter((h) => h === hotel.GUID).length > 0) {
      let filtered = hotels.filter((h) => h !== hotel.GUID);
      return setState((prev) => ({ ...prev, hotelSelected: filtered }));
    } else {
      let newOnes = hotels.concat(hotel.GUID);
      return setState((prev) => ({ ...prev, hotelSelected: newOnes }));
    }
  };

  const emptySelecteds = () => {
    setState((prev) => ({ ...prev, eventsSelected: [] }));
  };

  const toggleModal = (showMembers, hotelItem) => {
    console.log({ showMembers, hotelItem });
    setState((prev) => ({ ...prev, modal: !state.modal }));

    if (showMembers === true) {
      setTimeout(() => {
        handleSidebarItem("HOTEL", hotelItem);
        toggleNewMembersModal(hotelItem);
      }, 1000);
    }
  };

  const toggleMoveModal = () => {
    setState((prev) => ({ ...prev, moveComponent: !state.moveComponent }));
  };

  const toggleExportModal = () => {
    setState((prev) => ({ ...prev, exportComponent: !state.exportComponent }));
  };

  const toggleLinkModal = () => {
    setState((prev) => ({ ...prev, linkModal: !state.linkModal }));
  };

  const handleAddMemberSucess = (hotel) => {
    console.log({ hotel });
    handleSidebarItem("HOTEL", hotel);
  };

  const toggleNewMembersModal = (opt) => {
    setState((prev) => ({ ...prev, addNewsModal: !state.addNewsModal }));
    if (state.addNewsModal) {
      dispatch(
        actions.getHotel(
          event.GUID,
          sideBarItemData.GUID,
          handleAddMemberSucess
        )
      );
    }
  };

  const toggleExportReservations = () => {
    setState((prev) => ({
      ...prev,
      modalReservations: !state.modalReservations,
    }));
  };

  // const addReservations = (staffs) => {
  //   setState((prev) => ({ ...prev, staffSelected: staffs }));
  // };

  // const cleanReservations = () => {
  //   setState((prev) => ({ ...prev, staffSelected: [] }));
  // };

  // const updateReservation = (reservation) => {
  //   let staffs = state.staffSelected.map((staff) =>
  //     staff.AccountGUID === reservation.AccountGUID
  //       ? (staff = reservation)
  //       : staff
  //   );
  //   setState((prev) => ({ ...prev, staffSelected: staffs }));
  // };

  // const updateReservations = () => {
  //   const { sideBarItemData } = props;
  //   dispatch(
  //     actions.editReservations(state.staffSelected, sideBarItemData, () => {
  //       ToastNotificationSuccess(1, "Updated reservations");
  //       toggleNewMembersModal();
  //     })
  //   );
  // };

  const editModal = (hotel) => {
    handleSidebarItem("HOTEL", hotel);
    setState((prev) => ({ ...prev, editMode: true }));
    toggleModal();
  };

  const deleteHotel = (hotel) => {
    Swal.fire({
      text: "Are you sure you want to delete '" + hotel.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteHotel(hotel, () => {
            setTimeout(() => {
              dispatch(actions.getHotel(event.GUID));
              ToastNotificationSuccess(1, "Hotel deleted successfully");
              handleSidebarItem("EVENT_DETAILS", {});
            }, 1000);
          })
        );
      }
    });
  };

  const closeEdit = () => {
    setState((prev) => ({ ...prev, editMode: false }));
    toggleModal();
  };

  const editHotel = (hotel) => {
    dispatch(
      actions.editHotel(hotel, false, () => {
        dispatch(actions.getHotel(event.GUID));
        ToastNotificationSuccess(1, "Updated Hotel");
        closeEdit();
      })
    );
  };

  const editLabel = (hotel) => {
    dispatch(
      actions.editHotel(hotel, false, () => {
        dispatch(actions.getHotel(event.GUID));
        toggleLabelForm();
        ToastNotificationSuccess(1, "Label updated");
      })
    );
  };

  const exportReservations = () => {
    dispatch(
      actions.exportReservations(
        sideBarItemData.HotelReservations,
        state.hotelSelected,
        () => {
          ToastNotificationSuccess(1, "Updated Hotel");
          toggleExportReservations();
          dispatch(actions.getHotel(event.GUID));
        }
      )
    );
  };

  // const toggleSelectAll = (team, checkIn, checkOut) => {
  //   let staffs = state.staffSelected;
  //   team.forEach((staff) => {
  //     if (
  //       staffs.filter((s) => s.AccountGUID === staff.AccountGUID).length > 0
  //     ) {
  //       let filtered = staffs.filter(
  //         (s) => s.AccountGUID !== staff.AccountGUID
  //       );
  //       staffs = filtered;
  //     } else {
  //       staffs.push({ ...staff, CheckInDate: checkIn, CheckOutDate: checkOut });
  //     }
  //   });
  //   setState((prev) => ({ ...prev, visibleStaffs: staffs }));
  // };

  const toggleLabelForm = () => {
    setState((prev) => ({ ...prev, labelModal: !state.labelModal }));
  };

  const GoBackToEditHotel = (hotel) => {
    toggleNewMembersModal();
    setState((prev) => ({ ...prev, editMode: true }));
    toggleModal();
    props.handleSidebarItem("HOTEL", hotel);
    toggleModal();
  };

  console.log("Hotels was render");
  return (
    <>
      <ModalComponent
        toggle={toggleNewMembersModal}
        modal={state.addNewsModal}
        childeren={
          <AssignMembersHotel
            hotel={sideBarItemData}
            toggle={toggleNewMembersModal}
            originEvent={event}
            active={state.addNewsModal}
            edit={false}
            donable
            GoBackToEditHotel={GoBackToEditHotel}
          />
        }
      />
      <ModalComponent
        toggle={state.editMode ? closeEdit : toggleModal}
        modal={state.modal}
        childeren={
          <AddHotelComponent
            searchHotels={commonActions.searchHotels}
            emptyHotels={commonActions.emptyHotels}
            toggle={state.editMode ? closeEdit : toggleModal}
            event={event}
            edit={state.editMode}
            hotel={sideBarItemData}
            editHotel={editHotel}
            loading={loading}
            handleSidebarItem={handleSidebarItem}
            toggleNewMembersModal={toggleNewMembersModal}
          />
        }
      />

      <ModalComponent
        toggle={toggleExportReservations}
        modal={state.modalReservations}
        childeren={
          <ExportReservations
            hotel={props.sideBarItemData}
            hotelSelected={state.hotelSelected}
            toggleHotel={toggleHotel}
            arrayOfHotels={hotels}
            exportReservations={exportReservations}
            loading={loading}
          />
        }
      />

      <ModalComponent
        toggle={() => handleCloseEmail(toggleMoveModal)}
        modal={state.moveComponent}
        childeren={
          <EmailModal
            subject={
              sideBarItemData ? sideBarItemData.Name + " - Roomlist" : ""
            }
            hotel={sideBarItemData}
            fromSection="HOTEL_ROOMLIST"
            teams={teams}
            toggle={toggleMoveModal}
          />
        }
      />

      <ModalComponent
        toggle={toggleExportModal}
        modal={state.exportComponent}
        childeren={
          <LinkModal
            title={
              <FormattedMessage
                id="copyToAnotherEvent"
                defaultMessage="Copy to another event"
              />
            }
            footer={"Export"}
            type={"hotel"}
            arrayEvents={events}
            toggle={toggleExportModal}
            toggleEvent={toggleEvent}
            eventsSelected={state.eventsSelected}
            sideBarItemData={sideBarItemData}
            actionResource={commonActions.exportResource}
            event={event}
            loading={loading}
            success={() => {
              ToastNotificationSuccess(1, "Hotel exported successfully.");
              emptySelecteds();
            }}
          />
        }
      />

      <ModalComponent
        toggle={toggleLinkModal}
        modal={state.linkModal}
        childeren={
          <LinkModal
            title={
              <FormattedMessage
                id="moveToAnotherEvent"
                defaultMessage="Move to another event"
              />
            }
            footer={"Move"}
            type={"hotel"}
            arrayEvents={events}
            toggle={toggleLinkModal}
            toggleEvent={toggleEvent}
            eventsSelected={state.eventsSelected}
            sideBarItemData={sideBarItemData}
            actionResource={commonActions.moveResource}
            event={event}
            loading={loading}
            success={() => {
              ToastNotificationSuccess(1, "Hotel moved successfully.");
              emptySelecteds();
            }}
          />
        }
      />

      <ModalComponent
        modal={state.labelModal}
        toggle={() => toggleLabelForm()}
        childeren={
          <LabelForm
            loading={loading}
            submitFunction={editLabel}
            resource={sideBarItemData}
            toggle={() => toggleLabelForm()}
          />
        }
      />

      <Hotel
        blockName={props.blockName}
        deleteHotel={deleteHotel}
        handleSidebarItem={handleSidebarItem}
        hotels={hotels}
        toggleModal={toggleModal}
        toggleMoveModal={toggleMoveModal}
        toggleLink={toggleLinkModal}
        toggleExport={toggleExportModal}
        sideBarItemData={sideBarItemData}
        toggleNewMembersModal={toggleNewMembersModal}
        editModal={editModal}
        toggleExportReservations={toggleExportReservations}
        toggleLabelForm={toggleLabelForm}
        actions={props.actions}
        originEvent={event}
      />
    </>
  );
};

export default HotelContainers;
