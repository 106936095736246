import React from "react";
import { IMAGES } from "../../constant";
import { parseLogo } from "../../utils";
import history from "../common/history";

import { randomImage } from "../common/randomImage";
import { useDispatch } from "react-redux";

const SelectItem = (props) => {
  const dispatch = useDispatch();
  let { organization } = props;
  const selectOrganization = (organization) => {
    dispatch(props.setOrganization(organization, () => history.push("/")));
  };
  const parsedLogo =
    organization && organization.Logo && organization.Logo;
  let logo = organization.Logo !== undefined && organization.Logo.includes("http")
    ? organization.Logo
    : "https://service.plnify.com/files/" +
      parsedLogo +
      "?w=35&h=35&mode=crop&autorotate=true";
  logo = logo.replace("http:", "https:");
  let imgPlaceholder = randomImage(organization.Name.charAt(0));

  function imgErrorHandler() {
    logo = imgPlaceholder;
  }
  return (
    <div
      className={`row mx-0 clickable ${props?.customClass && props?.customClass}`}
      onClick={() => selectOrganization(organization)}
    >
      <div className="col-12 py-2 m-0">
        <div className="d-inline avatarContainer">
          {organization && organization.Logo ? (
            <img
              className="avatar"
              onError={() => imgErrorHandler()}
              src={logo}
              alt={organization.Name}
            />
          ) : (
            <img alt=""
              className="img-fluid"
              src={`https://dummyimage.com/35x35/8e9bb6/ffffff.png&text=${organization.Name.split(
                " "
              )
                .map((x) => x.charAt(0))
                .join("")
                .substr(0, 3)
                .toUpperCase()}`}
            />
          )}
        </div>
        <div className="d-inline ml-4 title text-dark">
          {organization.Name}
        </div>
      </div>
    </div>
  );
};

export default SelectItem;
