import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import NewFileComponent from "./NewFileComponent";
import FileListItem from "./FileListItem";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IMAGES } from "../../../constant";
import { apiService } from "../../../common/api";
import { getBasicInfo } from "../../../utils";

const MoreDetailsCreationModal = ({
  showModal,
  toggleModal,
  handleAddDocument,
  createFileFromTemplate,
}) => {
  const mockFileData = [
    {
      Type: "folder",
      GUID: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Title: "Empty Folder",
      Path: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2024-03-08T15:25:10.7435788+00:00",
    },
    {
      Type: "folder",
      GUID: "c0902659-3a48-4903-80b6-33a366f6e435",
      Title: "Manuel Perezs",
      Path: "c0902659-3a48-4903-80b6-33a366f6e435",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-06-05T21:09:01.5408187+00:00",
    },
    {
      Type: "folder",
      GUID: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Title: "My Folder",
      Path: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: "#f7cb91",
      Created: "2023-10-17T22:43:37.5334726+00:00",
    },
    {
      Type: "folder",
      GUID: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Title: "New Folder 3 4",
      Path: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:02.3331328+00:00",
    },
    {
      Type: "folder",
      GUID: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Title: "New Folder 5",
      Path: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:13.1769712+00:00",
    },
    {
      Type: "folder",
      GUID: "ae031f49-50bf-4928-abef-d307db85405f",
      Title: "New Folter Test",
      Path: "ae031f49-50bf-4928-abef-d307db85405f",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: "#fbb6bc",
      Created: "2023-11-28T13:22:19.4823245+00:00",
    },
    {
      Type: "folder",
      GUID: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Title: "Empty Folder",
      Path: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2024-03-08T15:25:10.7435788+00:00",
    },
    {
      Type: "folder",
      GUID: "c0902659-3a48-4903-80b6-33a366f6e435",
      Title: "Manuel Perezs",
      Path: "c0902659-3a48-4903-80b6-33a366f6e435",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-06-05T21:09:01.5408187+00:00",
    },
    {
      Type: "folder",
      GUID: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Title: "My Folder",
      Path: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: "#f7cb91",
      Created: "2023-10-17T22:43:37.5334726+00:00",
    },
    {
      Type: "folder",
      GUID: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Title: "New Folder 3 4",
      Path: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:02.3331328+00:00",
    },
    {
      Type: "folder",
      GUID: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Title: "New Folder 5",
      Path: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:13.1769712+00:00",
    },
    {
      Type: "folder",
      GUID: "ae031f49-50bf-4928-abef-d307db85405f",
      Title: "New Folter Test",
      Path: "ae031f49-50bf-4928-abef-d307db85405f",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: "#fbb6bc",
      Created: "2023-11-28T13:22:19.4823245+00:00",
    },
    {
      Type: "folder",
      GUID: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Title: "Empty Folder",
      Path: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2024-03-08T15:25:10.7435788+00:00",
    },
    {
      Type: "folder",
      GUID: "c0902659-3a48-4903-80b6-33a366f6e435",
      Title: "Manuel Perezs",
      Path: "c0902659-3a48-4903-80b6-33a366f6e435",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-06-05T21:09:01.5408187+00:00",
    },
    {
      Type: "folder",
      GUID: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Title: "My Folder",
      Path: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: "#f7cb91",
      Created: "2023-10-17T22:43:37.5334726+00:00",
    },
    {
      Type: "folder",
      GUID: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Title: "New Folder 3 4",
      Path: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:02.3331328+00:00",
    },
    {
      Type: "folder",
      GUID: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Title: "New Folder 5",
      Path: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:13.1769712+00:00",
    },
    {
      Type: "folder",
      GUID: "ae031f49-50bf-4928-abef-d307db85405f",
      Title: "New Folter Test",
      Path: "ae031f49-50bf-4928-abef-d307db85405f",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: "#fbb6bc",
      Created: "2023-11-28T13:22:19.4823245+00:00",
    },
    {
      Type: "folder",
      GUID: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Title: "Empty Folder",
      Path: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2024-03-08T15:25:10.7435788+00:00",
    },
    {
      Type: "folder",
      GUID: "c0902659-3a48-4903-80b6-33a366f6e435",
      Title: "Manuel Perezs",
      Path: "c0902659-3a48-4903-80b6-33a366f6e435",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-06-05T21:09:01.5408187+00:00",
    },
    {
      Type: "folder",
      GUID: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Title: "My Folder",
      Path: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: "#f7cb91",
      Created: "2023-10-17T22:43:37.5334726+00:00",
    },
    {
      Type: "folder",
      GUID: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Title: "New Folder 3 4",
      Path: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:02.3331328+00:00",
    },
    {
      Type: "folder",
      GUID: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Title: "New Folder 5",
      Path: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:13.1769712+00:00",
    },
    {
      Type: "folder",
      GUID: "ae031f49-50bf-4928-abef-d307db85405f",
      Title: "New Folter Test",
      Path: "ae031f49-50bf-4928-abef-d307db85405f",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: "#fbb6bc",
      Created: "2023-11-28T13:22:19.4823245+00:00",
    },
    {
      Type: "folder",
      GUID: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Title: "Empty Folder",
      Path: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2024-03-08T15:25:10.7435788+00:00",
    },
    {
      Type: "folder",
      GUID: "c0902659-3a48-4903-80b6-33a366f6e435",
      Title: "Manuel Perezs",
      Path: "c0902659-3a48-4903-80b6-33a366f6e435",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-06-05T21:09:01.5408187+00:00",
    },
    {
      Type: "folder",
      GUID: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Title: "My Folder",
      Path: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: "#f7cb91",
      Created: "2023-10-17T22:43:37.5334726+00:00",
    },
    {
      Type: "folder",
      GUID: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Title: "New Folder 3 4",
      Path: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:02.3331328+00:00",
    },
    {
      Type: "folder",
      GUID: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Title: "New Folder 5",
      Path: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:13.1769712+00:00",
    },
    {
      Type: "folder",
      GUID: "ae031f49-50bf-4928-abef-d307db85405f",
      Title: "New Folter Test",
      Path: "ae031f49-50bf-4928-abef-d307db85405f",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: "#fbb6bc",
      Created: "2023-11-28T13:22:19.4823245+00:00",
    },
    {
      Type: "folder",
      GUID: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Title: "Empty Folder",
      Path: "4186677c-0af6-4c6a-ab5c-895145d15237",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2024-03-08T15:25:10.7435788+00:00",
    },
    {
      Type: "folder",
      GUID: "c0902659-3a48-4903-80b6-33a366f6e435",
      Title: "Manuel Perezs",
      Path: "c0902659-3a48-4903-80b6-33a366f6e435",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-06-05T21:09:01.5408187+00:00",
    },
    {
      Type: "folder",
      GUID: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Title: "My Folder",
      Path: "4d80b7e3-b2be-4cbe-aa23-d9f9afa24426",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: "#f7cb91",
      Created: "2023-10-17T22:43:37.5334726+00:00",
    },
    {
      Type: "folder",
      GUID: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Title: "New Folder 3 4",
      Path: "581e8f7d-32be-4a4c-a932-4fa862c89f8d",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: '["16b031f0-e859-4bdd-8948-19691d345e69"]',
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:02.3331328+00:00",
    },
    {
      Type: "folder",
      GUID: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Title: "New Folder 5",
      Path: "ce814228-031a-4fea-a752-31f2b24e2e37",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: "[]",
      ParentGUID: null,
      Note: null,
      Color: null,
      Created: "2023-11-01T21:50:13.1769712+00:00",
    },
    {
      Type: "folder",
      GUID: "ae031f49-50bf-4928-abef-d307db85405f",
      Title: "New Folter Test",
      Path: "ae031f49-50bf-4928-abef-d307db85405f",
      Update: null,
      UserId: "fd8df124-9166-4a51-8f81-b13b0e8ca5c5",
      EventShared: null,
      ParentGUID: null,
      Note: null,
      Color: "#fbb6bc",
      Created: "2023-11-28T13:22:19.4823245+00:00",
    },
  ];

  const [templateData, setTemplateData] = useState(mockFileData);
  const [templateSearch, setTemplateSearch] = useState("");
  const [systemOnly, setSystemOnly] = useState(false);
  const toggleRef = React.useRef(null);

  const [isChecked, setIsChecked] = useState(false);

  const handleTogglePersonalTemplatesOnly = () => {
    setIsChecked((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    (async () => {
      const response = await apiService.get(
        `EventMoreDetails/Templates?CreatedBy=${getBasicInfo().GUID}`
      );
      setTemplateData((prev) => prev.concat(response.data.response));
    })();
  }, []);

  let filterTemplates = templateData?.filter((item) =>
    item?.Title?.toLowerCase()?.includes(templateSearch?.toLowerCase())
  );

  if (isChecked) {
    filterTemplates = filterTemplates.filter(
      (item) => item.CreatorAccountGUID === getBasicInfo().GUID
    );
  }

  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      backdrop={true}
      className="d-flex justify-content-center align-items-center"
      dialogClassName="w-100 h-100 p-0 m-0 d-flex justify-content-center "
      contentClassName="w-screen h-screen border-0 d-flex align-items-center"
      style={{
        width: "100dvw",
        height: "100dvw",
      }}
    >
      <div
        className="bg-white"
        style={{
          width: "100dvw",
          height: "100dvw",
        }}
      >
        <div className="d-flex text-head justify-content-between align-items-center text-dark font-weight-bold">
          <div className="d-flex align-items-center">
            <span>Advanced Details</span>
          </div>
          <div className="text-center page-header-logo">
            <img
              src={IMAGES.LOGO_BLACK}
              alt="Plnify"
              style={{ width: "75px" }}
            />
          </div>
          <button className="btn " onClick={toggleModal}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div
          style={{
            height: "calc(100dvh - 50px)",
            overflowY: "auto",
          }}
        >
          <div className="p-4 bg-light w-screen">
            <div className="container px-4 ">
              <span className="text-dark font-weight-bold ">Create New</span>
              <div
                className="row mt-2"
                style={{
                  gap: "20px",
                }}
              >
                <NewFileComponent
                  color="darkBlue"
                  cardClass="bg-white"
                  icon="fa-file-alt"
                  title="Document"
                  description="New Blank Document"
                  action={() => {
                    handleAddDocument(null, ".docx");
                  }}
                />
                <NewFileComponent
                  color="green"
                  cardClass="bg-white"
                  icon="fa-file-excel"
                  title="Spreadsheet"
                  description="New Workbook Document"
                  action={() => {
                    handleAddDocument(null, ".xlsx");
                  }}
                />
                <NewFileComponent
                  color="darkred"
                  cardClass="bg-white"
                  icon="fa-file-pdf"
                  title="PDF"
                  description="New PDF Document"
                  type={"file"}
                  accept={".pdf"}
                  action={(e) => {
                    handleAddDocument(e?.target?.files[0]);
                  }}
                />
                <NewFileComponent
                  color="blue"
                  cardClass="bg-white"
                  icon="fa-file-upload"
                  title="Upload"
                  description="Upload a file"
                  type={"file"}
                  action={(e) => {
                    handleAddDocument(e?.target?.files[0]);
                  }}
                />
                <NewFileComponent
                  color="blue"
                  cardClass="bg-white"
                  icon="fa-globe"
                  title="Create a Link"
                  description="Create a Link"
                  type={"link"}
                  action={(e) => {
                    handleAddDocument("link", "link");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container p-4">
            <div className="d-flex w-100 text-dark px-2 justify-content-between align-items-center">
              <span className="text-dark font-weight-bold">Templates</span>
              <div
                className="d-flex align-items-center w-50"
                style={{ gap: "10px" }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={templateSearch}
                  onChange={(e) => setTemplateSearch(e.target.value)}
                />
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "5px", width: "180px" }}
                >
                  <span className="text-black-50" style={{ fontSize: "12px" }}>
                    All
                  </span>
                  <div
                    className={`toggle-switch ${isChecked && "active"}`}
                    id="myToggle"
                    onClick={handleTogglePersonalTemplatesOnly}
                  >
                    <div
                      ref={toggleRef}
                      className={`toggle-switch-inner ${isChecked && "active"}`}
                    ></div>
                  </div>
                  <span className="text-black-50" style={{ fontSize: "12px" }}>
                    Only Mine
                  </span>
                </div>
              </div>
            </div>
            <div
              className="d-flex w-100 p-4 overflow-auto container"
              style={{
                overflowX: "scroll",
                overflowY: "hidden",
                gap: "20px",
              }}
            >
              <div className="row">
                {filterTemplates.map((item) => {
                  let icon =
                    item.Path.split(".")[item.Path.split(".").length - 1] ===
                    "pdf"
                      ? "fa-file-pdf"
                      : item.Path.split(".")[
                          item.Path.split(".").length - 1
                        ] === "xlsx"
                      ? "fa-file-excel"
                      : "fa-file-alt";

                  let color =
                    item.Path.split(".")[item.Path.split(".").length - 1] ===
                    "pdf"
                      ? "darkred"
                      : item.Path.split(".")[
                          item.Path.split(".").length - 1
                        ] === "xlsx"
                      ? "green"
                      : "darkBlue";
                  return (
                    <div className="d-flex align-items-center p-2 col-sm">
                      <NewFileComponent
                        color={color}
                        icon={icon}
                        title={item.Title}
                        hasBash={!item?.CreatorAccountGUID}
                        description="New Blank Document"
                        action={() => {
                          createFileFromTemplate(item);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="container p-4">
            <div className="border-bottom p-2 my-0 d-flex justify-content-between align-items-center text-dark font-weight-bold">
              <span className="text-dark">Quick Access</span>
              <button
                className="btn btn-primary align-items-center d-flex"
                style={{
                  gap: "5px",
                }}
              >
                <i class="fas fa-solid fa-upload"></i>Upload
              </button>
            </div>
            <div className="row quick-access-files-container">
              {mockFileData.map((item) => (
                <FileListItem item={item} key={item.GUID} />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default MoreDetailsCreationModal;
