import React from "react";
import { ChildItemContent } from "./EmailExport/ChildItemContent";
import UserItem from "../hotel/UserItem";

export const MoveHotelChildItem = ({
  item,
  section,
  event,
  selectedItems,
  handleParams,
  isParentChecked,
  sectionsParams,
  setSectionsParams,
}) => {
  const HandleToggle = () => {
    setIsChecked((prev) => !prev);
    handleParams({
      hotelGUID: item.GUID,
      eventGUID: event,
      checkIn: item.CheckIn,
      checkOut: item.CheckOut,
      hotelName: item.Name,
    });
  };

  const toggleRef = React.createRef();
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (
      (selectedItems?.includes(item.GUID) ||
        selectedItems?.includes(item) ||
        selectedItems?.includes(item.Connection)) &&
      isParentChecked
    ) {
      setIsChecked(true);
      handleParams({
        hotelGUID: item.GUID,
        eventGUID: event,
        checkIn: item.CheckIn,
        checkOut: item.CheckOut,
        hotelName: item.Name,
      });
    } else {
      setIsChecked(false);
    }
  }, [selectedItems, isParentChecked, item]);

  const [showReservations, setShowReservations] = React.useState(false);

  const handleShowReservations = () => {
    setShowReservations((prev) => !prev);
  };

  return (
    <div className="export-style-menu border border-bottom">
      <div className="w-100 d-flex justify-content-between align-items-center px-4 py-2">
        <div className="d-flex flex-column" style={{ width: "80%" }}>
          <div style={{ width: "100%" }}>
            <ChildItemContent section={section} item={item} />
          </div>
        </div>
        <div className="d-flex align-items-center ml-2">
          <div
            style={{ height: "10px", width: "40px" }}
            className={`toggle-switch ${isChecked && "active"}`}
            id="myToggle"
            title="Show/Hide Section"
            onClick={!isParentChecked ? () => {} : HandleToggle}
          >
            <div
              ref={toggleRef}
              style={{
                height: "20px",
                width: "20px",
                boxShadow: "0px 0px 5px 0px #000000",
                backgroundColor: `${isChecked ? "#007bff" : "#fff"}`,
              }}
              className={`toggle-switch-inner ${isChecked && "active"}`}
            ></div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column">
        <div className="d-flex justify-content-between px-4 py-2">
          <span className="font-weight-bold">Members</span>
          <div className="d-flex align-items-center">
            <small className="mr-2">{`Assigned Members: ${
              item.HotelReservations.filter((x) => !x.Deleted).length
            }`}</small>
            <button
              className="btn btn-primary"
              onClick={handleShowReservations}
            >
              {showReservations ? "Hide" : "Show"}
            </button>
          </div>
        </div>

        {showReservations && (
          <div className="d-flex flex-column p-2">
            {item.HotelReservations.filter((x) => !x.Deleted).map(
              (reservation) => (
                <UserItem user={reservation} noActions />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};
