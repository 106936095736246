import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { DropDownComponent, ToastNotificationSuccess } from "../common";
import { PriorityDropDown } from "./PriorityDropDown";
import { StaffDropDown } from "./StaffDropDown";
import ShowMoreText from "react-show-more-text";

function getPriority(p) {
  if (p === 0) return "Low";
  if (p === 1) return "Medium";
  if (p === 2) return "High";
  return "";
}

function showPriority(p) {
  if (p === 1) return "!";
  if (p === 2) return "!!";
  if (p === 3) return "!!!";
  return "";
}

function getPriorityStyle(p) {
  if (p === 0) return "success";
  if (p === 1) return "warning";
  if (p === 2) return "danger";
  return "";
}
export const TaskTable = (props) => {
  let {
    todo,
    toggleTask,
    deleteTask,
    toggleTaskEdit,
    currentList,
    handleCancelTask,
    handleSaveTask,
    editMode,
    handleEditTask,
    handleShareModal,
    isLoading,
  } = props;

  // const data = localStorage.getItem(todo.GUID);
  // const assignee =
  //   data !== "undefined" && data !== null ? JSON.parse(data) : "";
  const priorityItems = [
    {
      id: 0,
      name: "Low",
      style: "success",
    },
    {
      id: 1,
      name: "Medium",
      style: "warning",
    },
    {
      id: 2,
      name: "High",
      style: "danger",
    },
  ];

  let assignString = todo?.Assignees;
  if (typeof todo?.Assignees === "object")
    assignString =
      typeof todo?.Assignees === "object" && JSON.stringify(todo?.Assignees);

  const [expanded, setExpanded] = useState(false);
  const [staffSelected, setStaffSelected] = useState(
    assignString ? JSON.parse(assignString)?.[0] : ""
  );
  const [isOver, setIsOver] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isCompleting, setIsCompleting] = useState({
    id: null,
    isLoading: false,
  });
  const [isDatePick, setIsDatePick] = useState(false);
  const [todoState, setTodoState] = useState(todo);
  const [selectedPriority, setSelectedPriority] = useState(
    todo.Priority
      ? {
          id: todo.Priority,
          name: getPriority(todo.Priority),
          style: getPriorityStyle(todo.Priority),
        }
      : {
          id: null,
          name: null,
          style: null,
        }
  );
  const history = useHistory();

  const daysCount = ["Today", "Tomorrow"];

  const handleSelectPriority = (priority) => {
    setTodoState((prev) => ({
      ...prev,
      Priority: priority.id,
    }));
    setSelectedPriority(priority);
  };

  const handleSelectAssignee = (Assignee) => {
    setTodoState((prev) => ({ ...prev, Assignees: Assignee }));
    setStaffSelected({ ...Assignee });
  };

  const handleChange = ({ target }) => {
    console.log({ value: target.value });
    setTodoState((prev) => ({
      ...prev,
      [target.name]: target.value === "" ? null : target.value,
    }));
  };

  const [selectedLocation, setSelectedLocation] = useState(null);

  function handleLocationSelected(location) {
    setSelectedLocation(location);
  }

  const handleOpenMap = () => {
    window.open(
      `https://www.google.com/maps`,
      "MapWindow",
      "width=1280,height=840"
    );
  };

  const handleMouseOver = () => {
    setIsOver((prev) => !prev);
  };

  const handleInputDate = () => {
    setIsDatePick((prev) => true);
  };

  const handleView = (todo) => {
    const taskId = todo.TodoGUID;
    const todoId = todo.GUID;

    history.push(`todo/${taskId}/${todoId}`);
    // history.push(`/todo/${todo.GUID}`)
  };

  const handleShare = async (todo) => {
    if (!navigator.canShare) return handleShareModal();
    navigator.canShare();
    const shareData = {
      title: "Plnify",
      text: "Check your pending todo.",
      url: `${window.location.protocol}//${window.location.host}/todo/${todo.TodoGUID}/${todo.GUID}`,
    };

    try {
      await navigator.share(shareData);
      // ToastNotificationSuccess(1,"Link shared successfully")
    } catch (err) {
      ToastNotificationSuccess(2, `Error: ${err}`);
    }
  };

  const DropDownData =
    todo.Status === 0
      ? [
          {
            name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
            border: true,
            trigger: () => handleEditTask(todo),
          },
          {
            name: <FormattedMessage id="share" defaultMessage="Share" />,
            border: true,
            trigger: () => handleShare(todo),
          },
          {
            name: <FormattedMessage id="delete" defaultMessage="Delete" />,
            delete: true,
            trigger: () => deleteTask(todo),
          },
        ]
      : [
          {
            name: <FormattedMessage id="delete" defaultMessage="Delete" />,
            delete: true,
            trigger: () => deleteTask(todo),
          },
        ];

  const handleCompleteTask = (todo) => {
    setIsCompleting({ id: todo.GUID, isLoading: true });
    toggleTask(todo);
  };

  console.log({ date: todoState.DueDate });
  return (
    <tr
      className=""
      style={{ height: "3rem" }}
      onDoubleClick={
        !editMode && !todo.Status
          ? () => {
              handleEditTask(todoState);
            }
          : () => {}
      }
      // onClick={() => handleView(todoState)}
    >
      <td
        onClick={() => handleCompleteTask(todo)}
        style={{
          verticalAlign: "middle",
          width: "2rem",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {todo.Status ? (
          <>
            <i className="fas fa-check-circle pt-1"></i>
          </>
        ) : (
          <>
            {todo.GUID === isCompleting.id && isCompleting.isLoading ? (
              <div
                className="spinner-grow text-primary  spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <i
                className={`pt-1 far ${
                  isOver ? "fa-check" : "fa-circle"
                } uncheck `}
                // onMouseEnter={handleMouseOver}
                // onMouseLeave={handleMouseOver}
              ></i>
            )}
          </>
        )}
      </td>
      <td
        style={{
          textDecoration: todo.Status ? "line-through" : "",
          verticalAlign: "middle",
          width: "25rem",
        }}
        // onClick={() => toggleTaskEdit(todo, currentList)}
      >
        {todo.isEditable ? (
          <>
            <input
              name="Name"
              placeholder="Task Title"
              value={todoState.Name}
              onChange={handleChange}
              className="border-0 title form-control"
              autoFocus
            />
            <textarea
              name="Note"
              placeholder="Task Description"
              rows={5}
              value={todoState.Note}
              onChange={handleChange}
              className="border-0 subTitle form-control"
            ></textarea>
          </>
        ) : (
          <div className="d-flex flex-column">
            {/* <a className="title" href={`todo/${todo.TodoGUID}/${todo.GUID}`}> */}
            <span className="title">
              {todo.Name.length > 50
                ? todo.Name.slice(0, 50) + "..."
                : todo.Name}
            </span>
            {/* </a> */}

            <ShowMoreText
              className={"cursor-default"}
              anchorClass="d-inline text-primary anchor-class"
              lines={1}
              more="See"
              less="Less"
              expanded={false}
              width={0}
              children={<span className="subTitle">{todo.Note}</span>}
            />
          </div>
        )}
      </td>
      <td style={{ verticalAlign: "middle", fontSize: "14px", width: "10rem" }}>
        {todo.isEditable ? (
          <div className="d-flex align-items-center">
            <input
              name="DueDate"
              placeholder="Date"
              type="date"
              value={todoState.DueDate}
              onChange={handleChange}
              className="border-0 form-control p-0"
              onClick={handleInputDate}
              style={{
                width: `${!isDatePick ? "20px" : "auto"}`,
                fontSize: "14px",
              }}
            />
            {/* {!isDatePick && <span>Due Date</span>} */}
            {/* <input name="dueTime" placeholder="due date" type="time" /> */}
          </div>
        ) : (
          <span style={{ fontSize: "14px" }}>
            {todo?.DueDate && moment(todo.DueDate).format("MMM, DD YYYY")}
          </span>
        )}
      </td>

      <td style={{ verticalAlign: "middle", fontSize: "14px", width: "10rem" }}>
        {todo.isEditable ? (
          <div className="d-flex align-items-center">
            <input
              name="time"
              placeholder="Time"
              type="time"
              value={todoState.time}
              onChange={handleChange}
              className="border-0 form-control p-0"
              onClick={handleInputDate}
              style={{
                width: `${!isDatePick ? "20px" : "auto"}`,
                fontSize: "14px",
              }}
            />
            {/* {!isDatePick && <span>Due Date</span>} */}
            {/* <input name="dueTime" placeholder="due date" type="time" /> */}
          </div>
        ) : (
          <span style={{ fontSize: "14px" }}>
            {todo?.DueDate &&
            moment(todo.DueDate).format("hh:mm A") !== "12:00 AM"
              ? moment(todo.DueDate).format("hh:mm A")
              : "--:--"}
          </span>
        )}
      </td>
      <td
        className="text-center"
        style={{ verticalAlign: "middle", width: "10rem", fontSize: "14px" }}
      >
        <StaffDropDown
          staffSelected={staffSelected}
          handleSelectAssignee={handleSelectAssignee}
          isEditable={todo.isEditable}
        />
      </td>
      {/* <td style={{ verticalAlign: "middle", width: "10rem" }}>
        {todo.isEditable ? (
          <>
            <span onClick={handleOpenMap} style={{ cursor: "pointer" }}>
              <i className="fa fa-location mr-2"></i>
              Location
            </span>
          </>
        ) : (
          <span>
            <i className="fa fa-location mr-2"></i>Location
          </span>
        )}
      </td> */}
      <td
        className={`${getPriority(todo.Priority).toLowerCase()}`}
        style={{ width: "10rem", verticalAlign: "middle", textAlign: "center" }}
      >
        <PriorityDropDown
          isEditable={todo.isEditable}
          handleSelectPriority={handleSelectPriority}
          priorityItems={priorityItems}
          selectedPriority={selectedPriority}
          isMobile={false}
        />
      </td>
      <td
        className="text-center"
        style={{
          verticalAlign: "middle",
          witdh: todo.isEditable ? "2rem" : "1rem",
        }}
      >
        {todo.isEditable ? (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary mr-1 d-flex align-items-center"
              disabled={isLoading}
              onClick={() => {
                handleSaveTask(todoState, todo.isEditable, todo.isNew);
              }}
            >
              {isLoading && (
                <div
                  className="spinner-border mr-2 text-white"
                  style={{
                    color: "white !important",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => handleCancelTask(todo)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button className="teamItem__more btn-reset">
            <DropDownComponent data={DropDownData} />
          </button>
        )}
      </td>
    </tr>
  );
};
