import React from "react";
import { getEventGroups } from "../../utils/getEventGroups";
import { filterEvents } from "../../utils/filterEvents";
import useEventFilters from "../../hooks/useEventFilters";
import moment from "moment";

const EventFilter = ({
  events,
  eventGroups,
  eventFilterState,
  handleDateChange,
  handleFilterByGroup,
  handleSearch,
}) => {
  return (
    <div className="text-black-50 d-flex align-items-center justify-content-between">
      <label className="mx-2" htmlFor="dateFrom">
        Filters:
      </label>
      <input
        className="form-control py-2 px-2 bg-light rounded-lg"
        name="dateFrom"
        type="date"
        value={moment(eventFilterState.from).format("YYYY-MM-DD")}
        onChange={(e) => handleDateChange(e, "from")}
      />
      <label className="mx-2" htmlFor="dateTo">
        -
      </label>
      <input
        name="dateTo"
        type="date"
        className="form-control py-2 px-2 bg-light rounded-lg"
        value={moment(eventFilterState.to).format("YYYY-MM-DD")}
        onChange={(e) => handleDateChange(e, "to")}
      />

      {eventGroups?.length > 0 && (
        <select
          className="form-control mx-2 p-2 align-self-end bg-light rounded-lg"
          defaultValue={
            eventFilterState?.group?.Name === null
              ? "Select"
              : eventFilterState?.group?.Name
          }
          onChange={handleFilterByGroup}
        >
          <option>--Select Group--</option>
          {eventGroups.map((group) => (
            <option
              key={group.GUID}
              value={group.GUID}
              selected={eventFilterState?.group?.GUID === group.GUID}
            >
              {group.Name}
            </option>
          ))}
        </select>
      )}
      <input
        name="search"
        type="search"
        placeholder="search"
        className="form-control py-2 px-2 bg-light rounded-lg"
        value={eventFilterState.search}
        onChange={(e) => handleSearch(e)}
      />
    </div>
  );
};

export default EventFilter;
