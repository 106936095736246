import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConsoleNetworkOutline } from "mdi-material-ui";
import React, { useEffect, useState } from "react";
import { apiService } from "../../../common/api";
import NonAppUser from "../NonAppUser";
import NonAppUserItem from "./NonAppUserItem";
import { SimpleLoader } from "../../common";

const NonAppUserModal = (props) => {
  const [nonAppUsers, setNonAppUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getNonAppUsers = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get(
        `/NonAppUsers/List?OrganizationGUID=${props.currentOrganization.GUID}`
      );

      if (response.data.status === "ok") {
        const cleanUsers = response?.data?.response?.filter(
          (users) => !users.Deleted
        );
        setNonAppUsers(cleanUsers);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNonAppUsers();
  }, []);

  console.log("NonAppUsers", nonAppUsers);
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="event-scroll">
            <div className="text-head">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h1>Non-App Users</h1>
                <button
                  type="button"
                  className="btn btn-sm btn-link ml-2"
                  onClick={() => props.toggle()}
                >
                  <FontAwesomeIcon icon={faTimes} color="black" />
                </button>
              </div>
            </div>
          </div>
          <div className="event-inside p-0">
            <div className="event-content">
              {isLoading && (
                <div className="text-center">
                  <SimpleLoader loading={isLoading} />
                </div>
              )}
              <div
                style={{
                  maxHeight: "40vh",
                  overflowY: "auto",
                }}
              >
                {nonAppUsers.map((user) => (
                  <NonAppUserItem
                    key={user.AccountGUID}
                    user={user}
                    toggleStaff={props.toggleStaff}
                    deleteStaff={props.deleteStaff}
                    toggleAdminDelete={props.toggleAdminDelete}
                    toggleMakeAdmin={props.toggleMakeAdmin}
                    toggleShowMove={props.toggleShowMove}
                    setDataAllTeam={props.setDataAllTeam}
                    currentTeam={props.currentTeam}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonAppUserModal;
