import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../page/event/redux/actions";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import LabelForm from "../form/LabelForm";
import AddEditGround from "../grounds/AddEditGround";
import AssignMembersGround from "../grounds/AssignMembersGround";
import Ground from "./ground";
import { ConsoleLine } from "mdi-material-ui";

const GroundContainers = (props) => {
  const teams = useSelector((state) => state.organizations.teams);
  const event = useSelector((state) => state.event.event);
  // const events = useSelector((state) => state.calendar.calendarEvents);
  const loading = useSelector((state) => state.event.loading);
  const grounds = useSelector((state) => state.event.grounds);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    addGround: false,
    addMode: false,
    moveComponent: false,
    linkModal: false,
    passengers: false,
    editMode: false,
    eventsSelected: [],
    staffSelected: [],
    labelModal: false,
  });

  const { handleSidebarItem, addGround, sideBarItemData } = props;
  const { editMode, labelModal } = state;

  useEffect(() => {
    dispatch(actions.getGrounds(event.GUID));
  }, []);
  // const toggleEvent = (event) => {
  //   let events = state.eventsSelected;
  //   if (events.filter((s) => s.GUID === event.GUID).length > 0) {
  //     let filtered = events.filter((s) => s.GUID !== event.GUID);
  //     return setState((prev) => ({ ...prev, eventsSelected: filtered }));
  //   } else {
  //     let newOnes = events.concat(event);
  //     return setState((prev) => ({ ...prev, eventsSelected: newOnes }));
  //   }
  // };

  // const toggleMember = (staff) => {
  //   let staffs = state.staffSelected.map((staff) => ({
  //     StaffGUID: staff.StaffGUID,
  //     GUID: staff.GUID,
  //     Deleted: staff.Deleted,
  //   }));
  //   if (staffs.filter((s) => s.StaffGUID === staff.GUID).length > 0) {
  //     let filtered = staffs.map((s) => {
  //       if (s.StaffGUID === staff.GUID) {
  //         if (s.Deleted === null) {
  //           return { ...s, Deleted: new Date() };
  //         } else {
  //           return { ...s, Deleted: null };
  //         }
  //       }
  //       return s;
  //     });
  //     return setState((prev) => ({ ...prev, staffSelected: filtered }));
  //   } else {
  //     let newOnes = staffs.concat({
  //       StaffGUID: staff.GUID,
  //       GUID: uuid.v4(),
  //       Deleted: null,
  //     });
  //     return setState((prev) => ({ ...prev, staffSelected: newOnes }));
  //   }
  // };

  // const emptySelecteds = () => {
  //   setState((prev) => ({ ...prev, eventsSelected: [] }));
  // };

  const toggleLabelForm = () => {
    setState((prev) => ({ ...prev, labelModal: !state.labelModal }));
  };

  const togglePassengers = () => {
    setState((prev) => ({ ...prev, passengers: !state.passengers }));
  };

  const toggleGroundModal = (editMode) => {
    const mode = editMode ?? false;
    setState((prev) => {
      return { ...prev, addGround: !prev.addGround, editMode: mode };
    });
  };

  // const toggleModal = () => {
  //   setState((prev) => ({ ...prev, modal: !state.modal }));
  // };

  const toggleMoveModal = () => {
    setState((prev) => ({ ...prev, moveComponent: !state.moveComponent }));
  };

  const toggleLinkModal = () => {
    setState((prev) => ({ ...prev, linkModal: !state.linkModal }));
  };

  // const addPassengers = (staffs) => {
  //   setState((prev) => ({ ...prev, staffSelected: staffs }));
  // };

  const editModal = (ground) => {
    props.handleSidebarItem("GROUND", ground);
    setState((prev) => ({ ...prev, editMode: true }));
    toggleGroundModal();
  };

  const closeEdit = () => {
    setState((prev) => ({ ...prev, editMode: false }));
    toggleGroundModal();
  };

  const editGround = (ground, fromLabel) => {
    dispatch(
      actions.editGround(ground, () => {
        dispatch(actions.getGrounds(event.GUID));
        props.handleSidebarItem("GROUND", ground);
        ToastNotificationSuccess(1, "Updated Ground");
        if (!fromLabel) {
          closeEdit();
        } else if (fromLabel) {
          toggleLabelForm();
        }
      })
    );
  };

  return (
    <>
      <ModalComponent
        toggle={togglePassengers}
        modal={state.passengers}
        childeren={
          <AssignMembersGround
            toggle={togglePassengers}
            // parentState={state}
            parentToggle={toggleGroundModal}
            donable
            usersSelected={state.staffSelected}
            ground={sideBarItemData}
          />
        }
      />
      <ModalComponent
        modal={labelModal}
        toggle={() => toggleLabelForm()}
        childeren={
          <LabelForm
            loading={loading}
            submitFunction={editGround}
            resource={sideBarItemData}
            toggle={() => toggleLabelForm()}
          />
        }
      />
      <ModalComponent
        toggle={state.editMode ? closeEdit : toggleGroundModal}
        modal={state.addGround}
        childeren={
          <AddEditGround
            event={event}
            teams={teams}
            toggle={state.editMode ? closeEdit : toggleGroundModal}
            addGround={addGround}
            ground={sideBarItemData}
            editGround={editGround}
            editMode={state.editMode}
            loading={loading}
            getGrounds={actions.getGrounds}
            handleSidebarItem={props.handleSidebarItem}
          />
        }
      />

      <Ground
        // deleteGround={deleteGround}
        blockName={props.blockName}
        grounds={grounds}
        handleSidebarItem={handleSidebarItem}
        toggleGroundModal={state.editMode ? closeEdit : toggleGroundModal}
        toggleMoveModal={toggleMoveModal}
        toggleLink={toggleLinkModal}
        togglePassengers={togglePassengers}
        editModal={editModal}
        // actions={this.props.actions}
        originEvent={event}
        sideBarItemData={sideBarItemData}
        toggleLabelForm={toggleLabelForm}
      />
    </>
  );
};

export default GroundContainers;
