import { LOCAL_STORAGE } from "../constant";

import { deleteDatabase } from "../common/db";
import { randomImage } from "../components/common/randomImage";
import { parseJwt } from "./parsedJWT";
export * from "./imagePickerFunction";

export function validateEmail(mail) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

/**
 * @description this function is to validate if it's base 64
 */
export function isBase64(str) {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

/**
 * @description this function is uded to find params from query
 */
export function getParam(type) {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var type_val = url.searchParams.get(type);
  return type_val;
}

/**
 * @description this function is used to format phone numberÎ
 * @param {int} value
 */
export function formatPhoneNumber(value) {
  var numb = value.replace(/\D+/g, "");
  return numb;
}

/**
 * @param {string} token
 * @description storing Access Token for api localstorage
 */
export function setAccessToken(token) {
  localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
}

/**
 * @return {string}
 * @description returning api Access Token from localstorage
 */
export function getAccessToken() {
  return localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
}

/**
 * @param {object} user
 * @description storing basic information of the user
 */
export function setHaveOrganization() {
  localStorage.setItem(LOCAL_STORAGE.HAS_ORGANIZATION, JSON.stringify("true"));
}

/**
 * @param {object} user
 * @description storing basic information of the user
 */
export function getUserHaveOrganization() {
  localStorage.getItem(LOCAL_STORAGE.CURRENT_ORGANIZATION);
}

/**
 * @param {object} user
 * @description storing basic information of the user
 */
export function setBasicInfo(user) {
  localStorage.setItem(LOCAL_STORAGE.USER_INFO, JSON.stringify(user));
}

/**
 * @description storing basic information of the user
 */
export function getBasicInfo() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_INFO));
}

/**
 * @description get the guid of the current event
 */

export function getCurrentEventGUID() {
  return localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID);
}

/**
 *  @param {object} x
 * @param {object} y
 * @description compare two objects and return is false or true
 */
export function isEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => isEqual(x[key], y[key]))
    : x === y;
}
//This is used for listing the conversations
export function convertMessagesToReadable(data, currentUser) {
  const messages = data.map((message) => {
    let img =
      message.senderAccount.Picture &&
      message.senderAccount.Picture.includes("http")
        ? message.senderAccount.Picture
        : message.senderAccount.Picture
        ? `https://app.plnify.com/photos/${message.senderAccount.Picture}?w=75&h=75&mode=crop&autorotate=true`
        : randomImage(message?.senderAccount?.name?.substr(0, 1));

    return {
      type: "text",
      text: message.message,
      author: {
        username:
          message.senderAccount.name + " " + message.senderAccount.lastName,
        id: message.senderAccountGUID,
        avatarUrl: img,
      },
      //timestamp: new Date(d.date).getTime(),
    };
  });

  return messages;
}

//This is used when you send a message/receive, like locally
export function converToChatMessage(data) {
  console.log("The data to convert to chat message", data);
  let initials =
    (data.senderAccount.Name &&
      data.senderAccount.Name.charAt(0).toUpperCase()) +
    (data.senderAccount.LastName && data.senderAccount.LastName.charAt(0));
  let img =
    data.senderAccount.Picture && data.senderAccount.Picture.includes("http")
      ? data.senderAccount.Picture
      : data.senderAccount.Picture
      ? `https://app.plnify.com/photos/${data.senderAccount.Picture}?w=75&h=75&mode=crop&autorotate=true`
      : randomImage(initials);
  const message = {
    type: "text",
    text: data.message,
    // timestamp: data.Date.getTime(),
    author: {
      username: data.senderAccount.Name,
      id: data.SenderAccountGuid,
      avatarUrl: img,
    },
  };

  return message;
}

/**
 * @param {string} url
 * @description parse the url and strip all the innecesary letters
 * @returns return the logo route cleaned
 */
export function parseLogo(url) {
  if (url.length > 41) {
    return url.split("/")[4];
  } else {
    return url;
  }
}

/**
 * @description logout user from app
 */
export function logoutUser() {
  localStorage.clear();
  deleteDatabase();
  window.location = "/";
}

/**
 * @param {object} obj
 * @description remove null and blanks from object
 * @returns return the cleaned object
 */
export function removeNull(obj) {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === "object" && removeNull(val)) ||
      ((val === null || val === "") && delete obj[key])
  );
  return obj;
}

/**
 * @param {array} array
 * @description clean the array for organization, active and deletes nulls
 */
export function cleanArray(array) {
  return array
    .filter((org) => org.Organization !== null)
    .filter((org) => org.Active === true)
    .filter((org) => org.Deleted === null);
}

export function cleanDelete(array) {
  return array?.filter((element) => element.Deleted === null);
}

export function rememberMe(email, password) {
  localStorage.setItem(LOCAL_STORAGE.USER_NAME, email);
  localStorage.setItem(LOCAL_STORAGE.PASSWORD, password);
}

export function getToken(string) {
  return string.split("=")[1];
}

export function getRememberMeValue() {
  var email = localStorage.getItem(LOCAL_STORAGE.USER_NAME);
  var password = localStorage.getItem(LOCAL_STORAGE.PASSWORD);
  return {
    email,
    password,
  };
}

export function sortByDate(array) {
  return array
    .sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime())
    .reverse();
}

export function isEquivalent(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
}

/**
 * @param {object} organization
 * @description storing current organization of the user
 */
export function setOrganization(organization) {
  localStorage.setItem(
    LOCAL_STORAGE.CURRENT_ORGANIZATION,
    JSON.stringify(organization)
  );
}

export function getOrganization() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE.CURRENT_ORGANIZATION));
}

export function getCurrentOrganizationId(authentication) {
  let orhanization_id = null;
  if (authentication.users) {
    let { users, loginUserId } = authentication;
    orhanization_id = users[loginUserId].selectedOrganizationId;
  }
  return orhanization_id;
}

export function setCurrentOrganizationIs(authentication) {
  let orhanization_id = null;
  if (authentication.users) {
    let { users, loginUserId } = authentication;
    if (users[loginUserId].organizationIds) {
      orhanization_id =
        users[loginUserId].organizationIds.length > 0
          ? users[loginUserId].organizationIds[0]
          : null;
    }
  }
  return orhanization_id;
}
export function getEvent(organizationGuid, authentication) {
  let events_list = null;
  if (authentication.users) {
    let { users, loginUserId } = authentication;
    let { organization } = users[loginUserId];
    let { events } = organization[organizationGuid];
    events_list = events;
  }
  return events_list;
}

export function getCurrentEvent(event, eventId) {
  let currentEvent = {};
  if (event) {
    currentEvent = event.eventByIds[eventId];
  }
  return currentEvent;
}

export function uppercaseFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCurrentEventDetails(authentication) {
  let currentEvent = null;
  if (authentication.users) {
    let { users, loginUserId } = authentication;
    let currentUser = users[loginUserId];
    let currentOrganization =
      currentUser.organization[currentUser.selectedOrganizationId];
    currentEvent =
      currentOrganization.events.eventByIds[
        currentOrganization.selectedEventId
      ];
  }
  return currentEvent;
}

export function checkIfStaffExists(staffGUID, arrayOfStaffs) {
  return arrayOfStaffs.filter((s) => s.AccountGUID === staffGUID).length > 0;
}

export function checkIfStaffExistsB(staffGUID, arrayOfStaffs) {
  return (
    arrayOfStaffs
      .filter((s) => s.StaffGUID === staffGUID)
      .filter((s) => s.Deleted === null).length > 0
  );
}

export function checkIfStaffExistsC(staffGUID, arrayOfStaffs) {
  return arrayOfStaffs.filter((s) => s === staffGUID).length > 0;
}

export function checkIfReservationExists(staffGUID, arrayOfStaffs) {
  return (
    arrayOfStaffs
      .filter((s) => s.AccountGUID === staffGUID)
      .filter((s) => s.Deleted === null).length > 0
  );
}

export function checkIfHotelExists(hotelGUID, arrayOfHotels) {
  return arrayOfHotels.includes(hotelGUID);
}

export function checkIfEventExists(eventGUID, arrayOfEvents) {
  return arrayOfEvents.filter((e) => e.GUID === eventGUID).length > 0;
}

export function checkIfEventExistsGroup(eventGUID, arrayOfEvents) {
  return (
    arrayOfEvents
      ?.filter((e) => e.Deleted === null)
      .filter((e) => e.GUID === eventGUID).length > 0
  );
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  let R = 6371;
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d.toFixed(2);
}

export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function resourceUrl(type) {
  let resourcesUrls = {
    ground: "/Grounds/CopyToOtherEvents",
    flight: "/Flights/CopyToOtherEvents",
    hotel: "/Hotels/CopyToOtherEvents",
    contact: "/ProductionVenueContact/AddEdit",
    attachment: "/Attachment/AddEditForEventWithoutImage",
    itineraries: "/Schedules/CopyToOtherEvents",
  };

  return resourcesUrls[type] || resourceUrl["ground"];
}

export function bytesToMegaBytes(bytes, digits, roundTo) {
  return roundTo
    ? (bytes / (1024 * 1024)).toFixed(digits)
    : bytes / (1024 * 1024);
}

export function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export function prepareFlights(objectOfFlights) {
  let exportableFlights = [];

  for (let key in objectOfFlights) {
    let value = objectOfFlights[key];
    if (key === "null") {
      value.map((g) => exportableFlights.push(g));
    } else {
      value
        .slice(0, 1)
        .map((g) => exportableFlights.push({ ...g, Stops: value.length }));
    }
  }

  return exportableFlights;
}

export function arraysEqual(arrayOne, arrayTwo) {
  return JSON.stringify(arrayOne) === JSON.stringify(arrayTwo);
}

// Use dynamic filters arrays comparing array with another array

export const dynamicFilter = (filters, data) => {
  return data.filter((val) => {
    let result = true;
    for (let i = 0; i < filters.length; i++) {
      if (val[filters[i][0]] !== filters[i][1]) {
        result = false;
      }
    }
    return result;
  });
};

/**
 * @param {array} requests
 * @description parse and return statistics  for tickets and passes
 * @returns return guestlist statistics
 */
export function guestListStats(requests) {
  const passes = requests.filter(
    (t) => t.PassType !== "Ticket" && t.Deleted === null
  );
  const tickets = requests.filter(
    (t) => t.PassType === "Ticket" && t.Deleted === null
  );

  const rejectedPasses = passes
    .filter((x) => x.Confirmed === false && x.ConfirmedPasses === 0)
    .map((p) => p.Passes - p.ConfirmedPasses)
    .reduce((a, b) => a + b, 0);
  const approvedPasses = passes
    .filter((x) => x.Confirmed === true)
    .map((p) => p.ConfirmedPasses)
    .reduce((a, b) => a + b, 0);

  const rejectedTickets = tickets
    .filter((x) => x.Confirmed === false && x.ConfirmedPasses === 0)
    .map((p) => p.Passes - p.ConfirmedPasses)
    .reduce((a, b) => a + b, 0);
  const approvedTickets = tickets
    .filter((x) => x.Confirmed === true)
    .map((p) => p.ConfirmedPasses)
    .reduce((a, b) => a + b, 0);

  const passesStats = {
    total: passes.map((p) => p.Passes).reduce((a, b) => a + b, 0),
    approved: approvedPasses > 0 ? approvedPasses : 0,
    rejected: rejectedPasses > 0 ? rejectedPasses : 0,
    pending: passes
      .filter((p) => p.ConfirmedPasses === null)
      .map((p) => p.Passes)
      .reduce((a, b) => a + b, 0),
  };

  const ticketsStats = {
    total: tickets.map((p) => p.Passes).reduce((a, b) => a + b, 0),
    approved: approvedTickets > 0 ? approvedTickets : 0,
    rejected: rejectedTickets > 0 ? rejectedTickets : 0,
    pending: tickets
      .filter((p) => p.ConfirmedPasses === null)
      .map((p) => p.Passes)
      .reduce((a, b) => a + b, 0),
  };

  return {
    passesStats,
    ticketsStats,
  };
}

///FILTER ONLY TEAMS AND ENABLED MEMBERS
export function TeamsAndMembersAvailableOnly(objs) {
  //Only teams that exist
  let newObj = objs.filter(
    (x) =>
      x.Deleted === null &&
      x.Staffs.length > 0 &&
      x.Staffs.filter((y) => y.Deleted === null)
  );
  return newObj;
}
