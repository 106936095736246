import {
  CalendarBlankOutline,
  FormatListBulletedSquare,
} from "mdi-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Calendar as CalendarComponent,
  momentLocalizer,
} from "react-big-calendar";
import { FormattedMessage, useIntl } from "react-intl";
import db from "../../../common/db";
import { EventList, ModalComponent } from "../../../components";
import CalendarEvent from "../../../components/calendar/CalendarEvent";
import CustomToolbar from "../../../components/calendar/CustomToolbar";
import { DropDownComponent, SimpleLoader } from "../../../components/common";
import { IMAGES, ROUTES } from "../../../constant";
import {
  getAccessToken,
  getBasicInfo,
  getOrganization,
  logoutUser,
} from "../../../utils";
import CalendarMapView from "./CalendarMapView";
import { useDispatch, useSelector } from "react-redux";
import CalendarEventDots from "../../../components/calendar/CalendarEventDots";
import { CustomNotification } from "../../../components/common/CustomNotification";
import { useCustomNotification } from "../../../hooks/useCustomNotification";
import { useHistory } from "react-router-dom";
import EventVisibleBy from "../../../components/event/EventVisibleBy";
import { useCustomContextMenu } from "../../../hooks/useCustomContextMenu";
import { ContextMenu } from "../../../components/common/ContextMenu";
import EmailModal from "../../../components/hotel/emailModal";
import { SandwichButton } from "../../../components/common/SandwichButton";
import { handleCloseEmail } from "../../../utils/closeMailModal";
import Popup from "reactjs-popup";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useWorkspaceInvite } from "../../../hooks/useWorkspaceInvite";
import { apiService } from "../../../common/api";
import Swal from "sweetalert2";
import { getEvents } from "../redux/getEvents";
import { isValidJWT } from "../../../utils/isValidJwt";

const Calendar = (props) => {
  let basicInfo = getBasicInfo();
  const basicInfoGUID = basicInfo.GUID;
  const [DataSubmit, setDataSubmit] = useState({});
  const [search, setSearch] = useState("");
  let {
    fetchEventList,
    toggleListView,
    showList,
    events,
    paginationInfo,
    defaultDate,
    setStateValue,
    fromDate,
    toData,
    toggleEditEvent,
    setEventInfoAction,
    toggleImportEvents,
    toggleEmailModal,
    toggleGroupModal,
    toggleAddEvent,
    toggleStartingDay,
    toggleFilterModal,
    loadingCalendar,
    deleteEvent,
    currentDate,
    fetchAndSetVisibleBy,
    toggleExportMail,
    editEvent,
    toggleMoveEvent,
    displayMode,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [records, setRecords] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [viewMode, setViewMode] = useState(displayMode || "calendar");
  const [inviteModel, setInviteModel] = useState({});
  const [screenSize, setScreenSize] = useState({
    screenSize: 0,
    desktopView: 1124,
  });

  const loadData = async () => {
    const appSettings = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();
    if (appSettings) {
      setDataSubmit(appSettings);
    } else {
      await db.table("appSettings").put({ AccountGUID: basicInfoGUID });
    }
  };

  const bindData = () => {
    let localEvents = events;
    if (search.length > 0) {
      let simplifiedSearch = search.toLowerCase().trim();
      localEvents = localEvents.filter(
        (x) =>
          x.Name.toLowerCase().includes(simplifiedSearch) ||
          (x.City && x.City.toLowerCase().includes(simplifiedSearch)) ||
          (x.EventType && x.EventType.toLowerCase().includes(simplifiedSearch))
      );
    }

    setRecords(localEvents);
  };

  useEffect(() => {
    const token = getAccessToken();
    const isValidToke = isValidJWT(token);
    if (!isValidToke) {
      logoutUser();
    }
  }, []);

  useEffect(() => {
    loadData();
    setScreenSize({
      screenSize: window.innerWidth,
      desktopView: 1124,
    });
    setInviteModel(JSON.parse(localStorage.getItem("inviteModel")));
  }, []);

  moment.locale("en", {
    week: {
      dow: DataSubmit.Preferred ? parseInt(DataSubmit.Preferred) : 0,
      doy: DataSubmit.Preferred ? parseInt(DataSubmit.Preferred) : 0,
    },
  });

  const localizer = momentLocalizer(moment);

  const groups = events.reduce((groups, activity) => {
    const date = activity.Date && activity.Date.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(activity);
    return groups;
  }, {});

  const eventsArray = Object.keys(groups).map((date) => {
    return {
      date,
      activities: groups[date],
    };
  });

  const exportAsICS = () => {
    let user = getBasicInfo();
    let currentOrg = getOrganization();
    window.open(
      "https://app.plnify.com/api/organizations/OrganizationCalendar?OrganizationGUID=" +
        currentOrg.GUID +
        "&AccountGUID=" +
        user.GUID,
      "_blank"
    );
  };

  function getStartAndEndOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Calculate the start of the month
    const startOfMonth = new Date(year, month - 1, 1);

    // Calculate the end of the month
    const nextMonth = month + 1 > 11 ? 0 : month + 1;
    const nextYear = nextMonth === 0 ? year + 1 : year;
    const endOfMonth = new Date(nextYear, nextMonth + 1, 0);

    return {
      startOfMonth: startOfMonth,
      endOfMonth: endOfMonth,
    };
  }

  const handleCalendarNavigation = (date) => {
    const { endOfMonth, startOfMonth } = getStartAndEndOfMonth(date);

    dispatch(
      getEvents(
        getOrganization().GUID,
        getBasicInfo().GUID,
        startOfMonth.toDateString(),
        endOfMonth.toDateString()
      )
    );

    setRecords(() => {
      return props.events.filter((event) => {
        const eventDate = new Date(event.Date);
        return eventDate >= startOfMonth && eventDate <= endOfMonth;
      });
    });
  };

  const handleResize = () => {
    setScreenSize({
      screenSize: window.innerWidth,
      desktopView: 1200,
    });
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const composedArray = eventsArray.map((event) => event.activities[0]);

  const handleListCalendarView = () => {
    const view = showList ? "calendar" : "list";
    toggleListView(view);
    view === "list" && setRecords(composedArray);
  };

  const history = useHistory();

  // const { toggle, handleSubmit, needPermission } = useCustomNotification(() => {
  //   localStorage.setItem("beta", "true");
  //   history.go("/calendar");
  // });

  const handleSubmitAceptInvite = async () => {
    const obj = JSON.parse(localStorage.getItem("inviteModel"));
    let query = {
      OrganizationGUID: obj?.Organization?.GUID,
      UserGUID: obj?.User?.GUID,
      Email: basicInfo.Email,
    };
    try {
      let request = await apiService.post(
        "User/ProcessInvitationToJoin",
        query
      );

      console.log({ request });
      localStorage.removeItem("inviteModel");
      Swal.fire("Success", "Join successfully", "success");
      history.push("/");
    } catch (err) {
      if (err.response.data === "user_exist") {
        Swal.fire({
          text: intl.formatMessage({
            id: "This email is already in use",
          }),
          imageUrl: IMAGES.LOGO_BLACK_P,
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "rgb(123 120 120)",
          confirmButtonText: intl.formatMessage({ id: "Sign In" }),
          cancelButtonText: intl.formatMessage({ id: "Try again" }),
        }).then(async (result) => {
          if (result.value) {
            history.push("/");
          }
        });
      } else {
        Swal.fire("Error", "There was an error, please try again", "error");
      }
    }
  };

  const { toggle, handleSubmit, show, declineInvite } = useWorkspaceInvite(
    handleSubmitAceptInvite
  );

  // console.log("saber",composedArray.length !== 0 && (composedArray))
  // <div className={theState.value.NotificationsUI.updates? "d-none":"dashboard-screen"}>

  const {
    ContextMenuOptions,
    contextMenuEvent,
    ContextMenuState,
    exportType,
    setExportType,
    toggleVisibilty,
    sendEmail,
    handleShow,
    handleExport,
  } = useCustomContextMenu();

  function handleViewMode(view) {
    if (viewMode === view) return;
    setViewMode(view);
    toggleListView(view);
  }

  const viewOptions = [
    {
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-calendar mr-2"></i>
            <FormattedMessage id="calendar" defaultMessage="Calendar" />
          </div>
          {viewMode === "calendar" && (
            <span className="badge badge-info px-2 py-1">Current</span>
          )}
        </div>
      ),
      border: true,
      trigger: () => {
        if (viewMode === "calendar") return;
        setViewMode("calendar");
        toggleListView("calendar");
      },
    },
    {
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-list mr-2"></i>
            <FormattedMessage id="list" defaultMessage="List View" />
          </div>
          {viewMode === "list" && (
            <span className="badge badge-info px-2 py-1">Current</span>
          )}
        </div>
      ),
      border: true,
      trigger: () => {
        if (viewMode === "list") return;
        setViewMode("list");
        toggleListView("list");
        setRecords(composedArray);
      },
    },
    {
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-map mr-2"></i>
            <FormattedMessage id="map" defaultMessage="Map View" />
          </div>
          {viewMode === "map" && (
            <span className="badge badge-info px-2 py-1">Current</span>
          )}
        </div>
      ),
      border: true,
      trigger: () => {
        setViewMode("map");
        toggleListView("map");
      },
    },
  ];

  const calendarOptions = [
    {
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-pen-to-square mr-1"></i>
            <FormattedMessage
              id="manageEventGroups"
              defaultMessage="Manage Event Groups"
            />
          </div>
        </div>
      ),
      border: true,
      trigger: () => toggleGroupModal(),
    },
    {
      border: true,
      trigger: () => toggleImportEvents(),
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-upload mr-1"></i>
            <FormattedMessage
              id="importEvents"
              defaultMessage="Import Events (.ics file)"
            />
          </div>
        </div>
      ),
    },
    {
      border: true,
      trigger: () => exportAsICS(),
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-download mr-1"></i>
            <FormattedMessage
              id="exportCalendar"
              defaultMessage="Export Calendar"
            />
          </div>
        </div>
      ),
    },
    {
      border: true,
      trigger: () => toggleEmailModal(),
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="fas fa-envelope mr-1"></i>
            <FormattedMessage
              id="exportEmail"
              defaultMessage="Export as Email"
            />
          </div>
        </div>
      ),
    },
  ];

  const contextMenuOptions =
    viewMode === "map" || viewMode === "list"
      ? [
          // {
          //   trigger: () => toggleFilterModal(),
          //   name: <FormattedMessage id="fileters" defaultMessage="Filters" />,
          // },
          // {
          //   trigger: () => toggleStartingDay(),
          //   name: (
          //     <FormattedMessage
          //       id="startWeekFrom"
          //       defaultMessage="Start Week From"
          //     />
          //   ),
          // },
          {
            trigger: () => toggleGroupModal(),
            name: (
              <FormattedMessage
                id="manageEventGroups"
                defaultMessage="Manage Event Groups"
              />
            ),
          },
          {
            trigger: () => toggleImportEvents(),
            name: (
              <FormattedMessage
                id="importEvents"
                defaultMessage="Import Events (.ics file)"
              />
            ),
          },
          // {
          //   trigger: () => exportAsICS(),
          //   name: (
          //     <FormattedMessage
          //       id="exportCalendar"
          //       defaultMessage="Export Calendar"
          //     />
          //   ),
          // },
          // {
          //   trigger: () => toggleEmailModal(),
          //   name: (
          //     <FormattedMessage
          //       id="exportEmail"
          //       defaultMessage="Export as Email"
          //     />
          //   ),
          // },
          {
            trigger: () => toggleMoveEvent(),
            name: (
              <FormattedMessage
                id="moveEvent"
                defaultMessage="Move Event to Another Workspace"
              />
            ),
          },
        ]
      : [
          {
            trigger: () => toggleStartingDay(),
            name: (
              <FormattedMessage
                id="startWeekFrom"
                defaultMessage="Start Week From"
              />
            ),
          },
          {
            trigger: () => toggleGroupModal(),
            name: (
              <FormattedMessage
                id="manageEventGroups"
                defaultMessage="Manage Event Groups"
              />
            ),
          },
          {
            trigger: () => toggleImportEvents(),
            name: (
              <FormattedMessage
                id="importEvents"
                defaultMessage="Import Events (.ics file)"
              />
            ),
          },
          // {
          //   trigger: () => exportAsICS(),
          //   name: (
          //     <FormattedMessage
          //       id="exportCalendar"
          //       defaultMessage="Export Calendar"
          //     />
          //   ),
          // },
          // {
          //   trigger: () => toggleEmailModal(),
          //   name: (
          //     <FormattedMessage
          //       id="exportEmail"
          //       defaultMessage="Export as Email"
          //     />
          //   ),
          // },
          {
            trigger: () => toggleMoveEvent(),
            name: (
              <FormattedMessage
                id="moveEvent"
                defaultMessage="Move Event to Another Workspace"
              />
            ),
          },
        ];

  useEffect(() => {
    handleViewMode(viewMode);
    if (viewMode === "list") setRecords(composedArray);
  }, [viewMode]);

  let searchValue = search?.toLowerCase()?.trim();
  let filteEvents = events;
  if (searchValue) {
    filteEvents = records
      ? records?.filter(
          (x) =>
            x?.Name?.toLowerCase()?.includes(searchValue) ||
            x?.City?.toLowerCase()?.includes(searchValue) ||
            x?.EventType?.toLowerCase()?.includes(searchValue)
        )
      : events;
  }

  return (
    <div className="dashboard-screen">
      <div className="calendar-page">
        <div className="page-header d-flex justify-content-between flex-wrap align-items-center">
          <ContextMenu
            menuId={contextMenuEvent.GUID}
            ContextMenuOptions={ContextMenuOptions}
          />

          <ModalComponent
            toggle={toggleVisibilty}
            modal={ContextMenuState.showVisibleBy}
            childeren={
              <EventVisibleBy
                event={contextMenuEvent}
                toggle={toggleVisibilty}
              />
            }
          />

          <ModalComponent
            modal={ContextMenuState.showMailModal}
            toggle={() => handleCloseEmail(handleExport)}
            childeren={
              <EmailModal
                EventDetails={true}
                sendEmail={sendEmail}
                fromSection="EVENT_DETAILS"
                exportType={exportType}
                setExportType={setExportType}
                event={contextMenuEvent}
                toggle={() => handleExport()}
              />
            }
          />
          {/* 
          <CustomNotification
            toggle={toggle}
            bgClass="bg-info"
            handleSubmit={handleSubmit}
            needPermission={needPermission}
            title="Enable Beta Features"
            message=" Enable experimental (beta) features to be the first to try out our new functionalities."
            btn="Enable"
            icon="fas fa-star"
          /> */}

          <CustomNotification
            toggle={toggle}
            bgClass="bg-info"
            handleSubmit={handleSubmit}
            needPermission={show}
            title={`Join ${inviteModel?.Organization?.Name}`}
            message={`${inviteModel?.User?.Name} ${inviteModel?.User?.LastName} invite you to join ${inviteModel?.Organization?.Name}'s workspace`}
            btn="Accept"
            icon="fas fa-building"
            btnCancel={"Decline"}
            handleCancel={declineInvite}
          />

          <div className="d-flex align-items-center">
            <SandwichButton
              expanded={props.expanded}
              showExpanded={props.showExpanded}
            />

            <Popup
              modal={false}
              closeOnDocumentClick={true}
              closeOnEscape={true}
              position={"bottom left"}
              contentStyle={{ width: "215px" }}
              on="hover"
              trigger={(isOpen) => {
                return (
                  <div className="calendar-text clickable">
                    <div className="d-flex align-items-center position-relative">
                      <h1 className="mr-2">
                        <FormattedMessage
                          id="calendar"
                          defaultMessage="Calendar"
                        />
                      </h1>
                      <div className={"isActiveMenu"}>
                        <FontAwesomeIcon
                          icon={isOpen ? faAngleDown : faAngleUp}
                          color="black"
                          width={"50px"}
                          height={"50px"}
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                      <div className="d-flex align-items-center position-absolute">
                        <SimpleLoader loading={loadingCalendar} />
                      </div>
                    </div>
                  </div>
                );
              }}
            >
              {(open) => {
                return (
                  <div className="text-left">
                    {viewOptions.map((o, index2) => (
                      <div
                        key={index2}
                        className={`${o.border ? "border-bottom " : ""}  ${
                          o.delete ? "delete " : ""
                        } dropDownList`}
                        onClick={() => {
                          o.trigger();
                          open();
                        }}
                      >
                        {o.name}
                      </div>
                    ))}
                  </div>
                );
              }}
            </Popup>
          </div>
          <div className="text-center page-header-logo">
            <img
              src={IMAGES.LOGO_BLACK}
              alt="Plnify"
              style={{ width: "75px" }}
            />
          </div>
          <div className="h-100 d-flex icon-right px-0 align-items-center">
            {(viewMode === "map" || viewMode === "list") && (
              <>
                <div className="text-right formAlternativeStyle">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      bindData();
                    }}
                    style={{ height: "31px" }}
                  />
                </div>
                <button
                  className="btn btn-sm mx-1 py-2 px-2 btn-primary"
                  title="Add Event"
                  type="button"
                  onClick={() => toggleFilterModal()}
                >
                  <i className="fas fa-filter mr-1"></i>
                  Filters
                </button>
              </>
            )}

            {viewMode === "calendar" && (
              <button
                className="btn btn-sm mx-1 py-2 px-2 btn-primary"
                onClick={() => {
                  handleViewMode("list");
                }}
              >
                <i className="fas fa-search mr-1"></i>
                search
              </button>
            )}

            <button
              className="btn btn-sm mx-1 py-2 px-2 btn-primary"
              title="Add Event"
              type="button"
              onClick={(e) => toggleAddEvent(e)}
            >
              <i className="fas fa-plus"></i>
              {screenSize.screenSize > screenSize.desktopView && (
                <span className="ml-1">Add Event</span>
              )}
            </button>
            <button
              className="btn btn-sm mx-1 py-2 px-2 btn-primary"
              title="Event History"
              type="button"
              onClick={() => {
                props.toggleHistory();
              }}
            >
              <i className="fas fa-history"></i>
            </button>
            <Popup
              modal={false}
              closeOnDocumentClick={true}
              closeOnEscape={true}
              position={"bottom right"}
              on="click"
              trigger={
                <button
                  className={`btn m-0 mx-1  ${
                    viewMode === "list" ? "orange" : "bg-secondary"
                  } text-white icon-squared`}
                  title={`Calendar View: ${capitalizeFirstLetter(viewMode)}`}
                  style={{ width: "35px", height: "30px" }}
                >
                  <i className={`fas fa-${viewMode}`}></i>
                </button>
              }
            >
              {(open) => {
                return (
                  <div className="text-left ">
                    {/* Work Here */}
                    {viewOptions.map((o, index2) => (
                      <div
                        key={index2}
                        className={`${o.border ? "border-bottom " : ""}  ${
                          o.delete ? "delete " : ""
                        } dropDownList`}
                        onClick={() => {
                          o.trigger();
                          open();
                        }}
                      >
                        {o.name}
                      </div>
                    ))}
                  </div>
                );
              }}
            </Popup>
            {/* <button
              className="btn btn-sm mx-1 py-2 px-2 bg-secondary text-white icon-squared"
              title={`View Mode: ${showMap ? "Calendar View" : "Map View"}`}
              type="button"
              onClick={() => setShowMap(!showMap)}
            >
              <i className={`fas ${showMap ? "fa-calendar" : "fa-map"}`}></i>
            </button> */}
            {/* {screenSize.screenSize >= screenSize.desktopView && (
              <div
                className="icon-squared px-2 py-1 orange"
                onClick={handleListCalendarView}
              >
                {!showList ? (
                  <FormatListBulletedSquare />
                ) : (
                  <CalendarBlankOutline />
                )}
              </div>
            )} */}

            <div className="icon-squared mx-1 px-2 py-1 purple">
              <DropDownComponent direction="right" data={contextMenuOptions} />
            </div>
          </div>
        </div>
        {viewMode === "list" ? (
          <EventList
            fromDate={fromDate}
            toData={toData}
            setStateValue={setStateValue}
            defaultDate={defaultDate}
            events={filteEvents}
            paginationInfo={paginationInfo}
            fetchEventList={fetchEventList}
            toggleEditEvent={toggleEditEvent}
            setEventInfoAction={setEventInfoAction}
            deleteEvent={deleteEvent}
            fetchAndSetVisibleBy={fetchAndSetVisibleBy}
            toggleExportMail={toggleExportMail}
            editEvent={editEvent}
            //   toggleAddEvent={toggleAddEvent}
          />
        ) : viewMode === "calendar" ? (
          <>
            <div className="px-4 calendar-view">
              <CalendarComponent
                selectable
                onNavigate={(date) => {
                  handleCalendarNavigation(date);
                  console.log("Calendar on navigate", { date });
                }}
                culture="en"
                components={{
                  event:
                    screenSize.screenSize < screenSize.desktopView
                      ? CalendarEventDots
                      : (props) => CalendarEvent({ ...props, handleShow }),
                  toolbar: CustomToolbar,
                }}
                views={["month"]}
                //date={moment(currentDate).toDate()}
                date={currentDate}
                defaultView="month"
                localizer={localizer}
                events={composedArray} //aqui se pasan los eventos
                titleAccessor="Name"
                startAccessor="Date"
                endAccessor="Date"
                onSelectSlot={(e) => toggleAddEvent(e)}
                style={{ flex: 1 }}
              />
            </div>

            {screenSize.screenSize < screenSize.desktopView && (
              <div className="px-2 mt-2 overflow-hidden events-view">
                <EventList
                  fromDate={fromDate}
                  toData={toData}
                  setStateValue={setStateValue}
                  defaultDate={defaultDate}
                  events={filteEvents}
                  paginationInfo={paginationInfo}
                  fetchEventList={fetchEventList}
                  toggleEditEvent={toggleEditEvent}
                  setEventInfoAction={setEventInfoAction}
                  deleteEvent={deleteEvent}
                  fetchAndSetVisibleBy={fetchAndSetVisibleBy}
                  toggleExportMail={toggleExportMail}
                  editEvent={editEvent}
                  //   toggleAddEvent={toggleAddEvent}
                />
              </div>
            )}
          </>
        ) : (
          <CalendarMapView
            toggleEditEvent={toggleEditEvent}
            events={filteEvents}
          ></CalendarMapView>
        )}
      </div>
    </div>
  );
};

export default Calendar;
