import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiService } from "../../common/api";
import { IMAGES, ROUTES } from "../../constant";
import { getBasicInfo, logoutUser } from "../../utils";
import { Loader, ModalComponent } from "../../components";
import { Loading } from "../../components/common/loading";
import AccountStatement from "./AccountStatement";

const BillingPage = () => {
  const currentDomain = window.location.origin;
  const Navigate = useHistory();
  const currentUser = getBasicInfo();
  const [workspaces, setWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    showReceipt: false,
  });

  const payments = [
    {
      date: "Jul 06, 2024",
      concept:
        "Plan: Negocio | Renewal Period: 4 | Amount: 519.88| HR| finance| workshop",
      amount: "USD 519.88",
    },
    {
      date: "Jul 06, 2024",
      concept: "Plan: Negocio | Renewal Period: 1 | Amount: 99.98| HR| finance",
      amount: "USD 99.98",
    },
    {
      date: "Jul 06, 2024",
      concept:
        "Plan: Negocio | Renewal Period: 3 | Amount: 299.94| HR| finance",
      amount: "USD 299.94",
    },
    {
      date: "Jul 06, 2024",
      concept:
        "Plan: {selectedSubscription.name} | Renewal Period: 1 | Amount: 49.99| HR",
      amount: "USD 49.99",
    },
    { date: "Jul 06, 2024", concept: "Negocio", amount: "USD 49.99" },
  ];

  const handleChangePlan = () => {
    Navigate.push(`${ROUTES.PLAN_SELECTION}?id=${currentUser.GUID}`);
  };

  const fetchWorkspaces = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get(
        `/Subscriptions/workspaces?Id=${currentUser.GUID}`
      );
      setWorkspaces(response.data.Response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleReceipt = () => {
    setState({ ...state, showReceipt: !state.showReceipt });
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  return (
    <>
      <ModalComponent
        toggle={toggleReceipt}
        modal={state.showReceipt}
        className="modal-lg"
        childeren={
          <div
            style={{ width: "100dvw", color: "black" }}
            className={
              "w-100 h-100 d-flex justify-content-center align-items-center bg-white text-center text-black"
            }
          >
            <AccountStatement />
          </div>
        }
      />
      <div
        className="d-flex justify-content-between align-items-center mx-auto flex-column"
        style={{
          maxWidth: "1440px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center p-4 w-100  border-bottom position-relative">
          <button
            className="btn btn-primary position-absolute"
            style={{ left: "10px" }}
            onClick={() => Navigate.goBack()}
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>

          <a href={currentDomain}>
            <img src={IMAGES.LOGO_COLOR} alt="plnify logo" />
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-3 border-bottom mx-auto w-100">
          <div>
            <h5 className="mb-1 d-flex align-items-center">
              {`${currentUser.Name} ${currentUser.LastName} `}{" "}
              <button
                className="btn btn-primary ml-2"
                onClick={() => {
                  logoutUser();
                }}
              >
                Logout
              </button>
            </h5>
            <a
              href="mailto:info@valegos.com"
              className="text-muted small mt-0 p-0"
            >
              {currentUser.Email}
            </a>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center p-4">
          <p className="align-self-end mx-2 p-1   ">
            Next Billing Date:
            <span className="btn-primary px-2 text-white rounded-lg py-1">
              Feb 13, 2025
            </span>
          </p>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <p className="mb-1 fa-2x">
              Subscription: <strong className="fa-1x">Business</strong>
            </p>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <button
                className="btn btn-primary py-2 px-2"
                onClick={handleChangePlan}
              >
                Change Plan
              </button>
              <button className="btn btn-dark py-2 px-2">
                Payment History
              </button>
            </div>
          </div>
        </div>  */}
        </div>

        {isLoading && (
          <div className="spinner-main p-4">
            <div className="spinner-border text-light" role="status"></div>
          </div>
        )}
        <span className="text-muted mt-4 mb-2 fa-2x ">Workspaces</span>
        <button className="btn btn-primary mb-3" onClick={handleChangePlan}>
          <i className="fa fa-plus mr-2"></i>
          Add Workspace
        </button>
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Workspace</th>
              <th>Subscription</th>
              <th>Next Billing Date</th>
              <th>Admins</th>
            </tr>
          </thead>

          <tbody>
            {workspaces.map((workspace, index) => (
              <tr key={index}>
                <td>{workspace?.Name}</td>
                <td>
                  <span className="">Standard</span>
                  <button
                    type="button"
                    title="Change Plan"
                    className="btn btn-primary p-2 mx-2"
                    onClick={() => {
                      handleChangePlan();
                    }}
                  >
                    Change Plan
                  </button>
                  <span className="">Payment History</span>
                </td>
                <td>Feb 13, 2025</td>
                <td className="d-flex ">
                  <span className="badge badge-primary mx-1 p-2">1/5 used</span>
                  {workspace?.Admins?.map((admin, index) => (
                    <span
                      key={index}
                      className="btn btn-outline-primary mx-1 p-2"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {`${admin.FirstName} ${admin.LastName}`}
                      <i className="fa fa-times ml-1 text-danger"></i>
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <span className="text-muted mt-4 mb-2 fa-2x ">Payment History</span>
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>DATE</th>
              <th>CONCEPT</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td>{payment.date}</td>
                <td>
                  <button
                    type="button"
                    title="View Receipt"
                    className="btn btn-primary p-2 mx-2"
                    onClick={() => {
                      toggleReceipt();
                    }}
                  >
                    View Receipt
                  </button>
                  <span>{payment.concept}</span>
                </td>
                <td>{payment.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BillingPage;
