import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IframeGoogleDoc } from "../..";
import api, { apiService } from "../../../common/api";
import { IMAGES } from "../../../constant";
import { Loading } from "../loading";
import { ExportItem } from "./ExportItem";
import { Domain } from "mdi-material-ui";
import { getBasicInfo } from "../../../utils";
import ExportEmailSkeletton from "./ExportEmailSkeletton";

const ExportSheetComponent = ({
  ExportItems,
  hideEvents,
  isMobile,
  toggleSideEvents,
  setSectionsParams,
  sectionsParams,
  selectedEvent,
  setSelectedEvent,
  sectionsData,
  fileUrl,
  handlePDFAttachment,
  isLoading,
  setIsLoading,
  toggleExportModal,
  handleSendHtmlAsEmailBody,
  fileHTML,
  calledBy,
  getTemplateData,
  legacyTemplate,
  setExportType,
}) => {
  const url = "JavaScript+Concepts.pdf";
  const emailExportState = useSelector((state) => state.emailExport);

  if (calledBy === "ITINERARIES") {
    ExportItems = [
      {
        icon: "fa-file-invoice",
        title: "Header",
      },
      {
        icon: "fa-clipboard-list",
        title: "Itineraries",
      },
      {
        icon: () => <Domain />,
        title: "Hotels",
      },
      {
        icon: "fa-calendar-alt",
        title: "UpcomingEvents",
      },
    ];
  }

  const renderHTML = (html) => {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 border bg-secondary">
        <div
          className="overflow-auto shadow-lg bg-white p-4"
          style={{
            height: "calc(100vh - 250px)",
            width: "798px",
            minWidth: "798px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    );
  };

  const handleFileDownload = async () => {
    setIsLoading(true);
    if (!fileHTML)
      return Swal.fire({
        title: "Error Downloading",
        text: "Please select an item before trying to download the PDF file",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(() => setIsLoading(false));

    try {
      const response = await apiService.post("/GeneratePDF/FromHTML", {
        html: fileHTML,
      });
      const { Response } = response.data;
      const fileUrl = `https://service.plnify.com/pdf/${Response.name}`;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred during file download:", error);
      setIsLoading(false);
    }
  };

  const handleAttachPDFFile = async () => {
    setIsLoading(true);
    if (!fileHTML)
      return Swal.fire({
        title: "Error Attaching",
        text: "Please select an item before trying to attach the PDF file",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(() => setIsLoading(false));

    try {
      const response = await apiService.post("/GeneratePDF/FromHTML", {
        html: fileHTML,
      });
      const { Response } = response.data;

      const newFile = {
        lastModified: null,
        lastModifiedDate: new Date(),
        name: Response?.name,
        size: Response?.size,
        type: "application/pdf",
        webkitRelativePath: "",
        isPDF: true,
      };

      handlePDFAttachment(newFile, toggleExportModal);
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred during file download:", error);
      setIsLoading(false);
    }
  };

  const handleSendBodyEmail = () => {
    handleSendHtmlAsEmailBody(fileHTML, "");
    setExportType((prev) => {
      return { ...prev, ExportType: "email" };
    });
  };

  const handleSaveAsTemplate = async () => {
    try {
      Swal.fire({
        title: "Save as a template",
        text: "Please enter the name of the template",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        inputValidator: (value) => {
          if (!value) {
            return "Please enter your template name.";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newTemplate = {
            Id: null,
            Name: result.value,
            Content: JSON.stringify(sectionsParams),
            Path: null,
          };
          const response = await apiService.post(
            `/Export/AddEditTemplate?userId=${getBasicInfo().GUID}`,
            newTemplate
          );
          getTemplateData();
        }
      });
    } catch (error) {
      console.error("Error occurred during file download:", error);
    }
  };

  return (
    <div className="bg-white rounded-lg w-100 relative overflow-hidden">
      {hideEvents && !isMobile && (
        <button
          title="Show Events"
          className="btn btn-primary border-0 position-absolute event-sidebar-btn"
          onClick={toggleSideEvents}
          style={{ zIndex: "50", left: "-20px", top: "72px" }}
        >
          <i className="fas fa-angle-right" style={{ marginLeft: "13px" }}></i>
        </button>
      )}
      <div className="p-2 d-flex justify-content-between ">
        <div>
          <h4 className="font-weight-bold" style={{ color: "black" }}>
            PDF Creation Panel
          </h4>
          <p>Select what information should be included on your PDF.</p>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-secondary mr-2"
            onClick={handleFileDownload}
            disabled={isLoading}
          >
            Download PDF
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSendBodyEmail}
            disabled={isLoading}
          >
            Send via E-mail
          </button>
        </div>
      </div>
      <div className="d-flex">
        <div
          className="w-75 overflow-auto border"
          style={{ height: "calc(100vh - 205px)", minWidth: "798px" }}
        >
          <div className="d-flex flex-column justify-content-between border-top h-100 w-100">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center h-100 border bg-secondary w-100">
                <div
                  className="bg-white p-4 d-flex flex-column justify-content-center align-items-center"
                  style={{
                    height: "calc(100vh - 250px)",
                    width: "798px",
                    minWidth: "798px",
                  }}
                >
                  <ExportEmailSkeletton />
                </div>
              </div>
            ) : fileHTML ? (
              renderHTML(fileHTML)
            ) : fileUrl ? (
              <IframeGoogleDoc className={"h-100"} url={fileUrl} />
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100 border bg-secondary w-100">
                <div
                  className="bg-white p-4 d-flex flex-column justify-content-center align-items-center"
                  style={{
                    height: "calc(100vh - 250px)",
                    width: "798px",
                    minWidth: "798px",
                  }}
                >
                  <i className="fas fa-file-pdf fa-5x"></i>
                  Choose an item from the list to view PDF
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="w-25 d-flex flex-column justify-content-between border-left"
          style={{
            height: "calc(100vh - 210px)",
            minWidth: "350px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <div
            className="w-100"
            style={{
              height: "calc(100vh - 250px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {!legacyTemplate &&
              ExportItems.map((item, index) => (
                <ExportItem
                  key={index + item.title}
                  icon={item.icon}
                  title={item.title}
                  setSectionsParams={setSectionsParams}
                  selectedEvent={selectedEvent}
                  sectionsParams={sectionsParams}
                  setSelectedEvent={setSelectedEvent}
                />
              ))}
          </div>

          {!legacyTemplate && (
            <div
              className="w-100 border p-2 d-flex justify-content-end align-items-center bg-white"
              style={{ bottom: 0 }}
            >
              <button
                className="btn btn-primary"
                onClick={handleSaveAsTemplate}
              >
                Save as a template
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExportSheetComponent;
