import { ToastNotificationSuccess } from "../components";
import * as Actions from "../page/calendar/redux/editGroup";
import { getGroups } from "../page/calendar/redux/getGroups";

export function resizeGroupImage(image, groupObject, dispatch, handleShowEditGroup) {
  const file = image;
  const maxImageWidth = 1000; // Set your desired maximum width

  if (file) {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxImageWidth) {
        newWidth = maxImageWidth;
        newHeight = (maxImageWidth / img.width) * img.height;
      }

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      canvas.toBlob(
        (blob) => {
          dispatch(
            Actions.editGroup(
              {
                Name: groupObject.groupNameEdit,
                GUID: groupObject.groupGUID,
                Color: groupObject.groupColorEdit,
                OrganizationGUID: groupObject.groupOrg,
              },
              groupObject?.editEventsSelected,
              blob,
              handleShowEditGroup,
              () => {
                ToastNotificationSuccess(
                  Math.random(),
                  "Group updated successfuly."
                );
                dispatch(getGroups(groupObject.groupOrg));
              }
            )
          );
        },
        "image/png",
        0.5
      ); // You can adjust the format and quality here
    };
  }
}
