import * as constant from "./constants";
import api, { apiService } from "../../../common/api";

export function setVenue(venue, event, callback) {
  const urlEvent = "/Events/Edit";
  return async (dispatch) => {
    try {
      event.Venue = venue;
      event.VenueGUID = venue.GUID;
      await apiService.post(urlEvent, {
        Name: event.Name,
        EventType: event.EventType,
        Date: event.Date,
        VenueGUID: event.VenueGUID,
        Notes: event.Notes,
        City: event.City,
        Country: event.Country,
        State: event.State,
        Status: event.Status,
        Latitude: event.Latitude,
        Longitude: event.Longitude,
        GUID: event.GUID,
        OrganizationGUID: event.OrganizationGUID,
        LastSync: event.LastSync,
        DateTime: event.DateTime,
        CreationDate: event.CreateDate,
        Deleted: null,
        Image: event.Image,
        AllowUsersToRequestTickets: event.AllowUsersToRequestTickets,
        WillCallCity: event.WillCallCity,
        WillCallHours: event.WillCallHours,
        WillCallState: event.WillCallState,
        WillCallAddress: event.WillCallAddress,
        WillCallCountry: event.WillCallCountry,
        WillZipCode: event.WillZipCode,
        WillCallPhone: event.WillCallPhone,
        WillCallComment: event.WillCallComment,
        VenueGUID: venue.GUID,
      });
      dispatch({
        type: constant.SET_VENUE,
        data: venue,
      });
      callback();
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.SET_VENUE: {
      return {
        ...state,
        eventVenue: action.data,
        event: Object.assign({}, state.event, {
          Venue: action.data,
          VenueGUID: action.data.GUID,
        }),
      };
    }
    default:
      return state;
  }
}
