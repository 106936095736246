import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { broadcastMessageSchema } from "../../utils/validationsSchemas";
import { ModalComponent } from "./modal";
import UsersSelector from "./UsersSelector";

export const BroadcastMessageModal = ({ show, toggle, handleSubmit }) => {
  const toolbarOptions = [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline"], // toggled buttons
    [{ list: "ordered" }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"],
  ];

  const [state, setState] = useState({
    showReceivers: false,
    listOfReceivers: [],
    to: "",
    isLoading: false,
    message: "",
    count: 0,
  });

  const initialValues = {
    channels: "push|",
    to: state.to || "",
    title: "",
    message: state.message || "",
  };

  const toggleReceivers = () => {
    setState((prev) => {
      return {
        ...prev,
        showReceivers: !prev.showReceivers,
      };
    });
  };

  const handleReceiversSelect = (receivers) => {
    setState((prev) => {
      let to = "";
      let count = 0;
      receivers.forEach((receiver) => {
        if (!to.includes(receiver.Account.Email)) {
          to += `${receiver.Account.Email}; `;
          count++;
        }
      });

      return {
        ...prev,
        receivers: receivers,
        to,
        listOfReceivers: receivers.map((receiver) => {
          return {
            Name: `${receiver.Account.Name} ${receiver.Account.LastName}`,
            GUID: receiver.Account.GUID,
            RoleGUID: receiver.RoleGUID,
            email: receiver.Account.Email,
          };
        }),
        count,
        showReceivers: !prev.showReceivers,
      };
    });
  };

  const handleBroadcastMessage = (values) => {
    setState({
      showReceivers: false,
      listOfReceivers: [],
      to: "",
      message: "",
      isLoading: true,
    });
    handleSubmit(values, state.listOfReceivers, setState);
  };

  const handleCancel = () => {
    setState({
      showReceivers: false,
      listOfReceivers: [],
      to: "",
    });
    toggle();
  };

  const handleChannelPipe = (channels, value, setFieldValue) => {
    if (channels.includes(value)) {
      setFieldValue("channels", channels.replace(`${value}|`, ""));
    } else {
      let newValue = channels.length > 1 ? `${channels}${value}|` : `${value}|`;
      setFieldValue("channels", newValue);
    }
  };

  return (
    <>
      <ModalComponent
        toggle={toggleReceivers}
        modal={state.showReceivers}
        childeren={
          <UsersSelector
            menuTitleId="selectUsers"
            menuTitle={"Select Users"}
            receivers={state.listOfReceivers}
            toggle={toggleReceivers}
            handleReceiversSelect={handleReceiversSelect}
          />
        }
      />
      <Modal show={show} onHide={toggle} backdrop="static">
        <div className="bg-white">
          <div className="d-flex text-head justify-content-between align-items-center text-dark font-weight-bold">
            <span>Broadcast Message</span>
            <button className="btn " onClick={handleCancel}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="px-4 border-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={broadcastMessageSchema}
              onSubmit={(values) => handleBroadcastMessage(values)}
            >
              {(props) => (
                <Form>
                  <div className="attachment__section mt-4">
                    <div className=" attachment__name">
                      <h6>
                        <FormattedMessage id="to" defaultMessage="To" />
                      </h6>
                    </div>
                    <div className="attachment__input">
                      <div className="d-flex">
                        <Field
                          onBlur={() => props.setFieldValue("to", state.to)}
                          onChange={() => props.setFieldValue("to", state.to)}
                          id="to"
                          // autoFocus={true}
                          style={{
                            outline: "none",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "5px 0 0 5px",
                          }}
                          className="border w-100 p-2"
                          type="text"
                          name="to"
                          placeHolder={`Click on the "Select" button to add recipients`}
                          value={
                            state.to.split(";").length > 2
                              ? `${state.to.split(";")[0]}; ${
                                  state.to.split(";")[1]
                                }`
                              : state.to
                          }
                          autoComplete="off"
                        />
                        {state.count > 2 && (
                          <div
                            className="p-2 bg-secondary"
                            style={{
                              outline: "none",
                              border: "none",
                              borderRadius: "0",
                            }}
                          >
                            {"+" + (state.count - 2)}
                          </div>
                        )}
                        <button
                          style={{
                            outline: "none",
                            border: "none",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReceivers();
                          }}
                        >
                          {state.to ? "Show" : "Select"}
                        </button>
                      </div>
                      <ErrorMessage
                        className="error-message"
                        component="div"
                        name="to"
                      />
                    </div>

                    {/* <div className=" attachment__name">
                      <h6>
                        <FormattedMessage
                          id="title"
                          defaultMessage="Message Title"
                        />
                      </h6>
                    </div>
                    <div className="attachment__input">
                      <Field
                        // onBlur={() => props.handleSubmit()}
                        id="title"
                        className="form-control"
                        type="text"
                        name="title"
                        // disabled={disabledInputs}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message text-right"
                        component="div"
                        name="title"
                      />
                    </div> */}

                    <div className="attachment__name">
                      <h6>
                        <FormattedMessage
                          id="message"
                          defaultMessage="Message"
                        />
                      </h6>
                    </div>
                    <div className="h-100 text-dark">
                      <textarea
                        style={{
                          outline: "none",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          padding: "8px",
                        }}
                        className={
                          props.values.message.length > 140
                            ? `w-100 border-danger text-danger`
                            : `w-100 text-dark`
                        }
                        rows="3"
                        maxLength={141}
                        value={props.values.message}
                        onChange={(e) => {
                          props.setFieldValue("message", e.target.value);
                          setState((prev) => {
                            return {
                              ...prev,
                              message: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center w-100 justify-content-between"
                      style={{ fontSize: "14px" }}
                    >
                      <ErrorMessage
                        className="error-message p-0 m-0 mr-2 align-items-center w-50"
                        component="div"
                        name="message"
                      />
                      <span
                        className={
                          props.values.message.length > 140
                            ? `text-danger w-100 text-right`
                            : `text-dark w-100 text-right`
                        }
                      >
                        {`${
                          props.values.message.length > 140
                            ? "Character limit: " + props.values.message.length
                            : props.values.message.length
                        }  /140`}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="text-dark font-weight-light">
                        Send via:
                      </span>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          name="push"
                          value="push"
                          checked={props.values.channels.includes("push")}
                          onChange={() => {
                            handleChannelPipe(
                              props.values.channels,
                              "push",
                              props.setFieldValue
                            );
                          }}
                        />
                        <label className="ml-1 mr-3 text-dark" htmlFor="push">
                          Push
                        </label>
                        <input
                          type="checkbox"
                          name="email"
                          value="email"
                          checked={props.values.channels.includes("email")}
                          onChange={() => {
                            handleChannelPipe(
                              props.values.channels,
                              "email",
                              props.setFieldValue
                            );
                          }}
                        />
                        <label className="ml-1 mr-3 text-dark" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="checkbox"
                          name="sms"
                          value="sms"
                          disabled
                          checked={props.values.channels.includes("sms")}
                          onChange={() => {
                            handleChannelPipe(
                              props.values.channels,
                              "sms",
                              props.setFieldValue
                            );
                          }}
                        />
                        <label className="ml-1 text-dark" htmlFor="sms">
                          SMS
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      className="error-message p-0 m-0 mr-2 align-items-center w-100"
                      component="div"
                      name="channels"
                    />
                  </div>

                  <div className="d-flex justify-content-end pb-3">
                    <button
                      className="btn btn-primary ml-2 border-0 d-flex align-items-center justify-content-center px-4 py-2"
                      type="submit"
                    >
                      {state.isLoading && (
                        <span
                          className="spinner-border mr-2"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        ></span>
                      )}
                      Send
                    </button>
                    <button
                      className="btn btn-danger ml-2 border-0 px-4 py-2"
                      onClick={handleCancel}
                      type="reset"
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};
