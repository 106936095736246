import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";
import React, { useEffect, useRef, useState } from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { IMAGES } from "../../../constant";

import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import moment from "moment";
import { useParams } from "react-router-dom";

DocumentEditorContainerComponent.Inject(Toolbar);

export const Settings = {
  serviceApiUrl: "https://service.plnify.com/api/",
  appApiUrl: "https://app.plnify.com/",
};

export const DocsComponent = (props) => {
  let documentEditorRef = "";
  // const { id } = useParams();

  const [fileSeleted, setFileSelected] = useState(null);
  const [fileName, setfileName] = useState(null);

  const requestFileRemote = async () => {};
  useEffect(() => {
    //Now that we have the file, let's revise what kind of type it is
    if (fileSeleted !== null) {
    }
  }, [fileSeleted]);

  // useEffect(() => {
  //   //This is to be used to request for the remote file
  //   if (id) {
  //     requestFileRemote();
  //   }
  // }, [id]);

  useEffect(() => {
    //Now let's try to get the file and open
    setfileName(`${props?.file?.Name}`);
    setFileSelected(`${Settings.appApiUrl}files/${props?.file?.Path}`);
  }, []);

  const loadTheFile = () => {
    if (props?.file?.Path?.includes("newFile")) return;
    let http = new XMLHttpRequest();
    let fullPath = `${Settings.appApiUrl}files/${props?.file?.Path}`;
    //add your url in which you want to open document inside the ""
    let content = { fileUrl: fullPath };
    let baseurl = Settings.serviceApiUrl + "file/OpenWord";

    http.open("POST", baseurl, true);
    http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200 || http.status === 304) {
          //open the SFDT text in Document Editor
          if (!documentEditorRef) {
            return;
          }
          documentEditorRef.documentEditor.open(http.responseText);
        }
      }
    };
    http.send(JSON.stringify(content));
  };

  const saveDoc = async () => {
    const blob = await documentEditorRef?.documentEditorInternal?.saveAsBlob(
      "Docx"
    );
    props?.onSave && props?.onSave(blob);
  };

  const loadUI = () => {
    return (
      <>
        <button
          className="btn btn-primary position-absolute d-flex align-items-center justify-content-center"
          style={{ zIndex: 100, top: "8px", right: "273px"}}
          onClick={saveDoc}
        >
          {/* <i className="fa fa-save mr-2"></i> */}
          Save
        </button>
        <DocumentEditorContainerComponent
          ref={(scope) => {
            documentEditorRef = scope;
            props?.file && loadTheFile();
          }}
          enableSfdtExport={true}
          enableTextExport={true}
          enableWordExport={true}
          beforeSave={(arg) => console.log({ arg })}
          // style={{ height: "590px"}}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        />
      </>
    );
  };

  if (props.modal) {
    return (
      <Modal
        show={props.modal}
        onHide={props.toggle}
        className="overflow-hidden"
        size="xl"
        style={{ margin: 0, padding: 0 }}
      >
        <div
          className="e-documents-container bg-light d-flex flex-column align-items-center"
          style={{ width: "60vw", position: "relative", right: "-70px" }}
        >
          {!props?.hideHeader && (
            <div className="w-100 d-flex align-items-center justify-content-center px-5 px-2">
              <img
                src={IMAGES.LOGO_BLACK}
                alt="Plnify"
                height={60}
                width={130}
              />
              <p
                style={{ fontSize: "40px", fontWeight: "bold", color: "black" }}
              >
                Docs
              </p>
            </div>
          )}

          {loadUI()}
        </div>
      </Modal>
    );
  }

  return (
    <div className="e-documents-container w-100">
      {!props?.hideHeader && (
        <div className="w-100 d-flex align-items-center justify-content-center px-5 px-2">
          <img src={IMAGES.LOGO_BLACK} alt="Plnify" height={60} width={130} />
          <p style={{ fontSize: "40px", fontWeight: "bold" }}>Docs</p>
        </div>
      )}
      {loadUI()}
    </div>
  );
};
