import axios from "axios";
import { Airplane, Domain } from "mdi-material-ui";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api, { apiService } from "../../../common/api";
import { IMAGES } from "../../../constant";
import { debounce } from "../../../utils/debounceRequest";
import SidebarEvents from "../../event/SidebarEvents";
import { Event } from "../../event/event";
import EmptyState from "../emptyState";
import ExportSheetComponent from "./ExportSheetComponent";
import { ExportTab } from "./ExportTab";
import { getBasicInfo, getOrganization } from "../../../utils";
import NewFileComponent from "../../event/MoreDetailsCreationModal/NewFileComponent";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../toastNotification";
import Swal from "sweetalert2";
import moment from "moment";

// const ApiUrlBySection = [
//   {
//     section: "header",
//     apiUrl: "/Event/GetEventHeader",
//     apiToUse: api,
//     dataLocation: "local",
//   },
//   {
//     section: "itineraries",
//     apiUrl: "/ScheduleMaster/ScheduleForEvent",
//     apiToUse: apiService,
//     dataLocation: "local",
//   },
//   {
//     section: "flights",
//     apiUrl: "/Flights/FlightsForEvent",
//     apiToUse: apiService,
//     dataLocation: "Response",
//   },
//   {
//     section: "hotels",
//     apiUrl: "/Hotels/HotelForEvent",
//     apiToUse: api,
//     dataLocation: "response",
//   },
//   {
//     section: "transportation",
//     apiUrl: "/Grounds/GroundForEvent",
//     apiToUse: apiService,
//     dataLocation: "response",
//   },
//   {
//     section: "contacts",
//     apiUrl: "/ProductionVenueContact/GetProductionVenueContact",
//     apiToUse: api,
//     dataLocation: "local",
//   },
//   {
//     section: "attachments",
//     apiUrl: "/Attachment/Attachments",
//     apiToUse: apiService,
//     dataLocation: "local",
//   },
// ];

const ApiUrlBySection = {
  // Header: "/Event/GetEventHeader",
  Itineraries: "/ScheduleMaster/ScheduleForEvent",
  Flights: "/Flights/FlightsForEvent",
  Hotels: "/Hotels/HotelForEvent",
  Transportation: "/Grounds/GroundForEvent",
  Contacts: "/ProductionVenueContact/GetProductionVenueContact",
  Attachments: "/Attachment/Attachments",
  // Upcoming: "/Event/GetUpcomingEvents",
};

let ExportItems = [
  {
    icon: "fa-file-invoice",
    title: "Header",
  },
  {
    icon: "fa-clipboard-list",
    title: "Itineraries",
  },
  {
    icon: () => <Airplane />,
    title: "Flights",
  },
  {
    icon: () => <Domain />,
    title: "Hotels",
  },
  {
    icon: "fa-bus",
    title: "Transportation",
  },
  {
    icon: "fa-address-book",
    title: "Contacts",
  },
  {
    icon: "fa-paperclip",
    title: "Attachments",
  },
  {
    icon: "fa-calendar-alt",
    title: "UpcomingEvents",
  },
  {
    icon: "fa-sticky-note",
    title: "Notes",
  },
];

const Tabs = [
  {
    title: "Export",
    icon: "fa-file-export",
  },
  {
    title: "Templates",
    icon: "fa-file-invoice",
  },
];

const SectionParamsInit = {
  Position: [
    {
      order: 1,
      section: "Header",
      align: null,
    },
    {
      order: 2,
      section: "Itineraries",
      align: null,
    },
    {
      order: 3,
      section: "Flights",
      align: null,
    },
    {
      order: 4,
      section: "Hotels",
      align: null,
    },
    {
      order: 5,
      section: "Transportation",
      align: null,
    },
    {
      order: 6,
      section: "Contacts",
      align: null,
    },
    {
      order: 7,
      section: "Attachments",
      align: null,
    },
    {
      order: 8,
      section: "UpcomingEvents",
      align: null,
    },
    {
      order: 9,
      section: "Notes",
      align: null,
    },
  ],
  Itineraries: [],
  ItinerariesShow: true,
  ItinerariesStyles: null,
  Flights: [],
  FlightsShow: true,
  FlightsStyles: null,
  Hotels: [],
  HotelsShow: true,
  HotelsStyles: null,
  Transportation: [],
  TransportationShow: true,
  TransportationStyles: null,
  Contacts: [],
  ContactsShow: true,
  ContactsStyles: null,
  Attachments: [],
  AttachmentsShow: true,
  AttachmentsStyles: null,
  Header: [],
  HeaderShow: true,
  HeaderStyles: null,
  UpcomingEvents: [],
  UpcomingEventsShow: true,
  UpcomingEventsStyles: null,
  Notes: [],
  NotesShow: true,
  NotesStyles: null,
};

const EmailExportComponent = ({
  calledBy,
  itineraryId,
  toggle,
  toggleEmailModal,
  handlePDFAttachment,
  handleSendHtmlAsEmailBody,
  showExportEmail,
  setExportType,
}) => {
  const location = useLocation();
  // const eventsTemp = useSelector((state) => state.calendar.calendarEvents);

  const [events, setEvents] = useState([]);
  const [currentTab, setCurrentTab] = useState("Export");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [hideEvents, setHideEvents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileHTML, setFileHTML] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [templatesData, setTemplatesData] = useState([]);
  const [legacyTemplates, setLegacyTemplates] = useState([]);
  const [legacyTemplate, setLegacyTemplate] = useState(null);
  const [seletedItinerary, setSelectedItinerary] = useState(null);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [itineraries, setItineraries] = useState(null);
  const [sectionsParams, setSectionsParams] = useState({
    ...SectionParamsInit,
  });

  const intl = useIntl();

  const getEvents = async (from, to) => {
    const basicInfo = getBasicInfo();
    const currentOrganization = getOrganization().GUID;
    const currentAccount = basicInfo.GUID;

    const res = await apiService.get(
      `/Events/listClean?OrganizationGUID=${currentOrganization}&AccountGUID=${currentAccount}`
    );

    setEvents(res.data.response);
  };

  //fetch events for filters...
  useEffect(() => {
    const currentDate = new Date();
    getEvents();
  }, []);

  const cleanedEvents = events
    ? events
        ?.filter((event) => event.EventType !== "Day Off")
        ?.filter((event) => event.EventType !== "Travel Day")
        ?.filter((event) => event.EventType !== "Travel")
    : [];

  const toggleSidebarEvents = () => {
    setHideEvents(!hideEvents);
  };

  const toggleSideEvents = () => {
    setHideEvents(!hideEvents);
  };

  const handleSelectEvent = (event) => {
    setSectionsParams({
      ...SectionParamsInit,
    });
    setFileHTML(null);
    console.log(events);
    const currentEvent = Array.isArray(events)
      ? events.find((e) => e.GUID === event)
      : [];
    setSelectedEvent(currentEvent);
  };

  function addOneYearToDate(inputDate) {
    if (!(inputDate instanceof Date)) {
      return "Invalid Date";
    }
    const newDate = new Date(inputDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    return newDate;
  }

  const cancelTokenSourceRef = useRef(null);

  const debouncedRequest = debounce(async () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("New search initiated"); // Cancel ongoing request
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
    const isAnySelected = JSON.stringify(sectionsParams).includes("true");

    try {
      setIsLoading(true);
      if (calledBy === "EVENT_DETAILS") {
        const res = await apiService.post(
          `Export/Details?EventGUID=${selectedEvent?.GUID}&AlternativeView=true`,
          {
            ...sectionsParams,
          },
          {
            cancelToken: cancelTokenSourceRef.current.token,
          }
        );

        if (res.data && isAnySelected) {
          setFileHTML(res.data);
        } else {
          setFileHTML(null);
        }
      } else if (calledBy === "ITINERARIES") {
        const dailySheetParams = {
          ...sectionsParams,
          Attachments: [],
          AttachmentsShow: false,
          AttachmentsStyles: null,
          Contacts: [],
          ContactsShow: false,
          ContactsStyles: null,
          Flights: [],
          FlightsShow: false,
          FlightsStyles: null,
          Transportation: [],
          TransportationShow: false,
          TransportationStyles: null,
        };

        const res = await apiService.post(
          `Export/Details?EventGUID=${selectedEvent?.GUID}&AlternativeView=true`,
          {
            ...dailySheetParams,
          },
          {
            cancelToken: cancelTokenSourceRef.current.token,
          }
        );

        if (res.data && isAnySelected) {
          setFileHTML(res.data);
        } else {
          setFileHTML(null);
        }
      } else {
        const res = await apiService.post(
          `/Export/Details?EventGUID=${selectedEvent?.GUID}`,
          {
            ...sectionsParams,
          },
          {
            cancelToken: cancelTokenSourceRef.current.token,
          }
        );
        if (res.data && isAnySelected) {
          setFileHTML(res.data);
        } else {
          setFileHTML(null);
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error occurred during request:", error);
      }
    }
  }, 500);

  const handleLegacyTemplateRequest = async (template) => {
    try {
      setIsLoading(true);
      const queryId = template?.templatePath?.includes("EventGUID")
        ? selectedEvent?.GUID
        : template?.templateItinerary;

      const res = await apiService.get(
        template?.templatePath?.replace("{0}", queryId)
      );

      if (res.data) {
        setFileHTML(res.data);
      } else {
        setFileHTML(null);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred during request:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedEvent && !legacyTemplate) {
      debouncedRequest();
    } else if (legacyTemplate) {
      handleLegacyTemplateRequest(legacyTemplate);
    }
  }, [sectionsParams, selectedEvent, legacyTemplate]);

  const handleFileRequest = async (e) => {};

  const handleCloseModals = () => {
    toggleEmailModal();
    toggle();
  };

  const setDocumentSectionPosition = (hasData, title) => {
    setSectionsParams((prev) => {
      const currentPosition = prev.Position || [];
      const newPosition = {
        order: currentPosition?.length + 1,
        section: title,
        align: null,
      };
      const exist = currentPosition.some((item) => item.section === title);
      if (!exist && hasData) currentPosition.push(newPosition);
      return {
        ...prev,
        Position: currentPosition,
      };
    });
  };

  const fetchLocalHeaderData = () => {
    return new Promise((resolve) => {
      resolve({
        section: "Header",
        data: [
          {
            GUID: "logo",
            Name: "Logo",
          },
          {
            GUID: "venue",
            Name: "Venue",
          },
          {
            GUID: "event",
            Name: "Event",
          },
        ],
      });
    });
  };

  const fetchSectionData = async (title) => {
    const dataInResponseTitles = ["Hotels", "Transportation"];
    const fetchFromApi = ["Hotels", "Contacts"];
    const apiToUse = fetchFromApi.includes(title) ? api : apiService;

    const { data } =
      title == "Header"
        ? await fetchLocalHeaderData()
        : ApiUrlBySection[title]
        ? await apiToUse.get(
            `${ApiUrlBySection[title]}?EventGUID=${selectedEvent?.GUID}`
          )
        : [];

    let stateData =
      title === "Flights" && data?.Response
        ? data?.Response?.reduce((acc, current) => {
            const x = acc.find(
              (item) => item.Connection === current.Connection
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : title === "Attachments"
        ? data?.filter((item) => !item.Deleted)
        : dataInResponseTitles.includes(title)
        ? data.response
        : data;

    if (title === "Itineraries") {
      stateData = stateData?.map((data) => ({
        ...data,
        Schedules: data?.Schedules?.filter((item) => !item.Deleted).sort(
          (a, b) => new Date(a.Date) - new Date(b.Date)
        ),
      }));
    }

    setSectionData((prev) => [
      ...prev,
      {
        section: title,
        data: stateData?.filter((item) => !item.Deleted),
      },
    ]);
    setDocumentSectionPosition(
      title === "UpcomingEvents" || title === "Header" || title === "Notes"
        ? true
        : stateData?.length > 0,
      title
    );
  };

  const fetchSectionsData = async () => {
    const fetchPromises = ExportItems.map((section) =>
      fetchSectionData(section.title)
    );
    await Promise.all(fetchPromises);
  };

  const getTemplateData = async () => {
    const response = await apiService.get(
      `/Export/Templates?UserId=${getBasicInfo().GUID}`
    );
    const legacy = await apiService.get(`/Export/SystemTEmplates`);
    const { Response } = response.data;
    const itineraries = await apiService.get(
      `/ScheduleMaster/ScheduleForEvent?EventGUID=${selectedEvent?.GUID}`
    );
    const cleanedItineraries = itineraries?.data?.filter(
      (item) => !item.Deleted
    );
    setItineraries(cleanedItineraries);
    const savedTemplates = Response?.filter((item) => item.Legacy === null);
    const legacyTemp =
      cleanedItineraries.length > 0
        ? legacy?.data?.Response
        : legacy?.data?.Response.filter(
            (item) => !item.Id.includes("legacyExportDaysheet")
          );
    setTemplatesData(savedTemplates);
    setLegacyTemplates(legacyTemp);
  };

  useEffect(() => {
    getTemplateData();
  }, [selectedEvent]);

  // useEffect(() => {
  //   if (selectedEvent) {
  //     fetchSectionsData();
  //   }
  // }, [selectedEvent]);

  const handleTemplateSelection = async (template) => {
    setTemplateSelected(template?.Id);

    if (template.Legacy) {
      if (template.Id.includes("legacyExportDaysheet")) {
        if (itineraries.length === 0)
          return ToastNotificationError(
            "noItineraries",
            "No itineraries found for this event"
          );

        const filteredItineraries = itineraries?.map((item) => ({
          [item.GUID]: item.Name,
        }));

        const options = filteredItineraries.reduce((acc, current) => {
          const key = Object.keys(current)[0];
          const value = Object.values(current)[0];
          acc[key] = value;
          return acc;
        }, {});

        await Swal.fire({
          title: "Select an itinerary",
          input: "select",
          inputOptions: options,
          showCancelButton: true,
          confirmButtonText: "Select",
          cancelButtonText: "Cancel",
          imageUrl: IMAGES.LOGO_BLACK_P,
          imageWidth: 80,
          imageHeight: 80,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          inputValidator: (value) => {
            if (!value) {
              return "Please select an itinerary.";
            }
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            const itinerary = result.value;
            const legacyTemplate = {
              templateId: template.Id,
              templateName: template.Name,
              templatePath: template.Path,
              templateItinerary: itinerary,
            };
            setLegacyTemplate(legacyTemplate);
            setSelectedItinerary(itinerary);
            setCurrentTab("Export");
            ToastNotificationSuccess(
              "templateSelected",
              `Template ${template?.Name} is the template in use.`
            );
          }
        });
      } else {
        setLegacyTemplate({
          templateId: template.Id,
          templateName: template.Name,
          templatePath: template.Path,
        });
        setCurrentTab("Export");
        ToastNotificationSuccess(
          "templateSelected",
          `Template ${template?.Name} is the template in use.`
        );
      }
    } else {
      setLegacyTemplate(null);
      setSectionsParams({
        ...SectionParamsInit,
        ...JSON.parse(template?.Content),
      });
      setCurrentTab("Export");
      ToastNotificationSuccess(
        "templateSelected",
        `Template ${template?.Name} is the template in use.`
      );
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      Swal.fire({
        title: "Are you sure, you want to delete this template?",
        text: "You won't be able to revert this!",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService.get(`/Export/DeleteTemplate?id=${templateId}`);
          getTemplateData();
          ToastNotificationError(
            "templateDeleted",
            "Template deleted successfully"
          );
        }
      });
    } catch (error) {
      console.error("Error occurred during request:", error);
    }
  };

  const handleEditTemplate = async (template) => {
    try {
      Swal.fire({
        title: "Edit template",
        text: "Please enter the name of the template",
        input: "text",
        inputValue: template.Name,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        inputValidator: (value) => {
          if (!value) {
            return "Please enter your template name.";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const updatedTemplate = {
            Id: template?.Id,
            Name: result?.value,
            Content: template?.Content,
            Path: template?.Path,
          };

          const response = await apiService.post(
            `/Export/AddEditTemplate?userId=${getBasicInfo().GUID}`,
            updatedTemplate
          );
          getTemplateData();
        }
      });
    } catch (error) {
      console.error("Error occurred during request:", error);
    }
  };

  //Select Triggered Event or Select First Event based on Filter on First Load
  useEffect(() => {
    if (location.pathname.includes("calendar/")) {
      const event = location.pathname.split("/")[2];
      event && handleSelectEvent(event);
    } else if (!selectedEvent) {
      const currentDate = new Date();
      const twoYears = 2 * 365 * 24 * 60 * 60 * 1000;
      const minDate = new Date(currentDate);
      const maxDate = addOneYearToDate(new Date());

      const filteredEvents = cleanedEvents.filter((event) => {
        const eventDate = new Date(event.Date);
        const isDateInRange = eventDate >= currentDate && eventDate <= maxDate;
        return isDateInRange && !event.Deleted;
      });
      setSelectedEvent(filteredEvents[0]);
    }
  }, []);

  return (
    <div
      className="bg-white text-black-50 organization-transparent h-100"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div className="d-flex align-items-center justify-content-between p-2">
        <div className="d-flex">
          <div
            className="d-flex  align-items-center"
            style={{ height: "50px" }}
          >
            {selectedEvent ? (
              <Event noIcons event={selectedEvent} />
            ) : (
              <h3
                className="font-weight-bold ml-4"
                style={{ color: "black", fontSize: "20px" }}
              >
                Exports
              </h3>
            )}
          </div>
        </div>
        <div
          className="d-flex align-items-center mx-4 position-absolute"
          style={{ left: "50%" }}
        >
          <img
            src={IMAGES.LOGO_BLACK}
            alt="plnify logo"
            style={{ width: "100px" }}
          />
          {/* <h4 className="ml-1">Exports</h4> */}
        </div>
        <div className="d-flex h-75">
          {/* <button className="btn btn-primary mr-1">
            <i className="fas fa-plus"></i>
          </button>
          <button className="btn btn-secondary mr-1">
            <i className="fas fa-file-export"></i>
          </button> */}
          <button className="btn btn-danger mr-4" onClick={handleCloseModals}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
      <hr className="m-0 mb-2" />
      <div className="d-flex px-2">
        {Tabs.map((tab, index) => (
          <ExportTab
            key={index + tab.title}
            title={tab.title}
            icon={tab.icon}
            isActive={currentTab === tab.title}
            setIsActive={setCurrentTab}
          />
        ))}
      </div>

      <div className="bg-light p-4 d-flex w-100">
        <div
          className={
            hideEvents && !isMobile
              ? "event-sidebar-container"
              : "event-sidebar-container_expanded rounded-lg shadow-xl bg-white mr-4 overflow-hidden"
          }
          style={
            isMobile
              ? { width: "100%", height: "20%" }
              : { width: "20%", minWidth: "350px" }
          }
        >
          <SidebarEvents
            noClickable
            hideSearch
            eventConatinerClass="w-100 d-flex flex-column "
            eventSecondContainerClass=""
            eventThirdContainerClass=""
            eventHeadContainerClass="d-flex justify-content-between align-items-center p-2 border-bottom shadow-xl"
            eventContent="bg-white"
            scrollbarsStyles={
              isMobile
                ? { height: "calc(20vh - 75px)" }
                : { height: "calc(100vh - 210px)" }
            }
            events={cleanedEvents}
            selectedEvent={selectedEvent}
            isMobile={isMobile}
            renderBy={"guestList"}
            iconsToRemove={["published", "private", "attachment", "account"]}
            setCurrentEvent={handleSelectEvent}
            toggleSideEvents={toggleSideEvents}
            toggle={toggleSidebarEvents}
          />
        </div>
        {currentTab === "Export" && (
          <ExportSheetComponent
            ExportItems={ExportItems}
            hideEvents={hideEvents}
            isMobile={isMobile}
            sectionsParams={sectionsParams}
            selectedEvent={selectedEvent}
            sectionsData={sectionData}
            fileUrl={fileUrl}
            fileHTML={fileHTML}
            handlePDFAttachment={handlePDFAttachment}
            handleSendHtmlAsEmailBody={handleSendHtmlAsEmailBody}
            toggleExportModal={toggle}
            isLoading={isLoading}
            calledBy={calledBy}
            setSectionsParams={setSectionsParams}
            handleFileRequest={handleFileRequest}
            setIsLoading={setIsLoading}
            toggleSideEvents={toggleSideEvents}
            setSelectedEvent={setSelectedEvent}
            getTemplateData={getTemplateData}
            setExportType={setExportType}
            legacyTemplate={legacyTemplate}
          />
        )}

        {currentTab === "Templates" && (
          <div
            style={{ height: "calc(100vh - 148px)" }}
            className="bg-white rounded-lg w-100 relative overflow-hidden align-items-center d-flex flex-column"
          >
            {!selectedEvent && (
              <div className="no-event-selected">
                <span className="text-dark font-weight-bold bg-white p-4 rounded">
                  Please select an event before selecting a template
                </span>
              </div>
            )}
            <div className="w-100 p-2 border-bottom m-0">
              <span className="text-dark font-weight-bold">Templates</span>
            </div>
            {hideEvents && !isMobile && (
              <button
                title="Show Events"
                className="btn btn-primary border-0 position-absolute event-sidebar-btn"
                onClick={toggleSideEvents}
                style={{ zIndex: "50", left: "-20px", top: "72px" }}
              >
                <i
                  className="fas fa-angle-right"
                  style={{ marginLeft: "13px" }}
                ></i>
              </button>
            )}
            {legacyTemplates?.length > 0 && (
              <div className="container p-4 ">
                <div className="d-flex w-100 text-dark px-2 justify-content-between align-items-center">
                  <span className="text-dark font-weight-bold">
                    System Templates
                  </span>
                </div>
                <div
                  className="d-flex w-100 p-4 overflow-auto carousel slide"
                  style={{
                    overflowX: "scroll",
                    overflowY: "hidden",
                    gap: "20px",
                  }}
                >
                  <Swiper
                    className="w-100 z-0 my-0"
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={5}
                    slidesPerGroup={1}
                    loop={false}
                    navigation={true}
                    pagination={false}
                  >
                    {legacyTemplates?.map((template, index) => (
                      <SwiperSlide className="d-flex justify-content-center align-items-center p-2">
                        <NewFileComponent
                          key={template.Id}
                          color="darkBlue"
                          icon="fa-file-alt"
                          title={template.Name}
                          isSelected={templateSelected === template.Id}
                          action={() => handleTemplateSelection(template)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            )}

            {templatesData?.length > 0 ? (
              <div className="container p-4">
                <div className="d-flex w-100 text-dark px-2 justify-content-between align-items-center">
                  <span className="text-dark font-weight-bold">
                    Saved Templates
                  </span>
                </div>
                <div className="row p-4">
                  {templatesData?.map((template, index) => (
                    <div className="col-3 p-2">
                      <NewFileComponent
                        key={template.Id}
                        color="darkBlue"
                        icon="fa-file-alt"
                        title={template.Name}
                        isSelected={templateSelected === template.Id}
                        action={() => handleTemplateSelection(template)}
                        hasBtns={true}
                        handleDelete={() => {
                          handleDeleteTemplate(template.Id);
                        }}
                        handleEdit={() => {
                          handleEditTemplate(template);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="w-50 d-flex flex-column align-items-center">
                <EmptyState
                  image={IMAGES.ATTACHMENTS_FILES}
                  cta="Your saved templates will appear here"
                  text={intl.formatMessage({
                    id: "emptyState.templates",
                    defaultMessage: "Your saved templates will appear here",
                  })}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => setCurrentTab("Export")}
                >
                  Go to Export
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailExportComponent;
