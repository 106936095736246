import OneSignal from "react-onesignal";

async function runOneSignal() {
  const key = process.env.REACT_APP_ONE_SIGNAL_DEV_KEY;
  await OneSignal.init({
    appId: "82ecda70-c1e9-4620-98fc-d91340380c6a",
    safari_web_id: "web.onesignal.auto.62c55c8f-4b76-4a2c-bf2b-fd3fb88f056f",
    notifyButton: {
      enable: false,
    },
    allowLocalhostAsSecureOrigin: true,
  });
  // OneSignal.Slidedown.promptPush();
}
export { runOneSignal };
