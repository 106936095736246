import React, { Component } from "react";
import { connect } from "react-redux";
import Flights from "./flight";
import {
  ToastNotificationSuccess,
  ModalComponent,
} from "../../../components/common";
//import EditStopsModal from "../../../components/flight/EditStopsModal";
//import AddCommercialStopForm from "../../../components/flight/forms/AddCommercialStopForm";

import api from "../../../common/api";
import { setPayloadData, setEditPayloadData } from "./setFlightData";
import { getAccessToken } from "../../../utils";
import * as actions from "../../event/redux/actions";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import uuid from "uuid";
import AssignMembersFlight from "../../../components/flights/AssignMembersFlight";

class FlightContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addFlightModal: false,
      notes: false,
      editModal: false,
      singleEditModal: false,
      addNewsModal: false,
      single: null,
      searchedFlights: [],
      staffSelected: [],
      proposals: [],
      airports: [],
      airlines: [],
      status: "new",
    };
  }

  /**
   * @description this function handle payload for flight add and edit
   */
  handleFlightPayload = () => {
    let {
      isReturn,
      isPrivate,
      filledDepartureData,
      filledReturnData,
      isRoundTrip,
      isEdit,
      flightEditDetails,
    } = this.state;
    let flightData = {
      departData: filledDepartureData,
      returnData: filledReturnData,
    };
    this.setState({ loading: true });
    if (!isEdit) this.addFlight(isReturn, isPrivate, flightData, isRoundTrip);
    else
      this.editFlightFunction(
        isReturn,
        isPrivate,
        flightData,
        isRoundTrip,
        flightEditDetails
      );
  };

  /**
   * @description this function is used to add flight
   */
  addFlight = (isReturn, isPrivate, flightData, isRoundTrip) => {
    const { addFlightAction, event } = this.props;
    let payload = setPayloadData(
      isReturn,
      isPrivate,
      event,
      flightData,
      isRoundTrip
    );
    addFlightAction(payload, getAccessToken(), false)
      .then((r) => {
        this.setState({ loading: false });
        this.toggleAddFlightModal();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  /**
   * @description this function is for edit flight
   */
  editFlightFunction = (
    isReturn,
    isPrivate,
    flightData,
    isRoundTrip,
    flightEditDetails
  ) => {
    const { editFlight } = this.props;
    let eidtPayload = setEditPayloadData(
      isReturn,
      isPrivate,
      flightData,
      isRoundTrip,
      flightEditDetails
    );
    let localStoreData = {
      depart: isReturn ? null : { ...eidtPayload },
      return: isReturn ? { ...eidtPayload } : null,
    };
    editFlight(eidtPayload, localStoreData, getAccessToken())
      .then(() => {
        this.toggleAddFlightModal();
        this.setState({ loading: false, isEdit: false, flightEditDetails: {} });
      })
      .catch(() => {
        this.setState({ loading: false, isEdit: false, flightEditDetails: {} });
      });
  };

  /**
   * @description this function is to handle drop down action for flight
   * @param {e} object this will give dropdown id
   * @param {flight} object this will give flight details
   */
  handleDropDownOption = (e, flight) => {
    const { deleteFlight } = this.props;
    switch (e.target.id) {
      case "Edit Details":
        this.showEditModal(flight);
        break;
      case "Delete Flight":
        deleteFlight(flight.guid, getAccessToken());
        break;
      default:
        break;
    }
  };

  updateFlight = (flight) => {
    if (flight.GUID && this.state.single === null) {
      let stops = this.state.stops.map((stop) =>
        stop.GUID === flight.GUID ? (stop = flight) : stop
      );
      this.setState({
        stops: stops,
      });
    }
  };

  editFlight = (flight) => {
    const {
      event,
      actions: { getFlights, editFlight },
    } = this.props;
    editFlight(flight, () => {
      this.toggleSingleEdit();
      getFlights(event.GUID);
      ToastNotificationSuccess(1, "Flight Edited");
    });
  };

  toggleNewMembersModal = () => {
    this.setState({
      addNewsModal: !this.state.addNewsModal,
    });
  };

  toggleSingleEdit = () => {
    this.setState({
      singleEditModal: !this.state.singleEditModal,
    });
  };

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };

  singleEdit = (flight) => {
    this.setState(
      {
        single: flight,
      },
      this.toggleSingleEdit
    );
  };

  updatePassengers = () => {
    const {
      sideBarItemData,
      event,
      actions: { addFlightStaff, getFlights },
    } = this.props;
    addFlightStaff(this.state.staffSelected, sideBarItemData, () => {
      ToastNotificationSuccess(1, "Updated staffs");
      getFlights(event.GUID);
      this.toggleNewMembersModal();
    });
  };

  updatePassenger = (reservation) => {
    debugger;
    let staffs = this.state.staffSelected.map((staff) => {
      if (staff.StaffGUID === reservation.StaffGUID) {
        staff = { ...staff, ...reservation };
        return staff;
      }
      return staff;
    });
    this.setState({
      staffSelected: staffs,
    });
  };

  toggleMember = (staff) => {
    let staffs = this.state.staffSelected.map((staff) => ({
      ...staff,
      Account: null,
      Staff: null,
    }));
    if (staffs.filter((s) => s.StaffGUID === staff.GUID).length > 0) {
      let filtered = staffs.map((s) => {
        if (s.StaffGUID === staff.GUID) {
          if (s.Deleted === null) {
            return {
              ...s,
              Deleted: new Date(),
              ReservationNumber: "",
              SeatNumber: "",
              TicketNumber: "",
            };
          } else {
            return { ...s, Deleted: null };
          }
        }
        return s;
      });
      return this.setState({
        staffSelected: filtered,
      });
    } else {
      let newOnes = staffs.concat({
        StaffGUID: staff.GUID,
        GUID: uuid.v4(),
        Deleted: null,
        ReservationNumber: [],
        SeatNumber: [],
        TicketNumber: [],
      });
      return this.setState({
        staffSelected: newOnes,
      });
    }
  };

  addReservations = (staffs) => {
    this.setState({
      staffSelected: staffs,
    });
  };

  cleanReservations = () => {
    this.setState({
      staffSelected: [],
    });
  };

  searchFlights = async (query, util) => {
    this.setState({ loadingProposals: true });
    util && util();
    const flights = await api.get("/Flight/FlightDetailsExternal", {
      params: { Query: query },
    });

    const groups = flights.data.reduce((groups, flight) => {
      const date = flight.DepartureDate.split("T")[1];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(flight);
      return groups;
    }, {});

    const flightsArray = Object.keys(groups).map((date) => {
      return {
        date,
        flights: groups[date],
      };
    });

    this.setState({ loadingProposals: false, proposals: flightsArray });
  };

  searchAirports = async (query) => {
    const airports = await api.get(`/Airports/GetAirports?Search=${query}`);
    this.setState({
      airports: airports.data,
    });
  };

  searchAirlines = async (query) => {
    const airlines = await api.get(`/Airlines/GetAirlines?Search=${query}`);
    this.setState({
      airlines: airlines.data,
    });
  };

  cleanAirlines = () => {
    this.setState({
      airlines: [],
    });
  };

  deleteFlight = (flight) => {
    const {
      handleSidebarItem,
      actions: { deleteFlight, getFlights },
      flights,
      event,
    } = this.props;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteFlight(flight, () => {
          getFlights(event.GUID);
          ToastNotificationSuccess(1, "Deleted all staffs");
          handleSidebarItem("FLIGHT_LIST", flights.pop());
        });
      }
    });
  };

  render() {
    let {
      airlines,
      flightSearch,
      event,
      handleSidebarItem,
      searchedAirport,
      sideBarItemData,
      flights,
      teams,
      loading,
      loadingFlights,
    } = this.props;
    if (!sideBarItemData) {
      console.log("sidebar item data came empty");
      debugger;
      return <></>;
    }
    const { editModal, singleEditModal, single, proposals, staffSelected } =
      this.state;
    const stops =
      flights &&
      flights
        .filter((f) => f.Connection !== null)
        .filter((f) => f.Connection === sideBarItemData.Connection);
    return (
      <div>
        {/* <ModalComponent
          modal={editModal}
          toggle={() => this.toggleEditModal()}
          childeren={
            <EditStopsModal
              toggle={this.toggleEditModal}
              stops={stops}
              handleSidebarItem={handleSidebarItem}
            />
          }
        /> */}
        {/* <ModalComponent
          modal={singleEditModal}
          toggle={() => this.toggleSingleEdit()}
          childeren={
            <AddCommercialStopForm
              edit={true}
              stop={single}
              toggle={() => this.toggleSingleEdit()}
              updateFlight={this.updateFlight}
              loading={loading}
              editFlight={this.editFlight}
              searchFlights={this.searchFlights}
              proposals={proposals}
            />
          }
        /> */}
        <ModalComponent
          toggle={this.toggleNewMembersModal}
          modal={this.state.addNewsModal}
          childeren={
            //   <AssignFlightMembers
            //   flight={sideBarItemData}
            //   toggle={this.toggleNewMembersModal}
            //   teams={teams}
            //   eventStaffs={teams}
            //   toggleMember={this.toggleMember}
            //   arrayToBeSelected={staffSelected}
            //   currentPassengers={sideBarItemData && sideBarItemData.FlightStaffs}
            //   edit={true}
            //   addStaff={this.addStaff}
            //   cleanReservations={this.cleanReservations}
            //   addReservations={this.addReservations}
            //   updatePassenger={this.updatePassenger}
            //   updatePassengers={this.updatePassengers}
            //   loading={loading}
            //   stops={stops}
            // />
            <AssignMembersFlight
              flight={sideBarItemData}
              toggle={this.toggleNewMembersModal}
              teams={teams}
              eventStaffs={teams}
              toggleMember={this.toggleMember}
              arrayToBeSelected={staffSelected}
              currentPassengers={
                sideBarItemData && sideBarItemData.FlightStaffs
              }
              edit={true}
              addStaff={this.addStaff}
              cleanReservations={this.cleanReservations}
              addReservations={this.addReservations}
              updatePassenger={this.updatePassenger}
              updatePassengers={this.updatePassengers}
              loading={loading}
              stops={stops}
              donable
            />
          }
        />
        <Flights
          {...this.state}
          blockName={this.props.blockName}
          event={this.props.event}
          loadingFlights={loadingFlights}
          searchFlight={this.searchFlight}
          airlines={airlines}
          handleKeyDown={this.handleKeyDown}
          setAirlineDetails={this.setAirlineDetails}
          flightSearch={flightSearch}
          flightNumberOnClick={this.flightNumberOnClick}
          handleSubmit={this.handleSubmit}
          addStopHandler={this.addStopHandler}
          addStop={this.showAddStopModal}
          deleteStop={this.handleDeleteStop}
          handleDropDownOption={this.handleDropDownOption}
          //  handleEditStop={this.showEditStopModal}
          handleSidebarItem={handleSidebarItem}
          flights={flights}
          deleteFlight={this.deleteFlight}
          sideBarItemData={sideBarItemData}
          toggleNewMembersModal={this.toggleNewMembersModal}
          toggleEditModal={this.toggleEditModal}
          singleEdit={this.singleEdit}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  flights: state.event.flights,
  event: state.event.event,
  loadingFlights: state.event.loadingFlights,
  teams: state.organizations.teams,
  loading: state.event.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightContainers);
