import React from "react";
import { useIntl } from "react-intl";
import { GuestListTableRow } from "./GuestListTableRow";
import { Table } from "reactstrap";

export const GuestListTable = ({ data, deleteTicket }) => {
  const intl = useIntl();
  return (
    <Table className="border">
      <thead>
        <tr>
          <th
            //onClick={() => setExpandedView(!expandedView)}
            className="col-1 py-1"
          >
            <span className="requester">Guest Name</span>
          </th>
          <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Amount" })}
            {/* {requests.map((r) => r.Passes).reduce((a, b) => a + b, 0)}) */}
          </th>
          <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Type" })}
          </th>
          {/* <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Pick Up" })}
          </th> */}
          <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Request Notes" })}
          </th>
          {/* <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Confirmed by" })}
          </th>
          <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Seats" })}
          </th> */}
          <th className="col-1 py-1 text-center">
            {intl.formatMessage({ id: "Actions" })}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((ticket) => {
          return (
            <GuestListTableRow ticket={ticket} deleteTicket={deleteTicket} />
          );
        })}
      </tbody>
    </Table>
  );
};
