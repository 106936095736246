import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function editEvent(event) {
  const url = `/Events/Edit2`;
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_EVENT,
    });
    try {
      await db.table("events").put(event);
      await db.table("event").put(event);
      const request = await apiService.post(url, {
        Name: event.Name || "",
        EventType: event.EventType ?? null,
        EventCapacity: event.EventCapacity ?? null,
        Date: `${event.Date}` ?? null,
        VenueGUID: event.Venue && event.Venue.Name ? event.VenueGUID : null,
        Notes: event.Notes ?? null,
        City: event.City ?? null,
        Country: event.Country ?? null,
        State: event.State ?? null,
        Status: event.Status ?? null,
        Latitude: event.Latitude ?? null,
        Longitude: event.Longitude ?? null,
        GUID: event.GUID ?? null,
        OrganizationGUID: event.OrganizationGUID ?? null,
        LastSync: event.LastSync ?? null,
        DateTime: event.DateTime ?? null,
        CreationDate: event.CreateDate ?? null,
        Deleted: null,
        Image: event.Image ?? null,
        AllowUsersToRequestTickets: event.AllowUsersToRequestTickets ?? null,
        WillCallCity: event.WillCallCity ?? null,
        WillCallHours: event.WillCallHours ?? null,
        WillCallState: event.WillCallState ?? null,
        WillCallAddress: event.WillCallAddress ?? null,
        WillCallCountry: event.WillCallCountry ?? null,
        WillZipCode: event.WillZipCode ?? null,
        WillCallPhone: event.WillCallPhone ?? null,
        WillCallComment: event.WillCallComment ?? null,
        WillCallZipCode: event.WillCallZipCode ?? null,
        CancelDate: event.CancelDate ?? null,

        CompsCount: event.CompsCount ?? null,
        ReturnedCompsCount: event.ReturnedCompsCount ?? null,
        EventInvite: event.EventInvite ?? null,
      });
      let venueRequest = null;
      if (request.data.Status === "ok" && request.data.Response.VenueGUID) {
        venueRequest = await api.get(
          `/Venues/Details?GUID=${request.data.Response.VenueGUID}`
        );
      }
      dispatch({
        type: constant.EDITED_EVENT,
        data: {
          ...request.data.Response,
          Venue: venueRequest && venueRequest.data,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.EDITING_EVENT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_EVENT: {
      return {
        ...state,
        loading: false,
        event: { ...action.data },
      };
    }
    case constant.EDITING_EVENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
