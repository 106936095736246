import React, { useEffect, useState } from "react";
import EmptyState from "../common/emptyState";
import { IMAGES } from "../../constant";
import { useState as useHookState } from "@hookstate/core";
import mainState from "../../common/mainState";
import Scrollbars from "react-custom-scrollbars";
import GroundItem from "./groundItem";
import Swal from "sweetalert2";
import api from "../../common/api";
import {
  checkSelectionStatus,
  exist,
  hookStateToObject,
} from "../../common/helpers";
import { ModalComponent } from "..";
import ExportToEvent from "../common/ExportToEvent";
import db from "../../common/db";
import { useIntl, FormattedMessage } from "react-intl";

import EmptyStateImg from "../../images/ground-empty.svg";
import { useDispatch, useSelector } from "react-redux";
import { getGrounds } from "../../page/event/redux/getGrounds";
import { ConsoleLine, ConsoleNetworkOutline } from "mdi-material-ui";
import { deleteGround } from "../../page/event/redux/deleteGround";

const Ground = (props) => {
  let {
    toggleGroundModal,
    // grounds,
    handleSidebarItem,
    toggleMoveModal,
    toggleLink,
    togglePassengers,
    editModal,
    sideBarItemData,
    toggleLabelForm,
  } = props;

  const grounds = useSelector((state) =>
    state.event.grounds.filter((g) => g.Deleted === null)
  );
  const dispatch = useDispatch();

  const [state, setState] = useState({
    SelectedItems: [],
    UI: {
      showExportToEvent: false,
      exportMode: "",
    },
    Grounds: grounds ? grounds : [],
  });

  const toggleExportToEvent = async (exportMode, reload) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        showExportToEvent: !prev.UI.showExportToEvent,
        exportMode,
      },
    }));
    if (reload) {
      // await GroundsRemote();
      setState((prev) => ({
        ...prev,
        SelectedItems: [],
      }));
      //props.actions.getGrounds(props.event.GUID)
    }
  };
  const intl = useIntl();
  const deleteAllSelected = () => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete all selected items",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let query = {
          Items: "",
          Events: "",
        };

        state.SelectedItems.forEach((item) => {
          // query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;

          dispatch(deleteGround(item, () => {}));
        });

        // console.log({query})
        // await api.post("/grounds/DeleteBatch", query);

        setState((prev) => ({
          ...prev,
          SelectedItems: [],
        }));
        dispatch(getGrounds(props?.event?.GUID));
      }
    });
  };

  const selectAll = () => {
    let SelectedItems = JSON.parse(JSON.stringify(state.SelectedItems));
    let filter = grounds;
    let checkSelection = checkSelectionStatus(grounds, null, SelectedItems);

    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        }
      });

      setState((prev) => ({
        ...prev,
        SelectedItems,
      }));
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      setState((prev) => ({
        ...prev,
        SelectedItems: [],
      }));
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);
        } else {
          //Check if deleted
        }
      });
      setState((prev) => ({
        ...prev,
        SelectedItems,
      }));
    }
  };

  const toggleSelect = (item) => {
    let SelectedItems = JSON.parse(JSON.stringify(state.SelectedItems));
    if (exist(SelectedItems, item)) {
      if (
        SelectedItems.filter(
          (x) => x.GUID === item.GUID && x.Deleted === null
        )[0]
      ) {
        SelectedItems = SelectedItems.filter((x) => x.GUID !== item.GUID);
      }
    } else {
      SelectedItems.push(item);
    }

    setState((prev) => ({
      ...prev,
      SelectedItems,
    }));
  };

  function setGroundsSection() {
    if (grounds.length) {
    }
  }

  const selectionStatus = () => {
    try {
      let result = null;
      if (!state.SelectedItems) {
        return null;
      }
      let check = checkSelectionStatus(
        grounds,
        null,
        JSON.parse(JSON.stringify(state.SelectedItems))
      );
      if (check === 0) {
        result = <i className="far fa-square"></i>;
      } else if (check === 1) {
        result = <i className="fas fa-check-square"></i>;
      } else {
        result = <i className="fas fa-minus-square"></i>;
      }
      return result;
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };

  //Delete Selected Items on Delete Key Down
  useEffect(() => {
    const handleDeleteKey = ({ key }) => {
      if (key === "Delete") {
        deleteAllSelected();
      }
    };
    const rootElement = document.getElementById("root");
    rootElement.addEventListener("keydown", handleDeleteKey);

    return () => {
      rootElement.removeEventListener("keydown", handleDeleteKey);
    };
  }, [state.SelectedItems]);

  return (
    <>
      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state.UI.showExportToEvent}
        childeren={
          <ExportToEvent
            originEvent={props.originEvent}
            exportMode={state.UI.exportMode}
            items={state.SelectedItems}
            toggle={toggleExportToEvent}
            section="ground"
          />
        }
      />
      <div className="resource shadow-sm">
        <div className="resource__header " onClick={() => setGroundsSection()}>
          <div className="row">
            <div className="col-auto px-0 py-1">
              <button
                className="btn btn-sm text-secondary"
                onClick={() => selectAll()}
                type="button"
              >
                {selectionStatus()}
              </button>
            </div>
            <div className="col-5 px-0 py-1">
              <div className="head-left pt-1">
                <h4>
                  <FormattedMessage
                    id="transportation"
                    defaultMessage={props.blockName}
                  />
                </h4>
              </div>
            </div>

            <div className="col-auto px-0 pr-3 text-right ml-auto py-1">
              <div className="resource_button-right">
                <div className="filter-icon green d-none">
                  <img src={IMAGES.EXPORT} alt="dot" width="20" height="20" />
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => toggleGroundModal(false)}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
            {checkSelectionStatus(
              grounds,
              null,
              JSON.parse(JSON.stringify(state.SelectedItems))
            ) !== 0 ? (
              <div className="col-12 bg-subOption text-dark text-center py-2">
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("export")}
                >
                  <FormattedMessage
                    id="moveToAnotherEvent"
                    defaultMessage="Move to another event"
                  />
                </button>
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("copy")}
                >
                  <FormattedMessage
                    id="copyToAnotherEvent"
                    defaultMessage="Copy to another event"
                  />
                </button>
                <button
                  className="btn btn-danger mx-2 btn-sm"
                  onClick={() => deleteAllSelected()}
                >
                  <FormattedMessage
                    id="deleteSelected"
                    defaultMessage="Delete Selected"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {grounds && grounds.filter((x) => x.Deleted === null).length === 0 ? (
          <EmptyState
            image={EmptyStateImg}
            cta="Add a new ground"
            text="No transportation added yet"
            actionBtn={{
              action: () => toggleGroundModal(false),
              title: "Add Transportation",
            }}
          />
        ) : (
          <div className="resource__body selectorContainer">
            <Scrollbars style={{ width: "100%", height: 250 }}>
              {grounds
                .filter((x) => x.Deleted === null)
                .sort((a, b) => {
                  if (a.PickupDate > b.PickupDate) return 1;
                  if (a.PickupDate < b.PickupDate) return -1;
                })
                .map((ground, index) => {
                  let parsed = hookStateToObject(ground);
                  return (
                    <GroundItem
                      //   deleteGround={deleteGround}
                      key={index}
                      ground={parsed}
                      handleSidebarItem={handleSidebarItem}
                      toggleMoveModal={toggleMoveModal}
                      toggleLink={toggleLink}
                      togglePassengers={togglePassengers}
                      editModal={editModal}
                      sideBarItemData={sideBarItemData}
                      toggleLabelForm={toggleLabelForm}
                      deleteAllSelected={deleteAllSelected}
                      selectedItems={state.SelectedItems}
                      toggleSelect={toggleSelect}
                      checkSelectionStatus={checkSelectionStatus}
                    />
                  );
                })}
            </Scrollbars>
          </div>
        )}
      </div>
    </>
  );
};

export default Ground;
