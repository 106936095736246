import React from "react";
import { Modal } from "react-bootstrap";

export const CustomNotification = ({
  toggle,
  handleSubmit,
  btnCancel,
  handleCancel,
  title,
  message,
  btn,
  needPermission,
  bgClass,
  icon,
}) => {
  return (
    <Modal
      show={needPermission}
      onHide={() => toggle()}
      style={{ position: "absolute", top: 0, left: 0 }}
    >
      <div
        className={`${
          bgClass ?? "bg-primary"
        } px-4 py-3 rounded-lg d-flex flex-column m-2`}
      >
        <button
          className="btn btn-sm position-absolute text-light"
          style={{ top: 10, right: 10 }}
          onClick={() => toggle(true)}
        >
          <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
        </button>
        <span className="d-flex align-items-center mb-2 mt-2">
          <i
            className={icon ?? "fa fa-bell-o"}
            style={{ fontSize: "20px" }}
          ></i>
          <h4 className="font-weight-bold ml-2">{title}</h4>
        </span>
        <p className="my-2 h6">{message}</p>
        <div className="d-flex justify-content-end w-100" style={{ gap: 5 }}>
          <button
            className="btn btn-light align-self-end rounded border-0 py-2 px-4"
            onClick={handleSubmit}
          >
            {btn}
          </button>
          {btnCancel && (
            <button
              className="btn btn-danger align-self-end rounded border-0 py-2 px-4"
              onClick={handleCancel}
            >
              {btnCancel}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
