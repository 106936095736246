import React from "react";
import { parseLogo } from "../../utils";
import ReactTooltip from "react-tooltip";
import { IMAGES } from "../../constant";
const onErrorImage = (sender) => {
  sender.target.src = IMAGES.ORGANIZATION_PLACEHOLDER;
};
const OrganizationButton = (props) => {
  let { changeOrganization, organization, current } = props;

  return (
    <div
      style={{
        border: current === organization.GUID ? "1px solid #4084bf" : "none",
      }}
      className={
        current === organization.GUID ? "d-none" : "organizationSidebar__button"
      }
      data-tip={organization.Name}
      onClick={() => changeOrganization(organization)}
    >
      <a className="clean-link">
        {organization.Logo && organization.Logo.includes("http") ? (
          <img
            className="img-fluid"
            src={organization.Logo.replace("http:", "https:")}
            alt={organization.Name}
          />
        ) : organization && organization.Logo ? (
          <img
            onError={onErrorImage}
            className="img-fluid"
            src={`https://service.plnify.com/files/${organization.Logo}`}
            alt={organization.Name}
          />
        ) : null}
        <ReactTooltip place={"right"} effect="solid" />
      </a>
    </div>
  );
};

export default OrganizationButton;
