import React, { useEffect, useMemo, useState } from "react";
import TeamItem from "../../../components/organization/TeamItem";
import UserItem from "../../../components/organization/UserItem";
import CreateTeamForm from "../../../components/organization/CreateTeamForm";
import InviteUserFrom from "../../../components/organization/InviteUserForm";
import NonAppuser from "../../../components/organization/NonAppUser";
import EditOrganizationModal from "../../../components/organization/editOrganization/EditOrganizationModal";
import {
  ModalComponent,
  DropDownComponent,
  SimpleLoader,
  ToastNotificationSuccess,
  ToastNotificationError,
} from "../../../components/common";
import ExportModalComponent from "../../../components/common/ExportToEvent";
import { Scrollbars } from "react-custom-scrollbars";
import { cleanDelete, getBasicInfo, getOrganization } from "../../../utils";
import { IMAGES } from "../../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import uuid from "uuid";
import EMPTY_IMG from "../../../images/TEAM_emptystate.svg";
import MoveUserToTeam from "../../../components/organization/MoveUserToTeam";
import { useIntl, FormattedMessage } from "react-intl";
import ShareInviteLink from "../../../components/organization/shareInviteLink";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../calendar/redux/getEvents";
import api from "../../../common/api";
import { SandwichButton } from "../../../components/common/SandwichButton";
import { useBetaFeature } from "../../../hooks/useBetaFeature";
import Swal from "sweetalert2";
import NonAppUserModal from "../../../components/organization/nonAppUsers/NonAppUserModal";
import { checkSelectionStatus, exist } from "../../../common/helpers";
import * as actions from "../redux/actions";
import HotelsToMultipleEventsModal from "../../../components/common/MoveHotelToMultipleEventsModal";
// import GeneralUserItem from "../common/GeneralUserItem";
const Users = (props) => {
  let {
    currentOrganization,
    loading,
    currentTeam,
    setCurrentTeam,
    createTeam,
    rolesToSee,
    toggleTeam,
    teams,
    deleteTeam,
    emailToInvite,
    addAccount,
    cleanInvites,
    inviteUsers,
    toggleNotifications,
    editRole,
    roleToEdit,
    checkTeam,
    setTeams,
    clearTeams,
    toggleStaff,
    deleteStaff,
    refreshTeam,
    editTeam,
    toggleEditOrganization,
    editOrganizationModal,
    history,
    deleteOrganization,
    currentUser,
    toggleAdminDelete,
    toggleMakeAdmin,
  } = props;

  const [state, setState] = useState({
    Role: roleToEdit,
  });
  const intl = useIntl();

  const [stateSearch, setStateSearch] = useState({
    SelectedUsers: [],
    UI: {
      IsLoading: false,
      Search: "",
    },
  });

  const [teamForm, setTeamForm] = useState(false);
  const [inviteForm, setInviteForm] = useState(false);
  const [nonAppUser, setNonAppUser] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [showMove, setShowMove] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dataAllTeam, setDataAllTeam] = useState();
  const [SelectedItems, setSelectedItems] = useState([]);

  const [showShareInvite, setShowShareInvite] = useState(false);
  const [showAddUsersToHotels, setShowAddUsersToHotels] = useState(false);

  const organization = getOrganization().GUID;
  const basicInfo = getBasicInfo().GUID;
  const events = useSelector((state) => state.calendar.calendarEvents);

  const [allowedEvents, setAllowEvents] = useState(
    teams[currentTeam]?.EventsAllowed || []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvents(organization, basicInfo));
  }, []);

  useEffect(() => {
    const sortData = [];
    const sortDataNonApp = [];
    sortData.push(
      teams
        .filter((x) => x.Name !== "Non-App User")
        .sort((a, b) => a.Name.localeCompare(b.Name))
        .sort((a) => {
          if (a.Name === "Admin") return -1;
          if (a.Name) return 1;
        })
    );
    sortDataNonApp.push(teams.filter((x) => x.Name === "Non-App User"));
    sortData[0].push(sortDataNonApp[0][0]);
    setDataAllTeam(sortData[0]);
  }, [teams]);

  const toggleShareInviteLink = () => {
    setShowShareInvite(!showShareInvite);
  };

  const toggleAddEdit = (reload) => {
    clearTeams();
    setTeamForm((teamForm) => !teamForm);
    if (reload) {
      props.refreshTeam();
    }
  };
  const toggleShowMove = (item, reload) => {
    setSelectedUser(item);
    setShowMove(!showMove);
    if (reload) {
      props.refreshTeam();
    }
  };
  let teamAll = [];
  for (const cont in teams) {
    let item = teams[cont].Staffs;
    for (const conter in item) {
      let item1 = item[conter];
      teamAll.push(item1);
    }
  }

  const loadUsersUI = (role) => {
    try {
      if (
        role?.Account.Name.toLowerCase().includes(stateSearch.UI.Search) ||
        role?.Account.Email.toLowerCase().includes(stateSearch.UI.Search) ||
        role?.Account.LastName.toLowerCase().includes(stateSearch.UI.Search)
      ) {
        return (
          <UserItem
            key={role.GUID}
            user={role}
            toggleStaff={toggleStaff}
            deleteStaff={deleteStaff}
            toggleAdminDelete={toggleAdminDelete}
            toggleMakeAdmin={toggleMakeAdmin}
            currentOrganization={currentOrganization}
            toggleShowMove={toggleShowMove}
            setDataAllTeam={setDataAllTeam}
            currentTeam={currentTeam}
          />
        );
      } else {
        let filter = [];
        if (stateSearch.UI.Search) {
          let search = stateSearch.UI.Search.toLowerCase();
          filter = teamAll?.filter(
            (x) =>
              x.Account.Name.toLowerCase().includes(search) ||
              (x.Account.LastName &&
                x.Account.LastName.toLowerCase().includes(search))
          );
        }
        return filter.map((item) => (
          <UserItem
            key={item.GUID}
            user={item}
            toggleStaff={toggleStaff}
            deleteStaff={deleteStaff}
            toggleAdminDelete={toggleAdminDelete}
            toggleMakeAdmin={toggleMakeAdmin}
            currentOrganization={currentOrganization}
            toggleShowMove={toggleShowMove}
            setDataAllTeam={setDataAllTeam}
            currentTeam={currentTeam}
          />
        ));
      }
    } catch (ex) {
      console.error(ex);
      debugger;
      return <>Error</>;
    }
  };

  const eventsAllowed = dataAllTeam
    ? dataAllTeam[currentTeam]?.EventsAllowed
    : [];

  const [eventsSelected, setEventsSelected] = useState(eventsAllowed);

  const handleSelectEvent = (event) => {
    setEventsSelected((prev) => {
      if (prev.includes(event)) {
        const newState = [...prev];
        let index = newState.indexOf(event);
        newState.splice(index, 1);
        return newState;
      }
      return [...prev, event];
    });
  };

  const handleVisibleEventsSubmit = async (events) => {
    const url = "/Roles/AddEdit";

    let EventsAllowed = events.SelectedEvents.map((event) => {
      return event.GUID;
    });

    try {
      await api.post(url, {
        guid: teams[currentTeam]?.GUID,
        EventsAllowed: JSON.stringify(EventsAllowed),
      });
      ToastNotificationSuccess(
        Math.random(),
        "Allowed Events updated successfully."
      );
      setExportModal((exportModal) => !exportModal);
    } catch (error) {
      ToastNotificationError(
        Math.random(),
        "There was an error, please try again."
      );
      setExportModal((exportModal) => !exportModal);
    }
  };

  const { isChecked } = useBetaFeature();

  let dropDownMenu =
    currentOrganization.CreatorGUID === currentUser.GUID
      ? [
          {
            name: (
              <div className="d-flex align-items-center">
                <span>
                  <FormattedMessage id="tags" defaultMessage="Tags" />
                </span>
                <span className="badge badge-info ml-2 p-1">Beta</span>
              </div>
            ),
            border: true,
            trigger: () => console.log("tags"),
          },
          {
            name: (
              <FormattedMessage
                id="editOrganization"
                defaultMessage="Edit Organization"
              />
            ),
            trigger: () => toggleEditOrganization(),
            border: true,
          },
          {
            name: "Delete Organization",
            trigger: () => deleteOrganization(currentOrganization),
            delete: true,
          },
        ]
      : [
          {
            name: (
              <div className="d-flex align-items-center">
                <span>
                  <FormattedMessage id="tags" defaultMessage="Tags" />
                </span>
                <span className="badge badge-info ml-2 p-1">Beta</span>
              </div>
            ),
            border: true,
            trigger: () => console.log("tags"),
          },
          {
            name: (
              <FormattedMessage
                id="editOrganization"
                defaultMessage="Edit Organization"
              />
            ),
            trigger: () => toggleEditOrganization(),
            border: true,
          },
        ];

  !isChecked && delete dropDownMenu[0];

  const [user, setUser] = useState({});
  const [isDrag, setIsDrag] = useState(false);

  const handleDrag = (e, user) => {
    console.log({ event: e, user });
    setIsDrag(true);
    setUser(user);
  };

  const handleDragEnd = () => {
    setIsDrag(false);
    setUser({});
  };

  const handleDragDrop = (role) => {
    console.log({ role, user });
    Swal.fire({
      text: `Are you sure you want to move ${user?.Account?.Name || "Name"} ${
        user?.Account?.LastName || "Last Name"
      } to ${role?.Name || "Team"}?`,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        move(role);
      }
    });
  };
  const move = async (role) => {
    try {
      let query = "UserGUID=" + user.GUID + "&RoleGUID=" + role.GUID;
      //Add the can view

      const request = await api.get("/User/MoveToGroup?" + query);

      if (request.data.Status === "ok") {
        //Saving locally
        refreshTeam();
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleInviteUserBy = () => {
    Swal.fire({
      title: "How would you like to invite members?",
      input: "select",
      inputOptions: {
        link: "Generate Invite Link",
        manual: "Advanced Invite",
      },
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value === "link") {
        toggleShareInviteLink();
      } else if (result.value === "manual") {
        setInviteForm((inviteForm) => !inviteForm);
      }
    });
  };

  const cleanedMembers =
    dataAllTeam?.length > 0 &&
    dataAllTeam[currentTeam]?.Staffs &&
    dataAllTeam[currentTeam]?.Staffs.length > 0 &&
    cleanDelete(dataAllTeam[currentTeam]?.Staffs).sort((a, b) =>
      a.Account.Name.localeCompare(b.Account.Name)
    );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const [showNonAppUser, setShowNonAppUser] = useState(false);

  const toggleNonAppUser = () => {
    setShowNonAppUser(!showNonAppUser);
  };

  const selectAll = () => {
    // let SelectedItemsState = JSON.parse(JSON.stringify(SelectedItems));
    // let filter = cleanDelete(dataAllTeam[currentTeam]?.Staffs).sort((a, b) =>
    //   a.Account.Name.localeCompare(b.Account.Name)
    // );
    // let checkSelection = checkSelectionStatus(filter, null, SelectedItemsState);
    // if (checkSelection === 0) {
    //   //We don't have anything,proceed to select all
    //   filter.forEach((item) => {
    //     if (!exist(SelectedItemsState, item)) {
    //       SelectedItemsState.push(item);
    //     }
    //   });
    //   setSelectedItems((prev) => ({ ...prev, SelectedItemsState }));
    // } else if (checkSelection === 1) {
    //   //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
    //   setSelectedItems((prev) => ({ ...prev, SelectedItems: [] }));
    // } else {
    //   //is mixed selected the ones missed
    //   filter.forEach((item) => {
    //     if (!exist(SelectedItemsState, item)) {
    //       SelectedItemsState.push(item);
    //     } else {
    //       //Check if deleted
    //     }
    //   });
    //   setSelectedItems((prev) => ({ ...prev, SelectedItems }));
    // }
  };

  const handleDeleteBatch = async () => {
    Swal.fire({
      title: "Are you sure you want to delete the selected users?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        SelectedItems.forEach(async (item) => {
          if (item.AccountGUID === currentOrganization?.CreatorGUID) {
            ToastNotificationError(
              Math.random(),
              "You cannot delete the creator of the organization"
            );
            return;
          }
          dispatch(actions.deleteStaff(item, () => {}));
        });
      }
    });
  };

  const toggleAddUsersToHotels = () => {
    setShowAddUsersToHotels(!showAddUsersToHotels);
  };

  useEffect(() => {
    setSelectedItems([]);
  }, [currentTeam]);

  const handleSelectedItems = (item) => {
    let SelectedItemsState = JSON.parse(JSON.stringify(SelectedItems));
    let index = SelectedItemsState.findIndex((x) => x.GUID === item.GUID);
    if (index === -1) {
      SelectedItemsState.push(item);
    } else {
      SelectedItemsState.splice(index, 1);
    }
    setSelectedItems(SelectedItemsState);
  };

  return (
    <>
      <ModalComponent
        toggle={toggleAddUsersToHotels}
        modal={showAddUsersToHotels}
        childeren={
          <HotelsToMultipleEventsModal
            originEvent={events}
            selectedItem={SelectedItems}
            handleSelectedItems={handleSelectedItems}
            currentOrganization={currentOrganization}
            currentTeam={currentTeam}
            cleanUsers={
              dataAllTeam?.length > 0 &&
              dataAllTeam[currentTeam]?.Staffs &&
              dataAllTeam[currentTeam]?.Staffs.length > 0
                ? cleanDelete(dataAllTeam[currentTeam]?.Staffs).sort((a, b) =>
                    a.Account.Name.localeCompare(b.Account.Name)
                  )
                : []
            }
            toggle={toggleAddUsersToHotels}
            section="hotel"
          />
        }
      />

      <ModalComponent
        toggle={toggleShareInviteLink}
        modal={showShareInvite}
        childeren={
          <ShareInviteLink
            selectedUser={selectedUser}
            toggle={toggleShareInviteLink}
          />
        }
      />
      <ModalComponent
        toggle={toggleShowMove}
        modal={showMove}
        childeren={
          <MoveUserToTeam selectedUser={selectedUser} toggle={toggleShowMove} />
        }
      />

      {currentOrganization.Roles && (
        <ModalComponent
          toggle={toggleAddEdit}
          modal={teamForm}
          childeren={
            <CreateTeamForm
              editTeam={editTeam}
              roleToEdit={state.Role}
              loading={loading}
              rolesToSee={rolesToSee}
              currentOrganization={currentOrganization}
              createTeam={createTeam}
              teams={currentOrganization.Roles}
              toggleTeam={toggleTeam}
              toggle={toggleAddEdit}
              checkTeam={checkTeam}
              setTeams={setTeams}
              clearTeams={clearTeams}
            />
          }
        />
      )}
      <ModalComponent
        modal={editOrganizationModal}
        toggle={() => toggleEditOrganization()}
        childeren={
          <EditOrganizationModal
            history={history}
            toggle={() => toggleEditOrganization()}
          />
        }
      />
      <ModalComponent
        toggle={() => {
          cleanInvites();
          setInviteForm((inviteForm) => !inviteForm);
        }}
        modal={inviteForm}
        childeren={
          <InviteUserFrom
            inviteUsers={inviteUsers}
            addAccount={addAccount}
            emailToInvite={emailToInvite}
            cleanInvites={cleanInvites}
            SelectedTeam={currentTeam ? dataAllTeam[currentTeam]?.Name : ""}
            teams={dataAllTeam}
            loading={loading}
            toggle={() => setInviteForm((inviteForm) => !inviteForm)}
            refreshTeam={refreshTeam}
          />
        }
      />
      <ModalComponent
        toggle={() => setNonAppUser((nonAppUser) => !nonAppUser)}
        modal={nonAppUser}
        childeren={
          <NonAppuser
            toggle={() => setNonAppUser((nonAppUser) => !nonAppUser)}
          />
        }
      />
      <ModalComponent
        toggle={() => setExportModal((exportModal) => !exportModal)}
        modal={exportModal}
        childeren={
          <ExportModalComponent
            title={"Visible Events"}
            selectedTeam={currentTeam && dataAllTeam[currentTeam]}
            eventsSelected={eventsSelected}
            submit={handleVisibleEventsSubmit}
            team={true}
            toggle={() => setExportModal((exportModal) => !exportModal)}
            events={events}
            toggleEvent={handleSelectEvent}
          />
        }
      />

      <ModalComponent
        toggle={toggleNonAppUser}
        modal={showNonAppUser}
        childeren={
          <NonAppUserModal
            toggle={toggleNonAppUser}
            currentOrganization={currentOrganization}
            toggleStaff={toggleStaff}
            deleteStaff={deleteStaff}
            toggleAdminDelete={toggleAdminDelete}
            toggleMakeAdmin={toggleMakeAdmin}
            toggleShowMove={toggleShowMove}
            setDataAllTeam={setDataAllTeam}
            currentTeam={currentTeam}
          />
        }
      />

      <div className="main-container">
        <div className="main-with-header">
          <div className="page-header d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <SandwichButton
                expanded={props.expanded}
                showExpanded={props.showExpanded}
              />
              <div className="main-text px-0 d-flex align-items-center">
                <button
                  className="btn btn-primary btn-sm "
                  onClick={() => toggleEditOrganization()}
                >
                  <i className="fas fa-pen"></i>
                </button>
                <h1 className="ml-2">
                  {currentOrganization.Name}{" "}
                  <FormattedMessage id="team" defaultMessage="Teams" />
                </h1>
              </div>
            </div>
            <div className="text-center page-header-logo">
              <img
                src={IMAGES.LOGO_BLACK} 
                alt="Plnify"
                style={{ width: "75px" }}
              />
            </div>
            <div className="d-flex">
              <div className="">
                {!isMobile && (
                  <div className="formAlternativeStyle">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "search" })}
                      value={stateSearch.UI.Search}
                      onChange={(e) => {
                        setStateSearch({
                          ...stateSearch,
                          UI: {
                            ...stateSearch.UI,
                            Search: e.target.value.toLowerCase(),
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <button
                className="btn btn-secondary mx-2 btn-sm border-0"
                onClick={() => toggleAddUsersToHotels()}
              >
                <FormattedMessage
                  id="copyToAnotherEvent"
                  defaultMessage="Add Users to Hotels"
                />
              </button>
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={toggleNonAppUser}
              >
                <FormattedMessage
                  id="nonAppUsers"
                  defaultMessage="Non-App User"
                />
              </button>
              <button className="btn btn-primary btn-sm">
                <DropDownComponent
                  cog
                  color={"#fff"}
                  direction={"left"}
                  data={dropDownMenu}
                />
              </button>
            </div>
          </div>
          <div className="main-first-container">
            <div className="row">
              <div className="col-md-3 px-2 br-6 ">
                <div className="list">
                  <div className="list-header">
                    <div className="main-text">
                      <h3>
                        <FormattedMessage id="team" defaultMessage="Teams" />
                      </h3>
                    </div>
                    <>
                      {loading ? (
                        <SimpleLoader loading={loading} />
                      ) : (
                        <button
                          onClick={() => {
                            let parsedRoleToEdit = {
                              GUID: uuid.v4(),
                              OrganizationGUID: currentOrganization.GUID,
                              AllowToReceiveNotifications: true,
                              Deleted: null,
                              SystemGroup: false,
                            };
                            setState((prev) => ({
                              ...prev,
                              Role: parsedRoleToEdit,
                            }));
                            setTeamForm((teamForm) => !teamForm);
                          }}
                          className="btn btn-primary btn-sm"
                        >
                          <FontAwesomeIcon icon={faPlus} color="white" />
                          {!isMobile && (
                            <span className="ml-2">Create Team</span>
                          )}
                        </button>
                      )}
                    </>
                  </div>
                  <div className="list-content">
                    <Scrollbars
                      style={{ width: "100%", height: "calc(95vh - 138px)" }}
                    >
                      {dataAllTeam?.length > 0 &&
                        dataAllTeam?.map((team, index) => (
                          <TeamItem
                            toggleNotifications={toggleNotifications}
                            setCurrentTeam={setCurrentTeam}
                            key={`${team?.GUID + team?.OrganizationGUID}`}
                            index={index}
                            currentTeam={currentTeam}
                            team={team}
                            deleteTeam={deleteTeam}
                            editRole={editRole}
                            handleInviteUserBy={handleInviteUserBy}
                            handleDragDrop={handleDragDrop}
                            toggleExportModal={() =>
                              setExportModal((exportModal) => !exportModal)
                            }
                            toggleModalTeam={() => {
                              setState((prev) => ({
                                ...prev,
                                Role: team,
                              }));
                              setTeamForm((teamForm) => !teamForm);
                            }}
                          />
                        ))}
                    </Scrollbars>
                  </div>
                </div>
              </div>
              <div className="col-md-9 px-2 br-6 ">
                <div className="list">
                  <div className="list-header">
                    <div className="main-text">
                      <div className="d-flex col-auto px-0 py-1">
                        <button
                          className="btn btn-sm text-secondary"
                          onClick={() => selectAll()}
                          type="button"
                        >
                          {SelectedItems.length === 0 ? (
                            <i className="far fa-square"></i>
                          ) : checkSelectionStatus(
                              SelectedItems,
                              null,
                              JSON.parse(JSON.stringify([]))
                            ) === 1 ? (
                            <i className="fas fa-check-square"></i>
                          ) : (
                            <i className="fas fa-minus-square"></i>
                          )}
                        </button>

                        <h3>
                          <FormattedMessage
                            id="members"
                            defaultMessage="Members: "
                          />
                          <span>{cleanedMembers?.length || 0}</span>
                        </h3>
                      </div>
                    </div>

                    <div className="d-flex ml-4">
                      {loading ? <SimpleLoader loading={loading} /> : null}

                      <button
                        className="btn btn-primary mx-2"
                        onClick={handleInviteUserBy}
                        title="Invite Members"
                      >
                        <FontAwesomeIcon icon={faPlus} color="white" />
                        {!isMobile && (
                          <span className="ml-2">Invite Members</span>
                        )}
                      </button>
                      {/* <button
                        className="btn btn-secondary btn-sm mx-2"
                        onClick={() => {
                          toggleShareInviteLink();
                        }}
                      >
                        Generate Invite Link
                      </button>
                      <button
                        disabled={loading}
                        onClick={() =>
                          setInviteForm((inviteForm) => !inviteForm)
                        }
                        className="btn btn-primary btn-sm"
                      >
                        <FormattedMessage
                          id="inviteTeamMembers"
                          defaultMessage="Invite Team Members"
                        />
                      </button> */}
                    </div>
                  </div>
                  {SelectedItems.length > 0 && (
                    <div className="col-12 bg-subOption text-dark text-center py-2">
                      {/* <button
                        className="btn btn-secondary mx-2 btn-sm"
                        onClick={() => {}}
                      >
                        <FormattedMessage
                          id="moveToAnotherEvent"
                          defaultMessage="Move to another event"
                        />
                      </button> */}
                      {/* <button
                        className="btn btn-secondary mx-2 btn-sm"
                        onClick={() => toggleAddUsersToHotels()}
                      >
                        <FormattedMessage
                          id="copyToAnotherEvent"
                          defaultMessage="Add Users to Hotels"
                        />
                      </button> */}
                      <button
                        className="btn btn-danger mx-2 btn-sm"
                        onClick={() => handleDeleteBatch()}
                      >
                        <FormattedMessage
                          id="deleteSelected"
                          defaultMessage="Delete Selected"
                        />
                      </button>
                    </div>
                  )}
                  <div className="list-content">
                    <Scrollbars
                      style={{ width: "100%", height: "calc(100vh - 180px)" }}
                    >
                      {dataAllTeam?.length > 0 &&
                      dataAllTeam[currentTeam]?.Staffs &&
                      dataAllTeam[currentTeam]?.Staffs.length > 0 ? (
                        cleanDelete(dataAllTeam[currentTeam]?.Staffs)
                          .sort((a, b) =>
                            a.Account.Name.localeCompare(b.Account.Name)
                          )
                          .map((role, index) => {
                            try {
                              return (
                                <div
                                  className="d-flex px-0 align-items-center clickable border"
                                  key={role.AccountGUID}
                                  // draggable
                                >
                                  <span
                                    draggable
                                    onDragStart={(e) => handleDrag(e, role)}
                                    onDragEnd={handleDragEnd}
                                    onDragOver={(e) => e.preventDefault()}
                                    className="draggable border-right d-flex align-items-center p-2"
                                  >
                                    <i class="fa fa-grip-vertical"></i>
                                  </span>
                                  <div
                                    onClick={() => {
                                      setSelectedItems((prev) => {
                                        let SelectedItems = JSON.parse(
                                          JSON.stringify(prev)
                                        );
                                        let index = SelectedItems.findIndex(
                                          (x) => x.GUID === role.GUID
                                        );
                                        if (index === -1) {
                                          SelectedItems.push(role);
                                        } else {
                                          SelectedItems.splice(index, 1);
                                        }
                                        return SelectedItems;
                                      });
                                    }}
                                    className="d-flex align-items-center pl-2 mx-0 selectorContainer"
                                  >
                                    {checkSelectionStatus(
                                      SelectedItems,
                                      null,
                                      JSON.parse(JSON.stringify([role]))
                                    ) === 0 ? (
                                      <i className="far fa-square"></i>
                                    ) : (
                                      <i className="fas fa-check-square"></i>
                                    )}
                                  </div>

                                  <div className="w-100 px-0 mx-0 selectorContainer">
                                    {loadUsersUI(role)}
                                  </div>
                                </div>
                              );
                            } catch (ex) {
                              console.error(ex);
                              debugger;
                            }
                          })
                      ) : (
                        <div className="row h-100 d-flex align-items-center justify-content-center">
                          <img
                            src={EMPTY_IMG}
                            className="emptyStateStyle mt-0"
                            alt=""
                          />
                        </div>
                      )}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
