import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import api, { apiService } from "../../../common/api";
import { Loading } from "../../../components/common/loading";
import { TodoAccordion } from "../../../components/todo/TodoAccordion";
import { IMAGES } from "../../../constant";
import {
  DropDownComponent,
  ModalComponent,
  ToastNotificationSuccess,
} from "../../../components";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TodoComment } from "../../../components/todo/TodoComment";
import * as TodoActions from "../../todoPage/redux/actions";
import {
  getAccessToken,
  getBasicInfo,
  getOrganization,
  logoutUser,
} from "../../../utils";
import db from "../../../common/db";
import moment from "moment";
import { VenueFullAddress } from "../../../common/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal, Table } from "reactstrap";
import { TaskViewTable } from "../../../components/todo/TaskViewTable";
import SuccessPopup from "../../authentication/forgotPassword/successPopup";
import ForgotPasswordContainer from "../../authentication/forgotPassword/forgotPasswordContainer";
import { TodoViewLogin } from "../../todoPage/todo/TodoViewLogin";
import shareApi from "../../../common/shareApi";
import uuid from "uuid";
import SharingHub from "../../../components/common/sharingHub";
import { TodosView } from "./views/TodosView";
import { data } from "jquery";
import { Event } from "../../../components/event/event";
import { GuestListView } from "./views/GuestListView";

const initialValue = {
  SectionType: "",
  sectionGUID: "",
  SectionDeleted: null,
  SharedByAccountGUID: "",
  event: {},
  isLoading: false,
  data: [{}],
  accessToken: "",
};

export const CollabViewContainer = (props) => {
  const location = useLocation();
  const currentWitdh = window.innerWidth;

  const navigation = useHistory();
  let collabId = "";

  const dispatch = useDispatch();
  const [currentEvent, setCurrentEvent] = useState();
  const [state, setState] = useState(initialValue);
  const [comments, setComments] = useState([]);
  const [openComments, setOpenComments] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [loginInfo, setLoginInfo] = useState({
    accessToken: getAccessToken(),
    guest: localStorage.getItem("guestLogin"),
  });

  const [loggedIn, setLoggedIn] = useState(true);
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    if (!loginInfo?.guest && !loginInfo?.accessToken) {
      setLoggedIn(false);
    }
  }, [loginInfo]);

  const getEvent = async () => {
    if (currentEvent === undefined) return;
    const request = await apiService.get("/Events/Details", {
      params: { GUID: currentEvent },
    });
    const event = request.data.response;
    setState((prev) => ({ ...prev, event }));
  };

  const getCollabData = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    collabId = location.pathname.replace("/c/", "");
    const collabData = await shareApi.get(`collab/Details?Id=${collabId}`);
    const {
      Data,
      SectionType,
      SectionGUID,
      SharedByAccountGUID,
      Deleted,
      Section,
    } = await collabData?.data?.response;

    setState((prev) => ({
      ...prev,
      SectionType,
      SectionGUID,
      SectionDeleted: Deleted,
      SharedByAccountGUID,
      data:
        SectionType === "todo"
          ? Data?.filter((data) => data.Deleted === null)?.[0]
          : Data,
      isLoading: false,
    }));

    const eventGUID = Data?.[0]?.EventGUID || SectionGUID;

    setCurrentEvent(eventGUID);
  };

  const getCurrentUser = () => {
    const logInUser = getBasicInfo();
    const guestUser = JSON.parse(localStorage.getItem("guestLogin"));
    setCurrentUser({
      firstName: logInUser?.Name || guestUser?.firstName,
      lastName: logInUser?.LastName || guestUser?.lastName,
      email: logInUser?.Email || guestUser?.Email,
      GUID: logInUser?.GUID,
      guestGUID: guestUser?.GUID,
      type: logInUser?.GUID ? "user" : "guest",
    });
  };

  const getComments = async () => {
    const shareRequest = await shareApi.get(
      `collab/ListComments?CollabGUID=${collabId}`
    );
    const comments = (await shareRequest?.data?.Response) || [];

    setComments(comments.sort((a, b) => new Date(b.Date) - new Date(a.Date)));
  };

  useEffect(() => {
    getEvent();
    getCollabData();
    getCurrentUser();
    getComments();
  }, [currentEvent, loginInfo]);

  const Note = state?.data?.Note;

  const [noteState, setNoteState] = useState({ show: false, Note });
  const [isOver, setIsOver] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const handleAddDescription = () => {
    if (!loggedIn) return;
    setNoteState((prev) => ({
      ...prev,
      show: true,
    }));
  };

  const handleOpen = () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`);
      return;
    }
    setOpenComments((prev) => !prev);
  };

  const colors = ["#FAD7A0", "#D2B4DE", "#AED6F1", "#F8C471", "#E74C3C"];
  const randomColor = colors[Math.floor(Math.random() * colors?.length)];

  const handleAddComment = async () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`);
      return;
    }
    if (!commentValue) return;

    const { firstName, lastName, GUID, guestGUID } = currentUser;

    const thePost = {
      GUID: uuid.v4(),
      CollabGUID: location.pathname.replace("/c/", ""),
      Date: new Date(),
      Deleted: null,
      Comment: commentValue,
      AccountGUID: GUID,
      GuestGUID: guestGUID,
    };

    try {
      const addedComment = await shareApi.post(
        "Collab/AddEditComment",
        thePost
      );

      setComments((prev) => {
        const currentState = [...prev];
        const dateTime = new Date();
        currentState.unshift({
          FirstName: firstName,
          LastName: lastName,
          Comment: commentValue,
          Date: dateTime,
        });

        ToastNotificationSuccess(1, "Comment added succesfully");
        setCommentValue("");

        return currentState;
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const subTitleComplete = () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`);
      return;
    }
    let type = state?.event?.EventType;
    let subTitle = `${moment(state?.event?.Date)?.format("ddd")} | ${
      type ? type + " | " : ""
    }`;

    if (state?.event?.Venue && state?.event?.Venue?.Name) {
      subTitle += VenueFullAddress(state?.event?.Venue);
    } else {
      subTitle +=
        `${state?.event?.City ? state?.event?.City + ", " : ""} ${
          state?.event?.State ? state?.event?.State + ", " : ""
        } ${state?.event?.Country ? state?.event?.Country : ""}` +
        " " +
        moment(state?.event?.dateTime).format("MMM DD, YYYY");
      // (props.weather ? " | " + props.weather.TimeZoneCode : null)
    }
    return subTitle;
  };

  const copyToClipboard = () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`);
      return;
    }
    navigator.clipboard.writeText(
      `${window.location.protocol}//${
        window.location.host
      }/c/${location.pathname.replace("/c/", "")}`
    );
    ToastNotificationSuccess(1, `Link copy to clipboard`);
    handleShareModal();
  };

  const handleClose = () => {
    const organization = getOrganization();
    organization ? navigation.push("/todo") : navigation.push("/");
  };

  const handleLogOut = () => {
    localStorage.removeItem("guestLogin");
    navigation.push("/");
  };

  const handleShareModal = () => {
    setShowShare((prev) => !prev);
  };

  const getCurrentView = () => {
    if (state?.SectionDeleted)
      return (
        <div>
          Currently you don't have accessed to this section, please contact your
          administrator.
        </div>
      );

    switch (state.SectionType) {
      case "todo":
        return (
          <TodosView
            loggedIn={loggedIn}
            TodoActions={TodoActions}
            currentWitdh={currentWitdh}
            state={state}
            setComments={setComments}
            setParentState={setState}
          />
        );
      case "guestList":
        return (
          <GuestListView
            loggedIn={loggedIn}
            currentWitdh={currentWitdh}
            state={state}
            setParentState={setState}
            event={currentEvent}
            CollabGUID={location.pathname.replace("/c/", "")}
            user={currentUser}
            getCollabData={getCollabData}
          />
        );
    }
  };

  return state.isLoading ? (
    <Loading />
  ) : (
    <>
      <ModalComponent
        modal={showShare}
        toggle={() => handleShareModal()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            // Item={""}
            toggle={() => handleShareModal()}
            // ParentSectionGUID={"guid"}
            // Type="ATTACHMENT"
            // sectionGUID={"attachment.GUID"}

            Type="SHARECOLLAB"
            shareApi={{
              GUID: uuid.v4(),
              SectionType: state?.SectionType,
              SectionGUID: state?.sectionGUID, //example id of the todo
              SharedByAccountGUID: getBasicInfo()?.GUID, // id of the user who shared this
              OrganizationGUID: getOrganization()?.GUID, //id of the organization
              ParentSectionGUID: null, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 0, // 0 = readonly ; 1= read and write
              link: location.pathname.replace("/c/", ""),
              user: getBasicInfo(),
              data: state.dataToShare,
            }}
            copyToClipBoard={copyToClipboard}
          />
        }
      />
      <TodoViewLogin
        isLoggedIn={loggedIn}
        setLoginInfo={setLoginInfo}
        setLoggedIn={setLoggedIn}
      />
      <div className="collab-container">
        <div className="d-flex flex-column border bg-white rounded shadow justify-content-between align-items-center collab-content-container">
          <section className="list-header px-lg-5">
            {/* <div className="lh-base">
            <h5 className="font-weight-bold mb-1">{state?.event?.Name}</h5>
            <p className="lh-base">
              {`${state?.event?.EventType?? "Random"} | ${state?.event?.Name} | ${new Date(state?.event?.Date)?.toLocaleDateString( "us-EN", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}`}
            </p>
          </div> */}

            <div className="d-flex w-75 overflow-hidden">
              {/* <div className="icon d-inline-block align-self-center">
                <img src={IMAGES?.CALENDER} alt="calendar" width="43" />
                <span
                  title={
                    state?.event?.EventGroup && state?.event?.EventGroup?.Name
                  }
                  style={{
                    backgroundColor:
                      state?.event?.EventGroup &&
                      state?.event?.EventGroup?.Color,
                  }}
                ></span>
                <div
                  className="calendar-inside"
                  style={{
                    zIndex: 11,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <h3 className="">
                    {state?.event?.Date === null
                      ? "-"
                      : moment(state?.event?.Date).format("MMM")}
                  </h3>
                  <p>
                    {state?.event?.Date === null
                      ? "-"
                      : moment(state?.event?.Date).format("DD")}
                  </p>
                </div>
              </div>
              <div className="ml-2 px-0 d-flex flex-column justify-content-center">
                {!state?.event?.Name ? (
                  <h4 className="font-weight-bold">{"To-Do's"}</h4>
                ) : (
                  <>
                    <Link
                      className="clean-link font-weight-bold"
                      style={{ fontSize: "16px" }}
                      to={`/calendar/${state?.event?.GUID}`}
                    >
                      {state?.event?.Name}
                    </Link>

                    <p className="text-truncate" title={subTitleComplete()}>
                      {subTitleComplete()}
                    </p>
                  </>
                )}
              </div> */}
              <Event event={state?.event} noIcons={true} noClickable />
            </div>

            <div className="d-flex justify-content-end align-items-center">
              {currentWitdh > 1000 && (
                <button
                  type="button"
                  className="btn btn-danger d-flex border-0 mr-2"
                  onClick={handleLogOut}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 " />
                  <span>Sign out</span>
                </button>
              )}
              <DropDownComponent
                className="float-right mr-1 btn btn-link"
                data={
                  currentWitdh < 1000
                    ? [
                        {
                          name: (
                            <>
                              <i className="fa fa-share mr-2"></i>
                              <FormattedMessage
                                id="share"
                                defaultMessage="Share"
                              />
                            </>
                          ),
                          border: false,
                          trigger: () => handleShareModal(),
                        },
                        {
                          name: (
                            <>
                              <FontAwesomeIcon
                                icon={faSignOutAlt}
                                className="mr-2"
                              />
                              <FormattedMessage
                                id="signOut"
                                defaultMessage="Sign Out"
                              />
                            </>
                          ),
                          border: false,
                          trigger: () => handleClose(),
                        },
                      ]
                    : [
                        {
                          name: (
                            <>
                              <i className="fa fa-share mr-2"></i>
                              <FormattedMessage
                                id="share"
                                defaultMessage="Share"
                              />
                            </>
                          ),
                          border: false,
                          trigger: () => handleShareModal(),
                        },
                      ]
                }
              />

              <button
                type="button"
                className="btn btn-sm btn-link"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
          </section>

          <section className="overflow-auto p-2 p-lg-5 w-100 h-100">
            {getCurrentView()}
          </section>

          <section
            className={`w-100 d-flex border align-items-center justify-content-between p-1 px-lg-5 py-lg-4 ${
              currentWitdh < 1000 ? "position-fixed" : "position-relative"
            }`}
            style={
              currentWitdh < 1000
                ? {
                    height: "70px",
                    bottom: 0,
                    background: "white",
                    zIndex: 100,
                  }
                : { height: "70px" }
            }
          >
            <div
              className={`w-100 collab-comments ${
                openComments && "open overflow-auto"
              } position-absolute`}
            >
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>
                    {!openComments ? "See all comments" : "See less comments"}
                  </Tooltip>
                }
              >
                <div
                  onClick={handleOpen}
                  className="collab-comments-btn bg-info shadow-sm rounded position-absolute"
                ></div>
              </OverlayTrigger>
              <div className="p-1 px-lg-5 mt-2 w-100 h-100">
                {!comments.length > 0 ? (
                  <div className="p-4 d-flex flex-column align-items-center h-100 justify-content-center">
                    <i
                      className="fa fa-comment-alt text-secondary"
                      style={{ fontSize: "25px" }}
                    ></i>
                    <span>No comments added yet</span>
                  </div>
                ) : openComments ? (
                  comments?.map((comment, index) => (
                    <TodoComment key={index} {...comment} />
                  ))
                ) : (
                  <>
                    {comments.length > 1 && (
                      <a
                        onClick={handleOpen}
                        className="text-primary"
                      >{`See all ${comments.length} comments`}</a>
                    )}
                    <TodoComment {...comments?.[0]} />
                  </>
                )}
              </div>
            </div>
            <div className="bg-secondary d-flex align-items-center justify-content-center rounded p-2">
              <span
                className="text-uppercase text-white"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                {currentUser?.firstName?.[0]}
                {currentUser?.lastName?.[0]}
              </span>
            </div>
            <textarea
              onChange={(e) => setCommentValue(e.target.value)}
              className="form-control mx-3"
              style={{ resize: "none" }}
              placeholder="Add a comment"
              value={commentValue}
            ></textarea>
            {/* <div
            className="d-flex justify-content-around"
            style={{ width: "100px" }}
          >
            <i className="fa fa-paperclip"></i>
          </div> */}
            <button
              className="btn btn-primary p-2"
              style={{ width: "15%" }}
              onClick={() => handleAddComment()}
            >
              {currentWitdh < 1000 ? (
                <i
                  className="fa fa-send font-weight-bold"
                  style={{ fontSize: "16px" }}
                ></i>
              ) : (
                "Send"
              )}
              <div className="react-chat-SendIcon"></div>
            </button>
          </section>
        </div>
      </div>
    </>
  );
};
