import VenueDetail from "../../../components/event/eventDetail/VenueDetail";
import FlightDetails from "../../../components/flight/flightDetails/flightDetails";
import GroundDetail from "../../../components/ground/groundDetails/groundDetail";
import HotelDetail from "../../../components/hotel/hotelDetail";
import ActivitiesList from "../../../components/itineraries/activitiesList";
import AttachmentDetail from "../../../components/attachments/AttachmentDetail";
import ContactsDetail from "../../../components/contacts/contactDetail";

export const renderSidebarItem = (currentSideBarItem, props) => {
  let {
    editVenue,
    deleteImage,
    event,
    venue,
    addActivity,
    editActivity,
    refreshSchedules,
    clearSchedules,
    weather,
    toggleVenueModal,
    deleteVenue,
    eventsList,
  } = props;

  switch (currentSideBarItem) {
    case "EVENT_DETAILS":
      return (
        <VenueDetail
          weather={weather}
          event={event}
          venue={venue}
          editVenue={editVenue}
          deleteImage={deleteImage}
          toggleVenueModal={toggleVenueModal}
          deleteVenue={deleteVenue}
          eventsList={eventsList}
        />
      );
    case "FLIGHT_LIST":
      return <FlightDetails {...props} />;
    case "ACTIVITIES":
      return (
        <ActivitiesList
          sideBarItemData={props.sideBarItemData}
          scheduleMaster={props.sideBarItemData}
          deleteActivity={props.deleteActivity}
          handleSidebarItem={props.handleSidebarItem}
          event={event}
          addActivity={addActivity}
          editActivity={editActivity}
          refreshSchedules={refreshSchedules}
          clearSchedules={clearSchedules}
          loading={props.loading}
        />
      );
    case "HOTEL":
      return (
        <HotelDetail
          removeAllReservations={props.removeAllReservations}
          handleSidebarItem={props.handleSidebarItem}
          hotel={props.sideBarItemData}
          {...props}
        />
      );
    case "GROUND":
      return <GroundDetail {...props} />;
    case "CONTACTS":
      return (
        <ContactsDetail
          deleteContact={props.deleteContact}
          contact={props.sideBarItemData}
          contacts={props.contacts}
          handleSidebarItem={props.handleSidebarItem}
          {...props}
        />
      );
    case "ATTACHMENT":
      return (
        <AttachmentDetail
          attachments={props.attachments}
          handleSidebarItem={props.handleSidebarItem}
          deleteAttachment={props.deleteAttachment}
          attachment={props.sideBarItemData}
          {...props}
        />
      );

    default:
      return (
        <VenueDetail
          weather={weather}
          event={event}
          venue={venue}
          editVenue={editVenue}
          deleteImage={deleteImage}
          toggleVenueModal={toggleVenueModal}
          deleteVenue={deleteVenue}
        />
      );
  }
};
