import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import { googleApi } from "../../constant/googleApi";
import { useGooglePicker } from "../../hooks/useGooglePicker";
import * as actions from "../../page/event/redux/actions";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import ExportModalComponent from "../common/EmailExport/exportModalComponent";
import Attachments from "./attachments";

const AttachmentsContainers = (props) => {
  const addingAttachment = useSelector((state) => state.event.addingAttachment);
  const event = useSelector((state) => state.event.event);
  const dispatch = useDispatch();

  const developerKey =
    process.env.REACT_APP_GOOGLE_DEV_KEY || googleApi.GOOGLE_DEV_KEY;
  const cliendId =
    process.env.REACT_APP_GOOGLE_CLIENT_ID || googleApi.GOOGLE_CLIENT_ID;

  const { handleOpenGooglePicker } = useGooglePicker(
    cliendId,
    developerKey,
    handleGoogleDrive
  );

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   (async () => {
  //     await getOneDriveToken();
  //   })();
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const [state, setState] = useState({
    modalMove: false,
    modalExport: false,
    eventsSelected: [],
  });

  const toggleMove = () => {
    setState((prev) => ({
      ...prev,
      modalMove: !state.modalMove,
    }));
  };

  const toggleExport = () => {
    setState((prev) => ({
      ...prev,
      modalExport: !state.modalExport,
    }));
  };

  const toggleEvent = (event) => {
    let events = state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return setState((prev) => ({
        ...prev,
        eventsSelected: filtered,
      }));
    } else {
      let newOnes = events.concat(event);
      return setState((prev) => ({
        ...prev,
        eventsSelected: newOnes,
      }));
    }
  };

  const copyToAnotherEvent = () => {
    const { sideBarItemData } = props;
    let events = state.eventsSelected.map((event) => event.GUID);
    dispatch(
      actions.exportAttachments(sideBarItemData, events, false, () => {
        toggleExport();
        ToastNotificationSuccess(1, "Attachment succesfully exported");
      })
    );
  };

  const moveToAnotherEvent = () => {
    const { sideBarItemData } = props;
    let events = state.eventsSelected.map((event) => event.GUID);
    dispatch(
      actions.exportAttachments(sideBarItemData, events, true, () => {
        dispatch(actions.getAttachmentsEvent(props.event.GUID));
        toggleMove();
        ToastNotificationSuccess(1, "Attachment succesfully moved");
      })
    );
  };

  const onChange = (e) => {
    let files = e?.target?.files || e;
    try {
      if (files.length) {
        const arrFiles = Array.from(files);
        arrFiles.map((file, index) => {
          window.URL.createObjectURL(file);
          let attachment = {
            name: file.name,
            note: "",
            event: event.GUID,
            file: file,
          };

          dispatch(
            actions.addAttachment(
              event.GUID,
              attachment,
              () =>
                ToastNotificationSuccess(1, "Attachment succesfully created"),
              () => dispatch(actions.getAttachmentsEvent(event.GUID))
            )
          );
        });
      }
    } catch (error) {
      Swal.fire("Oops...", "Something went wrong!", "error");
    }
  };

  const addFromDropbox = (attachments) => {
    if (!attachments) return;
    const { event } = props;
    console.log({ attachments, event });
    attachments?.forEach((attachment) => {
      dispatch(
        actions.addAttachmentDropbox(
          event,
          attachment,
          () => ToastNotificationSuccess(1, "Attachment succesfully imported"),
          () => dispatch(actions.getAttachmentsEvent(event))
        )
      );
    });
  };

  const confirmDeleteAttachment = (attachment) => {
    const { handleSidebarItem, event, attachments } = props;
    Swal.fire({
      text: "Are you sure you want to delete this attachment?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteAttachment(attachment, () => {
            ToastNotificationSuccess(1, "Attachment deleted");
            handleSidebarItem(
              attachments[1] ? "ATTACHMENT" : "EVENT_DETAILS",
              attachments[1] ? attachments[1] : {}
            );
            dispatch(actions.getAttachmentsEvent(event))
          })
        );
      }
    });
  };

  

  function handleGoogleDrive(data) {
    if (data.action === "cancel") {
      return console.log("User clicked cancel/close button");
    }
    addFromDropbox(data.docs);
  }

  function handleAddLink(data) {
    data.EventGUID = event.GUID;

    addFromDropbox([data]);
  }

  function handleOneDrive(data) {
    addFromDropbox(data);
  }

  const {
    attachments,
    handleSidebarItem,
    sideBarItemData,
    loading,
    eventsSelected,
    toggleModal,
  } = props;

  return (
    <>
      <ModalComponent
        toggle={toggleMove}
        modal={state.modalMove}
        childeren={
          <ExportModalComponent
            title={
              <FormattedMessage
                id="moveToAnotherEvent"
                defaultMessage="Move to another event"
              />
            }
            loading={loading}
            toggle={toggleMove}
            toggleEvent={toggleEvent}
            eventsSelected={eventsSelected}
            submit={moveToAnotherEvent}
          />
        }
      />
      <ModalComponent
        toggle={toggleExport}
        modal={state.modalExport}
        childeren={
          <ExportModalComponent
            title={
              <FormattedMessage
                id="copyToAnotherEvent"
                defaultMessage="Copy to another event"
              />
            }
            loading={loading}
            toggle={toggleExport}
            toggleEvent={toggleEvent}
            eventsSelected={eventsSelected}
            submit={copyToAnotherEvent}
          />
        }
      />
      <Attachments
        blockName={props.blockName}
        handleSidebarItem={handleSidebarItem}
        onChange={onChange}
        toggle={toggleModal}
        attachments={attachments}
        addFromDropbox={addFromDropbox}
        sideBarItemData={sideBarItemData}
        confirmDeleteAttachment={confirmDeleteAttachment}
        addingAttachment={addingAttachment}
        toggleMove={toggleMove}
        toggleExport={toggleExport}
        originEvent={props.event}
        actions={actions}
        handleOpenGooglePicker={handleOpenGooglePicker}
        handleOneDrive={handleOneDrive}
        handleAddLink={handleAddLink}
      />
    </>
  );
};

export default AttachmentsContainers;
