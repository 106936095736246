import { faAngleUp, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatListChecks } from "mdi-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Progress, Table } from "reactstrap";
import Swal from "sweetalert2";
import uuid from "uuid";
import shareApi from "../../common/shareApi";
import { IMAGES } from "../../constant";
import * as actions from "../../page/todoPage/redux/actions";
import { cleanDelete, getBasicInfo, getOrganization } from "../../utils";
import {
  DropDownComponent,
  ModalComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../common";
import SharingHub from "../common/sharingHub";
import AddList from "./AddList";
import AddTask from "./AddTask";
import Assignees from "./Assignees";
import { TaskTable } from "./TaskTable";
import { apiService } from "../../common/api";

function compareDates(a, b) {
  const dateA = new Date(a.Date);
  const dateB = new Date(b.Date);
  return dateB - dateA;
}

const Todo = (props) => {
  const intl = useIntl();
  let {
    toggleAddList,
    addListModal,
    toggleAddTask,
    addTaskModal,
    events,
    todos,
    setCurrentList,
    currentList,
    addList,
    deleteList,
    teams,
    options,
    loading,
    addTask,
    toggleTask,
    deleteTask,
    toggleAssigneeModal,
    memberModals,
    editList,
    editListModal,
    setEditList,
    editListFunc,
    //setEditTask,
    toggleTaskEdit,
    taskEditModal,
    todoSelected,
    taskEdit,
    editTaskFunc,
    selectTodo,
    setSelectTodo,
  } = props;

  const [todosInState, setTodoInState] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [todoComments, setTodoComments] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const [linkIds, setLinkIds] = useState([]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   // props.selectList(todos[0]);
  //   // setSelectTodo(todos[0]);
  // }, []);

  useEffect(() => {
    setTodoInState(todos);
    if (isActive === "" && todosInState.length > 0)
      setIsActive({
        active: true,
        item: todos?.[0]?.GUID,
      });
  }, [todos]);

  function getPercentageDone(tasks) {
    if (!tasks) {
      let percentages = {
        Complete: 0,
        Incomplete: 0,
      };
      return percentages;
    }
    const tasksTotal = cleanDelete(tasks);
    if (tasksTotal) {
      const tasksDone = tasksTotal.filter((t) => t.Status).length;
      const total = (tasksDone / tasksTotal.length) * 100;
      let percentages = {
        Complete: isNaN(total) ? 0 : Math.round(total),
        Incomplete: isNaN(total) ? 100 : 100 - Math.round(total),
      };
      return percentages;
    }
  }

  const handleSaveTask = (task, isEditable, isNew) => {
    task = JSON.parse(
      JSON.stringify({
        ...task,
        DueDate: new Date(`${task.DueDate}T${task.time}`),
      })
    );
    if (task.Name === "")
      return ToastNotificationError(
        Math.random(),
        "Please add at least the task name before saving."
      );
    setEditMode(false);

    let Assignees = "";
    if (!task?.Assignees) {
      Assignees = "";
    } else {
      switch (typeof task?.Assignees) {
        case "object":
          Assignees = JSON.stringify([
            {
              GUID: task?.Assignees?.GUID,
              Email: task?.Assignees?.Email,
              LastName: task?.Assignees?.LastName,
              Name: task?.Assignees?.Name,
            },
          ]);
          break;
        case "string":
          if (task?.Assignees?.length > 0) {
            Assignees = task?.Assignees;
          } else {
            Assignees = "";
          }
          break;
        default:
          break;
      }
    }

    const taskToSave = {
      ...task,
      Assignees,
      isEditable: false,
      isNew: false,
      TodoGUID: currentList?.GUID,
    };

    delete taskToSave.isEditable;
    delete taskToSave.isNew;

    if (isEditable && !isNew) {
      editTask(taskToSave);
    } else {
      addTask(taskToSave, false);
    }
  };

  const editTask = (task) => {
    const user = getBasicInfo();
    if (task.DueDate) {
      const date = moment(task.DueDate).format("YYYY-MM-DD");
      const time = moment(task.DueDate).format("HH:mm");
      task.Date = moment(date + "T" + time).toDate();
    } else {
      task.Date = undefined;
    }

    dispatch(
      actions.editTask(task, () => {
        ToastNotificationSuccess(Math.random(), "Task updated successfully");
        dispatch(actions.getListsOfTodo(currentList.GUID, user.GUID, true));
        // toggleTaskEdit(null);
      })
    );
  };

  const HandleAddTask = (todo) => {
    let currentEditableItem = todosInState?.filter(
      (task) => task.GUID === isActive.item
    );

    currentEditableItem = currentEditableItem?.[0]?.TodoTasks?.filter(
      (todo) => todo.isEditable === true
    );

    if (currentEditableItem?.length > 0) {
      return ToastNotificationError(
        Math.random(),
        "Must finish editing current task before performing this action."
      );
    }

    const newItem = {
      Created: null,
      Date: "",
      Deleted: null,
      GUID: uuid.v4(),
      TodoGUID: todo.GUID || "",
      DueDate: null,
      Date: new Date(),
      Time: new Date(),
      Name: "",
      Note: "",
      ParentTaskGUID: "",
      Priority: 0,
      Status: 0,
      Assignees: null,
      isEditable: true,
      isNew: true,
    };

    setTodoInState((prev) => {
      const newState = prev.map((todoInState) => {
        if (todoInState.GUID === todo.GUID) {
          let taksInTodo = todoInState.TodoTasks;
          if (taksInTodo === undefined) {
            taksInTodo = [{ ...newItem, TodoGUID: todo.GUID }];
          } else {
            taksInTodo.unshift({ ...newItem, TodoGUID: todo.GUID });
          }
          return {
            ...todoInState,
            TodoTasks: taksInTodo,
          };
        } else {
          return todoInState;
        }
      });
      return newState;
    });
  };

  const handleCancelTask = (task) => {
    setTodoInState((prev) => {
      const newState = prev.map((todoInState) => {
        if (todoInState.GUID === task.TodoGUID) {
          let taksInTodo = todoInState.TodoTasks;

          taksInTodo.forEach((todo, index) => {
            if (task?.isNew && todo.GUID === task.GUID) {
              taksInTodo.splice(index, 1);
            } else if (todo.GUID === task.GUID) {
              todo.isEditable = false;
            }
          });

          return {
            ...todoInState,
            TodoTasks: taksInTodo,
          };
        } else {
          return todoInState;
        }
      });
      return newState;
    });
  };

  const handleEditTask = (task) => {
    let currentEditableItem = todosInState.filter(
      (task) => task.GUID === isActive.item
    );

    currentEditableItem = currentEditableItem?.[0]?.TodoTasks.filter(
      (todo) => todo.isEditable === true
    );

    if (currentEditableItem?.length > 0) {
      return ToastNotificationError(
        Math.random(),
        "Must finish editing current task before performing this action."
      );
    }
    const updatedState = todosInState.map((item, index) => {
      if (item.GUID === task.TodoGUID) {
        const editedTasks = item.TodoTasks.map((currentTask) => {
          if (currentTask.GUID === task.GUID)
            return { ...currentTask, isEditable: true };
          return currentTask;
        });
        return { ...item, TodoTasks: editedTasks };
      }
      return item;
    });
    setTodoInState(updatedState);
  };

  const [showComplete, setShowComplete] = useState(true);
  const handleCompleteShow = () => {
    setShowComplete((prev) => !prev);
  };

  const [showShare, setShowShare] = useState({ show: false, item: "" });
  const [selectedLink, setSelectedLink] = useState(null);

  const handleShareModal = (item) => {
    setShowShare((prev) => ({ show: !prev.showShared, item }));
  };

  const handleShare = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let innerSelectedLink = null;
    let permission = 0;

    const linksArray = linkIds || [];

    // const response = await shareApi.get(
    //   `collab/links?SectionId=${currentList.GUID}`
    // );

    // const links = await response.data;

    // const parsedLinks = typeof links;
    // console.log({ parsedLinks, links, response });

    const shareLink = async () => {
      if (!navigator.canShare) return handleShareModal();

      const { response } = !innerSelectedLink
        ? (
            await shareApi.post("collab/GenerateLink", {
              GUID: uuid.v4(),
              SectionType: "todo",
              SectionGUID: currentList.GUID, //example id of the todo
              SharedByAccountGUID: currentUser, // id of the user who shared this
              OrganizationGUID: currentOrganization, //id of the organization
              ParentSectionGUID: null, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 1, // 0 = readonly ; 1= read and write
            })
          ).data
        : { response: { GUID: innerSelectedLink } };

      navigator.canShare();

      const shareData = {
        title: "Plnify",
        text: "Check your pending task.",
        url: `${window.location.protocol}//${window.location.host}/c/${response.GUID}`,
      };

      try {
        await navigator.share(shareData);
        // ToastNotificationSuccess(1,"Link shared successfully")
      } catch (err) {
        ToastNotificationSuccess(2, `Error: ${err}`);
      }
    };

    Swal.fire({
      title: "Select a Link to Share",
      input: "select",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      inputOptions: {
        NewLink: "Create New Link",
        ...linkIds,
      },
      inputPlaceholder: "Select a link",
      confirmButtonText: "Create",
      showCancelButton: true,
      showDenyButton: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve(
              "You need to select a link or select the create new one option."
            );
          }
        });
      },
    }).then((result) => {
      if (result.isDismissed) return;
      if (result.value !== "NewLink") {
        setSelectedLink(() => {
          innerSelectedLink = linksArray[result.value];
          return innerSelectedLink;
        });
      }

      shareLink();
    });
  };

  const copyToClipboard = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let permission = 0;

    const { response } = selectedLink
      ? { response: { GUID: selectedLink } }
      : (
          await shareApi.post("collab/GenerateLink", {
            GUID: uuid.v4(),
            SectionType: "todo",
            SectionGUID: currentList.GUID, //example id of the todo
            SharedByAccountGUID: currentUser, // id of the user who shared this
            OrganizationGUID: currentOrganization, //id of the organization
            ParentSectionGUID: null, //sent it null
            Deleted: null, //sent it null
            Updated: null, //sent it null
            Created: null, //sent it null
            PermissionType: permission, // 0 = readonly ; 1= read and write
          })
        ).data;
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/c/${response.GUID}`
    );
    setShowShare((prev) => !prev);
    ToastNotificationSuccess(1, `Link copy to clipboard`);
  };

  // const handleEditMainTask = (edtiTodo) => {
  //   console.log({ edtiTodo });

  //   setTodoInState((prev) => {
  //     const newState = prev.map((todo) => {
  //       if (todo.GUID === edtiTodo.GUID)
  //         return { ...todo, Name: "Name To be Added" };
  //       return todo;
  //     });

  //     return newState;
  //   });
  // };

  useEffect(() => {
    (async () => {
      const url = "collab/listcommentsbysectionid";
      const todoGUID = sectionId ?? currentList?.GUID;

      if (todoGUID) {
        try {
          const response = await apiService.get(url + `?SectionId=${todoGUID}`);
          const comments = (await response.data.Response) || [];
          const linkIds = comments.map((comment) => {
            return comment.CollabGUID;
          });

          setTodoComments(comments);
          setLinkIds([...new Set(linkIds)]);
        } catch (error) {
          console.log(error);
          setTodoComments([]);
          setLinkIds(null);
        }
      }
    })();
  }, [currentList, sectionId]);

  return (
    <>
      <ModalComponent
        modal={showShare.show}
        toggle={() => handleShareModal()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            // Item={""}
            toggle={() => handleShareModal()}
            // ParentSectionGUID={"guid"}
            // Type="ATTACHMENT"
            // sectionGUID={"attachment.GUID"}
            Type="SHARECOLLAB"
            shareApi={{
              GUID: uuid.v4(),
              SectionType: "todo",
              SectionGUID: currentList?.GUID, //example id of the todo
              SharedByAccountGUID: getBasicInfo()?.GUID, // id of the user who shared this
              OrganizationGUID: getOrganization()?.GUID, //id of the organization
              ParentSectionGUID: null, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 0, // 0 = readonly ; 1= read and write
            }}
            copyToClipBoard={copyToClipboard}
          />
        }
      />
      <ModalComponent
        toggle={toggleAddTask}
        modal={addTaskModal}
        childeren={
          <AddTask
            loading={loading}
            addTask={addTask}
            currentList={currentList}
            toggle={toggleAddTask}
          />
        }
      />

      <ModalComponent
        toggle={() => toggleTaskEdit()}
        modal={taskEditModal}
        childeren={
          <AddTask
            task={todoSelected}
            edit={taskEdit}
            loading={loading}
            addTask={addTask}
            currentList={currentList}
            editTask={editTaskFunc}
            toggle={() => toggleTaskEdit()}
          />
        }
      />
      <ModalComponent
        toggle={() => setEditList()}
        modal={editListModal}
        childeren={
          <AddList
            currentList={currentList}
            edit={editList}
            loading={loading}
            options={options}
            events={events}
            addList={addList}
            teams={teams}
            editList={editListFunc}
            toggle={() => setEditList()}
            fromEvent={props.fromEventDetails}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleAddList()}
        modal={addListModal}
        childeren={
          <AddList
            loading={loading}
            options={options}
            events={events}
            addList={addList}
            teams={teams}
            toggle={() => toggleAddList()}
            fromEvent={props.fromEventDetails}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleAssigneeModal()}
        modal={memberModals}
        childeren={
          <Assignees
            setCurrentList={setCurrentList}
            currentList={currentList}
            toggle={() => toggleAssigneeModal()}
          />
        }
      />
      <div
        className={
          (props.fromEventDetails ? "toDoFromEvent " : "") +
          "main-first-container"
        }
      >
        <div
          className={
            (props.fromEventDetails ? "px-0 " : "px-2 ") + "col-md-12 br-6"
          }
        >
          <div className="list">
            <div
              className="list-content overflow-auto"
              style={{ height: "calc(100vh - 60px)" }}
            >
              {todosInState?.length === 0 ? (
                <>
                  <div className="flex-1 h-50 d-flex align-items-center justify-content-center">
                    <img
                      src={IMAGES.TODO_EMPTYSTATE}
                      className="emptyStateStyleToDo"
                    />
                  </div>
                  <br />
                  <div className="flex-1 h-50 d-flex flex-column align-items-center justify-content-center">
                    <h6 className="text-muted">
                      {props.fromEventDetails ? (
                        "No To-Do's tasks added yet"
                      ) : (
                        <FormattedMessage
                          id="noTo-DoAddedYet"
                          defaultMessage="No To-Do's added yet"
                        />
                      )}
                    </h6>

                    <button
                      className="btn btn-primary mt-2"
                      onClick={() => toggleAddList()}
                    >
                      Add To-Do
                    </button>
                  </div>
                </>
              ) : (
                <Container>
                  <Row>
                    <Col>
                      <Accordion defaultActiveKey={0}>
                        {todosInState?.map((item, index) => {
                          const pendingItems = item?.TodoTasks?.filter(
                            (element) => element.Deleted === null
                          )?.filter((todo) => todo.Status === 0);

                          const completedItems = item?.TodoTasks?.filter(
                            (element) => element.Deleted === null
                          )?.filter((todo) => todo.Status === 1);

                          if (
                            pendingItems?.length === 0 &&
                            completedItems?.length === 0
                          ) {
                            HandleAddTask(item);
                          }

                          return (
                            <Accordion.Item key={item.GUID} eventKey={index}>
                              <div
                                className="d-flex align-items-center justify-content-between w-100 border-bottom-1 my-2 px-4 py-2 rounded"
                                style={{ backgroundColor: "#E5E8EB" }}
                                onClick={(e) => {
                                  props?.selectList(item);
                                  setSelectTodo(item);
                                  typeof setCurrentList === "function" &&
                                    props?.setCurrentList(item);
                                }}
                              >
                                {/* {item.isEditable ? (
                                  <input
                                    name="itemName"
                                    placeholder="Task Name"
                                    value={item.Name}
                                    // onChange={handleChange}
                                    className="border-0 subTitle form-control"
                                  />
                                ) : ( */}
                                <span className="w-50 font-weight-bold">
                                  {item.Name}
                                </span>
                                {/* )} */}
                                {item && (
                                  <div
                                    className="d-flex align-items-center justify-content-end"
                                    style={{ width: "300px" }}
                                  >
                                    <div className="w-100">
                                      <Progress
                                        className="bg-white"
                                        value={
                                          item &&
                                          getPercentageDone(item.TodoTasks)
                                            .Complete
                                        }
                                      />
                                    </div>
                                    <span className="ml-2 w-25">
                                      {item &&
                                        getPercentageDone(item.TodoTasks)
                                          .Complete}{" "}
                                      %
                                    </span>
                                  </div>
                                )}

                                {item && (
                                  <div className="d-flex align-items-center">
                                    <button
                                      className="ml-2 btn btn-secondary btn-sm"
                                      onClick={() => handleShare(item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faShareAlt}
                                        color="white"
                                      />
                                    </button>

                                    {props.fromEventDetails ? (
                                      <a
                                        title="To-Do Section"
                                        href="/todo"
                                        className="btn btn-sm eventHeader__todo  ml-1 relative"
                                      >
                                        <FormatListChecks htmlColor="#fff" />
                                      </a>
                                    ) : null}
                                    <button
                                      disabled={!item}
                                      onClick={() => HandleAddTask(item)}
                                      className="ml-2 btn btn-primary btn-sm "
                                      style={{ width: "75px" }}
                                    >
                                      <FormattedMessage
                                        id="addTask"
                                        defaultMessage="Add Task"
                                      />
                                    </button>
                                  </div>
                                )}

                                {item && (
                                  <div>
                                    <button className="teamItem__more btn-reset">
                                      <DropDownComponent
                                        data={[
                                          {
                                            name: (
                                              <FormattedMessage
                                                id="Edit"
                                                defaultMessage="Edit"
                                              />
                                            ),
                                            border: true,
                                            trigger: () => {
                                              // handleEditMainTask(selectTodo);
                                              return setEditList(selectTodo);
                                            },
                                          },
                                          {
                                            name: (
                                              <FormattedMessage
                                                id="delete"
                                                defaultMessage="Delete"
                                              />
                                            ),
                                            trigger: () =>
                                              deleteList(selectTodo),
                                            delete: true,
                                          },
                                        ]}
                                      />
                                    </button>
                                  </div>
                                )}
                                <Accordion.Header
                                  onClick={() =>
                                    setIsActive((prev) => {
                                      return {
                                        active:
                                          prev.item === item.GUID
                                            ? !prev.active
                                            : true,
                                        item: item.GUID,
                                      };
                                    })
                                  }
                                >
                                  <div
                                    className={
                                      isActive.active &&
                                      isActive.item === item.GUID
                                        ? ""
                                        : "isActiveMenu"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faAngleUp}
                                      color="black"
                                      width={"50px"}
                                      height={"50px"}
                                      style={{ fontSize: "20px" }}
                                    />
                                  </div>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body>
                                {completedItems?.length === 0 &&
                                pendingItems?.length === 0 ? (
                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img
                                      src={IMAGES.TODO_EMPTYSTATE}
                                      className="w-25 h-25"
                                      alt="No Items Image"
                                    />
                                    <span className="text-center m-4">
                                      <h6 className="text-muted">
                                        <FormattedMessage
                                          id="noTo-DoAddedYet"
                                          defaultMessage="No Task added yet"
                                        />
                                      </h6>
                                    </span>
                                  </div>
                                ) : !showComplete &&
                                  pendingItems?.length < 1 ? (
                                  <div className="alert alert-info text-center font-weight-bold">
                                    No Pending Tasks
                                  </div>
                                ) : (
                                  pendingItems?.length > 0 && (
                                    <Table>
                                      <thead>
                                        <tr className="list-subheader">
                                          <th>
                                            <FormattedMessage
                                              id="incomplete"
                                              defaultMessage="Complete"
                                            />
                                          </th>
                                          <th>Title</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>Assignees</th>
                                          {/* <th>Location</th> */}
                                          <th>Priority</th>
                                          <th className="text-center">
                                            Actions
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {pendingItems?.map((todo) => (
                                          <TaskTable
                                            key={todo.GUID}
                                            todo={todo}
                                            toggleTask={toggleTask}
                                            deleteTask={deleteTask}
                                            toggleTaskEdit={toggleTaskEdit}
                                            handleCancelTask={handleCancelTask}
                                            handleSaveTask={handleSaveTask}
                                            handleEditTask={handleEditTask}
                                            handleShareModal={handleShareModal}
                                            editMode={editMode}
                                            isLoading={loading}
                                          />
                                        ))}
                                      </tbody>
                                    </Table>
                                  )
                                )}

                                {showComplete && completedItems?.length > 0 && (
                                  <Table>
                                    <thead>
                                      <tr className="list-subheader">
                                        <th>
                                          <FormattedMessage
                                            id="completed"
                                            defaultMessage="Complete"
                                          />
                                        </th>
                                        <th>Title</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Assignees</th>
                                        {/* <th>Location</th> */}
                                        <th>Priority</th>
                                        <th className="text-center">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {completedItems?.map((todo) => (
                                        <TaskTable
                                          key={todo.GUID}
                                          todo={todo}
                                          toggleTask={toggleTask}
                                          deleteTask={deleteTask}
                                          toggleTaskEdit={toggleTaskEdit}
                                        />
                                      ))}
                                    </tbody>
                                  </Table>
                                )}
                                {completedItems?.length > 0 && (
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{ width: "100%" }}
                                  >
                                    <button
                                      className="btn btn-secondary m-3"
                                      onClick={handleCompleteShow}
                                    >
                                      {showComplete
                                        ? "Hide Complete"
                                        : "Show Complete"}
                                    </button>
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Todo;
