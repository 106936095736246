import axios from 'axios';
import moment from 'moment';
import { db } from './db';

export const APIHeader = {
    "Authorization": localStorage.getItem("ACCESS_TOKEN") === null ? "" : "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
}
const options = {
    headers: APIHeader,
    crossdomain: true
};
axios.interceptors.request.use(config => {
    // log a message before any HTTP request is sent
    console.log("The request ==== ", config);
    return config;
});

const BasePathForAPI = "https://app.plnify.com/api/";
const api = axios.create({
    baseURL: BasePathForAPI,
    headers: null

});
export default {
    api,
    postAction: async (endpoint, params, newOpts) => {
        const newId = moment().unix();
        let newItem = { url: BasePathForAPI + endpoint, params: params, id: newId, method: "post", status: "pending" };
        //Save transaction locally
        await db.apiLog.put(newItem)
        try {
            //Send the information to the server 
            let re = await axios.post(BasePathForAPI + endpoint, params !== null ? params : null, newOpts !== null ? newOpts : options);
            //If we get here, then it means the transaction went just fine, but needs to remove the item from api
            await db.apiLog.delete(newItem.id);

            return re;
        } catch (ex) {
            //Oh oh, something happen! lets mark the record as faulty to check later if we can re-sent same info.
            console.error(ex);
            newItem.status = "error";
            newItem.error = ex;
            await db.apiLog.put(newItem);
            debugger;
        }

    },
    getAction: async (endpoint, params, newOpts, save, table) => {
        const newId = moment().unix();
        let newItem = { url: BasePathForAPI + endpoint, params: params, id: newId, method: "get", status: "pending" };
        //Save transaction locally
        await db.apiLog.put(newItem);
        try {
            let request = await axios.get(BasePathForAPI + endpoint + (params !== undefined && params !== null ? "?" + params : ""), newOpts !== undefined && newOpts !== null ? newOpts : options);
            //If we get here, then it means the transaction went just fine, but needs to remove the item from api
            await db.apiLog.delete(newItem.id);
            
            if (save) {
                await db.table(table).put(request.data.response);
            }
        } catch (ex) {
            //Oh oh, something happen! lets mark the record as faulty to check later if we can re-sent same info.
            console.error(ex);
            newItem.status = "error";
            newItem.error = ex;
            await db.apiLog.put(newItem);
            debugger;
        }
    },
    postActionExternal: async (endpoint, params, newOpts) => axios.post(endpoint, params !== null ? params : null, newOpts !== null ? newOpts : options),
    getActionExternal: async (endpoint, newOpts) => {

        let results = axios.get(endpoint, newOpts);

        return results;
    }

}
