import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IMAGES } from "../../../constant/images";
import * as actions from "../redux/actions";
import EventDetail from "./eventDetails";

const EventGeneral = (props) => {
  // const [state, setState] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();

  // actions: bindActionCreators({ ...actions }, dispatch),
  // calendarActions: bindActionCreators({ ...calendarActions }, dispatch),
  // commonActions: bindActionCreators({ ...commonActions }, dispatch),

  // const handleKeyDown = (e, list, onEnter) => {
  //   const { cursor } = state;
  //   if (e.keyCode === 38 && cursor > 0) {
  //     setState((prev) => ({ ...prev, cursor: prev.cursor - 1 }));
  //   } else if (e.keyCode === 40 && cursor < list.length - 1) {
  //     setState((prev) => ({ ...prev, cursor: prev.cursor + 1 }));
  //   }
  //   if (e.keyCode === 13) {
  //     onEnter(cursor);
  //     setState((prev) => ({ ...prev, cursor: 0 }));
  //   }
  // };

  // const setCountryStateValue = (id) => {
  //   const { searchCity } = props;
  //   searchCity[id].city !== state.city &&
  //     setState((prev) => ({ ...prev, venueName: "", venue: "" }));
  //   setState((prev) => ({
  //     ...prev,
  //     city: searchCity[id].city,
  //     country: searchCity[id].country ? searchCity[id].country : "",
  //     countryState: searchCity[id].state ? searchCity[id].state : "",
  //   }));
  //   props.emptySearchCity();
  //   dispatch(actions.editEvent());
  // };

  // const setVenueDetails = (id) => {
  //   const { searchVenue } = props;
  //   setState((prev) => ({
  //     ...prev,
  //     venueName: searchVenue[id].name,
  //     city: searchVenue[id].city ? searchVenue[id].city : searchVenue[id].city,
  //     countryState: searchVenue[id].state
  //       ? searchVenue[id].state
  //       : prev.countryState,
  //     country: searchVenue[id].country ? searchVenue[id].country : prev.country,
  //     venue: searchVenue[id],
  //   }));
  //   (async () => {
  //     props.emptySearchVenueAction();
  //     await dispatch(actions.editEvent());
  //   })();
  // };

  const deleteVenue = (event) => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this event?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(actions.deleteVenue(event));
        Swal.fire("Deleted!", "The venue has been deleted", "success");
      }
    });
  };

  let {
    searchVenue,
    eventTypeList,
    setVenue,
    handleSidebarItem,
    toggleVenueModal,
    venueModal,
  } = props;

  return (
    <EventDetail
      addItinerary={actions.addItinerary}
      setVenue={setVenue}
      blockName={props.blockName}
      handleSidebarItem={handleSidebarItem}
      searchVenue={searchVenue}
      eventTypeList={eventTypeList}
      deleteVenue={deleteVenue}
      toggleVenueModal={toggleVenueModal}
      venueModal={venueModal}
    />
  );
};
export default EventGeneral;
