import React, { useState, useRef, useEffect } from "react";
import { getBasicInfo, parseLogo } from "../../utils";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import { randomImage } from "../common/randomImage";
import api from "../../common/api";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import history from "../common/history";
import { useIntl, FormattedMessage } from "react-intl";
import EditOrganizationModal from "./editOrganization/EditOrganizationModal";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationList } from "../../page/organization/redux/getOrganizationList";
const SelectOrganizationInfo = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [requestAlert, setRequestAlert] = useState({});
  const [editOrganizationModal, setEditOrganizationModal] = useState(false);
  const organization = props.data.Organization;

  let logo =
    organization?.Logo !== undefined && organization?.Logo?.includes("http")
      ? organization?.Logo
      : "https://service.plnify.com/files/" + organization?.Logo;
  logo = logo.replace("http:", "https:");
  let imgPlaceholder = randomImage(organization.Name.charAt(0));
  // useEffect(() => {
  //   ToastNotificationSuccess(1, "Succesfully Updated")
  // }, [requestAlert]);
  const basicInfo = getBasicInfo();
  const deleteOrganization = (item) => {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDelete" }) + item.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        const request = await api.get(
          "/organizations/delete?GUID=" +
            item.GUID +
            "&DeletedBy=" +
            item.account
        );
        if (request.statusText == "OK") {
          ToastNotificationSuccess(1, "Succesfully Updated");
          history.push("/");
        }
      }
    });
  };
  const leaveOrganization = (item) => {
    Swal.fire({
      text: "Are you sure you want to leave'" + item.Organization.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        const request = await api.get(
          "/Organizations/Leave?OrganizationGUID=" +
            item.Organization.GUID +
            "&StaffGUID=" +
            item.Permissions[0].StaffGUID +
            "&AccountGUID=" +
            item.AccountGUID
        );

        if (request.statusText == "OK") {
          ToastNotificationSuccess(1, "Succesfully Updated");
          history.push("/");
        }
      }
    });
  };
  function imgErrorHandler() {
    logo = imgPlaceholder;
  }

  const toggleEditOrganization = () => {
    setEditOrganizationModal(!editOrganizationModal);
  };

  const loadOrganization = async () => {
    dispatch(getOrganizationList(getBasicInfo().GUID, false));
  };

  return (
    <>
      <ModalComponent
        modal={editOrganizationModal}
        toggle={() => toggleEditOrganization()}
        childeren={
          <EditOrganizationModal
            history={history}
            currentOrganization={organization}
            loadOrganization={loadOrganization}
            toggle={() => toggleEditOrganization()}
          />
        }
      />

      <div className="card shadow-sm my-1">
        <div className="card-body p-1 py-2">
          <div className="row">
            <div className="col-auto avatarContainer">
              {organization && organization?.Logo ? (
                <img
                  className="avatar"
                  onError={() => imgErrorHandler()}
                  src={logo}
                  alt={organization.Name}
                />
              ) : (
                <img
                  className="img-fluid"
                  src={`https://dummyimage.com/35x35/8e9bb6/ffffff.png&text=${organization.Name.split(
                    " "
                  )
                    .map((x) => x.charAt(0))
                    .join("")
                    .substr(0, 3)
                    .toUpperCase()}`}
                />
              )}
            </div>
            <div className="col-auto">{organization.Name}</div>

            <div className="col-auto ml-auto">
              {organization?.CreatorGUID === basicInfo?.GUID ? (
                <span className="badge bg-warning">
                  <FormattedMessage id="owner" defaultMessage="Owner" />
                </span>
              ) : props.data.Permissions.length > 0 &&
                props.data.Permissions[0].CanAccessMacApp ? (
                <span className="badge bg-primary text-light">Admin</span>
              ) : !props.data.Active ? (
                <span className="badge bg-secondary text-light">
                  <FormattedMessage id="inactive" defaultMessage="Inactive" />
                </span>
              ) : null}
              <Popup
                trigger={
                  <button type="button" className="btn btn-sm ml-2">
                    <i className="fa fa-ellipsis-v" />
                  </button>
                }
                on="click"
                position="bottom right"
                arrow={false}
              >
                {(open) => (
                  <>
                    <button
                      className="btn btn-block flex-1 mt-1"
                      type="button"
                      onClick={() => {
                        toggleEditOrganization();
                        open();
                      }}
                    >
                      <FormattedMessage
                        id="editOrganization"
                        defaultMessage="Edit Workspace"
                      />
                    </button>
                    {organization.CreatorGUID !== basicInfo.GUID && (
                      <button
                        className="btn btn-block flex-1 mt-1"
                        type="button"
                        onClick={() => leaveOrganization(props.data)}
                      >
                        <FormattedMessage
                          id="leaveWorkspace"
                          defaultMessage="Leave Workspace"
                        />
                      </button>
                    )}
                    {organization.CreatorGUID === basicInfo.GUID && (
                      <button
                        className="btn btn-block flex-1 mt-1 text-danger"
                        type="button"
                        onClick={() => deleteOrganization(organization)}
                      >
                        <FormattedMessage id="delete" defaultMessage="Delete" />
                      </button>
                    )}
                  </>
                )}
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectOrganizationInfo;
