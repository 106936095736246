import React, { useEffect, useState } from "react";
import { Form, Formik, Field, ErrorMessage, useFormik } from "formik";

import { useIntl, FormattedMessage } from "react-intl";
import api from "../../common/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { getBasicInfo } from "../../utils";
import { getCurrentOrganization } from "../../page/organization/redux/getCurrentOrganization";

const UserTag = (props) => {
  const dispatch = useDispatch();
  const handleSubmit = async (selected) => {
    try {
      let query = "UserGUID=" + props.user.GUID + "&Tag=" + selected.tag;
      await api.get("/User/AssignUserTag?" + query);
      
      // dispatch(getCurrentOrganizationLocal(basicInfoGUID));

      props.toggle(true);
    } catch (ex) {
      console.error(ex);
    }
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { tag: props.user.Tag },
    // validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="card shadow-sm my-2">
      <div className="card-body p-1">
        <div className="text-head">
          <div className="text-left d-flex w-100 justify-content-between">
            <h5 className="title">
              <FormattedMessage id="userTag" defaultMessage="User Tag" />
            </h5>

            <button
              type="button"
              className="btn btn-sm btn-link ml-2"
              onClick={() => props.toggle()}
            >
              <FontAwesomeIcon icon={faTimes} color="black" />
            </button>
          </div>
        </div>

        <div className="col-12">
          <div>
            <label className="mb-3 text-body">
              <FormattedMessage id="tag" defaultMessage="Tag" />
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                value={formik.values.tag}
                onChange={(e) => {
                  formik.setFieldValue("tag", e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-12 text-right">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => formik.handleSubmit()}
          >
            <FormattedMessage id="save" defaultMessage="Save" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTag;
