import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import AddContactComponent from "../../page/addContact/addContactComponent";
import * as actions from "../../page/event/redux/actions";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import ExportModalComponent from "../../components/common/ExportToEvent";
import Contacts from "./contacts";

const ContactsContainers = (props) => {
  const [state, setState] = useState({ modal: false, modalExport: false, eventsSelected: [] });
  const intl = useIntl();
  const loading = useSelector((state) => state.event.loading);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setState((prev) => ({
      ...prev,
      modal: !prev.modal,
    }));
  };

  const toggleExport = () => {
    setState((prev) => ({
      ...prev,
      modalExport: !state.modalExport,
    }));
  };

  const toggleEvent = (event) => {
    let events = state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return setState((prev) => ({
        ...prev,
        eventsSelected: filtered,
      }));
    } else {
      let newOnes = events.concat(event);
      return setState((prev) => ({
        ...prev,
        eventsSelected: newOnes,
      }));
    }
  };

  const exportContact = () => {
    const actualContact = props.sideBarItemData;
    const eventsGUID = state.eventsSelected.map((event) => event.GUID);
    dispatch(
      actions.exportContact(actualContact, eventsGUID, () => {
        ToastNotificationSuccess(1, "Contact exported successfully");
        toggleExport();
      })
    );
  };

  const deleteContact = (contact) => {
    Swal.fire({
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      imageAlt: "PLNIFY",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      text: intl.formatMessage({
        id: "Are you sure you want to delete this contact?",
      }),
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteContact(contact, () => {
            ToastNotificationSuccess(1, "Contact deleted successfully");
            props.handleSidebarItem("EVENT_DETAILS", {});
          })
        );
      }
    });
  };

  const { contacts, addContact, event, handleSidebarItem, sideBarItemData } = props;
  const { eventsSelected } = state;


  console.log("Contacts was render")
  return (
    <>
      <ModalComponent
        toggle={toggleExport}
        modal={state.modalExport}
        childeren={
          <ExportModalComponent
            loading={loading}
            toggle={toggleExport}
            toggleEvent={toggleEvent}
            eventsSelected={eventsSelected}
            submit={exportContact}
          />
        }
      />
      <ModalComponent
        toggle={toggleModal}
        modal={state.modal}
        childeren={
          <AddContactComponent
            event={event}
            addContact={addContact}
            toggle={toggleModal}
            loading={loading}
            handleSidebarItem={handleSidebarItem}
          />
        }
      />
      <Contacts
        blockName={props.blockName}
        handleSidebarItem={handleSidebarItem}
        contacts={contacts}
        toggleModal={toggleModal}
        sideBarItemData={sideBarItemData}
        deleteContact={deleteContact}
        toggleExport={toggleExport}
        originEvent={event}
        actions={props.actions}
      />
    </>
  );
};

export default ContactsContainers;
