import React, { useEffect, useState } from "react";
import api from "../../common/api";
import { getBasicInfo } from "../../utils";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import db from "../../common/db";
import { IMAGES } from "../../constant";

const MailSideBar = (props) => {
  const MenuOptions = [
    // {
    //   name: "Inbox",
    //   id: "inbox",
    //   icon: "fas fa-inbox",
    //   action: () => {},
    // },
    {
      name: "New Email",
      id: "newEmail",
      icon: "fas fa-plus fa-plus_none",
      action: () => {
        props?.toggleMailingList();
        props?.selectedEmail({
          Message: "",
          Title: "",
          Receivers: "",
        });
        props?.setCurrentEmail({ id: "", section: "" });
      },
    },
    {
      name: "Sent",
      id: "sentItems",
      icon: "fas fa-paper-plane",
      action: () => {
        props?.toggleMailHistory();
      },
    },
    {
      name: "Draft",
      id: "draft",
      records: props?.draftEmails?.Records?.drafts?.length || 0,
      icon: "fas fa-pen",
      action: () => {
        props?.toggleMailDraft();
      },
    },
    {
      name: "Trash",
      id: "deletedItems",
      icon: "fas fa-trash",
      records: props?.draftEmails?.Records?.trash?.length || 0,
      action: () => {
        props?.toggleMailDeleted();
      },
    },
    {
      name: "Mailing List",
      id: "mailList",
      icon: "fas fa-list",

      action: () => {
        props?.toggleMailingList();
      },
    },
  ];

  return (
    <div
      className={props.className ? props.className : "organization-transparent"}
    >
      <div className="text-head headerBg p-1 border-right">
        <div className="row align-items-center justify-content-between">
          <div
            className="col-auto d-flex align-items-center"
            style={{ padding: "5px" }}
          >
            <img
              src={IMAGES.LOGO_BLACK}
              alt="plnify logo"
              className="mr-1"
              height={30}
            />
            <h1>
              <FormattedMessage id="email" defaultMessage="Emails" />
            </h1>
          </div>
          <div className="ml-5 text-right">
            <button
              className="btn btn-secondary border-0"
              onClick={() => props.setShowSideBar((prev) => !prev)}
            >
              <i
                className={
                  !props.currentView
                    ? "fas fa-angle-right"
                    : "fas fa-angle-left"
                }
              ></i>
            </button>
          </div>
        </div>
      </div>

      <div
        className="event-inside px-1"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 60px)",
        }}
      >
        <div className="event-content px-1">
          {MenuOptions.map((menu) => {
            return (
              <div
                key={menu.id}
                className="card shadow-sm my-2 clickable overflow-hidden border"
                onClick={() => {
                  props.setCurrentView(menu.id);
                  menu.action();
                }}
              >
                <div
                  className={
                    props.currentView === menu.id
                      ? "card-body p-1 btn-primary"
                      : "card-body p-1 text-dark"
                  }
                >
                  <div className="row px-2">
                    <div className="d-flex text-truncate justify-content-between w-100">
                      <div>
                        <i className={`${menu.icon} mr-1`}></i>
                        <b>{menu.name}</b>
                      </div>
                      {menu.records && <span>{`(${menu.records})`}</span>}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MailSideBar;
